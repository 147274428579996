import React from "react";
// import { Launcher } from "react-chat-window";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import ip from "../../constants/ip";
import { find_conversation, load_messages, send_message, send_files } from "../../logic/chat_logic";

import { withAlert } from 'react-alert'
import { withTranslation } from "react-i18next";
import { set_conversation } from "../../actions/chat_actions";
import { store } from "../../store";
import { init_chat, loadScript, LoadCSS } from "../../logic/init_logic";


const styles = (theme) => ({

});

class ChatWidget extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messages : [
                {
                    author: 'them',
                    type: 'text',
                    data: {
                        text: 'The chat is the easiest way to get in touch with the advertiser. Get in touch now!',
                    }
                }
            ],
            has_chat : false,
        }
    }

    componentDidMount(){
    }

    componentWillMount(){
        const { conversations } = this.props;
        LoadCSS("/css/custom_chat.css"); // Was added wihtout testing but should be fine
        if(conversations !== undefined){
            this.init_chat_widget(conversations);
        }
    }

    componentWillReceiveProps(nextprops){
        if(this.props.conversations === undefined && typeof nextprops.conversations !== "undefined"){
            this.init_chat_widget( nextprops.conversations );
        }
    }

    format_to_popup_format = (messages) => {
        const { u_id } = this.props;
        return JSON.parse(JSON.stringify(messages)).sort(function(a,b){
            return a.message_id > b.message_id
        }).map( msg => {
            let type = msg.file_path ? "file" : "text";
            return {
                author: (msg.sender_id === u_id) ? 'me' : "them",
                type: type,
                data: {
                    text: msg.text,
                    url : msg.file_path,
                    fileName : msg.file_name
                }
            }
        });
    } 

    on_submit = async( msg ) => {
        const { conversations, t } = this.props;
        const text = msg.data.text;

        // let message_response = send_message(this.props.item.item_id , text, t);
        let message_response = await send_message( 
            this.props.item.item_id, 
            { text : text },
            t
        );


        // if(message_response.success){
        //     if(message_response.created_conversation){
        //         // await init_chat(); // Replace for a simple add to state instead
        //         // store.dispatch( set_conversation( find_conversation(conversations, message_response.created_conversation.conversation_id) ) );
        //     }
        // }
    }

    init_chat_widget = (conversations) => {
        const { t, item, active_conversation, u_id } = this.props;
        
        // let conversation = undefined;
        let jwt = localStorage.getItem('jwt');

        //Look through your conversations and try to find a conversation that has the same item_id, the problem with this is
        // There may be multiple of theese, that is why we need the user id from the database
        // conversations.forEach( (convo, i) => {
        //     if( convo.item_id === item.item_id){
        //         conversation = convo;
        //         return;
        //     }
        // })

        // conversations.forEach( (convo) => {
        //     if(convo.item_id == item.item_id){
        //         let participant_ids = convo.participants.map( (obj) => obj.id); //Will return array
        //         if( participant_ids[0] === ( u_id || item.owner_id ) && participant_ids[1] == ( u_id || item.owner_id ) ){
        //             conversation = convo;
        //         }
        //     }
        // });

        let conversation = find_conversation(conversations, item.item_id, "item_id");

        store.dispatch(set_conversation(conversation));

        if(jwt === null){
            this.setState({ messages : [ t('CHAT:default_msg_non_user', { returnObjects : true }) ] })
        }else{
            if(!conversation){
                this.setState({ messages : [ t('CHAT:default_msg', { returnObjects : true }) ] })
            }else{ this.setState({ messages : [ ] }) }
        }

    }

    handle_file_send = (file_list) => {
        const { t } = this.props;
        send_files(this.props.item.item_id, file_list, t);
    }

    render(){
        return(
            // <Launcher
            //     agentProfile={{ teamName: this.props.item.title, }}
            //     onMessageWasSent={ (msg) => { this.on_submit(msg)} }
            //     messageList = { 
            //         ( this.props.active_conversation ) ?
            //         this.format_to_popup_format(this.props.active_conversation.messages)
            //         :
            //         this.state.messages
            //     }
            //     onFilesSelected = { (file_list) => this.handle_file_send(file_list) }
            //     ref = { input => this.launcher = input }
            // />
            <></>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
      boxes  : state.products.boxes,
      conversations : state.chat.conversations,
      active_conversation : state.chat.active_conversation,
      u_id : state.authentication.u_id
    }
  }
  
  export default(
    connect(mapStateToProps)(
        withStyles(styles)(
            withAlert()(
                ChatWidget
            )
        )
    )
  );