import action_socket from "./action_socket_logic";

export async function sign_out(){
    let jwt = localStorage.getItem('jwt');
    let socket_id = localStorage.getItem('socket_id');

    // action_socket.emit('remove_client', {
    //     socket_id : socket_id,
    //     jwt : jwt
    // });

    localStorage.removeItem('jwt');
    localStorage.removeItem('socket_id');
    localStorage.removeItem('persist:root');
    // window.location.replace("/sign-in");
    window.location.replace("/sign-in");
}