import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { withWidth, Typography, Paper, Hidden } from '@material-ui/core';
import AppFrame from '../../components/AppFrame';
import { Container, Grid, IconButton, CardActions, Collapse, Tooltip, Select, FormControl, MenuItem, InputLabel, FormHelperText, OutlinedInput, InputAdornment, Button, ButtonGroup } from '@material-ui/core';

import { withTranslation } from 'react-i18next';
import Title from '../../components/title';

import { withAlert } from 'react-alert'


import FilterForm from '../../components/shop/forms/FilterForm';
import ip from '../../constants/ip';
import Lottie from 'react-lottie';

import CardLayout from '../../Layouts/CardLayout';
import PageLoadingLottie from '../../components/PageLoadingLottie';


import LocationSelector from '../../components/shop/forms/LocationSelector';

import { connect } from "react-redux";

import smoothscroll from 'smoothscroll-polyfill';

import  AppBarSearch from "../../components/AppBarSearch";
import ShopAutoComplete from '../../components/shop/ShopAutoComplete';
import { store } from '../../store';
import { set_form_value } from '../../actions/shop_form_actions';
import { FaShoppingBag, FaHome, FaRedo } from 'react-icons/fa';
import ShopLayout from '../../Layouts/ShopLayout';
import { IoMdCloseCircle } from 'react-icons/io';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';

import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Link } from 'react-router-dom';
import theme from '../../assets/styles/theme';
smoothscroll.polyfill();
  

const n_to_display = 20; // Server mirrored

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: require("../../assets/animations/money_2.json"),
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
};

const styles = theme => ({
  inner_container : {
    [theme.breakpoints.up('xl')]: {
      width : "80%",
      marginLeft : "10%"
    }
  },
  results_container : {
    padding : theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      padding : theme.spacing(4),
    },
  }
});

const ShopPagination = ({ onChange }) => {
  return (
    <Pagination count={10} color="primary" onChange = { onChange } />
  )
}


class ShopLandingPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      articles : {}, //[]

      show_filter : this.props.width !== 'xs',
      loading : false,
      
      n_matching : 0,
      did_load : false,
      
      last_query : '',
      last_query_string : '',

      query_str_display : '',

      first_query : true,

      d_i : 0, // Display index
      page : 0, //What set to query for
      queried_pages : []
    }

    this.scroll_ref = React.createRef();
    this.item_grid_ref = React.createRef();
  }

  async componentWillMount(){
    const { iso_data, user_location, form_data,history } = this.props;
    if(iso_data && user_location){ 
      this.fetch_articles(this.props);
    }
  }

  componentWillReceiveProps(nextprops){
    const { iso_data, user_location } = nextprops;

    let params_old = queryString.parse(this.props.location.search);
    let params_new = queryString.parse(nextprops.location.search);

    if( JSON.stringify(params_old) !== JSON.stringify(params_new) || this.state.first_query ){
      if( (iso_data && user_location) ){
        this.fetch_articles( nextprops );
        this.setState({ first_query : false })
      }
    }
    
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:shop_landing')
  }

  set_articles = ( articles ) => { this.setState({ articles : articles }) }
  add_articles = ( articles, prepend = false ) => { 
    this.setState({ articles : 
      prepend ? [ ...articles, ...this.state.articles,] : [...this.state.articles, ...articles]
    })
  }


  reset = async () => {
    this.props.set_form_value('query_string', '');
    this.fetch_articles({page : 0});
  }

  _render_empty = (t = this.props.t) => (
    <Paper style = {{ display : "flex", height : "60vh", marginTop : 30, /*backgroundImage: `url("/img/banner_bg.png")`,*/}}>
    <div style = {{display : "flex", flex : 1, flexDirection : "column", justifyContent : "center", alignItems : "center", padding : 20 }}>
        <h1 style = {{ textAlign : "center", }} > { t('SHOP_LANDING:no_items_found')} </h1>
        <h2 style = {{ marginTop : 5  }}>{'"' + this.state.last_query_string + '"'} </h2> 
        <div style = {{ display : "flex" }}>
        <Button style = {{ marginRight : 10 }} variant="contained" aria-label="next" onClick = { this.reset }>
          { t('SHOP_LANDING:reset_query') }
          <IoMdCloseCircle style = {{ marginLeft : 4, fontSize : 21 }}/>
        </Button>
        <Button style = {{ marginLeft : 10 }} variant="contained" aria-label="next" >
          { t('SHOP:try_again') }
          <FaRedo style = {{  marginLeft : 4 }} />
        </Button>
        </div>
    </div>
    </Paper>
  )

  _get_query_link = ( dict ) => {
      let params = queryString.parse(this.props.location.search);

      let str = "";
      for(let key in params){
        if( typeof dict[key] !== "undefined" ) continue
        str += ( key + "=" + params[key] + "&" );
      }
      for( let key in dict ){
        str += ( key + "=" + dict[key] + "&" );
      }
      return this.props.location.pathname + "?" + str.substring(0, str.length - 1);

  }

  _render_pagination = (page = parseInt(this.state.page)) => {
    return(
    <Pagination
      page={ page }
      count={ Math.ceil( this.state.n_matching / n_to_display ) }
      color = "primary"
      renderItem={item => (
        <PaginationItem component={Link} {...item}
          to = { this._get_query_link({ page : (item.type === "next") ? page + 1 : (item.type === "previous") ? page - 1 : item.page})}
        />
      )}
    />
    )
  }

  // fetch_articles = async(page = 0, iso_code = this.props.iso_data.code, user_location = this.props.user_location) => {
  fetch_articles = async( props = this.props ) => {
    const { form_data, alert, t } = props;
    const iso_code = props.iso_data.code;
    const user_location = props.user_location
    const params = queryString.parse(props.location.search);

    const target_page =  (params.page && params.page > 0 ) ? params.page : 1; // What section we want from the DB
    
    let query_string = params.q || ""; // If no present search all

    /* Small validation */
    if(this.state.loading){ return; }

    this.setState({ page : target_page, loading : true });
    const body = {
      radius : form_data.radius,
      sort_by : form_data.filter_type,
      query : query_string, 
      page : target_page - 1,
      iso_code : iso_code,
      user_location : user_location
    }
    
    // let performed_query = JSON.stringify(body);

    // This is something we COULD add but its not worth the guystle as of now
    // if( this.state.queried_pages.includes( target_page ) && performed_query != this.state.last_query ){
    //   this.setState({ loading : false });
    //   return;
    // }

    

    let response = await fetch(`${ip}/storager/shop/get_requests/search_articles.php`, {
      method : "POST",
      body : JSON.stringify(body)
    });
    
    let json_data = await response.json();
    if(json_data.success){
      this.setState({
        queried_pages : [...this.state.queried_pages, target_page], did_load : true,
        // last_query : performed_query,
        articles : {
          ...this.state.articles,
          [target_page] : json_data.items
        },
        n_matching : json_data.n
      })
    }else{
      alert.info("ERRORS:invalid_query");
    }

    this.setState({ loading : false, last_query_string : form_data.query_string });
  }

  render(){
    const { t, history, alert, form_data, classes } = this.props;

    const page = parseInt(this.state.page);

    if( this.state.articles === undefined || this.state.loading ){
      return <AppFrame><PageLoadingLottie/></AppFrame>
    }

    return(
      <AppFrame scroll_ref = { this.scroll_ref } no_spacing>
        <div className = { classes.inner_container }> 
            <div style = {{ display : "flex", flexDirection : "column", alignItems : "center", backgroundColor : theme.PRIMARY_COLOR, padding : 50 }}>
              {/* <h1 style = {{ color : "white", marginTop : 0 }}>Qboxr marketplace</h1> */}
              <Link to = "/shop">
                <img alt = "Qboxr" src = "/Qboxr_logo.png" style = {{ width : 150 }}></img>
              </Link>

              <div style = {{ width : "70%", marginTop : 20}}>
                <ShopAutoComplete
                  fetch_articles = { this.fetch_articles }
                  query_string = { form_data.query_string }
                  set_query_string = { (val) => store.dispatch( set_form_value('query_string', val) ) }
                  get_query_link = { this._get_query_link }
                />
              </div>
            </div>

            <div className = { classes.results_container}>
              <div style = {{ display : "flex", justifyContent : "space-between", paddingTop : 15 }}>
                <h1>Resultat</h1>
                { this._render_pagination() }
              </div>

              <div ref = { this.item_grid_ref }>
                
                {
                  this.state.did_load ? 
                  this.state.articles[page] && this.state.articles[page].length > 0 ?
                  <>
                    <ShopLayout data = { this.state.articles[page] } card_type = { "SHOP_CARD" } history = { history } />
                    <div style = {{ display : "flex", justifyContent : "space-between", alignItems : "center", paddingTop : 20, paddingBottom : 20 }}>
                      {/* <Typography variant = "subtitle2">{ (n_to_display * page + 1) + "/" + (n_to_display * (page + 1)  ) + " " +  t('G:of') + " - " +  this.state.n_matching + " " + t('G:matching') + " " + t('G:articles')}</Typography>                         */}
                      <Typography variant = "subtitle2">{ this.state.n_matching + " " + t('G:matching') + " " + t('G:articles')}</Typography>                        
                      { this._render_pagination() }
                    </div>

                  </>
                  : this._render_empty()
                  : <PageLoadingLottie/>
                }
              </div>
            </div>
          </div>
      </AppFrame>
    )
  }
}


const mapStateToProps = (state , ownProps) => {
  return {
    boxes  : state.products.boxes,
    form_data  : state.shop_form,
    iso_data : state.global.iso_data,
    user_location : state.global.user_location
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  { set_form_value }, dispatch,
)


export default (
  withStyles(styles)
  (
    connect(mapStateToProps, mapDispatchToProps)(
      withTranslation()
      (
        withAlert()(
          withRouter(
            ShopLandingPage
          )
        )
      )
    )
  )
);