export default {
    FONT_SIZE_SMALL: 12,
    FONT_SIZE_MEDIUM: 14,
    FONT_SIZE_LARGE: 16,
    PRIMARY_COLOR : "#10BBFF",
    PRIMARY_MONO_COLOR : "#52a2a3",
    SECONDARY_COLOR: 'rgb(238, 167, 2)',
    LINK_COLOR : '#39babd',
    GREEN_COLOR : "#4BCA81",
    YELLOW_COLOR : "#ffae42",
    RED_COLOR : "#ff0033",
    WHITE_BG_COLOR : "rgb(251,248,239)",
    FONT_WEIGHT_LIGHT: 200,
    FONT_WEIGHT_MEDIUM: 600,
    FONT_WEIGHT_HEAVY: 800,
    FORM_COLOR : "#50c878",
    TITLE : {
        fontFamily : "Roboto-Bold",
        fontSize : 30
    } 
};