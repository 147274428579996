import React from 'react';

import {
    Legend, ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';
import theme from '../../assets/styles/theme';

export const PIE_COLORS = ["orange", theme.PRIMARY_COLOR,];

export default class ItemsForSalePie extends React.PureComponent{
    constructor(props){
      super(props);
      this.state = {
        n_items : 0,
        n_items_for_sale : 0
      }
    }
    componentWillMount(){
      const {items} = this.props;
      const n_items = items.length;
      const n_items_for_sale = items.reduce( (tot, item) => (
        tot + item.is_for_sale
      ), 0);
      this.setState({ n_items : n_items , n_items_for_sale })
    }
    render(){
      const {t} = this.props;
      
      const data = [
        {name : t('MY_SHOP:not_for_sale') + " (" + this.state.n_items + ")", value : this.state.n_items},
        { name : t('MY_SHOP:for_sale') + " (" + this.state.n_items_for_sale + ")",  value : this.state.n_items_for_sale}
      ]
       
      return(
        <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            innerRadius={0} outerRadius={60} fill="#8884d8" dataKey="value"
            // label={renderCustomizedLabel}
            >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]}  />)
            }
            </Pie>
            <Legend/>
        </PieChart>
      </ResponsiveContainer>
      )
    }
  }