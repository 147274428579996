import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from "react-i18next";

import swe from "./locales/swe";
import en from "./locales/en";


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    // we init with resources
    resources: {
      "sv-SE": swe,
      en : en
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["P_G", "G"],
    defaultNS: "P_G",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;