import React from "react";
import { IconButton, Hidden, Avatar } from "@material-ui/core";

import { withRouter } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';


const AuthenticationHeader = ({title, history}) => {
    return(
        <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
            <div>
                <IconButton onClick={ () => history.goBack() }> <ArrowBackIcon /></IconButton>
                <h1 variant="h2"> { title }</h1>
            </div>
            <Hidden smDown>
                <Avatar> <LockOutlinedIcon/> </Avatar>
            </Hidden>
        </div>
    )
}

export default withRouter(AuthenticationHeader);