// /* eslint-disable no-use-before-define */
// import React, { useEffect, useRef } from 'react';
// import useAutocomplete from '@material-ui/lab/useAutocomplete';
// import styled from 'styled-components';

// import { connect } from "react-redux";
// import { FaBoxOpen, FaSearch, FaMap, FaMapMarkerAlt } from 'react-icons/fa';


// import { useTranslation } from 'react-i18next';
// import { useAlert } from 'react-alert'
// import { array } from 'prop-types';

// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';
// import MapField from '../map_components/MapField';


// // width: 300px;
// const InputWrapper = styled('div')`
//   width : 100%;
//   width : 50vw;
//   border: 1px solid #d9d9d9;
//   background-color: #fff;
//   border-radius: 4px;
//   padding: 7.5px;
//   display: flex;
//   flex-wrap: wrap;


//   &:hover {
//     border-color: #40a9ff;
//   }

//   &.focused {
//     border-color: #40a9ff;
//     box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
//   }

//   & input {
//     font-size: 14px;
//     height: 30px;
//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `;
// // width: 200px;
// const Listbox = styled('ul')`
//   width : 50vw;
//   max-height : 350px;

//   margin: 2px 0 0;

//   padding: 0;
//   position: absolute;
//   #position : relative;

//   list-style: none;
//   background-color: #fff;
//   color : black;
//   overflow: auto;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 10px 12px;
//     display: flex;
//     align-items : center;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: #fafafa;
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li[data-focus='true'] {
//     background-color: #e6f7ff;
//     cursor: pointer;

//     & svg {
//       color: #000;
//     }
//   }
// `;

// const Container = styled('div')`
//   width : 100%;
//   position : relative;
// `;



// function ShopAutoComplete(props) {

//   const [ res, setRes ] = React.useState({ categories : [ ], items : [ ], sub_categories : [ ] });
//   // const [val, setValue] = React.useState("");
//   const handle_click = async(e, obj) => {
//     if(obj && obj.value){
//       await props.set_query_string( obj.value );
//       props.fetch_articles();
//       setRes({  categories : [ ], items : [ ], sub_categories : [ ]  })
//     }
//   } 

//   const fetch_auto_content = async(s) => {

//     let response = await fetch('https://www.qboxr.com/storager/shop/get_requests/auto_search.php', {
//     method : "POST",
//       body : JSON.stringify({ query : s })
//     })
//     let json = await response.json();
//     let items = json.items.map( o => ({...o, type : t('G:results')}));

//     let sales_categories =  t('SHOP:dropdown_categories', { returnObjects : true });
//     let sales_sub_categories = t('SHOP:dropdown_sub_categories', { returnObjects : true });

    
//     let categories = sales_categories.map( obj => obj.items);
//     // .filter( obj => {
//     //   // return obj.text.toUpperCase().includes( s.toUpperCase() );
//     //   return true;
//     // }).map( o => ({ txt : o.text, type : t("G:results"), value : o.value }));
//     categories = [].concat(...categories)
//     .filter( obj => obj.text.toUpperCase().includes(s.toUpperCase() ))
//     .map( o => ({ txt : o.text, type : t("G:results"), value : o.value }) )
//     let sub_categories = [];
//     for( var key in sales_sub_categories ){
//       sales_sub_categories[key].shift(); //REMOVE OTHERS
//       sales_sub_categories[key].forEach( obj => {
//         if( obj.text.toUpperCase().includes( s.toUpperCase() )){
//           sub_categories.push({
//               txt : obj.text,
//               parent_cat : key,
//               value : obj.value,
//               type : t('G:results')
//               // type : t('ITEM_CARD:sub_category')
//           })
//         }
//       })
//     }

//     setRes({ ...res, categories, items, sub_categories });
//   }
  
//   const handle_change = async(e) => {
//     let s = e.target.value;
//     props.set_query_string(s);
//     if(s.length == 0){
//       setRes({ categories : [], items : [], sub_categories : [] })
//     } 
//     fetch_auto_content(s);
//   }

//   const options = [...res.categories, ...res.items, ...res.sub_categories]

//   const {
//     getRootProps,
//     getInputLabelProps,
//     getInputProps,
//     getTagProps,
//     getListboxProps,
//     getOptionProps,
//     groupedOptions,
//     value,
//     focused,
//     setAnchorEl,
//   } = useAutocomplete({
//     id: 'customized-hook-demo',
//     defaultValue: [],
//     disableCloseOnSelect : true,
//     blurOnSelect : false,
//     options : options,
//     onChange : handle_click ,
//     onInputChange : handle_change,
//     getOptionLabel: option => option,
//     groupBy : (option) => option.type
//   });


//   const { t } = useTranslation();
//   const alert = useAlert();

//   const inputProps = getInputProps();

//   let grouped = [];

//   grouped = groupedOptions.length > 0 ? groupedOptions : {
//     key : t('SEARCH_RESULTS:no_search_results'),
//     options : []
//   }

//   return (
//     <Container>
//       <div {...getRootProps()}>
//         {/* <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
//         <FaMapMarkerAlt style = {{ color : "gray", alignSelf : "center", marginRight : 10  }} /> */}
//           <MapField style = {{ width :  }} params = { inputProps }/>
//           {/* <input 
//             { ...inputProps }
//             placeholder = { t('SHOP_LANDING:looking_for') }
//             onChange = { (e) => {
//               inputProps.onChange(e);
//             }}
//             onKeyDown = { (e) => {
//               if(e.key == 'Enter'){
//                 fetch_auto_content(props.query_string);
//                 // fetch_auto_content(inputProps.value);
//               }
//             }}
//           />
//           <FaSearch style = {{ color : "gray", alignSelf : "center", marginRight : 10  }} />
//         </InputWrapper> */}
//       </div>

//       {grouped.length > 0 && focused ? (
//         <Listbox {...getListboxProps()}>
//           {grouped.map( (group, index) => (
//             <>
//             <h4 style = {{ paddingLeft : 12 }}>{group.key}</h4>
//             {
//               group.options.map( (obj, i) => {
//                 const matches = match(obj.txt, inputProps.value);
//                 const parts = parse(obj.txt, matches);
//                 return(
//                   <li {...getOptionProps({ item : obj, index : group.index + i })} onClick = { (e) => handle_click(e, obj) }>
//                     <div>
//                     {
//                       parts.map((part, index) => (
//                           <span key={index} style={{ fontWeight: part.highlight ? 700 : 400}}>
//                             {part.text}
//                           </span>
//                       ))
//                     }
//                     </div>
//                   </li>
//                 )
//               }
//               )
//             }
//             </>
//           ))}

//         </Listbox>
//       ) : null}
//     </Container>
//   );
// }

// const mapStateToProps = (state , ownProps) => {
//   return {
//     boxes  : state.products.boxes,
//   }
// }

// export default connect(mapStateToProps)(ShopAutoComplete)

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography, withStyles, Grid, Badge } from '@material-ui/core';
import { useTranslation, withTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import styled from 'styled-components';


import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { connect } from "react-redux";
import MapField from '../map_components/MapField';
import ShopField from './forms/ShopField';

import { withRouter } from 'react-router-dom';
import ip from '../../constants/ip';



const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: { ...style, top: style.top + LISTBOX_PADDING, },
  });
}
const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  // const itemSize = smUp ? 36 : 48;
  const itemSize = smUp ? 56 : 58;
  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) { return 48; }
    return itemSize;
  };
  const getHeight = () => {
    return itemCount > 8 ? 8 * itemSize : itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };
  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};


const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];

class AppBarSearch extends React.PureComponent{

  constructor(props){
    super(props);
    this.state = {
      results : { categories : [], items : [], sub_categories : [] }
    }
  }

  fetch_auto_content = async(s) => {
    const { t } = this.props;
    let response = await fetch(ip + '/storager/shop/get_requests/auto_search.php', {
    method : "POST",
      body : JSON.stringify({ query : s })
    })
    let json = await response.json();
    let items = json.items.map( o => ({...o, type : "ARTICLE"}));

    let sales_categories =  t('SHOP:dropdown_categories', { returnObjects : true });
    let sales_sub_categories = t('SHOP:dropdown_sub_categories', { returnObjects : true });

    let categories = [];
    let sub_categories = [];
    // let categories = sales_categories.map( obj => obj.items);
    // categories = [].concat(...categories)
    // .filter( obj => obj.text.toUpperCase().includes(s.toUpperCase() ))
    // .map( o => ({ txt : o.text, type : "SALES_CATEGORY", value : o.value }) )

    // let sub_categories = [];
    // for( var key in sales_sub_categories ){
    //   sales_sub_categories[key].shift(); //REMOVE OTHERS
    //   sales_sub_categories[key].forEach( obj => {
    //     if( obj.text.toUpperCase().includes( s.toUpperCase() )){
    //       sub_categories.push({
    //           txt : obj.text,
    //           parent_cat : key,
    //           value : obj.value,
    //           type : "SUB_CATEGORY"
    //       })
    //     }
    //   })
    // }
    this.setState({ results : {...this.state.results, categories, items, sub_categories} });
  }
  

  render(){

    const { classes, t, boxes } = this.props;
    const options = [...this.state.results.categories, ...this.state.results.items, ...this.state.results.sub_categories]
    // const all_items = ( boxes ? [].concat.apply([], boxes.map( box => { return box.items })) : [] ).map( item => ({
    //   ...item,
    //   firstLetter: /[0-9]/.test(item.title[0]) ? '0-9' : item.title[0],
    // })).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
    
    return (
      <Autocomplete
        id="virtualize-demo"
        disableListWrap
        classes={classes}
        ListboxComponent={ListboxComponent}
        renderGroup={renderGroup}
        options={options}
        groupBy={option => option.txt[0]}
        value = { this.props.query_string }
        freeSolo = { true }
        selectOnFocus = { true }
        onChange = { async (e, obj) => {
          if(obj && obj.value){
          
            this.props.set_query_string(obj.txt);  // For query fetch logic
            const translations = { SALES_CATEGORY : "cat", SUB_CATEGORY : "undercat", ARTICLE : "q" }
          
             this.props.history.push( this.props.get_query_link({
              page : 0, q : obj.value
            }));
          }
        }}

        renderInput={params => (
        <ShopField 
          style = {{ width : "100%" }} 
          params = {{
            ...params,
            onChange : (e) => {
              this.props.set_query_string(e.target.value);
              this.fetch_auto_content(e.target.value);
            },
            onKeyDown : (e) => {
              if(e.keyCode == 13){
                e.preventDefault();
                this.props.fetch_articles( );
              }
            }
          }}
          {...this.props}
        />
        )
        }

        renderOption={ (option, { inputValue }) => {
        const matches = match(option.txt, inputValue);
        const parts = parse(option.txt, matches);
        return (
          <Grid container alignItems="center" spacing = { 1 }>
            <Grid item xs = { 12 }>
              <Typography noWrap>
                {parts.map((part, index) => (
                  <span key={index} style={{ color : "black", fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
              <Typography variant="body2" color="textSecondary" noWrap>
                {t(`SHOP_AUTOCOMPLETE:${option.type}`)}
              </Typography>
            </Grid>    
          </Grid>
        );
      }}
      />
    );
  }
}

const useStyles = theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});


const mapStateToProps = (state , ownProps) => {
  return {
    boxes  : state.products.boxes,
  }
}

export default withStyles(useStyles)(
    connect(mapStateToProps)(
      withTranslation()(
        withRouter(
          AppBarSearch
        )
      )
    )
  )

