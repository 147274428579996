import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FormControlLabel, Checkbox, Typography, Grid, Slide, Tooltip, useMediaQuery, withWidth, IconButton } from '@material-ui/core';

import { withStyles, withTheme } from '@material-ui/styles';

import TextField from '@material-ui/core/TextField';

import Dropzone from 'react-dropzone';
import ip from "../../constants/ip";
import { prepare_socket_action } from "../../logic/action_socket_logic";
import { get_img, toBase64, get_end_point } from "../../logic/image_logic";
import { withTranslation } from "react-i18next";

import Zoom from '@material-ui/core/Zoom';
import SimpleSelect from "../shop/SimpleSelect";
import CurrencyTextfield from "../shop/CurrencyTextfield";

import { withAlert } from 'react-alert'

import { store } from "../../store";

import Transition from "./Transition";

import { set_dialog_status, set_dialog } from "../../actions/dialog_actions"

import { connect } from "react-redux";
import { GiMoneyStack } from "react-icons/gi";
import { handle_mark_as_sold_click } from "../BoxPage/BoxItemCard";
import AsyncAuto from "../Autocompletes/AsyncAuto";
    
export function open_item_dialog( data ){
    store.dispatch( set_dialog("item_dialog", data));
    store.dispatch( set_dialog_status('item_dialog', true) );
}

const initial_state = {
    item_title : '',
    n_of_item : 1,
    item_category : '',
    item_img : undefined,
    is_for_sale : false,
    sales_category : 'NONE',
    sales_sub_category : 'SUB_CATEGORY',
    price : 0,
    iso : "sek",
    currency : "kr",
    sales_type : "PIECE",
    description : '',
    form_errors : [],
    images : [],
    image_files : [],
}

class AddItemDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {...initial_state}
    }

    init = async() => {
        const { action_type, item } = this.props;
        if(action_type === "EDIT"){
            let images = item.images.map( id => ({ //We are going to treat the images as objects in this class
                url : get_end_point("ITEM", id),
                id  : id
            })) // Just get them... We cant rely on the fact that we have already queried for them. It's not really a big request
            
            this.setState({
                item_title : item.title,
                item_category : item.category,
                n_of_item : item.n_of_item,
                is_for_sale : item.is_for_sale,         
            });

            if(item.is_for_sale){
                this.setState({
                    sales_category : item.sales_category,
                    sales_sub_category : item.sub_category,
                    price : item.price,
                    sales_type : (item.is_bundle || item.n_of_item == 1) ? "BUNDLE" : "PIECE",
                    currency : item.currency,
                    description : item.description       
                });
            }

            this.setState({
                images : images.length === 0 ? [] : images,
                image_files : []
            })
        }else{ // Load sales data as well
            this.setState({...initial_state})
        }
    }

    handle_drop = (accepted_files) => {
        if(accepted_files.length === 0){ return }

        let accepted_types = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
        let correct_files = accepted_files.filter( (file) => { return accepted_types.includes(file.type); });
        if(correct_files.length === 0){ return }

        correct_files.forEach( file => {
            var reader = new FileReader();
            reader.onload = (e) => { // Add both a displayable image and the actual FILE
                let next_id = this.state.images.length > 0 ? this.state.images[this.state.images.length - 1].id + 1 : 0;
                
                //We need a link between the added displayable item objects and the files so we create a shared ID
                let appendage_image_object = { url : e.target.result, id : next_id, is_new : true }
                file['id'] = next_id;
                this.setState({
                    images : [...this.state.images, appendage_image_object],
                    image_files : [...this.state.image_files, file],
                    did_change_img : true
                });
            }
            reader.readAsDataURL(file);
        });
    }

    form_validate = () => {
        const { t } = this.props;
        let error_messages = [];
        let normal_success = true;
        let shop_success = true;


        const sub_categories   = JSON.parse( JSON.stringify( t('SHOP:dropdown_sub_categories', { returnObjects : true }) ));

        if( this.state.item_name === "" || this.state.item_category === ""){
            error_messages.push("INVALID_FORM");
            normal_success = false;
        }
        if(isNaN(this.state.n_of_item)){
            error_messages.push('N_OF_NOT_NUMBER');
            normal_success = false;
        }

        if(this.state.is_for_sale){ 
            if (this.state.price === "" || this.state.sales_category === "NONE" || this.state.sales_category === "" || this.state.currency === ""){
                shop_success = false;
                error_messages.push("INVALID_SHOP_ADD_FORM");
            }
        }

        if(isNaN(this.state.price)){
            error_messages.push('PRICE_NOT_NUMBER');
        }
        if(this.props.box_id === undefined){
            error_messages.push('NO_BOX_ID');
        }

        return {
            success  : (normal_success && shop_success),
            error_messages : error_messages
        }
    }

    handle_close = () => {
        this.setState({...initial_state})
        this.props.close_modal(false);
    }

    submit_form = async() => {
        const { alert , t } = this.props;
        let validation = this.form_validate();
        if(!validation.success){
            validation.error_messages.forEach( error => {
                alert.info(t(`ADD_ITEM_DIALOG_ERRORS:${error}`));
            })
            return;
        }

        let jwt = localStorage.getItem('jwt');
        let form_data = new FormData();

        form_data.append('jwt', jwt);

        form_data.append('box_id', this.props.box_id);
        form_data.append('title', this.state.item_title);
        form_data.append('category', this.state.item_category);
        form_data.append('n_of_item', this.state.n_of_item);
        form_data.append('submit_date',  new Date().toLocaleString() ); // Get the clients time
        
        /* Sales data */
        form_data.append('is_for_sale', this.state.is_for_sale ? 1 : 0);
        form_data.append('price', this.state.price);
        form_data.append('sales_category', this.state.sales_category);
        form_data.append('sub_category', this.state.sales_sub_category);
        form_data.append('description', this.state.description);
        
        form_data.append('is_bundle', this.state.sales_type === "BUNDLE" ? 1 : 0);

        form_data.append('currency', this.props.iso_data.symbol);
        form_data.append('iso_code', this.props.iso_data.code);

        form_data.append('has_new_img', this.state.did_change_img ? 1 : 0);

        if(this.props.action_type === 'EDIT'){ 
            form_data.append('item_id', this.props.item.item_id);
            form_data.append('box_id', this.props.item.box_id);
        }
        
        //New files
        this.state.image_files.forEach( file => { form_data.append('images[]', file); });

        form_data.append('images_to_keep',
            JSON.stringify(this.state.images.filter( img_obj => img_obj.id && !img_obj.is_new).map( img_obj => img_obj.id))
        );

        let query_file = (this.props.action_type == 'EDIT') ? "update_item.php" : "add_item.php";
        let response = await fetch(`${ip}/storager/set_requests/${query_file}`, {
            method: 'post',
            body: form_data,
        })
        let json_data = await response.json();

        if(json_data.success === 1){
            if(this.props.action_type == 'EDIT'){ 
                prepare_socket_action( "UPDATE_ITEM", {
                    box_id   : this.props.item.box_id,
                    new_item : {
                        ...json_data.updated_item_formated,
                    }
                });
            }else{
                let box_id = this.props.box_id;
                prepare_socket_action("ADD_ITEM", { box_id : box_id , form_data : {
                    ...json_data.inserted_item_formated,
                }});
            }
            this.handle_close();
        }else{
            if(json_data.error_code){
                alert.info( t('ERRORS:' + json_data.error_code) );
            }else{
                alert.info( t('ERRORS:NO_LOCATION_CANT_SELL') );
            }
        }
        
    }

    handle_img_click = (img_id) => {
        let filter_function = (arr) => {
            return arr.filter( (obj, index, arr) => ( obj.id != img_id ))
        }
        this.setState({ image_files : filter_function(this.state.image_files), images : filter_function(this.state.images) });
    }

    render(){

        const {classes, t, item} = this.props;

        const sales_categories = JSON.parse( JSON.stringify( t('SHOP:dropdown_categories', { returnObjects: true })));
        const sub_categories   = JSON.parse( JSON.stringify( t('SHOP:dropdown_sub_categories', { returnObjects : true }) ));
        const sales_types   = JSON.parse( JSON.stringify( t('SHOP:sales_type_dropdown', { returnObjects : true }) ));

        let sales_sub_categories = sub_categories[this.state.sales_category];

        let has_category = this.state.sales_category !== "NONE";//this.state.sales_category_index !== ;

        const fullScreen = this.props.width === "xs";

        return(
            <Dialog
                open={this.props.open_status}
                onClose={ this.props.close_modal }
                onEnter = { this.init }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={ Transition }
                fullScreen={fullScreen}
            >   
                <DialogTitle  id="alert-dialog-title">{ (this.props.action_type === "ADD") ? t('ADD_ITEM_DIALOG:add_item_title') :  t('ADD_ITEM_DIALOG:edit_item_title') }</DialogTitle>
                
                <DialogContent>

                <DialogContentText>
                    { t('ADD_ITEM_DIALOG:brag_text') }
                </DialogContentText>

                <h3>{t('G:information')}</h3>

                <TextField
                    variant = "outlined"
                    id="standard-helperText"
                    label = { t('ADD_ITEM_DIALOG:item_name_title') }
                    style = {{width : "100%"}}
                    value = {this.state.item_title}
                    onChange = {(e) => {this.setState({item_title : e.target.value})}}
                    margin="normal"
                    className = { classes.element }
                />
                <AsyncAuto
                    value = { this.state.item_category }
                    set_value = { (val) => this.setState({ item_category : val })}
                    label = { t('ADD_ITEM_DIALOG:item_category_title') }
                    style = {{width : "100%"}}
                    className = { classes.element }
                />

                {/* <SimpleSelect
                    value = { this.state.n_of_item }
                    values = { n }
                    set_value = { (int) => { this.setState({n_of_item : int}) } }
                    label = { t('ADD_ITEM_DIALOG:n_of_item_title') }
                    className = { classes.element }
                /> */}
                 <TextField
                    margin = "normal"
                    id="name"
                    value={this.state.n_of_item}
                    onChange={e => this.setState({ n_of_item : e.target.value })}
                    label = { t('ADD_ITEM_DIALOG:n_of_item_title') } 
                    style = {{width : "100%"}}
                    type="number"
                    variant = "outlined"
                    className = { classes.element }
                />


                <FormControlLabel
                    control={
                    <Checkbox
                        checked={this.state.is_for_sale}
                        onChange={ () => {this.setState({is_for_sale : !this.state.is_for_sale})} }
                        value="checked"
                        color="primary"
                    />
                    }   
                    label = { t('ADD_ITEM_DIALOG:check_box_label') }
                    className = {`${classes.check_box} ${classes.element}`}
                />
            

                <Zoom in={this.state.is_for_sale}>
                    <div style = {{display : this.state.is_for_sale ? undefined : "none"}}>
                        <h3>{t('ADD_ITEM_DIALOG:sales_information')}</h3>
                        <SimpleSelect
                            value = { this.state.sales_category } //Where value is the index of the wanted element in the dropdown
                            values = { sales_categories }
                            set_value = { (value) => {
                                let new_instance = sub_categories[value];
                                if( sub_categories[value] ){
                                    let sub_category_start = new_instance[Object.keys(new_instance)[0]].value; // For a given sub category we are going to want to assign the first sub category to be displayed realtive to the category that the user just pressed
                                    this.setState({ sales_sub_category : sub_category_start })
                                }else{
                                    this.setState({ sales_sub_category : "OTHERS" });  
                                }
                                this.setState({ sales_category : value })
                            }}
                            label = { t('ADD_ITEM_DIALOG:sales_category') }
                            className = { classes.element }
                        />
                        
                        {
                        ( has_category && sub_categories[this.state.sales_category] ) ? 
                            <SimpleSelect
                                value = { this.state.sales_sub_category } //Where value is the index of the wanted element in the dropdown
                                values = { sales_sub_categories }
                                set_value = { (value) => { this.setState({sales_sub_category : value}); }}
                                label = { t('ADD_ITEM_DIALOG:sub_category') }
                                className = { classes.element }
                            /> : null
                        }
                
                        <CurrencyTextfield 
                            placeholder = { t('ADD_ITEM_DIALOG:price') }
                            className = { classes.element }
                            margin = "normal"
                            value = { this.state.price }
                            currency = { this.state.currency }
                            set_value = { (val) => { this.setState({price : val}) } }
                        />
                        
                        {
                            this.state.n_of_item > 1 ?
                            <>
                            <SimpleSelect
                                value = { this.state.sales_type } //Where value is the index of the wanted element in the dropdown
                                values = { sales_types }
                                set_value = { (value) => { this.setState({sales_type : value}); }}
                                // label = { "Du har angett " + this.state.n_of_item + " stycken av denna produkt. Är priset " + this.state.price + " avsett /föremål eller för alla " + this.state.n_of_item }
                                className = { classes.element }
                            />
                            <Typography style = {{ marginTop : -5, color : "gray", fontSize : 10 }}variant = "subtitle2">{ t('ADD_ITEM_DIALOG:sales_type_text') }</Typography>
                            </>
                            : null
                        }

                        <TextField
                            variant = "outlined"
                            id="standard-helperText"
                            label = { t('ADD_ITEM_DIALOG:description') }
                            style = {{width : "100%"}}
                            value = {this.state.description}
                            onChange = {(e) => {this.setState({description : e.target.value})}}
                            margin="normal"
                            className = { classes.element }
                        />

                    </div>
                </Zoom>
                        
                <h3>{t('ADD_ITEM_DIALOG:image')}</h3>
                <Dropzone 
                    onDrop={this.handle_drop}
                >
                {({getRootProps, getInputProps}) => (
                    <section className = {classes.drop_zone}>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} accept="image/*" type = "file" name = "file"/>
                        <Typography>{t('A_B_S_1:files_drop_text')}</Typography>
                    </div>
                    </section>
                )}
                </Dropzone>
                
                {
                (this.state.images.length > 0) ?
                <Grid container style = {{marginTop : 24}} spacing = { 3 }>
                    {
                        this.state.images.map( (img, i) => {

                            return (
                                <Tooltip title = { t('ADD_ITEM_DIALOG:double_to_remove') }>
                                    <Grid item xs = {4} style = {{height : 150, cursor : "pointer"}} onDoubleClick = { () => this.handle_img_click( img.id ) }>
                                        <img  src = { img.url } style = {{maxHeight : "100%", maxWidth : "100%", width : "100%", height : "100%", objectFit : "contain", backgroundColor : "rgb(30,30,30)", borderRadius : 10}}/>
                                    </Grid>
                                </Tooltip>
                            )
                        })
                    }
                </Grid> : null
                }

                {
                    this.state.form_errors.length > 0 ? 
                    this.state.form_errors.map( error => {
                        return <DialogContentText color = "error">{error}</DialogContentText>
                    }) : null
                }

                </DialogContent>
                
                <DialogActions>

                {
                    fullScreen ? 
                    <Button onClick = { this.props.close_modal } variant="contained" color="default" className={classes.button}>
                        { t('G:back') }
                    </Button> : null
                }

                {
                    this.props.action_type !== "ADD" ?
                    <Button onClick = {() => handle_mark_as_sold_click( item , t, alert)} variant="contained" color="default" className={classes.button}>
                        { t('MARK_AS_SOLD_DIALOG:btn') }
                        <GiMoneyStack color = "black" className={classes.rightIcon} />
                    </Button> : null
                }

                <Button onClick = {this.submit_form} variant="contained" color="default" className={classes.button}>
                    { (this.props.action_type === "ADD") ? t('BOX_ACTIONS:add_item') : t('BOX_ACTIONS:edit_item')}
                    <CloudUploadIcon className={classes.rightIcon} />
                </Button>

                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
        iso_data : state.global.iso_data
    }
}
  
const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    drop_zone : {
        display : "flex",
        cursor : "pointer",
        backgroundColor : "rgb(230,230,230)",
        height : 150,
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "Roboto",
        marginTop : theme.spacing(4)
    },
    formControl : {
        width : "100%",
        marginTop : theme.spacing(2)
    },
    check_box : {
        marginTop : theme.spacing(2),
    },
    successIcon : {
        color : "#4BCA81",
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(-2),
    },
    element : {
        marginTop : theme.spacing(3)
    }
});



export default (
    connect(mapStateToProps)(
        withTranslation()
        (
            withStyles(styles)
            (
                withAlert()
                (
                    withWidth()(
                        AddItemDialog
                    )
                )
            ) 
        )
    )
);
