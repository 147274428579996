import React from "react";
import { withStyles,CardActionArea, Tooltip } from "@material-ui/core";


import Lottie from 'react-lottie';
import Navbar from "../../components/public/Navbar.js";

import HeaderPart from "../../components/public/HeaderPart";


import public_styles from "../../assets/styles/public_styles";

import MailIcon from "@material-ui/icons/Email";
import FAQIcon from "@material-ui/icons/ViewList";
import { withTranslation } from "react-i18next";
import AppFrame from "../../components/AppFrame.js";
import ContactUsModal from "../../components/dialogs/ContactUsModal.js";
import { ImageAndText } from "../../components/public/ImageAndText.js";
import { PublicButtonContainer } from "../../components/public/PublicButtonContainer.js";
import theme from "../../assets/styles/theme.js";


class ContactPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            modal_status : false
        }
    }

    componentDidMount(){
        const { t } = this.props;
        window.scrollTo(0, 0);
        document.title = t('TITLES:contact')
    }

    render(){
        const {classes, t, history} = this.props;
        return(
            <AppFrame no_spacing>
                
                <HeaderPart
                    animation_data = { require("../../assets/animations/man_on_computer.json") }
                    title  = { t('header_title') }//"Organisera Ditt Förråd Med Qboxr."
                    text   = { t('header_text') }
                    history = {this.props.history}
                    classes = {classes}
                />

                <ImageAndText
                    animation_data = { require("../../assets/animations/email_funny.json") }
                    title = { t('how_to_get_in_touch_title') }
                    text  = { t('how_to_get_in_touch_text') }
                    buttons = {
                        <PublicButtonContainer>
                            {/* <Tooltip title = { "FAQ" } aria-label = { "FAQ" }>                            
                                <CardActionArea className = {classes.amount_display} style = {{width : "50%", borderRadius : 10}} onClick = { () => history.push('/FAQ') }>
                                    <FAQIcon className = {classes.brand_icon}/>
                                </CardActionArea>
                            </Tooltip> */}

                            <Tooltip title = { t('SIGN_IN:email_placeholder') } aria-label = { t('SIGN_IN:email_placeholder') }>
                                <CardActionArea className = {classes.amount_display} style = {{borderRadius : 10, width : "50%" }} onClick = { () => this.setState({ modal_status : true }) }>
                                    <MailIcon className = {classes.brand_icon} style={{ color: theme.GREEN_COLOR }}/>
                                </CardActionArea>
                            </Tooltip>
                        
                        </PublicButtonContainer>
                    }
                    hide_on_small
                />
                            
                <ContactUsModal
                    open_status = { this.state.modal_status }
                    set_open_status = { (bool) => this.setState({ modal_status : bool }) }
                />
                
            </AppFrame>
        )
    }
}

const styles = (theme) => ({
    ...public_styles(theme),
    top_container : {
        [theme.breakpoints.down('sm')]: {
            flexDirection : "column-reverse"
        }
    },
    top_part_right : {
        maxWidth  : "70%",
        maxHeight : "70%"
    }
});

export default withStyles(styles)(withTranslation(['C_P'])(ContactPage));