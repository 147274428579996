import React from "react";
import { withStyles, withWidth } from "@material-ui/core";

import Lottie from 'react-lottie';
import Navbar from "../../components/public/Navbar.js";

import HeaderPart from "../../components/public/HeaderPart";

import ReactPlayer from 'react-player'

import public_styles from "../../assets/styles/public_styles";
import { withTranslation } from "react-i18next";
import AppFrame from "../../components/AppFrame.js";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};


class AboutUs extends React.Component{
    componentDidMount(){
        const { t } = this.props;
        window.scrollTo(0, 0);
        document.title = t('TITLES:dashboard')
    }

    render(){
        const {classes, t} = this.props;
        const is_mobile = this.props.width === 'xs' || this.props.width === 'sm';
        
        return(
            <AppFrame no_spacing>
                <div className = {classes.container}>
                    <HeaderPart
                        styles = {styles}
                        lottie = {require("../../assets/animations/office_working.json")}
                        width  = {700}
                        height = {700}
                        title  = {t('about_us_title')}
                        text   = {t('about_us_text')}
                        history= {this.props.history}
                    />

                    <div className = {classes.organize}>
                        <div className = {`${classes.top_part}`}>
                            <h2 className = {classes.top_title} style = {{color : "black"}}>{t('the_problem_title')}</h2>
                            <p className = {classes.top_mini_text} style = {{color : "gray", letterSpacing : 1,}}>{t('the_problem_text')}</p>
                        </div>

                        {
                            (is_mobile) ? null : 
                            <div className = {`${classes.top_part}`}>
                                <Lottie
                                    options={{...defaultOptions, animationData : require("../../assets/animations/box_animation.json")}}
                                    height= {550}
                                    width = {550}
                                />
                            </div>
                        }
                    </div>


                    <div className = {classes.organize}>
                        <div className = {`${classes.top_part}`}>
                            <h2 className = {classes.top_title} style = {{color : "black"}}>
                                {t('see_how_it_works_title')}
                            </h2>
                            <p className = {classes.top_mini_text} style = {{color : "gray", letterSpacing : 1,}}>
                                {t('see_how_it_works_text')}
                            </p>
                        </div>
                        
                        <div className = {`${classes.top_part}`}>
                          <div className= {classes.wrapper}>
                              <iframe className = { classes.player } width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>
                          </div>
                        </div>
                    </div>

                </div>
            </AppFrame>
        )
    }
}

const styles = (theme) => ({
    ...public_styles(theme),
    wrapper : {
        position: "relative",
        paddingTop: "56.25%", /* Player ratio: 100 / (1280 / 720) */
        flexGrow : 1,
        borderRadius : 20,
        overflow : "hidden",
        backgroundColor : "black"
    },  
    player : {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    }
});

export default withStyles(styles)(withWidth()( withTranslation(['A_U'])(AboutUs)));