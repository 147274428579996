import {

    SET_N_TOKENS,
    DECREMENT_TOKENS,
    INCREMENT_TOKENS,

    SET_GLOBAL_REDUCER_VALUE,


    SET_ISO_DATA,

    SET_USER_SUBSCRIPTION,
    SET_SUBSCRIPTION_LOADED,
  } from "../actions/global_actions.js"
  
  const GlobalInitialState = {
      n_tokens : undefined,
      
      user_subscription : undefined,
      subscription_loaded : false,

      iso_data : undefined, // Load
      user_location : undefined,


      last_page : undefined

      // navbar_height : 0
    };
  
  export default function GlobalReducer(state = GlobalInitialState, action) {
  
      //Redux perform a shallow comparrison meaning that we have to refer to a new object for the redraw to trigger.
      //Took a long time to figure this out, we therefore create a new object with new_address down here,
  
      switch(action.type) {
          case SET_GLOBAL_REDUCER_VALUE: 
            return {
              ...state,
              [action.payload.key] : action.payload.value
            }

          case SET_USER_SUBSCRIPTION:
          return {
            ...state,
            user_subscription : action.payload
          };

          case SET_SUBSCRIPTION_LOADED:
            return {
              ...state,
              subscription_loaded : action.payload 
            };
          
          case SET_ISO_DATA :
            return {
              ...state,
              iso_data : action.payload
            }
            
          case SET_N_TOKENS:
            return {
              ...state,
              n_tokens : action.payload
            };

          case DECREMENT_TOKENS:
            return {
              ...state,
              n_tokens : state.n_tokens - 1
            };

          case INCREMENT_TOKENS:
            return {
              ...state,
              n_tokens : state.n_tokens + 1
            };
    
        default :
          return state;
      }
    }