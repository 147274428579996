import React from "react";
import ImageDialog from "../components/dialogs/ImageDialog";

import { connect } from "react-redux";
import {bindActionCreators} from "redux";

import { set_dialog_status } from "../actions/dialog_actions"
import AreYouSureDialog from "../components/dialogs/AreYouSureDialog";
import AddItemDialog from "../components/dialogs/AddItemDialog";
import EditBoxDialog from "../components/dialogs/EditBoxDialog";
import PasswordPrompt from "../components/dialogs/PasswordPrompt";
import ShareDialog from "../components/dialogs/ShareDialog";

class Dialogs extends React.Component{
    constructor(props){
      super(props);
    }
    render(){
      const { dialogs } = this.props;
      return(
        <React.Fragment>
          <ImageDialog
            open_status = { dialogs.img.open_status }
            img = { dialogs.img.img }
            images = { dialogs.img.images }
            // set_open_status = { (val) => set_dialog_status('img', false)  }
            close_modal = { () => this.props.set_dialog_status('img', false) }
          />

          <AreYouSureDialog
            {...dialogs.ays}
            close_modal = { () => this.props.set_dialog_status('ays', false) }
          />

          <PasswordPrompt
            open_status = { dialogs.password_prompt.open_status }
            on_click = { dialogs.password_prompt.on_click }
            close_modal = { () => this.props.set_dialog_status('password_prompt', false) }
          />

          <AddItemDialog 
            open_status = { dialogs.item_dialog.open_status } 
            close_modal = { () => this.props.set_dialog_status('item_dialog', false) }
            item   = { dialogs.item_dialog.item }
            box_id = { dialogs.item_dialog.box_id }
            action_type = { dialogs.item_dialog.action_type }
          />

          <ShareDialog
            open_status = { dialogs.share_dialog.open_status }
            close_modal = { () => this.props.set_dialog_status('share_dialog', false) }
            item   = { dialogs.share_dialog.item }
          />
          
          <EditBoxDialog 
            open_status = { dialogs.box_dialog.open_status } 
            set_open_status = { () => this.props.set_dialog_status('box_dialog', false) }
            box = { dialogs.box_dialog.box }
            action_type = "ADD"
          />

        </React.Fragment>
      )
    }
}

const mapStateToProps = (state , ownProps) => {
  return {
    dialogs : state.dialogs,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators (
  {
    set_dialog_status
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);