import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, MenuItem, FormHelperText, OutlinedInput, ListSubheader } from '@material-ui/core';



const useStyles = makeStyles(theme => ({
    formControl: {
      margin: `${theme.spacing(1)}px 0`,
      minWidth : "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));


const render_section = (set, set_value) => {
    const title = <ListSubheader>{set.title}</ListSubheader>
    const menu_items = set.items.map( obj => (
        <MenuItem onClick = { () => set_value(obj.value) } value={ obj.value }>{obj.text}</MenuItem>
    ))
    return [title, menu_items]
}

export default function SimpleSelect(props) {
    const classes = useStyles();
  

    return (
      <FormControl className={`${classes.formControl} ${props.className}`}>
        {/* <InputLabel htmlFor="n_items">Antal</InputLabel> */}
        <Select
            value = { props.value }
            onChange={ (e) => { 
                props.set_value(e.target.value);
            }}
            input={
                <OutlinedInput name="n_items" id="n_items"/>        
            }
            disabled = { props.disabled }
        >
        {
            (props.values) ? 
            
            props.values.map( (inst , i) => {
                if(inst.title){ // For categorized
                    return render_section(inst, props.set_value)
                }else{
                    return  <MenuItem value={ inst.value }>{inst.text}</MenuItem>
                }
            }) : //#endregion
            []
        }
        </Select>
        <FormHelperText> { props.label } </FormHelperText>
    </FormControl>
  
    );
  }
  
  
  
  