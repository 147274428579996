import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/styles';

import AppFrame from "../components/AppFrame";
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import PageLoadingLottie from '../components/PageLoadingLottie';
import { withTranslation } from 'react-i18next';

import { Hidden, Avatar, Tooltip } from '@material-ui/core';
import CardLayout from '../Layouts/CardLayout';

import NoBoxes from "../components/EmptyReplacements/NoBoxes"
import { deep_asc_sort, deep_date_sort } from '../functions/sorting_functions';
import NoItemsForSale from '../components/EmptyReplacements/NoItemsForSale';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer,
  PieChart, Pie, Sector, Cell, ComposedChart, Bar
} from 'recharts';
import theme from '../assets/styles/theme';


import { FaRegCalendarAlt } from 'react-icons/fa';
import { GiClick, GiMoneyStack } from "react-icons/gi";
import { IoIosChatboxes } from "react-icons/io"
import ip from '../constants/ip';
import { api_call_based_on_jwt } from '../logic/init_logic';
import { f_a_c } from '../functions/money_functions';
import { format_date } from '../functions/date_functions';
import ItemsForSalePie from '../components/Graphs/ItemsForSalePie';
import ItemTable from '../components/Viritualizded/Table/ItemTable/ItemTable';



    


class MyShop extends React.Component{
  constructor(props){
    super(props);
    this.classes = this.props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    this.diagram_container = clsx(this.classes.paper, this.classes.fixedHeight_2);

    this.state = {
      click_week_report : [],
      revenue_data : []
    }
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:my_shop')
  }

  async componentWillMount(){
    const { t } = this.props;
    let response = await api_call_based_on_jwt(`shop/analytics/get/get_my_shop_dash_data.php`);

    for(var key in response){
      if(response[key][0]['date']){
        response[key] = response[key].map( (obj) => (
          { ...obj, date : format_date(obj.date, t) }
        ))
      }
    }

    this.setState({ 
      click_week_report : response.week_click_report,
      revenue_data : response.revenue_data
    })
  }

  render(){
    const {classes, t, history, boxes} = this.props; //Inherit styles from higher order component

    if(!boxes){
        return <AppFrame> <PageLoadingLottie/> </AppFrame>
    }

    const items_for_sale = [].concat.apply([], boxes.map( box => {
        return box.items.filter( item => { return item.is_for_sale; });
    }));

    const all_items = [].concat.apply([], boxes.map( box => { return box.items }));
    
    const n_clicks_today = this.state.click_week_report.length > 0 ? this.state.click_week_report[this.state.click_week_report.length - 1].value : 0;

  return (
    <AppFrame>
        { (this.props.boxes !== undefined) ? 
        <>
          <Grid container spacing={3} style = {{minHeight : "100%"}}>

            <Grid item xs={12} md={8} lg={9}>
              <Paper className={this.diagram_container}>
                
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
                  <div>
                    <h1>{ t('MY_SHOP:visits') }</h1>
                    <h5 style = {{ marginTop : -15 }}>{t('DATE:this_week')}</h5>
                  </div>
                  <Tooltip title = { t('MY_SHOP:visits_data_graph_tool') }>
                    <Avatar className={classes.avatar}  style = {{ background : theme.PRIMARY_COLOR }}>
                      <GiClick style = {{color : "white"}}/>
                    </Avatar>
                  </Tooltip>
                </div>
                <ResponsiveContainer>
                  <ComposedChart
                    data = { this.state.click_week_report }
                    margin = {{ top : 10, bottom : 10 }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date"/>
                    <YAxis />
                    <Bar dataKey="value" barSize={20} fill = { theme.PRIMARY_COLOR } />
                    <Line type="monotone" dataKey="value" stroke={ theme.GREEN_COLOR } activeDot={{ r: 8 }} />
                  </ComposedChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={this.diagram_container}>
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
                  <div>
                    <h1>{ t('DATE:today') }</h1>
                    <h5 style = {{ marginTop : -15 }}>{t('MY_SHOP:todays_data')}</h5>
                  </div>
                  <Tooltip title = { t('MY_SHOP:todays_data') }>
                    <Avatar className={classes.avatar}  style = {{ background : theme.PRIMARY_COLOR }}>
                      <FaRegCalendarAlt style = {{color : "white" }}/>
                    </Avatar>
                  </Tooltip>
                </div>
                
                <div style = {{ display : "flex", flexDirection : "column", justifyContent : "center" }} >
                  <div style = {{ display : "flex", alignItems : "center" }}>
                    <Tooltip title = {t('MY_SHOP:n_clicks_tool')}>
                      <Avatar style = {{ background : "orange" }}> <GiClick style = {{color : "white"}}/> </Avatar>
                    </Tooltip>
                    <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>
                      { t('PLURALS:n_clicks', { postProcess: 'interval', count: n_clicks_today }) }
                    </Typography>
                  </div>

                  <div style = {{ display : "flex", alignItems : "center", marginTop : 20}}>
                    <Tooltip title = { t('MY_SHOP:n_new_convos_tool') }>
                      <Avatar style = {{ background : theme.GREEN_COLOR,  }}> <IoIosChatboxes style = {{color : "white"}}/> </Avatar>
                    </Tooltip>   
                    <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>
                        {
                          // t('PLURALS:n_new_convos', { 
                          //   postProcess: 'interval',
                          //   count: 14
                          // })
                          "Could not load"
                        }
                    </Typography>
                  </div> 
                  
                </div>
              </Paper>
            </Grid>
              
            
            <Grid item xs={12} md={6} lg={3}>
              <Paper className={this.diagram_container} style = {{ display : "flex", justifyContent : "center", alignItems : "center" }}>
                <h1 style = {{ marginTop : -5 }}>{t('MY_SHOP:items_for_sale')}</h1>
                <ItemsForSalePie items = { all_items } t = { t }/>
              </Paper>
            </Grid>

            
            <Grid item xs={12} md={6} lg={9}>
              <Paper className={this.diagram_container}>
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
                  <div>
                    <h1>{ t('MY_SHOP:revenue') }</h1>
                    <h5 style = {{ marginTop : -15 }}>{ t('MY_SHOP:revenue_text') }</h5>
                  </div>
                  <Tooltip title = { t('MY_SHOP:revenue_data_graph_tool') }>
                    <Avatar className={classes.avatar}  style = {{ background : theme.PRIMARY_COLOR }}>
                      <GiMoneyStack style = {{color : "white"}}/>
                    </Avatar>
                  </Tooltip>
                </div>

                <ResponsiveContainer>
                  <ComposedChart
                    data={this.state.revenue_data}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="date" />
                    <YAxis/>
                    <Tooltip />
                    <Line type="monotone" dataKey="value" stroke = { theme.GREEN_COLOR } />
                  </ComposedChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>


            <Grid item xs={12}>
                { 
                  items_for_sale.length > 0 ? 
                  // <CardLayout
                  //   data = { deep_date_sort(items_for_sale) }
                  //   title = { t('MY_SHOP:my_articles') }
                  //   text  = { t('MY_SHOP:text') }
                  //   history = { history }
                  //   card_type = "BOX_ITEM"
                  // />
                  <Grid item xs = {12}>
                    <ItemTable data = { deep_date_sort(items_for_sale).reverse() } alert = { alert } t = {t} />
                  </Grid>
                  : 
                  <div style = {{flex : 1,}}>
                    <NoItemsForSale
                      history = { history } 
                    />
                  </div>
                  }
            </Grid>
            {/* </Grid> */}
            
          </Grid>
        
      </> : <PageLoadingLottie/>
      }
      {/* </main>  */}
    </AppFrame>
  );
  }
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeight_2 : {
    height : 300
  }
});

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    boxes : state.products.boxes,
    n_tokens : state.global.n_tokens
  }
}

const mapDispatchToProps = { 

}


export default (
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(
      withTranslation()(MyShop)
    )
  )
);