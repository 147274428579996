import React from "react";
import { DoneAll, LocalMall, LocalLaundryService, DirectionsBike, Computer, Hotel, NaturePeople, AllInclusive,  } from '@material-ui/icons';


export default function get_cat_icon( cat ){
    switch(cat){
        case "ALL" :
            return <DoneAll/>
        case "CLOTHES" :
            return <LocalMall/>
        case "APPLIANCES":
            return <LocalLaundryService/>
        case "HOBBY_WILDLIFE":
            return <DirectionsBike/>
        case "TECHNOLOGY":
            return <Computer/>
        case "FURNITURE":
            return <Hotel/>
        case "TOOLS_GARDEN":
            return <NaturePeople/>
        case "OTHER":
            return <AllInclusive/>
        default :
            return <AllInclusive/>
    }
}

// export function get_cat_icon( cat ){
//     // let Component = find_cat_icon(cat);
//     // return <Component style = {{  }} />
// }