import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { store } from "../../store";
import { connect } from "react-redux";

class MapContainer extends React.Component{

    constructor(props){
        super(props);
    }

    shouldComponentUpdate(nextprops, nextstate){
        return JSON.stringify(nextprops.cords) !== JSON.stringify(this.props.cords)
    }

    renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
          position: { lat: this.props.cords.lat, lng: this.props.cords.lng},
          map,
        });
        return marker;
    }

    render() {
        const defaultMapOptions = {
            fullscreenControl: false,
        };
        const mapStyles = {
            flex : 1,
            width : "100%",
            height : "100%",
            position : "relative",
        };
        
        let lat = this.props.cords.lat;
        let lng = this.props.cords.lng;

        if(lat == null || lng == null){
            lat = this.props.user_location.lat;
            lng = this.props.user_location.lng;
        }
        return (
            <div 
            style={{
                width : "100%",
                height : "100%",
                top : 0,
                left : 0,
                minHeight : 200, //Make paper height
                position : "relative",
                ...this.props.style
            }}
            >
            <Map
              google={this.props.google}
              zoom={11}
              style={mapStyles}
              initialCenter={{ lat: lat, lng: lng}}
              center={{ lat: lat, lng: lng}}
              disableDefaultUI = {true}
              onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
              scrollwheel = {false}
              zoomControl = {true}
            >
            <Marker 
                name={'Current location'}
                position = {{lat : lat, lng : lng}}
            />
            </Map>
            </div>
        );
    }
}

const mapStateToProps = (state , ownProps) => ({
    user_location : state.global.user_location,
    iso_data : state.global.iso_data
})

export default 
    connect(mapStateToProps)(
        GoogleApiWrapper({apiKey: 'AIzaSyA9mnSuMvXgqJjGeZ1kNKbeOqoiUzwqTns'})
            ( MapContainer)
    );