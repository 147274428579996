import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';

import { withTranslation } from 'react-i18next';


import { Button, IconButton, TextField, Link, Typography, withStyles, Paper, Avatar, Hidden } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ip from '../constants/ip';
import login_styles from "../assets/styles/login_styles_2";


import BlueWhiteFrame from '../components/Frames/BlueWhiteFrame';
import AuthenticationHeader from '../components/Frames/FrameReusables/AuthenticationHeader';


const useStyles = (theme) => ({ ...login_styles(theme) });

class SignIn extends React.PureComponent{
  
  constructor(props){
      super(props);
      this.state = { email : '', password : '', is_loading : false }
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };


  handleSignIn = async(event) => {
    event.preventDefault();
    const { alert, t } = this.props;
    let form_status = true;//this.validate_input();
    let file = "login.php"; //this.state.do_login ? "login.php" : "register_new_user.php";
    if(this.state.is_loading) return;

    this.setState({ is_loading : true })

    let response = await fetch(`${ip}/storager/authentication/${file}`, {
        method : "POST",
        body : JSON.stringify({
            email    : this.state.email,
            password : this.state.password,
        })
    })

    let json_data = await response.json();
    if(json_data.success === 1){
        this.handle_login(json_data.jwt);
    }else{
        alert.error( t('SIGN_IN_ERRORS:WRONG_EMAIL_OR_PASSWORD') );
        this.setState({ is_loading : false })
    }
  };


  handle_login = async(jwt) => {  
    localStorage.setItem("jwt", jwt);
    window.location.replace("/app/");
    this.setState({is_loading : false});
  }

  render(){
  const { t, classes } = this.props;
  return (
    <BlueWhiteFrame>
      <Paper className={classes.form}>
        <form  onSubmit={this.handleSignIn}>
          <AuthenticationHeader title = { t('SIGN_IN:sign_in') } />

          <TextField
            className={classes.textField}
            fullWidth
            label = { t('SIGN_IN:email_placeholder') }
            onChange={ (e) => {
              this.setState({ email : e.target.value })
            }}
            type="text"
            value={this.state.email}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            label = { t('SIGN_IN:password_placeholder') }
            onChange = { (e) => this.setState({ password : e.target.value }) }
            type="password"
            value={this.state.password}
          />
          <Button
            className={classes.signInButton}
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
          { t('SIGN_IN:sign_in') }
          </Button>
          
          <Typography color="textSecondary" variant="body1">
            { t('SIGN_IN:dont_have_account_link').substring(0, t('SIGN_IN:dont_have_account_link').indexOf('?') + 1 ) }
            <Link component={RouterLink} to="/sign-up" variant="subtitle1">
            { t('SIGN_IN:dont_have_account_link').substring(t('SIGN_IN:dont_have_account_link').indexOf('?') + 1, t('SIGN_IN:dont_have_account_link').length) }
            </Link>
          </Typography>

          <Link component={RouterLink} to="/forgot-password" variant="subtitle1">
            { t('SIGN_IN:forgot_password') }
          </Link>
        </form>
      </Paper>
    </BlueWhiteFrame>
  );
  }
};


export default withRouter(
    withTranslation()(
        withAlert()(
          withStyles(useStyles)(
            SignIn
          )
        )
    )
);
