import React from "react";
import { Typography, Tooltip, Avatar, IconButton, Button } from "@material-ui/core";
import theme from "../../assets/styles/theme";
import { format_date } from "../../functions/date_functions";
import { useTranslation } from "react-i18next";
import ip from "../../constants/ip";
import { set_image, set_dialog_status, set_dialog } from "../../actions/dialog_actions";
import { store } from "../../store";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

import FileIcon from '@material-ui/icons/InsertDriveFile';


function ChatText(props){
    const { is_me } = props;
    let msg_color = is_me ? "white" : "black";
    let weight = props.bold ? "bold" : undefined
    // let underlined = props.underlined;
    let link = props.link;
    
    return(
        !link ?
        <Typography style = {{color : msg_color, fontWeight : weight,fontSize : "1em" }}>{props.text}</Typography>
        :
        <a href = { link } target = "blank" style = {{color : msg_color, fontWeight : weight}}> {props.text}</a>
    )
}

function Bubble(props){
    let { is_me } = props;
    let borderRadius = 10;
    let borderLeftRadius  = is_me ? borderRadius : 3;
    let borderRightRadius = is_me ? 3 : borderRadius;
    let msg_bg = is_me ? theme.PRIMARY_COLOR : "rgb(230,230,230)";

    let is_file = props.is_file;

    if(is_file){
        // msg_bg = "rgb(180,180,180)";
    }

    const styles = {
        display : "flex", 
        alignItems : "center", 
        backgroundColor : msg_bg, 
        borderRadius : 10,
        borderBottomLeftRadius : borderLeftRadius,
        borderBottomRightRadius : borderRightRadius,
        padding : 10,
    }

    return(
        <div style = {styles}>
            { props.children }
        </div>
    )
}

function get_file_type(file_path){ //Opens up for scale...
    const img_types = ['.png', ".jpg", ".jpeg", ".gif"];

    let ext = file_path.substr(file_path.lastIndexOf('.', file_path.length - 1));
    
    if(img_types.includes(ext)){
        return "IMG";
    }else{
        return "OTHERS";
    }
}

export function Chatbubble(props){
    const { msg, participants } = props;

    // if( !(msg && participants) ){
    //     return null;
    // }

    const { t } = useTranslation()

    let is_me = msg.sender_id === props.u_id;

    let placement = ( is_me ) ? "left" : "right";

    let name = ( participants[msg.sender_id].name === "NO_NAME" ) ? t('CHAT:qboxr_user') : participants[msg.sender_id].name;
    
    let content;

    //Fix the filepaths on the server they dont seem to be writing to the correct directory

    if(msg.file_path){
        // let full_path = "" + ip + msg.file_path;
        let base64 = msg.file_path;

        let type = get_file_type(msg.file_name);

        if(type === "IMG"){
            content = (
                <img 
                    src = { base64 }
                    style = {{objectFit : "contain", maxWidth : 200, maxHeight : 300, borderRadius : 10, cursor : "pointer"}}
                    onClick = { () => {
                        store.dispatch(set_dialog('img', { images : [ base64 ] }));
                        store.dispatch(set_dialog_status('img', true));
                    }}
                />
            )
        }else if(type === "OTHERS"){
            let on_click = () => {
                window.open(base64);
            }

            content = (
                <Bubble is_me = { is_me } name = { name } is_file>
                    <ChatText
                        text = { msg.file_name }
                        is_me = { is_me }
                        underlined
                        link = { base64 }
                    />
                    <Tooltip placement = "top" title = { t('G:open') }>     
                        <IconButton style = {{marginLeft : 10}} onClick = { on_click }>
                            <FileIcon style = {{ color : "white" }}/>
                        </IconButton>
                    </Tooltip>
                </Bubble>
            )
        }
    }else{
        content = (
            <Bubble is_me = { is_me } name = { name }>
                <ChatText
                    is_me = { is_me }
                    text = {msg.text}
                />
            </Bubble>
        )
    }

    return(
        <div style = {{display : "flex", flexDirection : is_me ? "row-reverse" : "row" , alignItems : "center",  alignSelf : is_me ? "flex-end" : "flex-start",  marginTop : 10}}>
            <Tooltip placement = "top" title = { name }>            
                <Avatar> { name[0] } </Avatar>
            </Tooltip>
            
            <Tooltip placement = { placement } title = { format_date(msg.timestamp, t) }>
                <div style = {{ marginLeft : is_me ? 0 : 10, marginRight : is_me ? 10 : 0}}>
                    { content }
                </div>
            </Tooltip>
        </div>
    )
}