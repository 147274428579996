import React from 'react';

import { Button, TextField, Paper, Typography } from "@material-ui/core"
import { withStyles } from '@material-ui/styles';

import { Link } from "react-router-dom";


import ip from "../constants/ip";
import { withTranslation } from 'react-i18next';

import { withAlert } from 'react-alert'
import login_styles from '../assets/styles/login_styles_2';
import BlueWhiteFrame from '../components/Frames/BlueWhiteFrame';
import AuthenticationHeader from '../components/Frames/FrameReusables/AuthenticationHeader';
import { Link as RouterLink } from 'react-router-dom';
import { is_valid_email } from '../logic/authentication_logic';


const styles = (theme) => ({ ...login_styles(theme) });

class ForgotPasswordPage extends React.Component{
    constructor(props){
        super(props);
        this.state = { email : '' }
    }
    
    componentDidMount(){
        document.title = this.props.t('TITLES:forgot_password')
    }


    submit_form = async() => {
        const { alert, t } = this.props;
        if(!is_valid_email(this.state.email)){
            alert.error( t('SIGN_UP_ERRORS:INVALID_EMAIL_FORMAT') );
            return;
        }
        let response = await fetch(`${ip}/storager/authentication/forgot_password.php`, {
            method : "POST",
            body : JSON.stringify({ email : this.state.email })
        })
        let json_data = await response.json();
        if(json_data.success){
            alert.success( t('FORGOT_PASSWORD:email_has_been_sent') );
            this.props.history.replace('/sign-in');
        }else{
            alert.error( t(`RESET_PASSWORD_ERRORS:${json_data.ERROR_CODE}`) );
        }    
      }

    render(){
    const {classes, t} = this.props;
    return(
        <BlueWhiteFrame>
            <Paper className={classes.form}>
              <form  onSubmit={this.submit_form}>
                <AuthenticationHeader title = { t('FORGOT_PASSWORD:forgot_title') }/>

                <TextField
                    className={classes.textField}
                    fullWidth
                    label = { t('SIGN_IN:email_placeholder') }
                    onChange={ (e) => { this.setState({ email : e.target.value })}}
                    type="text"
                    value={this.state.email}
                    variant="outlined"
                />

                <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label = { t('SIGN_IN:password_placeholder') }
                    onChange = { (e) => this.setState({ password : e.target.value }) }
                    type="password"
                    value={this.state.password}
                />
                <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                { t('SIGN_IN:sign_in') }
                </Button>
                
                <Typography color="textSecondary" variant="body1">
                    { t('SIGN_IN:dont_have_account_link').substring(0, t('SIGN_IN:dont_have_account_link').indexOf('?') + 1 ) }
                    <Link component={RouterLink} to="/sign-up" variant="subtitle1">
                    { t('SIGN_IN:dont_have_account_link').substring(t('SIGN_IN:dont_have_account_link').indexOf('?') + 1, t('SIGN_IN:dont_have_account_link').length) }
                    </Link>
                </Typography>
                </form>
            </Paper>
        </BlueWhiteFrame>
    )
    // return (
    //     <AppFrame>
    //     <Container component="main" maxWidth="xs" className = {classes.container}>
    //     <CssBaseline />
    //     <div className={classes.paper}>
    //         <Avatar className={classes.avatar}>
    //             <LockOutlinedIcon />
    //         </Avatar>
    //         <Typography component="h1" variant="h5">
    //             { t('FORGOT_PASSWORD:forgot_title') }
    //         </Typography>
    //         <div className={classes.form} noValidate>
    //         <TextField
    //             variant="outlined"
    //             margin="normal"
    //             required
    //             fullWidth
    //             id="email"
    //             label = { t('SIGN_IN:email_placeholder') }
    //             name="email"
    //             autoComplete="email"
    //             autoFocus
    //             value = {this.state.email}
    //             onChange = {(e) => this.setState({email : e.target.value})}
    //         />
    //         <Button
    //             // type="submit"
    //             fullWidth
    //             variant="contained"
    //             color="primary"
    //             className={classes.submit}
    //             onClick = {this.submit_form}
    //         >
    //         { t('FORGOT_PASSWORD:button_title') }
    //         </Button>

    //         <Grid container>
    //             <Grid item xs>
    //             <Link to = "/sign-in" variant="body2">
    //                 <TextLink>
    //                     { t('G:sign_in') }
    //                 </TextLink>
    //             </Link>
    //             </Grid>
    //             <Grid item>
    //             <Link to = "/sign-up" variant="body2">
    //                 <TextLink>
    //                     { t('SIGN_IN:dont_have_account_link') }
    //                 </TextLink>
    //             </Link>
    //             </Grid>
    //         </Grid>
    //         </div>
    //     </div>
    //     </Container>
    //     </AppFrame>
    //     )
    }
}

export default(
    withTranslation()(   
        withAlert()(
            withStyles(styles)(ForgotPasswordPage)
        )
    )
);