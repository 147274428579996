export default (theme) => ({
    container : {
        // backgroundColor : "#29C8FF",
        backgroundColor : "white",
        boxSizing : "border-box",
        paddingBottom : theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
        },
    },
    title : {
        fontSize : "2.5em",
        [theme.breakpoints.up('sm')]: {
            fontSize : "4.5em"
        },
    },
    text : {
        fontSize : "1.3em",
    },
    amount_display : {
        borderRadius : 20,
        padding : 30,
        width : "30%",
        fontSize : "2em",
        textAlign : "center",
        fontWeight : "bold",
        backgroundColor : "rgb(250,250,250)",
        border : "1px solid lightgray",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },
    brand_icon : {
        maxWidth : 75,
        maxHeight : 75,
        height: "100%",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            maxWidth: 50,
            maxHeight: 50
        },
    },
});