import React from "react";

import { withStyles, Grid } from "@material-ui/core";


import HIW from "./public/HIW";
import { withTranslation } from "react-i18next";
import tier_prices from "../constants/tier_prices";

import "../assets/css/material_pricing.css";
import PricingComponent from "./PricingComponent";

class OurSubscriptions extends React.Component{
    render(){
        const { t, history } = this.props;

        let tier_names = ['BASIC', 'PRO', 'MASTER', 'CORPORATE'];
        let tiers = [];
        for(var key in tier_names){
            tiers.push(t(`tiers:${tier_names[key]}`, { returnObjects : true }));
        }
        return(
            <Grid container spacing={5} alignItems="center" justify = "center">
                { tiers.map( (tier, i) => (
                    <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={6} lg = {4}>
                        <PricingComponent
                            tier = { tier }
                            on_click = { () => this.props.handle_click( tier.id ) }
                            is_year =  { this.props.interval_checked }
                            iso_code = { this.props.iso_code }
                            current_subscription = { this.props.current_subscription }
                            history = { history }
                            i = { i }
                        />
                    </Grid>
                ))}
            </Grid>
        )
    }
}

export default (
    withTranslation(['SUBSCRIPTIONS'])(
        OurSubscriptions
    )
)