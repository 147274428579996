import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { get_img, get_end_point } from "../../logic/image_logic";

import { useAlert, withAlert } from 'react-alert'
import { withTranslation } from "react-i18next";
import { Zoom, Slide, withWidth } from "@material-ui/core";

import Lottie from 'react-lottie';
import { ImageCarousel } from "../../screens/shop/ArticlePage";
const defualt_img = "https://sanitationsolutions.net/wp-content/uploads/2015/05/empty-image.png";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ImageDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            img : undefined,
            images : [],
            title : ""
        }
    }

    componentWillMount(){
        this.init(this.props);
    }

    componentWillReceiveProps(next_props){
            if(JSON.stringify(next_props.images) !== JSON.stringify(this.props.images) ){
                this.init(next_props);
            }
    }

    init = (props) => {
        const { images : prop_images } = props;
        if(!prop_images){ return }
        this.setState({ images : prop_images.length === 0 ? ["/img/qboxr.png"] : prop_images.map( (id) => get_end_point("ITEM", id ) ) })
    }

    render(){
        const { t } = this.props;
        const full_screen = this.props.width === "xs";
        return(
            <Dialog
                open={this.props.open_status}
                onClose = { this.props.close_modal } //{ () => { this.props.set_open_status(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                fullScreen = { full_screen }
            >
                
                <DialogContent style = {{ display : "flex", justifyContent : "center", alignItems : "center", backgroundColor : full_screen ? "rgb(30,30,30)" : "white", padding : 0 }}>
                    <div>
                        <ImageCarousel images = { this.state.images }/>
                    </div>                
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={ this.props.close_modal } color="primary">
                        { t('G:back') }
                    </Button>
                </DialogActions>
            </Dialog>

        )
    }
}

export default (
    withTranslation()
    (
        withWidth()(
            withAlert()(ImageDialog)
        )
    )
);