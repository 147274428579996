import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import {Elements, StripeProvider} from 'react-stripe-elements';

import CheckoutForm from '../components/Stripe/CheckoutForm';
import { withStyles } from '@material-ui/styles';

import tiers from "../constants/tiers";
import ip from '../constants/ip';
import { FormControlLabel, Switch, IconButton } from '@material-ui/core';

import ItemActionPopup from '../components/IconComponents/ItemActionPopup';
import { f_a_c } from '../functions/money_functions';
import AppFrame from '../components/AppFrame';
import OurSubscriptions from '../components/OurSubscriptions';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert'


import { connect } from "react-redux";
import { stripe_key, default_iso, supported_stripe_iso_codes } from '../constants/stripe_constants';
import tier_prices from '../constants/tier_prices';
import PageLoadingLottie from '../components/PageLoadingLottie';
import { loadScript } from '../logic/init_logic';
import bg_container_styles from "../assets/styles/bg_container.js";
const styles = theme => ({
  content : {
      ...bg_container_styles(theme),
      display : "block"
  },
  heroContent: {
    padding: theme.spacing(8, 0, 8),
    padding : 20,
    color : "white"
  },
  switch_container : {
    float : "right",
    marginBottom : theme.spacing(2),
    alignItems : "center"
  },
  sub_types_text : {
    fontSize : "1.3em",

    [theme.breakpoints.down('sm')]: {
        // textAlign : "center"
        fontSize : "0.9em"
    },      
  },
  tool_container : {
      display : "flex",
      justifyContent : "flex-end",
      alignItems : "center"
  },
  title_styling : {
    fontSize : "2.25em",
    textAlign : "center",
    [theme.breakpoints.up('sm')]: {
        fontSize : "3.25em"
    },
  },
  sales_pitch : {
    [theme.breakpoints.up('sm')]: {
        fontSize : "1.25em"
    },
}
});


class Pricing extends React.Component {

  constructor(props){
      super(props);

      this.state = {
        subscription_modal_open : false,
        subscription_type : undefined,
        tier : undefined,
        currency_iso : "sek",
        current_subscription : undefined,
        interval_checked : false,
        warning : '',
        stripe : null
      }
  }
  
  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:pricing') 
  }



  async componentWillMount(){
    const { iso_data, subscription_loaded } = this.props;

    let stripe_loaded = await loadScript("https://js.stripe.com/v3/");
    this.setState({ stripe_loaded : stripe_loaded });

    let jwt = localStorage.getItem('jwt');
    if( subscription_loaded && iso_data || jwt === null && iso_data){
        this.set_subscription_iso_code(iso_data);
    }
  }

  async componentWillReceiveProps(nextprops){
    const { iso_data, subscription_loaded } = nextprops;

    let jwt = localStorage.getItem('jwt');

    if( (!this.props.iso_data && nextprops.iso_data !== undefined || !this.props.subscription_loaded && nextprops.subscription_loaded) && jwt !== null ){
        this.set_subscription_iso_code(nextprops.iso_data);
        return;
    }
    if( jwt === null && !this.props.iso_data && nextprops.iso_data){
        this.set_subscription_iso_code(nextprops.iso_data);
    }
    
  }


  get_upgrade_downgrade_data = async() => {
    const {alert, t} = this.props;
    if(!this.props.current_subscription) return;

    let interval = this.state.interval_checked ? 'year' : 'month';
    if(this.state.tier === this.props.current_subscription.app_identifier && interval === this.props.current_subscription.interval_code){
        alert.error( t('ERRORS:SAME_SUBSCRIPTION_SHORT') );
        this.setState({subscription_modal_open : false, warning : t('ERRORS:SAME_SUBSCRIPTION_SHORT')});
        return;    
    }


    let jwt = await localStorage.getItem('jwt');
    let proration = await fetch(`${ip}/storager/payments/get_proration.php`, {
        method : "POST",
        body : JSON.stringify({
            jwt : jwt,
            iso_code : this.state.iso_code,
            tier_code : this.state.tier,
            interval : interval
        })
    });

    let json_data = await proration.json();
    
    let tier_price = tier_prices[this.state.iso_code][interval][this.state.tier];

    //The proration is the extra / minus we have ALREADY from our account 
    let next_payment_cost = Math.round(json_data.cost / 100 * 100) / 100;

    this.setState({ 
        warning : t('PURCHASE_SUBSCRIPTION:next_payment_information', {
            next_payment : f_a_c(next_payment_cost, this.state.iso_code),
            new_price    : f_a_c(tier_price, this.state.iso_code)
        }) 
    });
}

  handle_click = (tier) => {
    this.setState({warning : undefined});
    this.setState({
        subscription_modal_open : true,
        tier : tier,
    }, () => {
        this.get_upgrade_downgrade_data();
    })
  }

  set_currency_iso = ( val ) => {
      this.setState({ currency_iso : val }, () => {
        if(this.state.tier){
            let itterate_over = tiers(this.state.currency_iso)/*[this.state.currency_iso]*/;
            itterate_over.forEach(tier_instance => {
                if(tier_instance.title === this.state.tier.title){
                    this.setState({tier : tier_instance});
                }
            });
        }
      });
  }

  set_subscription_iso_code = (iso_data) => {
    const { current_subscription } = this.props;
    console.log(current_subscription);
    let iso_code;
    if(current_subscription && current_subscription.iso_code){
        iso_code = current_subscription.iso_code;
    }else if(typeof iso_data !== "undefined"){
        if(supported_stripe_iso_codes.includes(iso_data.code)){
            iso_code = iso_data.code;
        }else{
            iso_code =  default_iso;
        }
    }
    this.setState({ iso_code : iso_code });
  }
  
  render(){
    const { classes, t, history } = this.props;

    const iso_code = this.state.iso_code;
    
    if(typeof iso_code === "undefined"){ return <AppFrame><PageLoadingLottie/></AppFrame> }

    return (
        <AppFrame no_spacing>
        <div className = { classes.content }>
        {/* Hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <h1 className = { classes.title_styling }>{ t('title') }</h1>
            <Typography variant="subtitle2" align="center" color="textSecondary" component="p" className = { classes.sales_pitch }>
                { t('text_big') }
            </Typography>
        </Container>
        
        {/* End hero unit */}
        <Container maxWidth="lg" component="main">
            <Grid container style = {{justifyContent : "center", alignItems : "center", padding : 20}}>
                <Grid item xs = {9} md = {8}>
                    <Typography variant = "body2" style = {{color : "white"}} className = {classes.sub_types_text}>
                        { t('text_small') }
                    </Typography>   
                </Grid>
                
                <Grid item xs = {3} md = {4} className = {classes.tool_container}>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={this.state.interval_checked}
                            onChange={() => {this.setState({interval_checked : !this.state.interval_checked})}}
                            value= {this.state.interval_checked}
                            color = "primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        }
                        label = { t('switch_label') }
                        style = {{ color : "white" }}
                        labelPlacement = "top"
                        className = {classes.switch_container}
                    />
                </Grid>
            </Grid>

            <OurSubscriptions
                iso_code = { iso_code }
                current_subscription = { this.props.current_subscription }
                handle_click = {this.handle_click}
                interval_checked = {this.state.interval_checked}
                history = { history }
            />
            </Container>
        
        {
        this.state.stripe_loaded ? 
        <StripeProvider apiKey = { stripe_key } >
            <div className="example">
            { (this.state.subscription_modal_open) ? 
            <Elements>
                <CheckoutForm
                    is_year     = { this.state.interval_checked }
                    open_status = {this.state.subscription_modal_open}
                    toggle_open_status = { () => this.setState({subscription_modal_open : !this.state.subscription_modal_open}) }
                    tier = { this.state.tier }
                    iso_code = { iso_code }
                    current_subscription = {this.props.current_subscription}
                    set_current_subscripton = { (subscription) => this.setState({current_subscription : subscription}) }
                    warning = { this.state.warning }
                /> 
            </Elements>
            : null
            }
            </div>
        </StripeProvider>
        : null
        }
        </div>
        </AppFrame>
    );
  }
}

const mapStateToProps = (state , ownProps) => {
    return {
      current_subscription : state.global.user_subscription,
      subscription_loaded : state.global.subscription_loaded,
      n_tokens : state.global.n_tokens,
      iso_data : state.global.iso_data
    }
} 

export default (
    connect(mapStateToProps)
    (
        withStyles(styles)
        (
            withAlert()
            (
                withTranslation('DASH_SUBSCRIPTION_PAGE')(Pricing)  
            )
        )
    )
);