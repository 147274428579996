import React from "react";
import get_cat_icon from "../../../../screens/shop/get_category_icon";
import { Grid, ListSubheader, Typography, Tooltip, Paper, Avatar } from "@material-ui/core";
import ExpandActionMenu from "./ExpandActionMenu";
import theme from "../../../../assets/styles/theme";
import { f_a_c } from "../../../../functions/money_functions";
import { set_dialog } from "../../../../actions/dialog_actions";
import ip from "../../../../constants/ip";
import { store } from "../../../../store";
import { update_item } from "../../../../actions/box_actions";
import { CircularProgress } from "@material-ui/core";
import { get_end_point } from "../../../../logic/image_logic";
import { format_date } from '../../../../functions/date_functions';


// export const get_private_images = async(item_id) => {
//   let response = await fetch(`${ip}/storager/get_requests/get_img.php`, {
//     method : "POST",
//     body : JSON.stringify({ type : "ITEM", id : item_id })
//   })
//   let images = await response.json();
//   return format_image_urls(images);
// }

//Call this for each item shown that is not in a public access route for item image
// export const format_image_urls = (img_arr) => {
//   let jwt = localStorage.getItem('jwt');
//   return img_arr.map( img_obj => (
//     { ...img_obj, url : img_obj.url + "&auth=" + jwt }
//   ))
// } 

export const get_image_urls = (arr) => (
  arr ? arr.map( img => get_end_point(img) ) : []
)

export const init_category = (t, item) => {
  let set = { sales_category : undefined, sales_sub_category : undefined };

  let sales_categories =  t('SHOP:dropdown_categories', { returnObjects : true });
  let sales_sub_categories = t('SHOP:dropdown_sub_categories', { returnObjects : true });     
  sales_categories.forEach( group => {
    group.items.forEach( obj => {
      if( obj.value == item.sales_category ) set.sales_category = obj.text
    })
  });

  if( typeof sales_sub_categories[item.sales_category] === "object" ){
    sales_sub_categories[item.sales_category].forEach( obj => {
      if( obj.value === item.sub_category ) set.sales_sub_category = obj.text
    });
  }
  return set;
}

class Expand extends React.PureComponent{
    constructor(props){
      super(props);
  
      const { t, item } = this.props;
      
      this.state = {
        is_loading : false,
        sales_category : undefined, sales_sub_category : undefined,
      }
    }


    handle_img_click = () => {
        const { item } = this.props;
        store.dispatch(set_dialog('img', { images : item.images, open_status : true }));
    }

    async componentWillMount(){
        const { item, t } = this.props;
        if( item.is_for_sale ){
          this.setState(init_category(t, item));
        }
    }

  
    render(){
      const { item, t, alert } = this.props;
  
      return(
        <Grid container  style = {{ paddingVertical : 10, alignItems : ""}}>
  
          <Grid onClick = { this.handle_img_click } conainer item md = { 2 } style = {{ overflow : "hidden", borderRadius : 10, padding : 10, alignSelf : "center", justifyContent : "center", cursor : "pointer" }}>
            {
                this.state.is_loading ?
                <div style = {{ display : "flex", justifyContent : "center", alignItems : "center" }}>
                    <CircularProgress style = {{ alignSelf : "center" }} />
                </div> : 
                
                <Tooltip title = { t('G:open') }>
                  <img style = {{ maxHeight : 150, width : "100%",  maxWidth : "100%", borderRadius : 10, overflow : "hidden",objectFit :"contain" }} src = { item.images.length > 0 ? get_end_point( "ITEM", item.images[0] ) : "/img/qboxr.png"}/>
                </Tooltip>
            }
          </Grid>
  
          <Grid item xs = { 12 } md = { 3 }>
            <ListSubheader>{ t('G:information') }</ListSubheader>
            <div style = {{ paddingLeft : 15 }}>
              <Typography variant = "h6"> { item.title }</Typography>
              <Typography variant = "body2">{ t('ADD_ITEM_DIALOG:item_category_title') } : { item.category }</Typography>
              <Typography variant = "body2">{ t('ADD_ITEM_DIALOG:n_of_item_title') } : { item.n_of_item }</Typography>
              <Typography variant = "body2">{ t('G:date') } : { format_date(item.submit_date, t) }</Typography>
            </div>
          </Grid>
  
          <Grid item xs = { 12 } md = { 4 } >
            <ListSubheader>{ t('ADD_ITEM_DIALOG:sales_information') }</ListSubheader>
            <div style = {{ paddingLeft : 15, marginBottom : 5 }}>
              <div style = {{ display : "flex", justifyContent : "space-between", alignItems : "center" }}>
                <Typography variant = "h6"> { item.is_for_sale ? t('MY_SHOP:for_sale') : t('MY_SHOP:not_for_sale')  }</Typography>
                {
                  item.is_for_sale ?
                  <Tooltip title = { this.state.sales_category }>
                  <Avatar style = {{ marginRight : 10 }}>
                    {  get_cat_icon(item.sales_category)  }
                  </Avatar>
                  </Tooltip> : null
                } 
              </div>
              {
                item.is_for_sale ? 
                <>
                  <Typography variant = "body2">{ t('ADD_ITEM_DIALOG:sales_category') } : { this.state.sales_category }</Typography>
                  {
                    this.state.sales_sub_category ? 
                    <Typography variant = "body2">{ t('ADD_ITEM_DIALOG:sub_category') } : { this.state.sales_sub_category }</Typography> : null
                  }
                  <Typography variant = "body2">{ t('ADD_ITEM_DIALOG:price') } : { f_a_c(item.price, item.iso_code) }</Typography>
                </>
                : null
              }
            
            </div>
          </Grid>
  
          <Grid item xs = { 12 } md = { 3 }  style = {{ paddingLeft : 10, paddingRight : 10}}>
            <ExpandActionMenu {...this.props}/>
          </Grid>
  
        </Grid>
      )
    }
}
export default Expand;