import {
    SET_FORM_VALUE
} from "../../actions/shop_form_actions.js"
  
const ShopFormInitalState = {
    sales_category : 'ALL',
    query_string : '',
    sub_category   : 'SUB_CATEGORY',
    price_min : "",
    price_max : "",
    radius : 50,
    filter_type : "DATE_RECENT",
    
    page : 0,
};
  
export default function ShopFormReducer(state = ShopFormInitalState, action) {

      switch(action.type) {
        case SET_FORM_VALUE:
        //   alert("Called with " + action.payload.name + "  :  " + action.payload.value);
          return {
            ...state,
            [action.payload.name] : action.payload.value
          };
          
        default :
          return state;
      }
    }