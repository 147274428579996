import {
    ADD_MESSAGE, SET_CONVERSATIONS, SET_MESSAGES, MARK_ALL_AS_READ, SORT_CONVERSATIONS, SET_CONVERSATION_ID, SET_CONVERSATION, ADD_CONVERSATION
} from '../actions/chat_actions';
import { FilledInput } from '@material-ui/core';


const initialState = {
    conversations : [],
    
    active_conversation_id : undefined,

    active_conversation : undefined,

    widet_open_status : false
};


function insert_message_in(conversations, msg){
    let conversations_updated = conversations.map( convo => {
        if( convo.conversation_id !== msg.conversation_id){
            return convo;
        }else{
            convo.messages.push(msg);
            return convo;
        }
    });
    return conversations_updated;
}

function get_conversations_with_insterted_messages(conversations, messages){
    if(typeof messages !== "object" || messages.length === 0){
        console.log("Will just return normal");
        return conversations;
    }else{
        return conversations.map( (convo, i) => {
            if(convo.conversation_id !== messages[0].conversation_id){
                console.log("Called " + messages[0].conversation_id);
                return convo;
            }else{
                convo.messages = messages;
                console.log("Changed to");
                console.log(messages);
                return convo;
            }
        });
    }
}

function mark_all_messages(conversations, conversation_id){
    return conversations.map( convo => {
        if(convo.conversation_id !== conversation_id){
            return convo;
        }else{
            convo.messages.forEach( msg => {
                msg.is_read = 1;
            });
            return convo;
        }
    }); 
}

function sort_conversations(conversations){
    
    let sorted_conversations = conversations.slice().sort((a,b) => { 
        if(a.messages.length === 0){
            return 1;
        }else if(b.messages.length === 0){
            return null;
        }
        return b.messages[b.messages.length - 1].message_id - a.messages[a.messages.length - 1].message_id
    })
    return sorted_conversations;

    // alert(conversations === sorted_conversations);
    // return sorted_conversations;
}




export default function ChatReducer(state = initialState, action) {

    switch(action.type) {
      case ADD_MESSAGE:
        let msg_to_add = action.payload;
        
        if (state.active_conversation_id === msg_to_add.conversation_id){ //If the reciever is ACTIVLY in the conversation of the which this messages is desired to be pushed to, mark is a read
            msg_to_add.is_read = true;
        }

        // console.table(state.conversations);
        // console.table(msg_to_add);
        let convos_with_inserted = sort_conversations( insert_message_in(state.conversations, msg_to_add) );
        
        return {
          ...state,
          conversations : convos_with_inserted
        }
    
      case SET_MESSAGES:
        let convos_with_initiated_messages = get_conversations_with_insterted_messages(
            state.conversations,   
            action.payload 
        );
        return {
          ...state,
          conversations : convos_with_initiated_messages
        }

      case ADD_MESSAGE:
        return {
            ...state,
            conversation : [...state.conversations, action.payload]
        }

      case SET_CONVERSATIONS :
        let conversations_to_init = action.payload;
        return {
            ...state,
            conversations : conversations_to_init
        }

        case ADD_CONVERSATION:
            return {
                ...state,
                conversations : [...state.conversations, action.payload]
            }
      
      case SET_CONVERSATION_ID : 
        return {
        ...state,
        active_conversation_id : action.payload
      }
      
      case SET_CONVERSATION : 
      return {
        ...state,
        active_conversation : action.payload
        }

      case MARK_ALL_AS_READ : 
        let conversations_with_marked_messages = mark_all_messages(state.conversations, action.payload); // We are actually directly modifying the state here so we don't really need to set the state aftwewards...
        return {
            ...state,
            conversations : conversations_with_marked_messages
        }
        
      case SORT_CONVERSATIONS:
        let sorted_converastions = sort_conversations(state.conversations);
        return {
            ...state,
            conversations : sorted_converastions//sort_conversations(state.conversations)//state.conversations.slice().sort((a,b) => { return a.messages[a.messages.length - 1].message_id <= b.messages[b.messages.length - 1].message_id})
        }
        

        default:
            return state;
    }
}