import { api_call_based_on_jwt } from "./init_logic";
import { set_dialog_status } from "../actions/dialog_actions";
import { store } from "../store";

export async function delete_account(password, alert, t){
    let response = await api_call_based_on_jwt("/authentication/delete_account.php", { password });
    if(response.success){
        store.dispatch( set_dialog_status('password_prompt', false) );
        setTimeout( () => store.dispatch( set_dialog_status('ays', false) ), 250 );
        setTimeout( () => window.location.reload(), 500 );
    }else{
        alert.info(t('ERRORS:' + response.error_code));
    }
}

export function is_valid_email(str) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(str.toLowerCase());
}