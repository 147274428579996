import React, { Fragment } from 'react';
import {  withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { Grid, FormControl, FormHelperText, Button, IconButton, Select, MenuItem } from '@material-ui/core';

import SimpleSelect from '../SimpleSelect';
import CurrencyTextField from "../CurrencyTextfield";
import { withTranslation } from 'react-i18next';

import ip from '../../../constants/ip';

import {connect} from "react-redux"


import { set_form_value } from "../../../actions/shop_form_actions";
import { withAlert } from 'react-alert'
import ShopAutoComplete from '../ShopAutoComplete';
import { bindActionCreators } from 'redux';

const styles = (theme) => ({
    formControl : {
        display : "flex",
        flex : 1,
        height : "100%",
        flexDirection : "column",
        margin: `${theme.spacing(1)}px 0`,
        alignSelf : "center",
      }
});


class FilterForm extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        last_query : ""
      }
    }

    // submit = async() => {
    //   const { form_data, page, set_articles, add_articles, set_page, alert } = this.props;

    //   let response = await fetch(`${ip}/storager/shop/get_requests/search_articles.php`, {
    //     method : "POST",
    //     body : JSON.stringify({
    //       page : page,
    //       form_data : JSON.stringify(form_data)
    //     })
    //   });
    //   let json_data = await response.json();

    //   if(json_data.success && json_data.items.length > 0){
    //     let performed_query = JSON.stringify(form_data);
    //     if( this.state.last_query !==  performed_query){
    //       set_articles( json_data.items );
    //       this.setState({ last_query : performed_query })
    //       set_page(0); // If it is a new query start from the bottom        
    //     }else{
    //       // set_page(page + 1);
    //       add_articles( json_data.items );
    //     }
    //   }else{
    //     alert.info("No more results");
    //   }

    //   console.log(json_data);
    // }
  
    render(){
  
      const { classes, t, form_data, set_form_value } = this.props;
  
      const sales_categories = t('SHOP:dropdown_categories', { returnObjects: true });
      const sub_categories   = t('SHOP:dropdown_sub_categories', { returnObjects : true });
  
      const has_category = form_data.sales_category !== "ALL" && form_data.sales_category !== undefined;
  
      return (
        <>
        {/* <Grid container className = {classes.form_container} spacing = {2}>
          <Grid item xs = {12}>
            <Typography variant = "h6" component = "b"> { t('G:filter') } </Typography>
          </Grid>
  
          <Grid item lg = { 3 } sm = { 6 } xs = { 12 }>
            <SimpleSelect
              value = { form_data.sales_category } //Where value is the index of the wanted element in the dropdown
              values = { sales_categories }
              set_value = { (value) => {
                let new_instance = sub_categories[value];
                let sub_category_start = new_instance[Object.keys(new_instance)[0]].value;;
                
                set_form_value('sales_category', value);
                set_form_value('sub_category', sub_category_start);
              }}
              label = { t('ADD_ITEM_DIALOG:sales_category') }
            />
          </Grid>
  
          <Grid item lg = { 3 } sm = { 6 } xs = { 12 }>
            <SimpleSelect
              value = { form_data.sub_category } //Where value is the index of the wanted element in the dropdown
              values = { sub_categories[form_data.sales_category] }
              set_value = { (value) => {
                set_form_value('sub_category', value);
              }}
              disabled = { !has_category }
              label = { t('ADD_ITEM_DIALOG:sub_category') }
            />
          </Grid>
  
          <Grid item lg = { 2 } sm = { 6 } xs = { 12 }>
            <FormControl className={classes.formControl}>
              <CurrencyTextField
                value = { form_data.price_min }
                set_value = { (val) => set_form_value('price_min', val)}
                placeholder = { t('FILTER_ELEMENTS:price_min') }
              />
              <FormHelperText> { t('FILTER_ELEMENTS:price_min') } </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item lg = { 2 } sm = { 6 } xs = { 12 }>
            <FormControl className={classes.formControl}>
              <CurrencyTextField
                placeholder = { t('FILTER_ELEMENTS:price_max') }
                value = { form_data.price_max }
                set_value = { (val) => set_form_value('price_max', val) }
              />
              <FormHelperText> { t('FILTER_ELEMENTS:price_max') } </FormHelperText>
            </FormControl>
          </Grid>
  
          <Grid item lg = { 2 } xs = { 12 }>
              <FormControl className={classes.formControl}>
                <Button variant = "contained" color = "primary" style = {{paddingTop : 15, paddingBottom : 15}} onClick = { () => this.props.submit(0) }>
                  { t('G:search') }
                </Button>
              </FormControl>
          </Grid>
        </Grid> */}
        <Grid container spacing = { 2 } >

          <Grid item xs = { 12 }>
            <ShopAutoComplete />
          </Grid>
{/* 
          <Grid item xs = { 2 }>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style = {{ width : "100%", height : "85%" }}
              variant = "outlined"
              value={100}
              // onChange={handleChange}
            >
              <MenuItem value="">
                <em>Alla</em>
              </MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={70}>70</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={90}>90</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>

          <Grid item xs = { 2 }>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style = {{ width : "100%", height : "85%" }}
              variant = "outlined"
              value={100}
              // onChange={handleChange}
            >
              <MenuItem value="">
                <em>Alla</em>
              </MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={70}>70</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={90}>90</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid> */}

        </Grid>
        </>
      )
    }
  }
  

  const mapStateToProps = (state , ownProps) => {
    return {
      form_data  : state.shop_form,
    }
  }

  const mapDispatchToProps = dispatch => bindActionCreators(
    {
      set_form_value
    },
    dispatch,
  )
  

  export default (
    withStyles(styles)(
        withTranslation()(
          withAlert()(
            connect(mapStateToProps, mapDispatchToProps)(
              FilterForm
            )
          )
        )
    )
  );