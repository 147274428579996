import React from "react";

import Lottie from 'react-lottie';

import { f_a_c } from '../../functions/money_functions';
import custom_theme from "../../assets/styles/theme";
import PublicButton from "./PublicButton";
import { withStyles, Typography } from "@material-ui/core";

import public_theme from "../../assets/styles/public_styles";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

function HIW(props){
    const {classes} = props;

    let title_align = ( props.title_align ) ? props.title_align : "center";

    return(
        <div className = {classes.container} style = {props.container_styles}>    
            {
                props.lottie ? 
                <Lottie
                    options={{...defaultOptions, animationData : props.lottie, autoplay : ( (props.autplay !== undefined) ? false : true ) }}
                    height= {props.size ? props.size : 200}
                    width= {props.size ? props.size : 200}
                /> : 
                <div style = {{display : "flex", justifyContent : "center", alignItems : "center" }}>
                    <img alt = "How it works" src = {props.url} style = {{width : "100%", height : "100%", borderTopLeftRadius : 7.5, borderTopRightRadius : 7.5}}/>
                </div>
            }
            
            <div style = {{
                padding : (typeof props.text === 'object') ? 30 : 30
            }}>
                <div className = {{display : "flex",  justifyContent : "space-between"}}>
                    <div style = {{flex : 1,fontWeight : "bold", fontSize : "1.6em", textAlign : title_align}}>{props.title}</div>
                    {
                        (props.price) ? 
                            <div style = {{fontWeight : "bold", fontSize : "1.6em",}}>{f_a_c(props.price, props.iso_code) + "/" + props.interval}</div> : null
                    }
                </div>
                <p style = {{fontSize : "1.3em", color : "gray", textAlign : "center"}}>{props.text}</p>
                
            </div>
            
            <div className = {classes.number_display}>{props.number}</div>
        </div>
    )
}

const styles = (theme) => ({
    ...public_theme(theme),
    text : {
        fontSize : "2em",
        fontFamily : "Roboto"
    },
    container : {
        // ...this.props.container_styles,
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        borderRadius : 10,
        minWidth : "100%",
        flexGrow : 1,
        flex : 1,
        border : "1px solid lightgray",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        position : "relative",
        marginTop : theme.spacing(3),
    },
    number_display : {
        position : "absolute",
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        top : 10,
        left : 10,
        height : 40,
        width : 40,
        borderRadius : "100%",
        color : custom_theme.PRIMARY_COLOR,
        backgroundColor : "white",//custom_theme.GREEN_COLOR,
        fontWeight : "bold",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    }
});

export default withStyles(styles)(HIW);