import React from "react";

import { withRouter } from 'react-router-dom';

// import { withTranslation } from 'react-i18next';

import bg_container_styles from "../../assets/styles/bg_container.js";
import AppFrame from "../AppFrame.js";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({ content : bg_container_styles(theme) });


class BlueWhiteFrame extends React.PureComponent{
    constructor(props){
        super(props);
    }
    render(){
        const { classes } = this.props;
        return(
            <AppFrame no_spacing>
                <div className = { classes.content }>
                    { this.props.children }
                </div>
            </AppFrame>
        )
    }
}


export default withRouter(
    // withTranslation()(
    //     withAlert()(
          withStyles(styles)(
            BlueWhiteFrame
          )
    //     )
    // )
);