import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';

import DeleteIcon from "@material-ui/icons/Delete";

import ip from "../../constants/ip";
import { prepare_socket_action } from "../../logic/action_socket_logic";
import { withTranslation } from "react-i18next";

import Transition from "./Transition";
import { withWidth } from "@material-ui/core";

const defualt_img = "https://sanitationsolutions.net/wp-content/uploads/2015/05/empty-image.png";


const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    drop_zone : {
        display : "flex",
        cursor : "pointer",
        backgroundColor : "rgb(230,230,230)",
        height : 150,
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "Roboto",
        marginTop : theme.spacing(4)
    },
    formControl : {
        width : "100%",
        marginTop : theme.spacing(2)
    }
});


class DialogBase extends React.Component{
    constructor(props){
        super(props);
    }

    render(){

        const {classes, t, width} = this.props;

        const full_screen = width === "xs";

        return(
            <Dialog
                open={this.props.open_status}
                onClose={ this.props.close_modal }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                fullScreen = { full_screen }
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                
                {/* <DialogContent> */}
                    { this.props.children }
                {/* </DialogContent> */}
                
                {/* <DialogActions>
                <Button variant="contained" color="default" className={classes.button} onClick = {this.props.close_modal}>
                    {this.props.cancel_text ? this.props.cancel_text : t('G:back') }
                </Button>
                {
                    this.props.buttons && this.props.buttons.map( (obj, i) => (
                        <Button variant="contained" color="default" className={classes.button} onClick = {obj.onClick}>
                            { obj.text }
                        </Button>
                    ))
                }
                </DialogActions> */}
            </Dialog>
        )
    }
}

export default (
    withStyles(styles)
    (
        withTranslation()
        (
            withWidth()(
                DialogBase
            )
        )
    )
);