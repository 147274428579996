import React from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { google_maps_key } from '../../constants/google_keys';


function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, cursor, selectedItem } = suggestionProps;
  const isHighlighted = cursor === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        zIndex : 3000
      }}
      onMouseDown = { (e) => {
        suggestionProps.handle_select(suggestion)
      }}
    >
      {suggestion.address}
    </MenuItem>
  );
}




const styles = ( theme ) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 3000,
    marginTop: theme.spacing(1),
    left: 0,
    maxHeight : 200,
    overflowY : "auto",
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});


class DownshiftSingle extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      cursor : 0,
      suggestions : []
    }
    this.timeout =  0;
  }

  handle_select = (suggestion) => {
    this.setState({ value : suggestion.address, is_open : false});

    this.props.set_cords(suggestion.cords);
    this.props.set_address(suggestion.address);
  }

  set_suggestions = async(value) => {

    if(this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout( async() => {
      if(value.length === 0){ return };


      let formatted_str = encodeURIComponent(value.trim());
      let response = await fetch(`https://maps.googleapis.com/maps/api/place/textsearch/json?fields=formatted_address,name,geometry&language=en&query=${formatted_str}&key=${google_maps_key}`)
      let json_data = await response.json();
    
      let suggestions = [];

      if(json_data.results.length > 0){
        suggestions = json_data.results.map( data_set => {
          return {
            address : data_set.formatted_address, // Str
            cords   : data_set.geometry.location, //Lat lng
          }
        });
      }
      this.setState({ suggestions : suggestions })
    }, 150);
  }

  handle_key_down = ( e ) => {
    const { cursor, suggestions } = this.state

    if (e.keyCode === 38 && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    }else if (e.keyCode === 40) {
      this.setState( prevState => ({
        cursor: ( cursor < suggestions.length - 1 ) ? prevState.cursor + 1 : 0
      }))
    }

    if(e.keyCode === 13 && cursor >= 0 && cursor < suggestions.length){
      this.handle_select(suggestions[cursor]);
    }

  }

  render(){
    const { t, classes } = this.props;
    return (
      <div className={classes.container}>
        <TextField
            fullWidth = {true}
            label = { t('A_B_S_1:box_address_title') }
            variant = { "outlined" }
            value = { this.props.address }
            onChange = { ( e ) => {
              this.props.set_address(e.target.value);
              this.setState({
                is_open : (e.target.value.length > 0)
              });
              this.set_suggestions(e.target.value);
            }}
            onFocus = { () => { this.setState({is_open : true}) } }
            onBlur  = { () => { this.setState({is_open : false}) } }
            onKeyDown = { this.handle_key_down }
        />


        <Paper className={classes.paper} square>
          {
            this.state.suggestions.length > 0 && this.state.is_open ?
              <>
              {
                this.state.suggestions.map((suggestion, index) => {
                  return(
                    renderSuggestion({
                      suggestion : suggestion,
                      index : index,
                      cursor : this.state.cursor,
                      handle_select : this.handle_select
                    })
                  )
                })
              }
              <img src = "/img/google/powered_by_google2.png" style = {{float : "right", padding : "5px 0"}}/>
              </>
            : null
          }
        </Paper>
        </div>
      );
    }
}

export default (
  withTranslation()(

    withStyles(styles)(
      DownshiftSingle
    )
  )
);




// import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import parse from 'autosuggest-highlight/parse';
// import throttle from 'lodash/throttle';

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

// const autocompleteService = { current: null };

// const useStyles = makeStyles(theme => ({
//   icon: {
//     color: theme.palette.text.secondary,
//     marginRight: theme.spacing(2),
//   },
// }));

// export default function GoogleMaps() {
//   const classes = useStyles();
//   const [inputValue, setInputValue] = React.useState('');
//   const [options, setOptions] = React.useState([]);
//   const loaded = React.useRef(false);

//   const handleChange = event => {
//     setInputValue(event.target.value);
//   };

//   const fetch = React.useMemo(
//     () =>
//       throttle((input, callback) => {
//         autocompleteService.current.getPlacePredictions(input, callback);
//       }, 200),
//     [],
//   );

//   React.useEffect(() => {
//     let active = true;

//     if (!autocompleteService.current && window.google) {
//       autocompleteService.current = new window.google.maps.places.AutocompleteService();
//     }
//     if (!autocompleteService.current) { return undefined; }

//     if (inputValue === '') { setOptions([]); return undefined; }

//     fetch({ input: inputValue }, results => {
//       console.log( results );
//       if (active) {
//         setOptions(results || []);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [inputValue, fetch]);

//   return (
//     <Autocomplete
//       id="google-map-demo"
//       style={{ flex : 1 }}
//       getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
//       filterOptions={x => x}
//       options={options}
//       autoComplete
//       includeInputInList
//       freeSolo
//       disableOpenOnFocus
//       renderInput={params => (
//         <TextField
//           {...params}
//           label="Add a location"
//           variant="outlined"
//           fullWidth
//           onChange={handleChange}
//         />
//       )}
//       renderOption={option => {
//         const matches = option.structured_formatting.main_text_matched_substrings;
//         const parts = parse(
//           option.structured_formatting.main_text,
//           matches.map(match => [match.offset, match.offset + match.length]),
//         );

//         return (
//           <Grid container alignItems="center">
//             <Grid item>
//               <LocationOnIcon className={classes.icon} />
//             </Grid>
//             <Grid item xs>
//               {parts.map((part, index) => (
//                 <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
//                   {part.text}
//                 </span>
//               ))}

//               <Typography variant="body2" color="textSecondary">
//                 {option.structured_formatting.secondary_text}
//               </Typography>
//             </Grid>
//           </Grid>
//         );
//       }}
//     />
//   );
// }
