import React from 'react';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/styles';
import { api_call_based_on_jwt } from '../logic/init_logic';

import AppFrame from "../components/AppFrame";
import { connect } from 'react-redux'
import PageLoadingLottie from "../components/PageLoadingLottie";
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert'
import { withWidth, Hidden} from '@material-ui/core';

import { set_conversations, sort_conversations, set_messages, mark_all_as_read, set_conversation_id, set_conversation} from '../actions/chat_actions';
import { bindActionCreators } from 'redux';

import { store } from "../store";
import { find_conversation, load_messages } from '../logic/chat_logic';
import Conversations from '../components/Chat/Conversations';
import MessageFeed from '../components/Chat/MessageFeed';

import queryString from 'query-string';


import useMediaQuery from '@material-ui/core/useMediaQuery';

const withMediaQuery = (...args) => Component => props => {
  const mediaQuery = useMediaQuery(...args);
  return <Component mediaQuery={mediaQuery} {...props} />;
}

const styles = theme => ({
  chat_container : {
    display : "flex",
    alignSelf : "center",
    height : "calc(100vh - 75px)",
    overflow : "hidden",
    [theme.breakpoints.up('sm')]: {
      height : "calc(100vh - 80px)",
    },
  }
});

class ChatPage extends React.Component{
  constructor(props){
    super(props);

    this.state = {
        u_id : undefined,
        navbar_height : undefined,
        clicked_conversation : false
    }
  }

  componentWillMount(){
    const { conversations } = this.props;
    if(conversations){
      this.handle_page_load(conversations);
    }
  }

  componentWillReceiveProps(nextprops){
    if(this.props.conversations === undefined && nextprops.conversations !== undefined){
      this.handle_page_load(nextprops.conversations)
    }
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:chat');
  }

  
  handle_page_load = (conversations) => {
    const { history, alert, t } = this.props;

    if(conversations.length > 0){

      let params = queryString.parse(this.props.location.search);
      let conversation_id = params.conversation;
      
      if(conversation_id){
        let convo_attempt = find_conversation(conversations, parseInt(conversation_id));
        if(convo_attempt){
          load_messages(convo_attempt, alert); 
          this.setState({ clicked_conversation : true })
        }else{
          load_messages(conversations[0], alert); 
          this.setState({ clicked_conversation : true })
        }
      }else{
        load_messages(conversations[0], alert); 
      }
    }else{
      alert.info( t('CHAT:no_chats') );
      if(history.length > 0){
        history.goBack();
      }else{
        window.location.replace('/app/');
      }
    }
  }

  render(){
    const {classes, t, width } = this.props; //Inherit styles from higher order component

    const { conversations, active_conversation, u_id } = this.props;

    console.log( this.state.clicked_conversation ? true : false );

    return (
    <AppFrame hideNav no_spacing>
      {
      ( u_id !== undefined && conversations !== undefined && active_conversation !== undefined) ? 
        <Grid container className = { classes.chat_container}>
            
            
              {/* ( !this.state.clicked_conversation && width === 'xs' || width !== 'xs') ?  */}
              <Hidden smDown = { this.state.clicked_conversation ? true : false }>
                <Grid item sm = {4} xs = {12}
                  style = {{
                  height : "100%"
                  }}
                >
                  <Conversations
                      u_id ={ u_id }
                      conversations = { conversations }
                      set_clicked_conversation = { ( bool ) => this.setState({clicked_conversation : bool}) }
                  />
                </Grid>
              </Hidden>
            {/* } */}
                  
            <Hidden smDown = { this.state.clicked_conversation ? false : true }>
              <Grid item sm = { 8 } xs = {12}
                style = {{
                  maxHeight : "100%",
                  height : "100%",
                }}
              >
              <MessageFeed
                  conversations = { conversations }
                  u_id = { u_id }
                  set_clicked_conversation = { ( bool ) => this.setState({clicked_conversation : bool}) }
              />
            </Grid>
            </Hidden>
        </Grid>
      :
      <PageLoadingLottie/>
      }
    </AppFrame>
  );
  }
}

const mapStateToProps = (state , ownProps) => {

  let sorted_conversations = state.chat.conversations;
    
  if(state.chat.conversations){
      sorted_conversations = state.chat.conversations.filter( (c) => {
        return c.messages && c.messages.length > 0
      })
      .sort(function(a, b){
        return b.messages[b.messages.length - 1].message_id > a.messages[a.messages.length - 1].message_id
      })
  }

  return {
    conversations : sorted_conversations,//state.chat.conversations,
    active_conversation_id : state.chat.active_conversation_id,
    active_conversation : state.chat.active_conversation,
    u_id : state.authentication.u_id
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        set_conversations,
        sort_conversations,
        set_conversation
    },
    dispatch,
)

export default (
    withAlert()(
      connect(mapStateToProps, mapDispatchToProps)(
        withTranslation()(
          withStyles(styles)(
            // withWidth()(
              ChatPage
            // )
          )
        )
      )
    )

);