import { api_call_based_on_jwt } from "./init_logic";
import { store } from "../store";
import { set_dialog_status } from "../actions/dialog_actions";

export async function cancel_subscription(password, alert, t){
    let response = await api_call_based_on_jwt("/payments/cancel_subscription.php", { password });
    if(response.success){
        store.dispatch(set_dialog_status('password_prompt', false));
        setTimeout( () => {
            store.dispatch(set_dialog_status('ays', false));
        }, 250);
        setTimeout( () => {
            window.location.reload();
        }, 500);

    }else{
        alert.error( t( 'ERRORS:' + response.ERROR_CODE ) );
    }
} 