/*ACTION_DECLARATIONS*/

/*FETCH*/
export const FETCH_BOXES_BEGIN   = 'FETCH_BOXES_BEGIN';
export const FETCH_BOXES_SUCCESS = 'FETCH_BOXES_SUCCESS';
export const FETCH_BOXES_FAILURE = 'FETCH_BOXES_FAILURE';

/*Box modification*/
export const ADD_CLIENT_BOX      = 'ADD_CLIENT_BOX';
export const UPDATE_BOX          = 'UPDATE_BOX';
export const REMOVE_CLIENT_BOX   = 'REMOVE_CLIENT_BOX';


/*Item modifications*/
export const REMOVE_BOX_ITEM     = 'REMOVE_BOX_ITEM';
export const ADD_BOX_ITEM        = 'ADD_BOX_ITEM';
export const UPDATE_ITEM         = 'UPDATE_ITEM';



/*ACTION BODIES*/
export const fetchBoxesBegin = () => ({
  type: FETCH_BOXES_BEGIN
});

export const fetchBoxesSuccess = (boxes) => ({ //When we fetch
  type: FETCH_BOXES_SUCCESS,
  payload: { boxes }
});

export const fetchBoxesFailure = (error) => ({
  type: FETCH_BOXES_FAILURE,
  payload: { error }
});

// export const add_new_box = (box_data) => ({
//   type : ADD_NEW_BOX,
//   payload : box_data
// });

export const update_client_box = (box_id, box_data) => ({
  type: UPDATE_BOX,
  payload: {
    box_id : box_id,
    box_data : box_data
  }
});

export const add_client_box = (new_box) => ({
  type: ADD_CLIENT_BOX,
  payload: {
    new_box : new_box
  }
});

export const remove_client_box = (box_id) => ({
  type: REMOVE_CLIENT_BOX,
  payload: {
    box_id : box_id
  }
});

/*ITEM ACTIONS*/
export const add_box_item = (box_id, item) => ({
  type: ADD_BOX_ITEM,
  payload: {
    box_id : box_id,
    item : item
  }
});

export const remove_box_item = (item) => ({
  type : REMOVE_BOX_ITEM,
  payload : {
    item : item
  }
});

export const update_item = (data) => ({
  type : UPDATE_ITEM, 
  payload : {
    box_id   : data.box_id,
    // old_item : data.old_item,
    new_item : data.new_item,
  }
});