import {combineReducers, createStore, applyMiddleware} from "redux";

import thunk from "redux-thunk";

import BoxReducer from "./reducers/BoxReducer";
import GlobalReducer from "./reducers/GlobalReducer";
import ComponentReducer from "./reducers/ComponentReducer";
import ChatReducer from "./reducers/ChatReducer";
import DialogReducer from "./reducers/DialogReducer";
import AuthenticationReducer from "./reducers/AuthenticationReducer";
import ShopFormReducer from "./reducers/forms/ShopFormReducer";


//import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
 

const middlewares = [/*socket_middlware()*/thunk];

const all_reducers = combineReducers({
  products : BoxReducer,
  //user : UserReducer,
  //add_box_form : AddFormBoxReducer,
  //item_form : ItemFormReducer,
  global : GlobalReducer,
  chat : ChatReducer,
  authentication : AuthenticationReducer,
  dialogs : DialogReducer,
  components : ComponentReducer,

  shop_form : ShopFormReducer

  //cart : ShoppingCartReducer,
  //shop_form : ShopFormReducer,
  //chat      : ChatReducer
});

// export const store = createStore(
//   all_reducers,
//   applyMiddleware(...middlewares),
// );

/*const persistConfig = {
  key: 'root',
  storage,
}*/

//const persistedReducer = persistReducer(persistConfig, all_reducers);

// const store = createStore(
//   all_reducers,
//   applyMiddleware(...middlewares),
// );

// export const store = createStore(persistedReducer); //Add thunk
export const store = createStore(
  all_reducers,
  applyMiddleware(...middlewares)
);
//export const persistor = persistStore(store);

// export const store = () => {
//   let store_instance = createStore(persistedReducer, applyMiddleware(...middlewares));
//   let persistor = persistStore(store_instance)
//   return { store : store_instance, persition : persistor }
// }

