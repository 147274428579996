import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';

import { withTranslation } from 'react-i18next';

import { Button, IconButton, TextField, Link, Typography, withStyles, Paper, Avatar, Hidden, FormControlLabel, Checkbox } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ip from '../constants/ip';
import login_styles from "../assets/styles/login_styles_2";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BlueWhiteFrame from '../components/Frames/BlueWhiteFrame';
import AuthenticationHeader from '../components/Frames/FrameReusables/AuthenticationHeader';
import { is_valid_email } from '../logic/authentication_logic';



const styles = (theme) => ({ ...login_styles(theme) });

class SignUp extends React.PureComponent{
  constructor(props){
    super(props);
    this.state = {
        email : '',
        password : '',
        password_re : '',
        email_accepted : false,
        terms_accepted : false,
        did_try_to_submit : false,
        errors : {
          email : [],
          password : [],
          password_re : [],
        },
        form_status : {
          email_available : true,
          email_format : false,
          password : false,
          password_red : false
        }
    }
  }



  validate_input = async() => {
    // This function will return a logic validation of if valid information and set state variables relative to a good UX more strict dependant on if did tried to post already.
    const { t } = this.props;
    const valid_email_format = is_valid_email(this.state.email);
    let is_available = false;

    const errors = { email : [], password : [], password_re : [] }

    if(valid_email_format){
      const response = await fetch(`${ip}/storager/authentication/is_free_email.php`, {
        method : "POST",
        body : JSON.stringify({ email : this.state.email })
      });
      const txt = await response.text();
      is_available = txt === '1';
    }
    // Email
    if(!valid_email_format && this.state.did_try_to_submit){ 
      errors.email = [ t('SIGN_UP_ERRORS:INVALID_EMAIL_FORMAT') ]
    }
    if(valid_email_format && !is_available){ errors.email = [ t('SIGN_UP_ERRORS:EMAIL_ALREADY_REGISTERED_SHORT') ] }
    // Password 
    const same_passwords = this.state.password === this.state.password_re;

    // if(!same_passwords && this.state.did_try_to_submit){ 
    if( this.state.password !== '' && this.state.password_re !== '' && !same_passwords ){
      // errors.password = [ "Passwords are not the same" ]
      errors.password_re = [ t('SIGN_UP_ERRORS:PASSWORDS_NOT_THE_SAME') ]
    }
    this.setState({ errors : errors })
    const status = valid_email_format && is_available && same_passwords;

    // this.setState({
    //   form_status : {
    //     email_format       : valid_email_format && is_available,
    //     email_available    : this.state.valid_email_format ? is_available : true, 
    //     password    : this.state.did_try_to_submit ? this.state.password === this.state.password_re : false,
    //     password_re : this.state.did_try_to_submit ? this.state.password === this.state.password_re : false,
    //   }
    // }, () => {
    //   console.log(this.state);
    // })

    return status;
  }

  handleSignUp = async(event) => {
    event.preventDefault();
    const { alert, t } = this.props;

    if(this.state.is_loading) return;

    this.setState({ is_loading : true })
    let response = await fetch(`${ip}/storager/authentication/register_new_user.php`, {
        method : "POST",
        body : JSON.stringify({
            email : this.state.email,
            password : this.state.password,
            password_re : this.state.password_re,
            user_wants_emails : this.state.email_accepted ? 1 : 0
        })
    })
    let json_data = await response.json();
    this.setState({ did_try_to_submit : true })
    if(json_data.success === 1){
        this.handle_login(json_data.jwt);
    }else{
        json_data.ERROR_CODES.forEach( err => {
          alert.error( t('SIGN_UP_ERRORS:' + err) );
        })
        this.setState({ is_loading : false })
    }
  };

  set_form_value = (e) => {
    this.setState({ [e.target.name] : e.target.value }, () => {
      this.validate_input();
    })
  }

  handle_login = async(jwt) => {  
    localStorage.setItem("jwt", jwt);
    window.location.replace("/app/"); // Will trigger init
    this.setState({is_loading : false});
  }

  render(){
  const { t, classes } = this.props;
  
  const valid_form = this.state.terms_accepted;

  return (
    <BlueWhiteFrame>
      <Paper className={classes.form}>
        <form  onSubmit={this.handleSignUp}>
          <AuthenticationHeader title = { t('SIGN_UP:register_title') } />

          <TextField
            className={classes.textField}
            // error={ !this.state.form_status.email && this.state.did_try_to_submit || !this.state.form_status.email_available }
            error = { this.state.errors.email.length > 0 }
            fullWidth
            helperText={ this.state.errors.email.length > 0 ? this.state.errors.email[0] : undefined
              // !this.state.form_status.email_format && this.state.did_try_to_submit ? "Invalid email format" :
              // !this.state.form_status.email_available ? "Redan tagen" : undefined
            }
            label = { t('SIGN_IN:email_placeholder') }
            name="email"
            onChange={ this.set_form_value }
            type="text"
            value={this.state.email || ''}
            variant="outlined"
          />

          <TextField
            className={classes.textField}
            error={ this.state.errors.password.length > 0 }
            fullWidth
            // helperText={ this.hasError('password') ? this.state.errors.password[0] : null }
            label = { t('SIGN_IN:password_placeholder') }
            name="password"
            onChange={ this.set_form_value }
            type="password"
            value={this.state.password || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={ this.state.errors.password_re.length > 0}
            fullWidth
            helperText = { this.state.errors.password_re.length > 0 ? this.state.errors.password_re[0] : undefined }
            // helperText={ this.hasError('password') ? this.state.errors.password[0] : null }
            label = { t('SIGN_UP:password_re_placeholder') }
            name="password_re"
            onChange={ this.set_form_value }
            type="password"
            value={this.state.password_re || ''}
            variant="outlined"
          />
          <div style = {{ display : "flex", alignItems : "center"}}>
            <FormControlLabel style = {{ marginTop : 10 }} control={<Checkbox color="primary" />} label = { t('SIGN_UP:term_accept_text') } 
              onClick = { () => this.setState({ terms_accepted : !this.state.terms_accepted }) }
            />
            <Link component={RouterLink} to="/sign-up" variant="subtitle1" style = {{ marginTop : "0.6em", marginLeft : -9 }}>{t('G:terms_of_agreement')}</Link>
          </div>
          <FormControlLabel style = {{ marginTop : 10 }} control={<Checkbox value="allowExtraEmails" color="primary" />}
              label = { t('SIGN_UP:allow_emails') } 
              onClick = { () => this.setState({ email_accepted : !this.state.email_accepted }) }
          />
          <Button
            className={classes.signInButton}
            color="primary"
            disabled={ !valid_form }
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
          { t('SIGN_UP:register_title') }
          </Button>
          
          <Typography color="textSecondary" variant="body1">
            { t('SIGN_UP:already_has_account').substring(0, t('SIGN_UP:already_has_account').indexOf('?') + 1 ) }
            <Link component={RouterLink} to="/sign-in" variant="subtitle1">
            { t('SIGN_UP:already_has_account').substring(t('SIGN_UP:already_has_account').indexOf('?') + 1, t('SIGN_UP:already_has_account').length) }
            </Link>
          </Typography>
        </form>
      </Paper>
    </BlueWhiteFrame>
  );
  }
};


export default withRouter(
    withTranslation()(
        withAlert()(
          withStyles(styles)(
            SignUp
          )
        )
    )
);