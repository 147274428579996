import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';

import TextField from '@material-ui/core/TextField';

import ip from "../../constants/ip";
import { prepare_socket_action } from "../../logic/action_socket_logic";
import MuiAutocomplete from "../map_components/MuiAutocomplete";
import { withTranslation } from "react-i18next";
import { set_dialog_status } from "../../actions/dialog_actions";
import { store } from "../../store";
import Transition from "./Transition";
import { Typography, withWidth } from "@material-ui/core";



const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    map_auto_complete : {
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(1)
    },
  });


class AddItemDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            box_title : '',
            box_location_name : '',
            box_address : '',
            box_description : '',
            cords : {
                lat : undefined,
                lng : undefined
            },
            form_errors : []
        }
        this.action_type = this.props.action_type;
        
        // this.box = this.props.box;
    }

    async componentWillMount(){
        let box = this.props.box;
        if(box){ this.init(box) }
    }

    componentWillReceiveProps(nextprops){
        if(this.props.box === undefined && nextprops.box){
            this.init(nextprops.box);
        }
    }

    init = (box) => {
        this.setState({
            box_title : box.box_title,
            box_location_name : box.location.name,
            box_description : box.box_description,
            box_address : box.location.address,
            cords : {
                lat : box.location.lat,
                lng : box.location.lng
            }
        });
    }


    form_validation = () => {
        let error_messages = [];

        let validation_status = true;
        let hash = {
            "box_title"            : "name_status",
            "box_location_name"    : "location_name_status",
        }

        for(var key in hash){
            let target = hash[key];
            
            if(this.state[key] === '' || this.state[key] === undefined){
                this.setState({ [target] : false });
                validation_status = false;
            }else{
                this.setState({ [target] : true });
            }
        }
        if(!validation_status){
            error_messages.push("SIGN_IN_ERRORS:INVALID_FORM");
        }
    
        this.setState({form_errors : error_messages});

        return validation_status;
    }


    submit_form = async() => {
        
        let validation_status = this.form_validation();
        
        if(!validation_status){ //As of now we rely on the fact that the color change of given inputs is enough for a retarded user to understand what may be the problem.
            return;
        }

        let jwt = localStorage.getItem("jwt");
        let form_data = new FormData();
        
        let has_location = ( this.state.address !== "" && !isNaN(this.state.cords.lat) && !isNaN(this.state.cords.lng) );

        if(!has_location){
            this.setState({ address : '', cords : { lat : 0, lng : 0 }  });
        }

        form_data.append('jwt', jwt);
        form_data.append('box_id', this.props.box.box_id);
        // form_data.append('label_id', this.props.label_id);
        form_data.append('name', this.state.box_title);
        form_data.append('location_name', this.state.box_location_name);
        form_data.append('address', this.state.box_address);
        form_data.append('description', this.state.box_description); 
        form_data.append("cords", JSON.stringify( this.state.cords));
        form_data.append('keep_img', 1); // Set to 0 when we want to remove the img

        const data = new URLSearchParams();
        for (const pair of form_data) {
            data.append(pair[0], pair[1]);
        }

        let response = await fetch(`${ip}/storager/set_requests/update_box.php`, {
            method : "POST",
            body : data
        });
        let json_data = await response.json();

        
        if(json_data.success === 1){
            prepare_socket_action("UPDATE_BOX", {
                box_id : this.props.box.box_id,
                box_data : json_data.formated_box, //Where box data is replacement data
            });
            store.dispatch( set_dialog_status('box_dialog', false) );
        }else{

            
        }
        // return json_data; 
    }


    render(){
        const {classes, t} = this.props;
        let box = this.props.box;
        if(!box){
            return null;
        }

        const full_screen = this.props.width === "xs";

        return(
            <Dialog
                open={this.props.open_status}
                onClose={ () => store.dispatch( set_dialog_status('box_dialog', false) ) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={ Transition }
                fullScreen = { full_screen }
            >
                <DialogTitle id="alert-dialog-title">{box.box_title}</DialogTitle>
                
                <DialogContent>

                <DialogContentText>
                    { t('EDIT_BOX_DIALOG:text') }
                </DialogContentText>

                <TextField
                    variant = "outlined"
                    id="standard-helperText"
                    label = { t('A_B_S_1:box_name_title') }
                    placeholder = { t('A_B_S_1:box_name_placeholder') }
                    style = {{width : "100%"}}
                    value = {this.state.box_title}
                    onChange = {(e) => {this.setState({box_title : e.target.value})}}
                    margin="normal"
                    color = "error"
                />
                <TextField
                    variant = "outlined"
                    id="standard-helperText"
                    label = { t('A_B_S_1:box_location_title') }
                    placeholder = { t('A_B_S_1:box_location_placeholder') }
                    style = {{width : "100%"}}
                    value = {this.state.box_location_name}
                    onChange = {(e) => {this.setState({box_location_name : e.target.value})}}
                    margin="normal"
                />

                <div className = {classes.map_auto_complete}>
                    <MuiAutocomplete
                        address     = {this.state.box_address}
                        set_address = { (value) => {this.setState({box_address : value})}}
                        set_cords   = { (obj) => {this.setState({cords : obj})}}
                    />  
                </div>       

                <TextField
                    variant = "outlined"
                    id="standard-helperText"
                    label = { t('A_B_S_1:box_description_title') }
                    placeholder = { t('A_B_S_1:box_description_placeholder') }
                    multiline
                    style = {{width : "100%"}}
                    value = {this.state.box_description}
                    onChange = {(e) => {this.setState({box_description : e.target.value})}}
                    margin="normal"
                />
                         
                {
                    this.state.form_errors.length > 0 ? 
                    this.state.form_errors.map( error => {
                        return <DialogContentText color = "error">{error}</DialogContentText>
                    }) : null
                }

                </DialogContent>
                
                <DialogActions>
                    {
                        full_screen ?
                        <Button onClick = { () => store.dispatch( set_dialog_status('box_dialog', false) ) } variant="contained" color="default" className={classes.button}>
                            { t('G:back') }
                        </Button> : null
                    }
                    <Button onClick = {this.submit_form} variant="contained" color="default" className={classes.button}>
                        { t('G:update') }
                        <CloudUploadIcon className={classes.rightIcon} />
                    </Button>

                </DialogActions>
            </Dialog>
        )
    }
}

export default (
    withTranslation()(
        withWidth()(
            withStyles(styles)(
                AddItemDialog
            )
        )
    )
);