import React from "react";
import { Button, Tooltip, IconButton, Typography } from "@material-ui/core";

import PlusIcon from "@material-ui/icons/AddCircle";
import PrintIcon from "@material-ui/icons/LocalPrintshop";
import DeleteIcon from "@material-ui/icons/Delete";

import theme from "../../assets/styles/theme";
import { open_print } from "../BoxCard";
import { store } from "../../store";
import { set_ays, set_dialog_status } from "../../actions/dialog_actions";
import { async_remove_client_and_db_box } from "../../logic/box_logic";

import { Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, ListSubheader } from "@material-ui/core";
import { withRouter } from 'react-router-dom';

function BoxActionButtons(props){

    const { t, handle_open_item_dialog, box, classes, history } = props;
        
    let open_remove_box_dialog = function(box){
      store.dispatch(set_ays({
        title : t('G:remove') + " " + box.box_title,
        text  : t('REMOVE_ITEM_DIALOG:are_you_sure', {item_name : box.box_title}),
        on_click : async() => {
            await async_remove_client_and_db_box(box.box_id, () => { store.dispatch(set_dialog_status('ays', false)) });
            history.replace('/app/my-boxes'); // Go back could work but if user goes directly to boxpage from url will be weird
        },
        accept_text : t('BOX_ACTIONS:remove_box'),
        no_icon : false,
        open_status : true
      }));
    }
  
    return(
      <List style = {{
        height : "100%",
        display : "flex",
        flexDirection : "column",
        justifyContent : "space-around",
      }}>
      
      <ListItem button style = {{ padding : 10, }} onClick = {handle_open_item_dialog}>
        <ListItemText id="switch-list-label-bluetooth" primary= { t('ADD_BOX_PAGE:add_item') } />
        <PlusIcon style = {{ color : "gray" }} />
      </ListItem>

                
      <ListItem button style = {{ padding : 10, }} onClick = { () => open_print( [box] , t) } >
        <ListItemText id="switch-list-label-bluetooth" primary= {  t('ADD_BOX_PAGE:print_label')  } />
        <PrintIcon style = {{ color : "gray" }}/>
      </ListItem>

      <ListItem button style = {{ padding : 10,  }} onClick = { () => open_remove_box_dialog(box) }>
        <ListItemText id="switch-list-label-wifi" primary = { t('ITEM_CARD:delete') } />
        <DeleteIcon style = {{ color : "gray" }}/>
      </ListItem>
    </List>
      
    )
  }

export default withRouter(BoxActionButtons);