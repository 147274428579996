import React from 'react';
import Grid from '@material-ui/core/Grid';

import ArrowNextIcon from "@material-ui/icons/ArrowForward"

import { withStyles } from '@material-ui/styles';

import { TextField, Button, Tooltip, Avatar, Typography, IconButton} from '@material-ui/core';

import MuiAutocomplete from '../../components/map_components/MuiAutocomplete';

import Dropzone from 'react-dropzone';

import CheckIcon from "@material-ui/icons/CheckCircle";

import Title from "../../components/title";
import { withTranslation } from 'react-i18next';
import { FaBox } from 'react-icons/fa';
import PlusIcon from "@material-ui/icons/AddCircle";
import theme from '../../assets/styles/theme';
import { AiOutlineBarcode } from 'react-icons/ai';
import { open_print } from '../../components/BoxCard';
import { store } from '../../store';


const styles = theme => ({
    map_auto_complete : {
        marginTop : theme.spacing(2)
    },
    extendedIcon: {
        marginLeft: theme.spacing(1),
    },
    fab : {
        marginBottom : theme.spacing(4),
        paddingTop : theme.spacing(1),
        paddingBottom : theme.spacing(1),
        paddingLeft : theme.spacing(3),
        paddingRight : theme.spacing(3)
    },
    drop_zone : {
        display : "flex",
        cursor : "pointer",
        backgroundColor : "rgb(245,245,245)",
        width : "90%",
        height : 150,
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "Roboto",
        marginTop : theme.spacing(4),
        marginBottom : theme.spacing(4),
        textAlign : "center"
    },
    successIcon : {
        color : "#4BCA81",
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(-2),
    }
  });

class PageOne extends React.Component{

    handle_next_click = () => {
        this.props.submit_form();
    }

    handle_drop = (accepted_files) => {
        if(accepted_files.length === 0){
            return;
        }
        if(accepted_files.length > 1){
            this.setState({form_errors : [...this.state.form_errors, "Du kan bara ladda upp en bild/föremål"]});
            return;
        }
        let file = accepted_files[0];
        let accepted_types = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
        
        if(accepted_types.indexOf(file.tyee) > -1){
            this.setState({form_errors : [...this.state.form_errors, "Ogiltigt filformat"]});
            return;
        }

        this.props.set_box_img(file);
    }



    render(){
        const {classes, t, boxes} = this.props;
        return(
            <Grid container direction = "column" justify = "space-around">
                <Grid item xs = {12} md = {12} container justify = "space-around">
                    <Grid item xs = {11}>
                        <div style = {{ display : "flex", justifyContent : "space-between", alignItems : "center" }}>
                            <h1>{ t('ADD_BOX_SCREEN:box_information_step')  }</h1>
        
                            <Tooltip title = { "Förhandsgranska label" }>
                                <IconButton style = {{ marginLeft : 10, backgroundColor : theme.PRIMARY_COLOR}} onClick = { () => {
                                    open_print([{
                                        box_title : this.props.box_title,
                                        box_number :  boxes && boxes.length > 0 ? boxes.sort( (a,b) => a.box_number < b.box_number )[0].box_number + 1 : 1,
                                        box_id : "DEMO",
                                        box_description : this.props.box_description
                                    }], t)
                                }}>
                                <AiOutlineBarcode style = {{ color : "white", }} />
                                </IconButton>
                            </Tooltip>
                         </div>
                    </Grid>
                    <Grid item xs = {11} md = {5}>
                        <TextField
                            variant = "outlined"
                            label = { t('box_name_title') }
                            placeholder = { t('box_name_placeholder') }
                            style    = {{width : "100%"}}
                            value    = {this.props.box_title}
                            onChange = {this.props.handle_change}
                            name     = {"box_title"} 
                            margin   = "normal"
                            autoFocus = {true}
                        />
                    </Grid>
                    
                    <Grid  item xs = {11} md = {5}>
                        <TextField
                            variant = "outlined"
                            label = { t('box_owner_title') }
                            placeholder = { t('box_owner_placeholder') }
                            name = "box_owner"
                            style = {{width : "100%"}}
                            value = {this.props.box_owner}
                            onChange = {this.props.handle_change}
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs = {11} md = {5}>
                        <TextField
                            variant = "outlined"
                            label = { t('box_location_title') + "*" } 
                            placeholder = { t('box_location_placeholder') }
                            style = {{width : "100%"}}
                            name = "box_location_name"
                            value = { this.props.box_location_name }
                            onChange = { this.props.handle_change }
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs = {11} md = {5}>
                        <div className = {classes.map_auto_complete}>
                            <MuiAutocomplete
                                address = {this.props.box_address}
                                set_address = { this.props.set_address }
                                set_cords   = { this.props.set_cords }
                                name = "box_address"
                            />  
                        </div>  
                    </Grid>

                    <Grid item xs = {11}>
                        <TextField
                            variant = "outlined"
                            label = { t('box_description_title') }
                            placeholder = { t('box_description_placeholder') }
                            multiline = {true}
                            style = {{width : "100%"}}
                            name = "box_description"
                            value = {this.props.box_description}
                            onChange = { this.props.handle_change }
                            margin="normal"
                        />
                    </Grid>

                    <Grid item xs = {11}>
                    { (this.props.box_img !== undefined) ? <div style = {{display : "flex", justifyContent : "flex-end"}}> <CheckIcon className = {classes.successIcon}/></div> : null } 
                    </Grid>

                    <Grid item xs = {11}>
                        <div style = {{display : "flex", justifyContent : "flex-end", marginTop : 10, marginBottom : 10}}>
                            <Button variant="contained" aria-label="next" className={classes.fab} onClick = { this.handle_next_click }>
                                {t('DASHBOARD_SIDE_BAR:add_box')}
                                <FaBox className={classes.extendedIcon} />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            <Grid/>
        </Grid>
        )
    }
}

export default (
    withStyles(styles)(
        withTranslation('A_B_S_1')(PageOne)
    )
);