import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';


import UserIcon from '@material-ui/icons/Person';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from "@material-ui/icons/Home";
import ShopIcon from "@material-ui/icons/ShoppingCart"
import ChatIcon from "@material-ui/icons/Chat"
import MyShopIcon from "@material-ui/icons/Style"

import AddIcon from "@material-ui/icons/AddCircle"
import SingOutButton from "@material-ui/icons/Input"

import MyBoxesIcon from '@material-ui/icons/ViewModule';
import PaymentIcon from "@material-ui/icons/Payment"

import { Link } from "react-router-dom";
import { Badge, makeStyles, Tooltip } from '@material-ui/core';
import { sign_out } from '../logic/exit_logic';
import { useTranslation } from 'react-i18next';
import { store } from '../store';
import { useTheme } from '@material-ui/styles';
import { set_sidebar } from '../actions/component_actions';
import { FaBoxOpen, FaShoppingBasket, FaCreditCard, FaFacebookMessenger, FaShoppingBag, FaAppStore, FaAppStoreIos, FaMailchimp, FaFile, FaPhone, FaEnvelope } from 'react-icons/fa';

import { IoMdPricetags, IoIosChatboxes } from "react-icons/io"
import { AiFillShopping } from 'react-icons/ai';

const useStyles = (theme) => (
  makeStyles({
    menu_title : {
      [theme.breakpoints.down('sm')]: {
        display : "none"
      },
    }
  })
);

function handle_click(path){
  store.dispatch(set_sidebar(false));
}

export function MainListItems(props){

    const { t } = useTranslation('DASHBOARD_SIDE_BAR');
    const theme = useTheme();
    const classes = useStyles(theme);
    

    const s_b_o = store.getState().components.side_bar_opened;

    return(
      <div>
      <br></br>

      <Tooltip title = { !s_b_o ? t('control_panel') : "" } placement = "right">
        <Link  onClick = { handle_click }  to = "/app/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary = { t('control_panel') } />
          </ListItem>
        </Link>
      </Tooltip>

      
      <Tooltip title = { !s_b_o ? t('my_boxes') : "" } placement = "right">
        <Link  onClick = { handle_click } to = "/app/my-boxes" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <ListItem button>
            <ListItemIcon>
              <Badge style = {{ fontSize : 24 }} badgeContent={props.n_boxes} color="primary">
                <FaBoxOpen />
              </Badge>
            </ListItemIcon>
            <ListItemText primary = { t('my_boxes') } />
          </ListItem>
        </Link>
      </Tooltip>
      
      <Tooltip title = { !s_b_o ? t('add_box') : "" } placement = "right">
        <Link onClick = { handle_click } to = "/app/add-box" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
            <Badge badgeContent={props.n_tokens} color="primary">
              <AddIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary = { t('add_box') } />
        </ListItem>
        </Link>
      </Tooltip>

      <Tooltip title = { !s_b_o ? t('my_shop') : "" } placement = "right">
        <Link  onClick = { handle_click }  to = "/app/my-shop" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          <ListItem button>
            <ListItemIcon>
              <IoMdPricetags style = {{ fontSize : 24 }} />
            </ListItemIcon>
            <ListItemText primary = { t('my_shop') } />
          </ListItem>
        </Link>
      </Tooltip>

      <Tooltip title = { !s_b_o ? t('messages') : "" } placement = "right">
        <Link onClick = { handle_click } to = "/app/chat" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
            <Badge badgeContent={ props.n_unread } max = {9} color="primary">
              <IoIosChatboxes style = {{fontSize : 25}} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary = { t('messages') }  />
        </ListItem>
        </Link>
      </Tooltip>

      <Tooltip title = { !s_b_o ? t('shop') : "" } placement = "right">
        <Link onClick = { handle_click } to = "/shop" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <ListItem button>
          <ListItemIcon>
              <FaShoppingBag style = {{ fontSize : 20, marginLeft : 3 }} />
          </ListItemIcon>
          <ListItemText primary = { t('shop') }  />
        </ListItem>
        </Link>
      </Tooltip>
      
    </div>
  );
}


export function SecondaryListItems(props){

    const { t } = useTranslation('DASHBOARD_SIDE_BAR');
    const s_b_o = store.getState().components.side_bar_opened;
    const classes = {};

    let sign_text = localStorage.getItem('jwt') !== null ? t('G:sign_out') : t('G:sign_in');
    return(
      <div>
        {/* <ListSubheader className = { classes.menu_title }> { t('bottom_nav_title') } </ListSubheader> */}
        <br></br>

        <Tooltip title = { !s_b_o ? t('start_page') : "" } placement = "right">
          <Link onClick = { handle_click } to = "/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary = { t('start_page') } />
            </ListItem>
          </Link>
        </Tooltip>

        {/* <Tooltip title = { !s_b_o ? t('my_profile') : "" } placement = "right">
          <Link onClick = { handle_click } to = "/app/profile" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <ListItem button>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary = { t('my_profile') } />
            </ListItem>
          </Link>
        </Tooltip> */}

        {/* <Tooltip title = { !s_b_o ? t('subscriptions') : "" } placement = "right">
          <Link onClick = { handle_click } to = "/app/pricing" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <ListItem button>
              <ListItemIcon>
                <FaCreditCard style = {{ fontSize : 19, marginLeft : 3 }}/>
              </ListItemIcon>
              <ListItemText primary = { t('subscriptions') }/>
            </ListItem>
          </Link>
        </Tooltip> */}

        <Tooltip title = { !s_b_o ? t('get_app') : "" } placement = "right">
          <Link onClick = { handle_click } to = "/product" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <ListItem button>
              <ListItemIcon>
                <FaAppStoreIos style = {{ fontSize : 22, marginLeft : 2 }}/>
              </ListItemIcon>
              <ListItemText primary = { t('get_app') } />
            </ListItem>
          </Link>
        </Tooltip>

        <Tooltip title = { !s_b_o ? t('G:contact') : "" } placement = "right">
          <Link onClick = { handle_click } to = "/contact" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <ListItem button>
              <ListItemIcon>
                <FaEnvelope style = {{ fontSize : 22, marginLeft : 2 }}/>
              </ListItemIcon>
              <ListItemText primary = { t('get_app') } />
            </ListItem>
          </Link>
        </Tooltip>

        {/* <Tooltip title = { !s_b_o ? sign_text : ""} placement = "right">          
          <ListItem onClick = {
            () => {
              if(localStorage.getItem('jwt') !== null){
                sign_out();
              }else{
                window.location.replace('/sign-in') //Ideally this should be done with history.push
              }
              handle_click();
            }
          } button>
            <ListItemIcon >
              <SingOutButton />
            </ListItemIcon>
            <ListItemText primary = { sign_text } />
          </ListItem>
        </Tooltip> */}
      </div>
    );
}