import ip from "../constants/ip";
import { store } from "../store";
import { mark_all_as_read, set_messages, set_conversation_id, set_conversation, add_conversation } from "../actions/chat_actions";

import { useAlert, withAlert } from 'react-alert'
import { prepare_chat_action } from "./action_socket_logic";
import { init_chat } from "./init_logic";

export function get_all_unread(conversation, u_id){
    let n = 0;
    conversation.messages.forEach( msg => {
        n += (!msg.is_read && msg.sender_id !== u_id);
    });
    return n;
}

export function get_receiver(participants, u_id, t){
    let receiver;
    
    for(var key in participants){
        receiver = ( key === u_id ) ? receiver : participants[key];
    }
    
    receiver.name = (receiver.name === "NO_NAME") ? t('CHAT:qboxr_user') : receiver.name;
    return receiver;
};

export function find_conversation (conversations, value, key = "conversation_id"){
    let convo = undefined;

    let matching_results = [];

    conversations.forEach( c => {
        if(c[key] ===  value){ //Not same types
            convo = c;
        }
    });
    return convo;
}

export async function mark_messages_as_read (conversation_id){
    let jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/chat/mark_as_read.php`, {
        method : "POST",
        body : JSON.stringify({
            jwt : jwt,
            conversation_id : conversation_id
        })
    });
    store.dispatch(mark_all_as_read(conversation_id)); //We only want to affect the LOCAL user for reading ergo we dispatch over prepare_chat_action        
}

export async function load_messages(conversation, alert){

    let jwt = localStorage.getItem('jwt');
    
    let messages_response = await fetch(`${ip}/storager/chat/get_messages.php`, {
        method : "POST",
        body : JSON.stringify({
            start_index : conversation.n_fetched, // Stored in coversation
            conversation_id : conversation.conversation_id,
            jwt : jwt
        })
    }); 
    let result = await messages_response.json();
    
    if(result.success){
        console.log("Will dispatch : "+ result.messages.length);
        store.dispatch(set_messages(result.messages));
        mark_messages_as_read(conversation.conversation_id);
        store.dispatch(set_conversation(conversation));
    }else{
        alert.error("Error loading messages");
    }
}


// export async function send_message(active_conversation, text, u_id, t){
        
//         let reciever_id = get_receiver(active_conversation.participants, u_id, t).id;

//         if(text.length === 0){
//             return;
//         }

//         let message = {
//             conversation_id : active_conversation.conversation_id,
//             text : text,
//             timestamp : new Date().toLocaleString()
//         }

//         for(let u_id in active_conversation.participants){
//             reciever_id = ( u_id !== u_id ) ? u_id : reciever_id ;
//         }
        
//         if(reciever_id === undefined){ return false }

//         let form_data = new FormData();

//         form_data.append('jwt', localStorage.getItem('jwt'));
//         form_data.append('message', JSON.stringify(message) );
//         form_data.append('item_id', active_conversation.item_id);
//         form_data.append('reciever_id', reciever_id);

//         let response = await fetch(`${ip}/storager/chat/submit_message.php`, {
//             method : "POST",
//             body   : form_data
//         });

//         let json_data = await response.json();

//         if(json_data.success === 1){
//             prepare_chat_action('ADD_MESSAGE', {
//                     reciever_id : reciever_id,
//                     sender_id : u_id,
//                     conversation_id : active_conversation.conversation_id, // This is where the sender is currently located
//                     text : text,
//                     timestamp : new Date().toLocaleString(),
//                     message_id : json_data.message_id,
//                     is_read : true
//             });
//             return true;
//         }else{
//             alert.error(`ERROR:${json_data.error_code}`);
//             return false;
//         }
// }


export function send_files(item_id, file_list, t){
    for(let i = 0; i < file_list.length; i++){
        let file = file_list[i];
        send_message(
            item_id,
            { file : file },
            t
        )
    }
}

export async function send_message(item_id, data , t,){
        
    if(data.text && data.text.length === 0){ return; }   

    let file = data.file;
    
    let message = {
        ...data,
        timestamp : new Date().toLocaleString(),
    }
    let convo_id = null;

    try{
        convo_id = store.getState().chat.active_conversation.conversation_id;
    }catch(error){ }
    
    let form_data = new FormData();

    form_data.append('jwt', localStorage.getItem('jwt'));
    form_data.append('message', JSON.stringify(message) );
    form_data.append('item_id', item_id);

    if(convo_id !== null){
        form_data.append('conversation_id', convo_id);
    }

    if(file){
        form_data.append('file', file); 
    }

    let response = await fetch(`${ip}/storager/chat/submit_message2.php`, {
        method : "POST",
        body   : form_data
    });

    let json_data = await response.json();


    if(json_data.success === 1){ // Return this object from the server instead maybe to create some more centralization
        if( json_data.created_conversation !== null ){ // May cause problem but this is only local, should not be a problem though as a given user U2 that is not U will not interact with this chat until refetch
            // await init_chat();
            store.dispatch( add_conversation(json_data.created_conversation) ); //Append the now created conversation to the redux state so that we can actually have a link between the active one and the one we are returning 
            store.dispatch( set_conversation( json_data.created_conversation ) ); //Find conversation will find this in O(n) and thats more centralized for sure but we already have the converastion object here and can set it in O(1) so why not...
            // console.table(json_data.created_conversation);
            // store.dispatch( set_conversation( find_conversation(store.getState().chat.conversations, json_data.formated_message.conversation_id ) ) ); //Local for only this client
        }else{
            // alert("Prepare chat action called");
            prepare_chat_action('ADD_MESSAGE', { // Global cross clients with same id
                ...json_data.formated_message,
                timestamp : new Date().toLocaleString()
            });
        }        
        return true;
    }else{
        // alert.error(`ERROR:${json_data.error_code}`);
        return false;
    }
}