import React from 'react';

import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/styles';

import AppFrame from "../components/AppFrame";

import {bindActionCreators} from "redux"
import { connect } from 'react-redux';

import { Stepper, StepLabel, Step, Grid } from '@material-ui/core';

import InfoIconOutlined from '@material-ui/icons/InfoOutlined';
import PrinterIconOutlined from '@material-ui/icons/PrintOutlined';
import DoneIconOutlined from '@material-ui/icons/DoneOutline';

import InfoIcon from '@material-ui/icons/Info';
import PrinterIcon from '@material-ui/icons/Print';
import DoneIcon from '@material-ui/icons/Done';
import PartOne from "./AddFormParts/PartOne";
import PartTwo from "./AddFormParts/PartTwo";


import ip from '../constants/ip';
import { prepare_socket_action } from '../logic/action_socket_logic';
import PartThree from './AddFormParts/PartThree';
import { withTranslation } from 'react-i18next';

import { withAlert } from 'react-alert'
import CardLayout from '../Layouts/CardLayout';

import bg_container_styles from "../assets/styles/bg_container.js";


const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    ...bg_container_styles(theme)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignSelf : "center",
    justifyContent : "center",
    // marginBottom : theme.spacing(2),
    marginTop : theme.spacing(2),
    marginBottom : theme.spacing(2),
    width : "90%"
  },
  fixedHeight: {
    height: 240,
  },
  map_auto_complete : {
      marginTop : theme.spacing(2)
  },
});

class AddBoxPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        box_title   : '',
        box_location_name : '',
        box_address : '',
        box_cords : {},
        box_img : undefined,
        box_description : '',
        label_id : undefined,
        label_title : '',
        label_description : '',
        active_step : 0,
        registered_box_id : undefined,
        registered_box_number : undefined,
        app_bar_height : 0
    }
    this.classes = this.props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:add_box_page');
  }

  set_form_page = (n) => { this.setState({active_step : n}) }

  handle_change = (e) => {
    let change = { [e.target.name] : e.target.value };
    this.setState(change);
  }

  form_validation = () => {
    let validation_status = true;
    let hash = { "box_title" : "box_title_status", "box_location_name" : "location_name_status", }

    for(var key in hash){
        let target = hash[key];
        if(this.state[key] == '' || this.state[key] == undefined){
            this.setState({ [target] : false });
            validation_status = false;
        }else{
            this.setState({ [target] : true });
        }
    }
    return validation_status;
  }


  submit_form = async() => {
    const { alert, t } = this.props;
    let validation_status = this.form_validation();

    if(!validation_status){ //As of now we rely on the fact that the color change of given inputs is enough for a retarded user to understand what may be the problem.
      this.set_form_page(0);
      alert.error( t('ERRORS:ADD_BOX_FORM_INVALID') );
      return;
    }

    let jwt = localStorage.getItem("jwt");
    let img = this.state.box_img;
    let form_data = new FormData();
  
    let has_location = ( this.state.box_address != "" && !isNaN(this.state.box_cords.lat) && !isNaN(this.state.box_cords.lng) );
    if(!has_location){
        this.setState({box_address : ''});
        this.setState({box_cords : {}});
    }

    form_data.append('jwt', jwt);
    form_data.append('name', this.state.box_title);
    form_data.append('location_name', this.state.box_location_name);
    form_data.append('address', this.state.box_address);
    form_data.append('description', this.state.box_description); 
    form_data.append("cords", JSON.stringify( this.state.box_cords ));
    form_data.append('has_img', (img != undefined) ? 1 : 0);

    form_data.append('label_title', this.state.label_title);
    form_data.append('label_description', this.state.label_description);

    if(img != undefined){
      form_data.append('img', this.state.box_img);
    }

    let response = await fetch(`${ip}/storager/set_requests/add_new_box_alt.php`, {
      method: 'post',
      body: form_data,
    })

    let json_data = await response.json();

    if(json_data.success == 1){
        prepare_socket_action("ADD_BOX", {
          box_data : {  ...json_data.formated_box, items : []  }
        });
        await this.setState({
          registered_box_id : json_data.formated_box.box_id,
          registered_box_number : json_data.formated_box.box_number
        });
        this.set_form_page(2);
    }else{
      alert.error( t(`ERRORS:${json_data.error_code}`));
    }
  }

  render(){
  const {classes, t, boxes} = this.props; //Inherit styles from higher order component
  const icons = [
    InfoIconOutlined,
    PrinterIconOutlined,
    DoneIconOutlined,
    InfoIcon,
    PrinterIcon,
    DoneIcon,
  ];
  
  const steps = [ t('ADD_BOX_SCREEN:box_information_step') , /*t('ADD_BOX_SCREEN:choose_label_step'),*/ t('ADD_BOX_SCREEN:done_step')];
    {/* <Stepper alternativeLabel activeStep={this.state.active_step}>
        {steps.map( (label, i) => (
            <Step key={label}>
                <StepLabel StepIconComponent = {
                  ( (i) > this.state.active_step ) ? icons[i] : icons[i + icons.length / 2] 
                }
                >{label}</StepLabel>
            </Step>
        ))}
    </Stepper> */}
  return (
    <AppFrame no_spacing>
      <div className = { classes.content }>
      { (this.props.boxes) ? 
          <Paper className = {classes.paper}>
            {
                (this.state.active_step === 0) ? 
                <PartOne
                    set_form_page = {this.set_form_page}
                    handle_change = {this.handle_change}
                    set_cords     = { (cords) => {  this.setState({ box_cords : cords }) } }
                    set_address   = { (value) => { this.setState({box_address : value}) }}
                    set_box_img   = { (file) => this.setState({box_img : file})}
                    box_title     = {this.state.box_title}
                    box_address   = {this.state.box_address}
                    box_location_name = {this.state.box_location_name}
                    box_description = {this.state.box_description}
                    box_img       = { this.state.box_img }
                    submit_form   = {this.submit_form}
                    boxes = { boxes }
                /> 
                : null                   
            }
            {
              (this.state.active_step === 2) ?
              <PartThree
                registered_box_id = {this.state.registered_box_id}
                registered_box_number = {this.state.registered_box_number}
                history = {this.props.history}
              />
              : null
            }
          </Paper>
          : null
      }
      </div>
    </AppFrame>
  );
  }

}

const mapStateToProps = (state) => {
  return {
    boxes : state.products.boxes,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        // set_box_labels
    },
    dispatch,
)


export default (
  withStyles(styles)
  (
      connect(mapStateToProps, mapDispatchToProps)
      (
        withTranslation()
        (
          withAlert()(AddBoxPage)
        )
      )
  )
);