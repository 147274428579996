import React, {Suspense} from 'react'

import {BrowserRouter, Route, Switch} from "react-router-dom";

import Dashboard from './screens/Dashboard';

import custom_theme from "./theme/muiTheme";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import SignInPage from './screens/SignInPage';
import PrivateRoute from './routes/PrivateRoute';

import { Provider, useSelector } from 'react-redux';
import { store } from './store';
import BoxPage from './screens/BoxPage';
import MyBoxes from './screens/MyBoxes';

import LandingPage from "./screens/public/LandingPage";
import AboutUs from "./screens/public/AboutUs";
import ProductPage from "./screens/public/ProductPage";
import ContactPage from "./screens/public/ContactPage";
import FourOFour from "./screens/public/404";

/* Shop */
import ShopLandingPage from "./screens/shop/ShopLandingPage";

import AddBoxPage from './screens/AddBoxPage';
import Pricing from './screens/Pricing';
import ProfilePage from './screens/ProfilePage';
import SearchResults from './screens/SearchResults';
import init_app from './logic/init_logic';

import SignUpPage from './screens/SignUpPage';
import ForgotPasswordPage from './screens/ForgotPasswordPage';
import ResetPasswordPage from './screens/ResetPasswordPage';
import ArticlePage from './screens/shop/ArticlePage';
import ChatPage from "./screens/ChatPage"

import Dialogs from "./HOC/Dialogs.js";

import { withAlert } from 'react-alert';
import { withTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import MyShop from './screens/MyShop';
import CookieConsent from './components/Concent/CookieConsent';
import { set_global_reducer_value } from './actions/global_actions';


export const public_page_routes = [
  /*INFO_PAGE*/
  {path : "/", component :LandingPage },
  // {path : "/about-us", component : AboutUs},
  {path : "/product" , component : ProductPage},
  {path : "/contact" , component : ContactPage},

  /* Public Dashboard */
  {path : "/sign-in", component : SignInPage},
  {path : "/sign-up", component : SignUpPage},
  {path : "/forgot-password", component : ForgotPasswordPage},
  {path : "/reset-password", component : ResetPasswordPage},
  {path : "/app/pricing", component : Pricing},

  /* Shop */
  {path : "/shop", component : ShopLandingPage},
  {path  : "/shop/item", component : ArticlePage}
  
];

export const private_page_routes = [
  {path : "/app/" , component : Dashboard},
  {path : "/app/dashboard" , component : Dashboard},
  // {path : "/about" , component : AboutUs},
  // {path : "/contact" , component : ContactPage},
  {path : "/app/box-page" , component : BoxPage},
  {path : "/app/my-shop" , component : MyShop},
  {path : "/app/my-boxes" , component : MyBoxes},
  {path : "/app/add-box" , component : AddBoxPage},
  {path : "/app/profile" , component : ProfilePage},
  {path : "/app/search-results", component : SearchResults},
  {path : "/app/chat", component : ChatPage}
];

export const navbar_paths = [
  ...private_page_routes.map( (obj) => obj.path ),
  "/app/pricing"
]




class App extends React.Component{
  constructor(props){
    super(props);
  }

  componentWillMount(){ // DID?
    const { t, alert } = this.props;

    window.addEventListener('load', function(){
      init_app(t, alert);
    });
  }

  // componentWillReceiveProps(nextprops){
  //   if (nextprops.location !== this.props.location) {
  //     store.dispatch( set_global_reducer_value('last_page'. this.props.location.pathname) );
  //   }
  // }

  render(){
  // let pathname = window.location;
  // let c_p = pathname.substring( pathname.indexOf('/') ? pathname.length - 1 : pathname.indexOf('/') , pathname.length - 1);

  return (
    <Provider store = {store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <MuiThemeProvider theme={custom_theme}>
        <BrowserRouter>
          <div className="App">
            <Suspense fallback="loading">
              <Switch>
                {
                  public_page_routes.map( route => {
                    return (
                      <Route exact path = {route.path} component = {route.component} />
                    )
                  }) 
                }
                {/* {
                  private_page_routes.map( route => {
                    return <PrivateRoute exact path = {route.path} component = {route.component} />
                  })
                } */}
                <Route component = { FourOFour } />
              </Switch>
            </Suspense>
            
            <CookieConsent/>

          </div>
          
          {/* Dialogs */}
          <Dialogs/>
          
        </BrowserRouter>
      </MuiThemeProvider>
    {/* </PersistGate> */}
    </Provider>
  );
  }
}




export default (
  withAlert()(
    withTranslation()(
      App
    )
  )
);
