import React from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom'


import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { Hidden} from '@material-ui/core';

import { connect } from 'react-redux'

import { MainListItems, SecondaryListItems } from '../components/list_items';

import NavBarUserIcon from "../components/IconComponents/NavBarUserIcon";

import { withStyles } from '@material-ui/styles';

import AppBarSearch from "../components/AppBarSearch";

import { Link } from "react-router-dom";
import { Container, Typography } from '@material-ui/core';
import { set_sidebar } from '../actions/component_actions';
import { Footer } from './Footer';

import {withRouter} from 'react-router-dom';
import { public_page_routes } from '../App';
import ShopAutoComplete from './shop/ShopAutoComplete';
import { bindActionCreators } from 'redux';

let drawerWidth = window.innerWidth < 600 ? "100vw" : 240;


const styles = (theme) => ({
  root: {
    display: 'flex',

    [theme.breakpoints.up('sm')]: {
      maxHeight : "100vh",
      overflow : "hidden",
    },
  },
  toolbar: {
    display : "flex",
    justifyContent : "space-between",
    alignItems : "space-between"
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    paddingTop : theme.spacing(1),
    paddingBottom : theme.spacing(1),
    zIndex: theme.zIndex.drawer + 1,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 36,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minHeight : "100vh",
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0.0001,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },


  appBarSpacer: theme.mixins.toolbar,
  content: {  

    boxSizing : "border-box",

    overflowY: 'hidden',
    overflowX : 'hidden',

    flexGrow : 1,
    
    padding : theme.spacing(2.5),
    marginTop : theme.spacing(9),

    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
      marginTop : theme.spacing(10),
      padding : theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },

  },
  container: {
    marginTop : theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  navbar_icon : {
    marginLeft : 10,
    marginTop : 5,
    height : 30,
    width : "auto",
    alignSelf : "center",
    [theme.breakpoints.up('sm')]: {
      height : 40
    },
  },
  no_spacing : {
    padding : 0
  }

});

class AppFrame extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      side_bar_open : this.props.width !== 'xs',
    }
    this.classes = this.props.classes;
  }

  componentWillMount(){
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(([key, val]) =>
    //   prevProps[key] !== val && console.log(`Prop '${key}' changed ` + prevProps[key] + " : " + val)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val && console.log(`State '${key}' changed`)
    //   );
    // }
  }

  

  toggle_open = () => {
    const { set_sidebar, side_bar_open } = this.props;
    set_sidebar(!side_bar_open);
    this.setState({side_bar_open : !this.state.side_bar_open});
  }

  app_frame_scroll = (x, y) => {
    this.scroll_ref.scrollTo(x, y);
  }

  get_all_unread = () => {
    const { conversations } = this.props;

    if(typeof conversations === "undefined"){
      return 0;
    }else{
      let n = 0;
      conversations.forEach(convo => {
        convo.messages.forEach( msg => {
          n += (!msg.is_read && msg.sender_id !== this.props.u_id) ? 1 : 0;
        });
      });
      return n;
    }
  }

  render(){
    const {classes, history} = this.props; //Inherit styles from higher order component
    const { pathname } = this.props.location;


    let jwt = localStorage.getItem('jwt');
    let display_when = ( jwt !== null ) && !public_page_routes.map( obj => obj.path).includes( pathname );

    // const width = window.innerHeight < 600 ? 'xs' : 'others';
    // const {width} = this.props -- CAUSED A FUCKING INFINITE LOOP FEELS BAD MAN
    return (
      <div className={classes.root}>
        <CssBaseline />
        
        {
        <Hidden smDown = { this.props.side_bar_open }>
        <AppBar className={clsx(classes.appBar, this.props.side_bar_open && classes.appBarShift)} id = "AppFrame">
          <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={this.toggle_open}
                className={clsx(classes.menuButton, this.props.side_bar_open && classes.menuButtonHidden)}
              >
                <MenuIcon />
              </IconButton>
              
              <div style = {{display : "flex", flex : 1, justifyContent : "space-between", alignItems : "center"}}>
                {/* <Link to = { this.props.location.pathname === "/shop" ? "/shop" : "/app/dashboard"}> */}
                <Link to = { this.props.location.pathname === "/shop" ? "/shop" : "/"}>
                  <img style={{ borderRadius: 10 }} alt = "Qboxr" src = "/img/QboxrFavIcon.png" className = { classes.navbar_icon }></img>
                </Link>

                <AppBarSearch do_display = { display_when }/>
              
              </div>
          </Toolbar>
        </AppBar> 
        </Hidden>
        // : null
        }

        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.props.side_bar_open && classes.drawerPaperClose),
          }}
          open={this.props.side_bar_open}
        >
          
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.toggle_open}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {/* <List>
            <MainListItems
              n_boxes  = {typeof this.props.boxes === "object" ? this.props.boxes.length : 0}
              n_tokens = {this.props.n_tokens}
              n_unread = { this.get_all_unread() }
            />
          </List>
          <Divider/> */}

          <List>
            <SecondaryListItems/>
          </List>

        </Drawer>
        
        
        <main ref = { this.props.scroll_ref } className = {`${classes.content} ${this.props.no_spacing ? classes.no_spacing : '' }`}>
            {this.props.children}
        </main>
      

      </div>
    );
    }
}

const mapStateToProps = (state , ownProps) => {
  return {
    boxes  : state.products.boxes,
    n_tokens : state.global.n_tokens,
    conversations : state.chat.conversations,
    side_bar_open : state.components.side_bar_opened,
    u_id : state.authentication.u_id
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    set_sidebar
  },
  dispatch,
)

// export default withStyles(styles)(AppFrame);
export default(
  connect(mapStateToProps, mapDispatchToProps)(
      withStyles(styles)(
        withRouter(
          AppFrame
        )
      )
  )
);