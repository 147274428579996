import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, Typography, Link, Card, CardContent, CardMedia, Divider, Avatar, Badge  } from '@material-ui/core';

import ShareIcon from "@material-ui/icons/Share"


import { format_date } from '../../functions/date_functions';
import { withTranslation } from 'react-i18next';

import GeneralCard from '../shop/GeneralCard';
import { f_a_c } from '../../functions/money_functions';
import { get_text_from_key } from '../../locales/language_functions';
import { store } from '../../store';
import { set_dialog, set_dialog_status } from '../../actions/dialog_actions';
import get_cat_icon from '../../screens/shop/get_category_icon';
import theme from '../../assets/styles/theme';
import { FaRegCalendarAlt, FaLocationArrow } from 'react-icons/fa';
import { IoMdPricetag } from 'react-icons/io';
import { init_category } from '../Viritualizded/Table/ItemTable/Expand';
import { get_end_point } from '../../logic/image_logic';



export function ShareButton({t, item}){
    return(
        <Tooltip title = { t('ITEM_CARD:share') } aria-label = { t('ITEM_CARD:share') }>
            <IconButton onClick = { () => {
                store.dispatch(set_dialog('share_dialog', { item : item, open_status : true }));
            }}>
                <ShareIcon/>
            </IconButton>
        </Tooltip>
    );
}

  
function TopIcons(props){
  const { t, item, history } = props;


  return(
  <Fragment>
    <Tooltip title = { t('ITEM_CARD:to_article') } aria-label = { t('ITEM_CARD:to_article') }>
        <Link 
        onClick = { () => {history.push( `/shop/item?item=${item.item_id}`)}}
        style = {{ marginLeft : 10, cursor : "pointer" }} variant = "subtitle2">
        {t('G:read_more')}
        </Link>
    </Tooltip>
  </Fragment>
  )
};

function BottomIcons({ t, item }){

  return(
  <Fragment>
      <ShareButton
        item = { item }
        t = { t }
      />
  </Fragment>
  )
};


// function cat_codes_to_word(category, sub_cat, t){
//     let sales_categories =  t('SHOP:dropdown_categories', { returnObjects : true });
//     let sales_sub_categories = t('SHOP:dropdown_sub_categories', { returnObjects : true }); 
//     return {
//         sales_category : get_text_from_key(sales_categories, category),
//         sales_sub_category : get_text_from_key(sales_sub_categories[category], sub_cat)
//     }
// }



const styles = muiBaseTheme => ({
    card: {
    //   maxWidth: 300,
      cursor : "pointer",
      margin: "auto",
      transition: "0.3s",
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
      }
    },
    media: {
      paddingTop: "56.25%"
    },
    content: {
      textAlign: "left",
      padding: muiBaseTheme.spacing.unit * 3
    },
    divider: {
      margin: `${muiBaseTheme.spacing.unit * 3}px 0`
    },
    heading: {
      fontWeight: "bold"
    },
    subheading: {
      lineHeight: 1.8
    },
    avatar: {
    //   display: "inline-block",
      border: "2px solid white",
      justifyContent : "center",
      alignItems : "center",
      backgroundColor : theme.PRIMARY_COLOR,
      "&:not(:first-of-type)": {
        marginLeft: -muiBaseTheme.spacing.unit
      }
    }
  });

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

class ShopItemCard extends React.Component{
  constructor(props){
      super(props);
  }

  render(){  
      const { item, t, classes, history } = this.props;

      let categories = init_category(t, item);//cat_codes_to_word(item.sales_category, item.sub_category, t);
    
      let cat_icons = [item.sales_category, item.sales_sub_category].map( cat => (
          get_cat_icon(cat)
      ))

      return(
        //   <GeneralCard
        //       TopIcons = { TopIcons }
        //       BottomIcons = { BottomIcons }
        //       title = { title }
        //       text_parts = { text_parts }
        //       bottom_text_parts = { bottom_text_parts }
        //       images = { item.images }
        //       history = { history }
        //       {
        //         ...this.props
        //       }
        //   />
        <Card className={classes.card} onClick = { () => {history.push( `/shop/item?item=${item.item_id}`)}}>
        <CardMedia
          className={classes.media}
          image={
            item.images.length > 0 ? get_end_point( "ITEM", item.images[0] ) : "/img/qboxr.png"
          }
        />
        <CardContent className={classes.content}>
          <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
          >
            {item.title}
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
            { categories.sales_category } 
          </Typography>
          <br></br>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
            { categories.sales_sub_category } 
          </Typography>


          <Divider className={classes.divider} light />

          <div style = {{ display : "flex" }}>
            <div style =  {{ flex : 1 }}> 
              <Tooltip title = { t('ITEM_CARD:estimated_distance', { distance : item.distance }) } >
                <Badge badgeContent={ item.distance + " km"} max = { 99 } color = "secondary">
                <Avatar className = { classes.avatar } style = {{ borderWidth : 0 }}>
                  <FaLocationArrow/>
                </Avatar>
                </Badge>
              </Tooltip>
            </div>

            <div style = {{ display : "flex", flexDirection : "column", flex : 1 }}>
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
                    <Typography className={"MuiTypography--subheading"} variant = "caption" noWrap>
                        { format_date(item.submit_date, t) } 
                    </Typography>

                    <Avatar style = {{ width : 20, height : 20, alignItems : "center", backgroundColor : theme.PRIMARY_COLOR }}>
                        <FaRegCalendarAlt style = {{ fontSize : 11, }} />
                    </Avatar>
                </div>
                
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between", marginTop : 5, }}>
                    <Typography className={"MuiTypography--subheading"} variant = "caption" noWrap>
                        { f_a_c(item.price, item.iso_code) }
                    </Typography>

                    <Avatar style = {{ width : 20, height : 20, alignItems : "center", backgroundColor : theme.PRIMARY_COLOR }}>
                        <IoMdPricetag style = {{ fontSize : 11 }} />
                    </Avatar>
                </div>

            </div>
          </div>
        
        </CardContent>
      </Card>
      )
  }
}

export default(
    withTranslation()(
      withStyles(styles) (
          ShopItemCard  
      )
    )   
)
  