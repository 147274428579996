import React from "react";

import { List, TextField } from "@material-ui/core";

import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withAlert } from 'react-alert';

import { set_conversations, sort_conversations, set_messages, mark_all_as_read, set_conversation_id} from '../../actions/chat_actions';

import { load_messages } from "../../logic/chat_logic"

import AttatchFileIcon from "@material-ui/icons/AttachFile"
import FileIcon from '@material-ui/icons/InsertDriveFile';
import theme from "../../assets/styles/theme";
import ConversationListItem from "./ConversationListItem";
import { Waypoint } from "react-waypoint";
import { init_chat } from "../../logic/init_logic";


class Conversations extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            query_string : '',
            loading : false,
            empty_fetch : false
        }
    }

    get_filtered_conversation = () => {
        const { conversations } = this.props;
        
        const query_string = this.state.query_string.toUpperCase();
        return conversations.filter( (convo) => {
            return convo.title.toUpperCase().includes( query_string );
        })
        .sort( (a, b) => { //Get the first occurance of substring first
            a = a.title.toUpperCase();
            b = b.title.toUpperCase();
            return a.indexOf(query_string) > b.indexOf( query_string ) ? 1 : 0;
        });
    }

    _render_conversations = () => {
        const { convo, t, u_id, set_clicked_conversation } = this.props;
        return(
            this.get_filtered_conversation()
            .map( (convo, i) => {
                return <ConversationListItem 
                    convo = { convo } 
                    t = { t } 
                    u_id = { u_id }
                    set_clicked_conversation = { set_clicked_conversation }
                />
            })
        )
    }

    load_more = async () => {
        if( !this.state.empty_fetch ){
            this.setState({ loading : true });
            let fetched_convos = await init_chat();
            this.setState({ 
                loading : false,
                empty_fetch : fetched_convos.length === 0 ? true : this.state.empty_fetch
            });
        }
    }

    _render_waypoint = () => {
        return !this.state.loading ? <Waypoint  onEnter = { this.load_more }/> : null
    }


    render(){
        const { t } = this.props;
        return(
            <div style = {{height : "100%", padding : "10px 20px", borderRight : "0.5px solid lightgray", overflow : "auto"}}>
                <List ref = { (ref) => this.list_ref = ref } component="nav" aria-label="main mailbox folders" onScroll = { this.handle_scroll }>
                    
                <h1>{t('CHAT:conversations')}</h1>                    
                    <TextField
                        placeholder = { t('CHAT:search_conversations') }
                        variant = "outlined"
                        style = {{width : "100%",}}
                        value = { this.state.query_string }
                        onChange = { (e) =>  this.setState({ query_string : e.target.value }) }
                    />
                    { this._render_conversations() }
                    { this._render_waypoint() }
                </List>
          </div>
        )
    }
}


const mapStateToProps = (state , ownProps) => {
    return {
    //   conversations : state.chat.conversations,
      active_conversation_id : state.chat.active_conversation_id,
      active_conversation : state.chat.active_conversation
    }
  }
  
  const mapDispatchToProps = dispatch => bindActionCreators(
      {
          set_conversations,
          set_messages,
          set_conversation_id,
          sort_conversations
      },
      dispatch,
  )


export default (
      withAlert()(
        connect(mapStateToProps, mapDispatchToProps)(
          withTranslation()(
            Conversations
          )
        )
      )
);