import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';


import TextField from '@material-ui/core/TextField';
import UpdateIcon from "@material-ui/icons/Update"
import ip from "../../constants/ip";

import custom_theme from "../../assets/styles/theme";
import { Typography } from "@material-ui/core";
import TextFormModal from "./TextFormModal";
import { withTranslation } from "react-i18next";

import { withAlert } from 'react-alert'

class ChangePasswordDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            old_password : '',
            new_password : '',
            new_password_re : '',
            error_messages : [],
        }
    }
    

    validate_input(){
        let success = true;
        let error_messages = [];
    
        if(this.state.new_password === "" || this.state.new_password_re === "" || this.state.old_password === ''){
          success = false;
          error_messages.push( 'ERRORS:FORM_INCOMPLETE' );
        }else if(this.state.new_password != "" && ( this.state.new_password != this.state.new_password_re ) ){
            success = false;
            error_messages.push( 'SIGN_UP_ERRORS:PASSWORDS_NOT_THE_SAME' );  
        }
        
        return{
          succes : success,
          error_messages : error_messages
        }
    }

    submit_form = async() => {
        const { alert, t } = this.props;
        let validation = this.validate_input();
      
        if(!validation.succes){
            validation.error_messages.forEach( err => {
              alert.info( t(err) );
            });
            return;
        }
        let jwt = localStorage.getItem('jwt');
        let response = await fetch(`${ip}/storager/authentication/change_password.php`, {
            method : "POST",
            body : JSON.stringify({
                jwt              : jwt,
                old_password     : this.state.old_password,
                new_password     : this.state.new_password,
                new_password_re  : this.state.new_password_re
            })
        })
        let json_data = await response.json();

        if(json_data.success == 1){
            this.props.set_open_status(false);
        }else{ //There will only be 1 error code coming back here
            alert.info( t('ERRORS:' + json_data.error_code) );
        }
    }


    set_value = (e) => {
        this.setState({[e.target.name] : e.target.value });
    } 

    render(){
        const {t} = this.props;
        
        this.fields = [
            {
                label : t('RESET_PASSWORD:old_password_pladeholder'),
                password : true,
                value : this.state.old_password,
                name  : "old_password", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('RESET_PASSWORD:new_password_placeholder'),
                password : true,
                value : this.state.new_password,
                name  : "new_password", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('RESET_PASSWORD:new_password_re_placeholder'),
                password : true,
                value : this.state.new_password_re,
                name  : "new_password_re", 
                set_value : (e) => { this.set_value(e) }
            },
        ]
        return(
            <TextFormModal
                open_status = { this.props.open_status }
                set_open_status = { this.props.set_open_status }
                fields = { this.fields }
                title = { t('CHANGE_PASSWORD_DIALOG:new_password_title') }
                button_text = { t('CHANGE_PASSWORD_DIALOG:new_password_button') }
                error_messages = { this.state.error_messages }
                on_click = { this.submit_form }
            />
        )
    }
}

export default (
    // withStyles(styles)(
        withTranslation()(
            withAlert()(
                ChangePasswordDialog
            )
        )
    // )
);