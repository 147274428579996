import React from "react";
import { List, ListSubheader, ListItem, ListItemText, makeStyles } from '@material-ui/core';

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import { GiMoneyStack } from 'react-icons/gi';
import { handle_remove_click, handle_mark_as_sold_click } from "../../../BoxPage/BoxItemCard";
import { open_item_dialog } from "../../../dialogs/AddItemDialog";

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));

export default function ExpandActionMenu({ item, t, alert }) {
    const classes = useStyles();

    return (
      <List subheader={<ListSubheader>{t('G:actions')}</ListSubheader>} className={classes.root}>
        <ListItem button style = {{ borderRadius : 10 }} onClick = { () => open_item_dialog({ action_type : 'EDIT', item : item }) }>
          <ListItemText id="switch-list-label-bluetooth" primary= { t('ITEM_CARD:edit') } />
          <EditIcon style = {{ color : "gray" }} />
        </ListItem>

        <ListItem button style = {{ borderRadius : 10 }} onClick = { () => handle_remove_click(item, t, alert) }>
          <ListItemText id="switch-list-label-wifi" primary = { t('ITEM_CARD:delete') } />
          <DeleteIcon style = {{ color : "gray" }}/>
        </ListItem>
        
        {
            item.is_for_sale ? 
            <ListItem button style = {{ borderRadius : 10 }} onClick = { () => handle_mark_as_sold_click(item, t, alert) }>
              <ListItemText id="switch-list-label-bluetooth" primary= { t('MARK_AS_SOLD_DIALOG:btn') } />
              <GiMoneyStack style = {{ fontSize : 24, color : "gray" }}/>
            </ListItem> : null
        }
      </List>
    );
  }