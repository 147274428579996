import React, { Fragment } from "react";
import { Paper, IconButton, Typography, Tooltip, Avatar } from "@material-ui/core";

import Title from "../title";
import EditIcon from '@material-ui/icons/Edit';
import { store } from "../../store";
import { set_box_dialog, set_dialog_status, set_dialog } from "../../actions/dialog_actions";
import { FaMapMarked, FaDolly, FaMapMarkerAlt } from "react-icons/fa";

import { AiOutlineNumber } from "react-icons/ai";

import theme from '../../assets/styles/theme';

export function BoxInfoTopPart(props){
    const { fixedHeightPaper, box, classes, t } = props;

    const address = box.location.address;

    return(
      <>
        <Paper className={ fixedHeightPaper }>
          <React.Fragment>
              <div style = {{display : "flex", justifyContent : "space-between", alignItems : "center" }}>
                <div>
                  <h2 style = {{marginTop : 0}}>{box.box_title}</h2>
                </div>
                <IconButton
                  color="gray"
                  onClick = { () =>{
                    store.dispatch( set_dialog("box_dialog", { box }));
                    store.dispatch( set_dialog_status('box_dialog', true) );
                  }}
                  >
                      <EditIcon />
                  </IconButton>
              </div>
              

              {/* <div style = {{ display : "flex", alignItems : "center" }}>
                // <Tooltip title = {t('MY_SHOP:n_clicks_tool')}>
                  <Avatar style = {{ background : "rgba(16,187,255,0.35)" }}> <FaMapMarked style = {{color : theme.PRIMARY_COLOR}}/> </Avatar>
                </Tooltip>
                <Typography component="p" variant = "body2" style = {{ marginLeft : 10 }}> {box.location.name}</Typography>
              </div> */}

              <div style = {{ display : "flex", alignItems : "center" }}>
                <Avatar style = {{ background : "rgba(16,187,255,0.35)" }}> <FaDolly style = {{color : theme.PRIMARY_COLOR}}/> </Avatar>
                <div>
                  <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>{ t('G:content') }</Typography>
                  <Typography component="p" variant = "body2" style = {{ marginLeft : 10 }}>{ t('PLURALS:itemInterval', { postProcess: 'interval', count: box.items.length })}</Typography>
                </div>
              </div>

              <div style = {{ display : "flex", alignItems : "center", marginTop : 15 }}>
                <Avatar style = {{ background : "rgba(16,187,255,0.35)" }}> <FaMapMarkerAlt style = {{color : theme.PRIMARY_COLOR}}/> </Avatar>
                <div>
                  <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>{ box.location.name }</Typography>
                  <Typography component="p" variant = "body2" style = {{ marginLeft : 10 }}>{ address }</Typography>
                </div>
              </div>

              <div style = {{ display : "flex", alignItems : "center", marginTop : 15 }}>
                <Avatar style = {{ background : "rgba(16,187,255,0.35)" }}> <AiOutlineNumber style = {{color : theme.PRIMARY_COLOR}}/> </Avatar>
                <div style = {{ display : "flex" }}>
                  <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>{ t('LABEL:box_number') + " :"}</Typography>
                  <Typography component="p" variant = "body2" style = {{ marginLeft : 10 }}>#{box.box_number}</Typography>
                </div>
              </div>

  
              {/* <Typography color="textSecondary" component="p" className = {classes.description}>
                  {box.box_description}
              </Typography> */}
          </React.Fragment> 
        </Paper>
      </>
    )
  }