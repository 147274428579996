import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import theme from '../../../assets/styles/theme';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
    padding: theme.spacing(3),
  },
  margin: {
    height: theme.spacing(3),
  },
}));



const PrettoSlider = withStyles({
  root: {
    color: theme.PRIMARY_COLOR,//'#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);



export default function QboxrSlider(props) {
  const classes = useStyles();

  return (
        // <>
            <PrettoSlider 
                valueLabelDisplay="auto" 
                aria-label="pretto slider" 
                value = { props.value } 
                max = { 100 }
                min = { 5 }
                step = { 1 }
                // onChange = { (e, value) => { props.set_value(value) } }
                onChange = { (e, value) => { props.set_value(value) }}
                // defaultValue = {  }
            />
        // </>
  );
}