import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import {Elements, StripeProvider} from 'react-stripe-elements';
import clsx from 'clsx';

import { withStyles } from '@material-ui/styles';
import { api_call_based_on_jwt, loadScript } from '../logic/init_logic';

import { connect } from 'react-redux'

import { Grid, Dialog, Typography, Input, OutlinedInput, InputLabel, FormControl, Select, MenuItem, IconButton, Link} from '@material-ui/core';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ItemActionPopup from '../components/IconComponents/ItemActionPopup';

/* LIST */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LockIcon from "@material-ui/icons/Lock";
import StorageIcon from "@material-ui/icons/Storage";

import PermaDeleteIcon from "@material-ui/icons/DeleteForever";

import CreditCardIcon from "@material-ui/icons/CreditCard";
import UpgradeIcon from "@material-ui/icons/TrendingUp";
import CancelIcon from "@material-ui/icons/CancelPresentation";
import ProfileIcon from "@material-ui/icons/PersonPin"

import ChangePasswordDialog from "../components/dialogs/ChangePasswordDialog";

import Title from "../components/title";

import bg_container_styles from "../assets/styles/bg_container.js";



import CheckoutForm from '../components/Stripe/CheckoutForm';
import AreYouSureDialog from '../components/dialogs/AreYouSureDialog';
import AppFrame from '../components/AppFrame';
import { withTranslation } from 'react-i18next';
import { stripe_key } from '../constants/stripe_constants';
import UserInformationDialog from '../components/dialogs/UserInformationDialog';
import { format_date } from '../functions/date_functions';
import PageLoadingLottie from '../components/PageLoadingLottie';

import { store } from "../store";
import { set_ays, set_dialog_status, set_dialog } from '../actions/dialog_actions';
import { delete_account } from '../logic/authentication_logic';
import { withAlert } from 'react-alert';

import { cancel_subscription } from "../logic/subscription_logic"


class ProfilePage extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      change_password_dialog_status : false,
      user_information_dialog_status : false,
      subscription_modal_open : false,
      are_you_sure_dialog_status : false,
      are_you_sure_title : '',
      are_you_sure_text  : '',
      are_you_sure_accept_text : '',
      are_you_sure_on_click : () => {},
      user_data : undefined,
      stripe : null
    }
    this.classes = this.props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
  }
  
  cancel_subscription = () => {
    const { t } = this.props;
    store.dispatch(set_ays({
      title : t('cancel_subscription', alert),
      text  : (this.props.user_subscription) ? 
        t('CANCEL_SUBSCRIPTION:are_you_sure', {postProcess: 'interval', days_until_next : this.props.user_subscription.days_until_next, next_payment : this.props.user_subscription.next_payment}) 
        :
        t('CANCEL_SUBSCRIPTION:no_subscription_to_cancel')
      ,
      on_click : () => {
        store.dispatch( set_dialog('password_prompt', { 
          open_status : true,
          on_click : (password) => cancel_subscription(password, alert, t)
        }))
      },
      accept_text : t('cancel_subscription'),
      no_icon : true,
    }));
    store.dispatch(set_dialog_status('ays', true));   
  }

  handle_cookie_click = () => {
    const { t } = this.props;
    store.dispatch(set_ays({
      title : t('COOKIE_CONSENT:cookie_complianse'),
      text  : t('COOKIE_CONSENT:profile_text'),
      on_click : () => {
        store.dispatch( set_dialog('password_prompt', { 
          open_status : true,
          on_click : (password) => cancel_subscription(password, alert, t)
        }))
      }, 
      content : () => ( 
        <div style = {{ textAlign : "center" }}>
          <h2>{t('COOKIE_CONSENT:how_we_use_it')}</h2>
          <a href = "http://www.qboxr.com/cookie-policy" target = "_blank"><Link>{t('COOKIE_CONSENT:cookie_policy')}</Link></a> { "   " }
          <a href = "http://www.qboxr.com/privacy-policy" target = "_blank"><Link>{t('SIGN_UP:privacy_policy')}</Link></a> { "   " }
          <a href = "http://www.qboxr.com/terms-of-agreement" target = "_blank"><Link>{t('G:terms_of_agreement')}</Link></a> { "   " }
        </div>
      ),
      accept_text : t('G:remove'),
      no_icon : true,
    }));
    store.dispatch(set_dialog_status('ays', true));  
  }




  
  async componentWillMount(){
    let stripe_loaded = await loadScript("https://js.stripe.com/v3/");
    this.setState({ stripe_loaded : stripe_loaded });

    let response = await api_call_based_on_jwt("/get_requests/get_user_data.php");
    this.setState({ user_data : response.user_data });
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:profile_page')
  }

  handle_change_payment_click = () => {
    const { user_subscription, subscription_loaded, alert, t } = this.props;
    if(!subscription_loaded){
      alert.info(t('ERRORS:SUBSCRIPTION_NOT_LOADED'));
    }else if(!user_subscription){
      alert.info(t('ERRORS:NO_SUBSCRIPTION'));
    }else{
      this.setState({subscription_modal_open : true})
    }
  }

  render(){
    const {classes, t, i18n, alert} = this.props; //Inherit styles from higher order component
    
    const { user_subscription, subscription_loaded } = this.props;
    let sub_text_data;

    if(user_subscription){
      sub_text_data = t(`tiers:${user_subscription.app_identifier}`, { returnObjects : true });
    }

    const sections = i18n.t('MY_PROFILE:sections', { returnObjects: true });

    return (
      <AppFrame no_spacing>
        <div className = { classes.content }>
          {
           (subscription_loaded !== undefined) ? 
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Paper className={this.fixedHeightPaper}>
                  <div style = {{display : "flex", flexDirection : "column", alignItems : "center" ,justifyContent : "center", padding : 20}}> 
                    <Title> { t('my_profile') } </Title>
                    <img src = "/profile_picture.png" className = {classes.profile_picture}/>  
                    <Typography type = "span">
                      {
                        (this.state.user_data) ? this.state.user_data.email : ""
                      }
                    </Typography>
                  </div>   
                </Paper>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Paper className={this.fixedHeightPaper}>
                  <div style = {{display : "flex", flexGrow : 1, flexDirection : "column", alignItems : "center" ,justifyContent : "space-around", padding : 20, position : "relative"}}> 
                    <Title>{ t('my_subscription') }</Title>
                    <Typography component="p" variant="h4">
                      { this.props.user_subscription ? sub_text_data.title : t('no_subscription')  }
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      { this.props.user_subscription ? format_date(user_subscription.start_date, t) : t('no_subscription')  }
                    </Typography>
                    <Typography type = "span">{ t('PLURALS:leftToRegister', {postProcess: 'interval', count : this.props.n_tokens} ) }</Typography>
                  </div>   
                </Paper>
              </Grid>

              <Grid item xs = {12}>
                <ExpansionPanel className = {classes.panel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={classes.heading}> { sections.account.title } </Typography>
                  </ExpansionPanelSummary>

                  <ExpansionPanelDetails>
                  <List component="nav" aria-label="main mailbox folders" className = {classes.list}>
                    <ListItem button onClick = {() => this.setState({change_password_dialog_status : true})} style = {{ borderRadius : 5 }} >
                      <ListItemIcon> <LockIcon /> </ListItemIcon>
                      <ListItemText primary = { sections.account.items[0] }  />
                    </ListItem>

                    
                    <ListItem button onClick = {() => this.setState({user_information_dialog_status : true})} style = {{ borderRadius : 5 }}>
                      <ListItemIcon> <ProfileIcon /> </ListItemIcon>
                      <ListItemText primary = { sections.account.items[1] }  />
                    </ListItem>

                    <ListItem button onClick = {() => {
                      store.dispatch(set_ays({
                        title : t('DELETE_ACCOUNT:title'),
                        text  : t('DELETE_ACCOUNT:text'),
                        on_click : () => {
                          store.dispatch( set_dialog('password_prompt', { 
                            open_status : true,
                            on_click : (password) => delete_account(password, alert ,t)
                          }))
                        },
                        accept_text : t('DELETE_ACCOUNT:accept_button_text'),
                        no_icon : true,
                    }));
                    store.dispatch(set_dialog_status('ays', true));   
                    }}
                    style = {{ borderRadius : 5 }}
                    >
                      <ListItemIcon> <PermaDeleteIcon /> </ListItemIcon>
                      <ListItemText primary = { sections.account.items[2] } />
                    </ListItem>

                  </List>
                  <Divider />
                  </ExpansionPanelDetails>
                </ExpansionPanel>


                <ExpansionPanel className = {classes.panel}>
                  <ExpansionPanelSummary expandIcon = { <ExpandMoreIcon /> }>
                    <Typography className={classes.heading}> { sections.subscriptions.title } </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List component="nav" aria-label="main mailbox folders" className = {classes.list}>

                      <ListItem button onClick = { () => this.props.history.push("/app/pricing") } style = {{ borderRadius : 5 }}>
                          <ListItemIcon> <UpgradeIcon /> </ListItemIcon>
                          <ListItemText primary = { sections.subscriptions.items[0] } />
                      </ListItem>

                      <ListItem button className = {classes.list_item} onClick = { this.handle_change_payment_click } style = {{ borderRadius : 5 }}>
                        <ListItemIcon> <CreditCardIcon /> </ListItemIcon>
                        <ListItemText primary = { sections.subscriptions.items[1]} />
                      </ListItem>

                      <ListItem button className = {classes.list_item} onClick = { this.cancel_subscription } style = {{ borderRadius : 5 }}>
                        <ListItemIcon> <CancelIcon /> </ListItemIcon>
                        <ListItemText primary = { sections.subscriptions.items[2] } />
                      </ListItem>
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                {/* Privacy */}
                <ExpansionPanel className = {classes.panel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography className={classes.heading}> { sections.privacy.title } </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <List component="nav" aria-label="main mailbox folders" className = {classes.list}>
                      <ListItem button onClick = { this.handle_cookie_click } style = {{ borderRadius : 5 }}>
                          <ListItemIcon><StorageIcon/></ListItemIcon>
                          <ListItemText primary = { sections.privacy.items[0] } />
                      </ListItem>
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

              </Grid>

            </Grid>
          : <PageLoadingLottie/>  
        }

          
          <ChangePasswordDialog
            open_status = {this.state.change_password_dialog_status}
            set_open_status = { (val) => this.setState({change_password_dialog_status : val}) }
          />

          <UserInformationDialog
            open_status = {this.state.user_information_dialog_status}
            set_open_status = { (val) => this.setState({user_information_dialog_status : val}) }
          />




          {/* Stripe */}
          {
          this.state.stripe_loaded ?
          <StripeProvider apiKey= { stripe_key } /*stripe = { this.state.stripe }*/>
              <div className="example">
                { (this.state.subscription_modal_open) ? 
                <Elements>
                    <CheckoutForm
                        open_status = {this.state.subscription_modal_open}
                        toggle_open_status = { () => this.setState({subscription_modal_open : !this.state.subscription_modal_open}) }
                        title = { t('CHANGE_PAYMENT_METHOD:title') }
                        button_text = { t('G:update') }
                        description = { t('CHANGE_PAYMENT_METHOD:text') }
                        edit = {true}
                    /> 
                </Elements>
                : null
                }
              </div>
          </StripeProvider>
          : null
          } 




        </div>
      </AppFrame>
    );
    }
}

const mapStateToProps = (state , ownProps) => {
  return {
    boxes : state.products.boxes,
    user_subscription : state.global.user_subscription,
    subscription_loaded : state.global.subscription_loaded,
    n_tokens : state.global.n_tokens
  }
}

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    ...bg_container_styles(theme)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight : "100vh"
  },
  profile_picture : {
    maxWidth : 150,
    maxHeight : 150,
  },
  fixedHeight: {
    height: 240,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  panel : {
    paddingTop : theme.spacing(2),
    paddingBottom : theme.spacing(2)
  },
  list : {
    minWidth : "100%"
  }
});
export default (
  connect(mapStateToProps)
  (
    withStyles(styles)(
      withAlert()(
        withTranslation('MY_PROFILE')(ProfilePage)
      )
    )
  )
);