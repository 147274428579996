import React from 'react';
import Grid from '@material-ui/core/Grid';

import Lottie from 'react-lottie';

import { withStyles } from '@material-ui/styles';

import { Fab } from '@material-ui/core';

import PrintIcon from "@material-ui/icons/LocalPrintshop";

import Typography from '@material-ui/core/Typography';

import ArrowNextIcon from "@material-ui/icons/ArrowForward";
import { withTranslation } from 'react-i18next';

import { withAlert } from 'react-alert'

import { connect } from 'react-redux';

import custom_theme from "../../assets/styles/theme";
import { open_print } from '../../components/BoxCard';

const styles = theme => ({
    button_container : {
        display : "flex",
        justifyContent : "center",
        marginTop : theme.spacing(4),
    },
    fab: {
        marginBottom : theme.spacing(4),
        backgroundColor : custom_theme.PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: custom_theme.PRIMARY_COLOR,
        },
        color : "white"
    },
    fab_left : {
        marginRight : theme.spacing(4),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    icon_right : {
      marginLeft: theme.spacing(1),
    },
    card_container : {
        margin : theme.spacing(2)
    },
    card: {
        width : "70%",
        display : "flex",
        flexDirection : "column",
        alignItems : "space-between",
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(2)
    },
    media: {
        height: 200,
    },
    text_input : {
        marginTop : theme.spacing(2)
    }
  });

class PageThree extends React.Component{

    componentWillMount(){
        const {alert, t} = this.props;
        alert.success( 
            // t('box_registered_alert_text', { postProcess: 'interval', box_number : this.props.registered_box_number, n_tokens_left: this.props.n_tokens - 1 })
            // t('box_registered_alert_text', { postProcess : 'interval', box_number : this.props.registered_box_number }) + "," + 
            t('PLURALS:leftToRegister', { postProcess : 'interval', count : this.props.n_tokens - 1 })
         )
    }

    render(){
        const {classes, t, boxes} = this.props;
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: require("../../assets/animations/box_animation.json"),
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        };

        const inserted_box = boxes[boxes.length - 1];

        return(
            <>
            <Grid container className = {classes.card_container}>                
              <Grid item xs = {12}>
                <Typography component="h1" variant="h4" color="primary" gutterBottom align = "center"> { t('box_registered') } </Typography>
                <Typography component="h1" variant="h6" color="primary" gutterBottom align = "center"> {t('box_number')} #{this.props.registered_box_number}</Typography>
              </Grid>
                <Lottie 
                  options={defaultOptions}
                  height={200}
                  width={200}
                />
            </Grid>
            <div className = {classes.button_container}>
                <Fab variant="extended" aria-label="delete" className={`${classes.fab_left} ${classes.fab}`} onClick = {() => open_print( [inserted_box] , t)}>
                    <PrintIcon className={classes.extendedIcon} />
                    { t('print_label') }
                </Fab>
                
                <Fab variant="extended" aria-label="delete" className={classes.fab} onClick = { () => { this.props.history.replace(`/app/box-page?box=${inserted_box.box_number}`);}}>
                    { t('redirect_to_box') }
                    <ArrowNextIcon className={classes.icon_right} />                    
                </Fab>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        n_tokens : state.global.n_tokens,
        boxes : state.products.boxes
    }
}



export default (
    withStyles(styles)(
        connect(mapStateToProps)(
            withTranslation('A_B_S_3')(
                withAlert()(PageThree)
            )
        )
    )
);
