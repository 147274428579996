import {
    FETCH_BOXES_BEGIN,
    FETCH_BOXES_SUCCESS,
    FETCH_BOXES_FAILURE,
    UPDATE_BOX,
    ADD_CLIENT_BOX,
    ADD_BOX_ITEM,
    REMOVE_BOX_ITEM,
    UPDATE_ITEM, 
    REMOVE_CLIENT_BOX
} from '../actions/box_actions';

import { find_box } from '../logic/box_logic';

// import { clear_form } from '../actions/add_box_form_actions';

function form_data_to_object(box, form_data){
    let has_location = ( form_data.address !== "" && !isNaN(form_data.cords.lat) && !isNaN(form_data.cords.lng) );
    let obj =  {
        items   : [],
        box_id : form_data.box_id,
        ...box,
        box_number      : form_data.box_number,
        box_description : form_data.description,
        box_title       : form_data.name,
        location        : {
          ...box.location,
          address  : form_data.address,
          name     : form_data.location_name,
          lat      : form_data.cords.lat,
          lng      : form_data.cords.lng,
          has_location : has_location
        },
        box_url : (form_data.img) ? form_data.img : "NO_IMG"//box.box_url
    }
    return obj;
}


const initialState = {
  boxes : undefined,
  loading : false,
  error : null,
  active_box_page : undefined
};
  
  export default function boxReducer(state = initialState, action) {

    let box, item, item_index;

    switch(action.type) {
      case FETCH_BOXES_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };

      case FETCH_BOXES_SUCCESS:
        return {
          ...state,
          loading: false,
          boxes: action.payload.boxes
        };
  
      case FETCH_BOXES_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          boxes : []
        };
      
      case ADD_CLIENT_BOX:
        return{
          ...state,
          boxes : [ ...state.boxes, action.payload.new_box ]
        }
      
      case REMOVE_CLIENT_BOX:
      return{
        ...state,
        boxes : state.boxes.filter( (box) => {
          return (box.box_id !== action.payload.box_id);
        })
      }

      case UPDATE_BOX : 
        let updated_array = state.boxes.map((box) => {
          if (box.box_id !== action.payload.box_id) {
            return box;
          }
          return {
            ...box,
            ...action.payload.box_data
          }
        })
        
        return {
          ...state,
          boxes : updated_array
        }
      
      /*ITEM LOGIC*/
      case ADD_BOX_ITEM:
        let new_item = action.payload.item;

        let boxes_with_new_item = state.boxes.map((box, index) => {
          if (new_item.box_id !== box.box_id) { 
            return box;
          }else{
            return { //Item is now just filled with ITEMS as we wich and we categorize these inside of the BoxItem class 
              ...box,
              items : [...box.items, new_item] 
            } 
          }     
        })
        return {
          ...state,
          boxes : boxes_with_new_item
        }
      

      case REMOVE_BOX_ITEM:     
        let remove_item = action.payload.item;

        let boxes_with_removed_item = state.boxes.map((box, index) => {
          if (remove_item.box_id !== box.box_id) { return box }
          item_index = 0;
          box.items.forEach( (itterator_item, i) => {
            if(itterator_item.item_id === remove_item.item_id){ item_index = i;}
          });
          return {
            ...box,
            items : [...box.items.slice(0, item_index), ...box.items.slice(item_index + 1)]
          }          
        })
        return {
          ...state,
          boxes : boxes_with_removed_item
        }

      case UPDATE_ITEM : 
        let boxes_with_updated_item = state.boxes.map((box, index) => {
          if ( box.box_id !== action.payload.box_id ) { return box }

          item_index = 0;
          box.items.forEach( (itterator_item, i) => {
            if(itterator_item.item_id === action.payload.new_item.item_id){ item_index = i }
          });
          return {
            ...box,
            items : [...box.items.slice(0, item_index), action.payload.new_item , ...box.items.slice(item_index + 1)]
          }          
        })
        return {
          ...state,
          boxes : boxes_with_updated_item
        }

      default:
        return state;
    }
  }