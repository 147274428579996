/*ACTION_DECLARATIONS*/
export const SET_N_TOKENS       = 'SET_N_TOKENS';
export const DECREMENT_TOKENS   = "DECREMENT_TOKENS";
export const INCREMENT_TOKENS   = "INCREMENT_TOKENS";

export const SET_ISO_DATA       = "SET_ISO_DATA";

export const SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION';
export const SET_SUBSCRIPTION_LOADED = 'SET_SUBSCRIPTION_LOADED';

export const SET_GLOBAL_REDUCER_VALUE = 'SET_GLOBAL_REDUCER_VALUE';


export const set_global_reducer_value = ( key , value) => ({
    type : SET_GLOBAL_REDUCER_VALUE, 
    payload : {
        key : key,
        value : value
    }
});

export const set_user_subscription = (subscription) => ({
    type : SET_USER_SUBSCRIPTION,
    payload : subscription
});


export const set_subscription_loaded = (bool) => ({
    type : SET_SUBSCRIPTION_LOADED,
    payload : bool
});

export const set_iso_data = ( obj ) => ({
    type : SET_ISO_DATA,
    payload : obj
});

export const set_n_tokens = (n) => ({
    type : SET_N_TOKENS,
    payload : n
});

export const decrement_tokens = () => ({
    type : DECREMENT_TOKENS
});
export const increment_tokens = () => ({
    type : INCREMENT_TOKENS
});


