import React from 'react';
import clsx from 'clsx';


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Chart from '../components/chart';
import DashboardNData from '../components/DashboardNData';

import { withStyles } from '@material-ui/styles';

import AppFrame from "../components/AppFrame";
import { connect } from 'react-redux';
import PageLoadingLottie from '../components/PageLoadingLottie';
import { withTranslation } from 'react-i18next';
import { Hidden, Typography, Avatar } from '@material-ui/core';

import CardLayout from '../Layouts/CardLayout';
import { FolderList } from '../components/BoxPage/FolderList';
import NoBoxes from "../components/EmptyReplacements/NoBoxes"

import { deep_desc_sort } from "../functions/sorting_functions"

import BoxInfoIcon from "@material-ui/icons/Pageview"
import BoxIcon from '@material-ui/icons/ViewModule';
import ItemIcon from '@material-ui/icons/ViewCompact';
import theme from '../assets/styles/theme';

import { FaBoxOpen, FaDolly } from 'react-icons/fa';
import Field from '../components/map_components/Field';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    // height: 240,
    height: 280,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button : {
    width : "100%",
    paddingTop : theme.spacing(1.3),
    paddingBottom : theme.spacing(1.3),
    [theme.breakpoints.down('sm')]: {
      width : "100%",
      marginTop : theme.spacing(2),
      paddingTop : theme.spacing(2),
      paddingBottom : theme.spacing(2),
    },
  },
  button_container : {
    display : "flex", 
    padding : "7.5%", 
    flexDirection : "column", 
    justifyContent : "space-around", 
    height : "100%",
    width : "100%",
    alignItems : "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection : "column",
    },
  },
});

class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      items : [],
      items_sorted : false,
      box_index : 0,
      timer : 0,

      img_dialog_open : false,
      img : undefined
    }
    this.classes = this.props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
  }


  handle_items_recieved = (boxes) => {
    if(boxes === undefined) return;
    let items = [];
    boxes.forEach(box => {
      box.items.forEach( item => {
        items.push(item);
      })
    });
    items.sort(function(a, b){
      return a.item_id <= b.item_id
    });
    this.setState({items : items, items_sorted : true});
  }

  async componentWillMount(){
    this.handle_items_recieved(this.props.boxes);
  }

  componentWillReceiveProps (nextprops){
    this.handle_items_recieved(nextprops.boxes);
  }

  componentDidMount(){ 
    const { t } = this.props;
    document.title = t('TITLES:dashboard')
  }

  // componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(([key, val]) =>
  //     prevProps[key] !== val && console.log(`Prop '${key}' changed ` + prevProps[key] + " : " + val)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(([key, val]) =>
  //       prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  render(){
  const {classes, t, history} = this.props; //Inherit styles from higher order component
  const { boxes, conversations } = this.props;
    
  let items = this.state.items;
  // let boxes = this.props.boxes;

  return (
    <AppFrame>
      { (boxes) ? 
          <Grid container spacing = { 3 }>
           
            <Hidden smUp>
              <Grid item xs = {12}><h1 style = {{margin : 0}}> My storage </h1></Grid>
            </Hidden>
            
            <Grid item xs={12} md={8} lg={8}>
              <Paper className={this.fixedHeightPaper}>
                <h1 style = {{ marginTop : 0 }}>{t('DASHBOARD_CHART:items')}</h1>
                <Chart boxes = {boxes}/>
              </Paper>
            </Grid>


            <Grid item xs={12} md={4} lg={4}>
              <Paper className={this.fixedHeightPaper}>
                <div style = {{ display : "flex", alignItems : "center", justifyContent : "space-between" }}>
                  <h1>{t('DASHBOARD_N_DATA:title')}</h1>
                  <Avatar className={classes.avatar}  style = {{ background :  theme.PRIMARY_COLOR }}>
                    <BoxInfoIcon style = {{color : "white"}}/>
                  </Avatar>
                </div>
                
                <div style = {{ display : "flex", flexDirection : "column", justifyContent : "center" }} >
                  <div style = {{ display : "flex", alignItems : "center" }}>
                    <Avatar style = {{ background : theme.PRIMARY_COLOR }}> <FaBoxOpen style = {{color : "white"}}/> </Avatar>
                    <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>
                      { t('PLURALS:boxInterval', { postProcess: 'interval', count: boxes.length }) }
                    </Typography>
                  </div>

                  <div style = {{ display : "flex", alignItems : "center", marginTop : 20}}>
                    <Avatar style = {{ background : theme.PRIMARY_COLOR,  }}> <FaDolly style = {{color : "white"}}/> </Avatar>
                    <Typography component="p" variant = "subtitle2" style = {{ marginLeft : 10 }}>
                      {
                        t('PLURALS:itemInterval', { 
                          postProcess: 'interval',
                          count: boxes.reduce(function (total, currentValue) {
                            return total + currentValue.items.length;
                        }, 0)
                        })
                      }
                    </Typography>
                  </div>
                </div>
              </Paper>
              
            </Grid>


            <Grid item xs = {12}  spacing = {3}>
              {
                ( boxes && boxes.length > 0 ) ?
                <CardLayout
                  data = { deep_desc_sort(boxes, "box_id").slice(0, 3) }
                  history = { history }
                  card_type = "BOX"
                  title = { t('DASHBOARD_GLOBALS:recent_boxes') }
                /> :
                <NoBoxes history = { history } />
              }
            </Grid>


            <Grid  item container spacing = {3} className = { classes.button_recent_container }>              

              <Grid item xs = {12} lg = {8}>
                <h1>{t('DASHBOARD_GLOBALS:recent_items')}</h1>
                <Paper>
                  <FolderList
                    title = { t('DASHBOARD_GLOBALS:recent_items') }
                    type = "items" 
                    data = { items.slice(0, 20) }
                    history = { history }
                  />
                </Paper>
              </Grid>

              <Grid item xs = {12} md = {12} lg = {4}>
                <h1>{t('ADD_BOX_PAGE:offers')}</h1>
                <Paper>
                  <FolderList
                    title = { t('ADD_BOX_PAGE:offers') }
                    type = "offers"
                    data = { conversations ? conversations.slice(0, 6) : [] }
                    history = { history }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid> :  <PageLoadingLottie/>
      }
    </AppFrame>
  );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    boxes : state.products.boxes,
    conversations : state.chat.conversations
  }
}

const mapDispatchToProps = { 

}


export default (
  withStyles(styles)
    (
      connect(mapStateToProps, mapDispatchToProps)
      (
        withTranslation()(Dashboard)
      )
    )
);