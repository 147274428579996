import React from "react";
import { withStyles } from "@material-ui/core";

import { Link } from "react-router-dom";
import withWidth from '@material-ui/core/withWidth';

import CustomDrawer from "./CustomDrawer"
import { withTranslation } from "react-i18next";
// import { sign_out } from "../../logic/exit_logic";

const styles = (theme) => ({
    navbar : {
        top : 0,
        display : "flex",
        zIndex : 2,
        padding : "15px 50px",
        boxSizing : "border-box",
        width : "100%",
        justifyContent : "space-between",
        alignItems : "center",
        transition : "0.4s ease-in-out",

        [theme.breakpoints.down('xs')]: {
            padding : "12px 20px",
            boxShadow : "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            backgroundColor : "#10BBFF"
        },

    },
    navbar_img : {
        maxWidth : 150,
        maxHeight : 40,
    },
    right_navbar : {
        display : "flex",
    },
    navbar_element : {
        padding : 15,
        color : "white",
        // fontWeight : "bold",
        fontWeight : 500,
        fontSize : "1.1em",
        cursor : "pointer",
        fontFamily : "Roboto",
        '&:hover': {
            color: "gray",
        },
        transition : "0.2s ease-in-out",
        [theme.breakpoints.down('sm')]: {
            fontSize : "0.9em"
        },
    },
});

class Navbar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            is_scrolled : false
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = () => {
        this.setState({is_scrolled : window.scrollY > 120});
    };
    render(){
        const {classes, t} = this.props;
        let is_mobile = this.props.width === 'xs';

        let jwt = localStorage.getItem('jwt');

        const pages = t('NAVBAR:public_paths', { returnObjects : true });

        return(
            (!is_mobile) ?
            <div className = {classes.navbar} style = {{
                backgroundColor : this.state.is_scrolled ? "#10BBFF" : undefined,
                position : (is_mobile) ? 
                    "static" : 
                    (this.state.is_scrolled ? "fixed" : "absolute"),
                boxShadow: this.state.is_scrolled ? "0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : undefined
            }}> 
                <Link to = "/">
                    <img alt = "Home" src = "/Qboxr_logo.png" className = {classes.navbar_img}/>
                </Link>
                <div className = {classes.right_navbar}>

                    {
                        pages.map( obj => {
                            return (
                                <Link to = { obj.path } style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                    <div className = {classes.navbar_element}>{ obj.text }</div>
                                </Link>
                            )
                        })
                    }

                    <div className = {classes.navbar_element} onClick = { () => {
                        if(jwt === null) 
                            this.props.history.push("/sign-in")
                        else{
                            this.props.history.push("/app/dashboard")//sign_out();
                        }
                    }}>
                        { (jwt === null) ? t('G:sign_in') : t('G:dashboard')}
                    </div>
                    {/* </Link> */}
                
                </div>                
            </div>
            : <CustomDrawer/>

        )
    }
}
export default withStyles(styles)(withWidth()(withTranslation()(Navbar)));