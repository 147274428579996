export function deep_date_sort(data_set){
    return JSON.parse(JSON.stringify(data_set)).sort(function(a,b){
        let d_1 = Date.parse(a.submit_date);
        let d_2 = Date.parse(b.submit_date);
        return d_1 > d_2 ? 1 : 0;
    });
}

export function deep_desc_sort(data_set, key){
    return JSON.parse(JSON.stringify(data_set)).sort(function(a,b){
        return b[key] > a[key] ? 1 : 0;
    });
}

export function deep_asc_sort(data_set, key){
    return JSON.parse(JSON.stringify(data_set)).sort(function(a,b){
        return a[key] > b[key] ? 1 : 0;
    });
}

export function deep_message_sort(data_set, key){
    return JSON.parse(JSON.stringify(data_set)).sort(function(a,b){
        return a.message_id > b.message_id ? 1 : 0;
    })
}

export function deep_conversation_sort(data_set, key){
    return JSON.parse(JSON.stringify(data_set)).sort(function(a,b){
        return a.messages[0] > b.message_id ? 1 : 0;
    })
}