import { withWidth, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Avatar from "@material-ui/core/Avatar";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MessageIcon from "@material-ui/icons/Chat";
import PhoneIcon from "@material-ui/icons/Phone";
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withAlert } from 'react-alert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AppFrame from "../../components/AppFrame";
import PageLoadingLottie from "../../components/PageLoadingLottie";
import { format_date } from '../../functions/date_functions';
import { f_a_c } from '../../functions/money_functions';

import DeleteIcon from "@material-ui/icons/Delete";

import AppFramStyles from "../../assets/styles/AppFrameContentStyles";

import { withRouter } from 'react-router-dom';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ChatWidget from '../../components/Chat/ChatWidget';

import queryString from 'query-string';
import ip from '../../constants/ip';
import { get_text_from_key } from '../../locales/language_functions';
import CardLayout from '../../Layouts/CardLayout';
import theme from '../../assets/styles/theme';
import get_cat_icon from './get_category_icon';
import { register_item_visit } from '../../logic/shop_logic';
import { init_category } from '../../components/Viritualizded/Table/ItemTable/Expand';
import { FaLocationArrow } from 'react-icons/fa';
import { get_end_point } from '../../logic/image_logic';


export function ImageCarousel(props){
    let height = "100%";
    let maxHeight = "50vh";
    return(
        <Carousel style = {{maxHeight : "100%", height : "100%"}} showStatus = {false} showIndicators = {false}>
            {
                props.images.map( url => {
                    let img = (url === "NO_IMG") ? "/img/qboxr.png" :  get_end_point( "ITEM", url );
                    return (
                        <div style = {{height : "100%", width : "100%"}}>
                            <img src = {img} style = {{height : height, maxHeight : maxHeight, objectFit : "contain"}}/>
                        </div>
                    )
                })
            }
        </Carousel>
    )
}


class ArticlePage extends React.Component{
  constructor(props){
    super(props); 
    this.state = {
        item : undefined,
        sales_category : '',
        sales_sub_category : ''
    }
  }

  init_article = async(user_location) => {
    const { alert, t, history } = this.props;

    let params = queryString.parse(this.props.location.search)
    let item_id = params.item;

    // Save the visit in the database
    register_item_visit( item_id );

    //The reason to why we are doing this instead of just passing down the already queried item to this subpage is cause we want to enable linkting with GET parameters and load the information on load
    let item_response = await fetch(`${ip}/storager/shop/get_requests/get_item_details.php`, {
        method : "POST",
        body : JSON.stringify({ item_id : item_id, user_location : user_location })
    });
    let json_data = await item_response.json();

    if(json_data.success){
        const { item, same_box_items } = json_data;
        this.setState({ item : item, same_box_items : same_box_items });
        document.title = 'Qboxr - ' + item.title;
        this.setState(init_category(t, item));
    }else{
        alert.error( t(`ERRORS:${json_data.error_code}`) );
        history.push('/shop');
    }
  }


  async componentWillMount(){
    if(this.props.user_location){
        this.init_article(this.props.user_location);
    }
    this.unlisten = this.props.history.listen((location, action) => {
        setTimeout(this.init_article, 50); //We need some time for the url to be set
    });
  }

  componentWillReceiveProps(nextprops){
    if(!this.props.user_location && nextprops.user_location){
        this.init_article(nextprops.user_location);
    }
  }

  componentWillUnmount(){
    //   this.unlisten();
  }

  show_chat = () => {
    const { item } = this.state;
    if(item && this.props.boxes){
        let show = true;
        this.props.boxes.forEach( box => {
            if(box.box_id === item.box_id){
                show = false;
            }
        });
        return show;
    }else{
        return false;
    }
  }

  render(){
    const { classes, t, history } = this.props; //Inherit styles from higher order component
    const { item, same_box_items } = this.state;
    //Will be multi supported in the future
    if(item === undefined){
        return <AppFrame><PageLoadingLottie/></AppFrame>
    }

    // let img = item.img === "NO_IMG" ? "/img/qboxr.png" : item.img;
    let images = item.images.length > 0 ? item.images : [ { url : "/img/qboxr.png" } ];

    const description = item.description === "" ? t('ITEM_CARD:no_description') : item.description ; 

    let CatIcon = React.cloneElement(
        get_cat_icon(item.sales_category),
        {
            // style : { color : "white", backgroundColor : theme.PRIMARY_COLOR, borderRadius : "50%", padding : 5, fontSize : 35, marginRight : 10},
        }
    );


    return (
    <AppFrame>
        <>
          <Grid container spacing={3}>
            
            <Grid item xs={12} md={12}>
                <Paper>
                    <ImageCarousel images = { images }/>
                </Paper> 
            </Grid>

            <Grid item xs = {12} md = { 12 } className = { classes.info_container }>
                
                <Paper className = { classes.info_paper }>

                    <div style = {{ display : "flex", alignItems : "center"}}>
                        {/* <Tooltip title = { this.state.sales_category }>
                            <Avatar style = {{ marginRight : 10, backgroundColor : theme.PRIMARY_COLOR }}>
                                { CatIcon }
                            </Avatar>
                        </Tooltip> */}
                        <Typography variant = "h5" >{item.title}</Typography>
                    </div>
                    

                    <div style = {{marginTop : 10}}></div>
                    <Typography variant = "h6" style = {{marginBottom : 10}}>{ "Information" }</Typography>
                    
                    <div style = {{ display : "flex", flexDirection : "column" }}>
                        <Typography variant = "subtitle">
                        { t('ITEM_CARD:category') } : { `${this.state.sales_category}`}
                        </Typography>

                        <Typography variant = "subtitle">
                            { t('ITEM_CARD:sub_category') } : { `${this.state.sales_sub_category}`}
                        </Typography>
                        
                        <Typography variant = "subtitle">
                            { t('ITEM_CARD:up_for_sale_since') } : { format_date( item.submit_date , t) }
                        </Typography>
                        
                        <Typography variant = "subtitle">
                            {t('SHOP_ARTICLE:price') } : {
                                f_a_c( item.price , item.iso_code) + ( item.is_bundle ? "" : "/" + t('ARTICLE_PAGE:a_piece'))
                            }
                        </Typography>
                        {
                            !item.is_bundle ?
                            <Typography variant = "subtitle">
                                { t('ARTICLE_PAGE:in_stock') + " : " + item.n_of_item + " " + t('ARTICLE_PAGE:pieces') }
                            </Typography>
                            : null
                        }
                    </div>
                   
                    <Typography variant = "subtitle2" style = {{marginTop : 20}}>{ t('ITEM_CARD:description') }</Typography>
                    
                    <Typography variant = "subtitle"> {description} </Typography>


                    <Typography variant = "subtitle2" style = {{marginTop : 20}}>{ t('ITEM_CARD:location') }</Typography>
                    <Typography style = {{ marginLeft : 10 }} variant = "subtitle">{ t('ITEM_CARD:within') + " : " + item.distance + " km"} </Typography>
                </Paper>


                <Paper className = { classes.contact_list_container }>
                    <Typography variant = "h6" style = {{marginBottom : 10}}>{ "Contact" }</Typography>
                    <List className = { classes.contact_list } >                
                        <ListItem button style = {{flex : 1, borderRadius : 5}} disabled = { !this.show_chat() }>
                            <ListItemIcon onClick = { () => this.launcher.click() }>
                                <MessageIcon />
                            </ListItemIcon>
                            <ListItemText primary = { t('ITEM_CARD:contact_seller') } />
                        </ListItem>

                        <ListItem button style = {{flex : 1, borderRadius : 5}} disabled>
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary = {
                            this.state.phone_number ? "Call (070**)" : t('ITEM_CARD:no_phone_number') 
                        } />
                        </ListItem>
                    </List>
                </Paper>
            </Grid>
            
        </Grid>

        <Grid container spacing = { 3 } className = { classes.suggestion_container }>
            {
            same_box_items.length > 0 ? 
            <>
            <Grid item xs = {12}><h1 style = {{margin : 0}}>{t('ARTICLE_PAGE:items_for_sale_in_the_same_box')}</h1></Grid>
            <Grid item xs = {12}>
                    <CardLayout
                        data = { same_box_items }
                        card_type = "SHOP_CARD"
                        history = { history }
                    />
            </Grid>
            </>
            : null
            }
        </Grid>
        
        { (this.show_chat()) ? <ChatWidget item = { item } t = { t } /> : null }


        </>
    </AppFrame>
  );
  }
}


const styles = theme => ({
    ...AppFramStyles(theme),
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    description : {
        marginTop : theme.spacing(1)
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    button : {
      width : "100%",
      paddingTop : theme.spacing(1.3),
      paddingBottom : theme.spacing(1.3),
      [theme.breakpoints.down('sm')]: {
        width : "100%",
        marginTop : theme.spacing(2),
        paddingTop : theme.spacing(2),
        paddingBottom : theme.spacing(2),
      },
    },
    button_container : {
      display : "flex", 
      padding : "7.5%", 
      flexDirection : "column", 
      justifyContent : "space-around", 
      height : "100%",
      width : "100%",
      alignItems : "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection : "column",
      },
    },
    info_paper : {
      padding : theme.spacing(3)
    },
  
    contact_list : {
        width : "100%"
    },
    contact_list_container : {
        marginTop : theme.spacing(4),
        padding : theme.spacing(3)
    },
  
    suggestion_container : {
        marginTop : theme.spacing(3)
    }
  });
  

const mapStateToProps = (state , ownProps) => {
  return {
    boxes : state.products.boxes,
    user_location : state.global.user_location
  }
}

const mapDispatchToProps = { 

}

export default (
    withAlert()(
      connect(mapStateToProps, mapDispatchToProps)(
        withTranslation()(
          withStyles(styles)(
            // withWidth()(
                withRouter(
                    ArticlePage
                )
            // )
          )
        )
      )
    )
);