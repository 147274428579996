import React from "react";
import {TextField, InputAdornment, IconButton } from '@material-ui/core';

import { connect } from "react-redux";

class CurrencyTextfield extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <TextField
                variant = "outlined"
                label = { this.props.placeholder }
                placeholder = { this.props.placeholder }
                style = {{width : "100%"}}
                value = {this.props.value}
                onChange = { (e) => this.props.set_value(e.target.value) }
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="start" style = {{alignSelf : "center"}}>{ this.props.iso_data ? this.props.iso_data.symbol : ''}</InputAdornment>
                  ),
                }}
                {
                    ...this.props
                }
              />
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
      iso_data : state.global.iso_data
    }
}
  

export default (
    connect(mapStateToProps)(
        CurrencyTextfield
    )
);