import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation, withTranslation } from "react-i18next";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Link as BlueLink, Tooltip } from "@material-ui/core";
import ip from "../../constants/ip";
import Transition from "../dialogs/Transition";

import NotInterested from "@material-ui/icons/NotInterested";
import Done from "@material-ui/icons/DoneAllOutlined";

import { Link } from "react-router-dom";
import { set_cookie, get_cookie } from "../../logic/cookies";
import init_app from "../../logic/init_logic";

import { withAlert } from 'react-alert'


class CookieConsent extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        show : true,
        open_status : true
      }
    }
    
    componentWillMount(){
      let jwt = localStorage.getItem('jwt');
      let has_consent = localStorage.getItem('cookie_consent');
      this.setState({ open_status : !(has_consent || jwt) })
    }

    submit_consent = async() => {
      const { alert, t } = this.props;
      // let response = await fetch(`${ip}/storager/authentication/accept_cookies.php`);
      // let json = await response.text();
      // if( json == 1 ){
      //   this.setState({ open_status : false });
      //   localStorage.setItem('cookie_consent', 1);
      //   init_app(t, alert);
      // }
    }

    handle_reject = async() => {
      window.location.href = "https://gdpr-info.eu";
    }

    render(){
    const { t } = this.props;
    return(  
      <Dialog
        open = { this.state.open_status && false }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={ Transition }
    >
      <DialogContent>

        <div style = {{ display : "flex", justifyContent : "space-between", alignItems : "center" }}>
          <h1>{t('COOKIE_CONSENT:we_use')}!</h1>
          <Tooltip title = { "English" } aria-label = { "English" }>
          <img onClick = { () => {
            localStorage.setItem('i18nextLng','en-EN');
            window.location.reload();
          }} src = "/img/usa.png" style = {{ width : 40, height : 25, borderRadius : 5, cursor : "pointer" }} />
          </Tooltip>
        </div>
        
        
        <DialogContentText>
          { t('COOKIE_CONSENT:text') + " "}
          <a href = "http://www.qboxr.com/cookie-policy" target = "_blank">
            <BlueLink>{ t('COOKIE_CONSENT:cookie_policy')}</BlueLink>
          </a>
          { "."  }
        </DialogContentText>

        <DialogActions>

          <Button onClick = { this.handle_reject } variant="contained" color="secondary">
            { t('COOKIE_CONSENT:not_accept') }
            <NotInterested style = {{ marginLeft : 3 }} />
          </Button>
          <Button onClick = { this.submit_consent } variant="contained" color="primary">
            { t('G:accept') }
            <Done style = {{ marginLeft : 3 }} />
          </Button>
        </DialogActions>

      </DialogContent>
    </Dialog>
    )
    }
  }
  
export default(
  withTranslation()(
    withAlert()(
      CookieConsent
    )
  )
)