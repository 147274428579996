// // import React from "react";
// // import { fade } from '@material-ui/core/styles';
// // import { withStyles } from '@material-ui/styles';
// // import SearchIcon from '@material-ui/icons/Search';
// // import { InputBase } from '@material-ui/core';
// // import { withRouter } from 'react-router-dom';
// // import { withTranslation } from "react-i18next";

// // const styles = (theme) => ({
// //     search: {
// //       position: 'relative',
// //       borderRadius: theme.shape.borderRadius,
// //       backgroundColor: fade(theme.palette.common.white, 0.15),
// //       '&:hover': {
// //         backgroundColor: fade(theme.palette.common.white, 0.25),
// //       },
// //       marginLeft: 0,
// //       width: "50%",
// //       marginRight : 20,
// //       // backgroundColor : "yellow",

// //       [theme.breakpoints.up('sm')]: {
// //         marginLeft: theme.spacing(1),
// //         width: 'auto',
// //       },

// //   },
// //   searchIcon: {
// //     width: theme.spacing(7),
// //     height: '100%',
// //     position: 'absolute',
// //     pointerEvents: 'none',
// //     display: 'flex',
// //     alignItems: 'center',
// //     justifyContent: 'center',
// //   },
// //   inputRoot: {
// //     color: 'inherit',
// //     height : "100%",
// //   },
// //   inputInput: {
// //     padding: theme.spacing(1.5, 1, 1.5, 7),
// //     transition: theme.transitions.create('width'),
// //     width: '100%',
    
// //     [theme.breakpoints.up('sm')]: {
// //       padding: theme.spacing(2, 1, 2, 7),
// //       width: 150,
// //       '&:focus': {
// //         width: 300,
// //       },
// //     },

// //   }
// // });

// // class AppBarSearch extends React.Component{
// //     constructor(props){
// //         super(props);
// //         this.state = {
// //           val : ''
// //         }
// //         this.text_ref = React.createRef();
// //     }

// //     handle_key_down = (e) => {
// //       if(e.key === 'Enter'){
// //         this.props.history.push(`/app/search-results?query=${this.state.val}`);
// //         e.target.select();
// //       }
// //     }

// //     render(){
// //         const {classes, t} = this.props;
// //         return(
// //             this.props.do_display ? 


// //             : null
// //         );
// //     }
// // }
// // export default (
// //   withStyles(styles)
// //   (
// //     withTranslation()
// //     (
// //       withRouter(AppBarSearch)
// //     )
// //   )
// // );

// /* eslint-disable no-use-before-define */
// import React from 'react';
// import useAutocomplete from '@material-ui/lab/useAutocomplete';
// import CheckIcon from '@material-ui/icons/Check';
// import CloseIcon from '@material-ui/icons/Close';
// import styled from 'styled-components';

// import { connect } from "react-redux";
// import { FaBoxOpen, FaSearch } from 'react-icons/fa';




// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import IconButton from '@material-ui/core/IconButton';
// import PermaDeleteIcon from "@material-ui/icons/DeleteForever";
// import EditIcon from '@material-ui/icons/Edit';
// import { open_item_dialog } from './dialogs/AddItemDialog';
// import { handle_remove_click } from './BoxPage/BoxItemCard';

// import { useTranslation } from 'react-i18next';
// import { useAlert } from 'react-alert';

// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';




// function LongMenu({item, t, alert}) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleClick = event => {
//     console.log(event.currentTarget);
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (e) => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <IconButton
//         aria-label="Edit item"
//         aria-controls="edit-item"
//         onClick={() => open_item_dialog({ action_type : 'EDIT', item : item, })}
//       >
//         <EditIcon style = {{ fontSize : 17 }} />
//       </IconButton>
//       <IconButton
//         aria-label="more"
//         aria-controls="long-menu"
//         onClick={ () => handle_remove_click(item, t, alert)}
//       >
//         <PermaDeleteIcon style = {{ fontSize : 17 }} />
//       </IconButton>
//     </div>
//   );
// }


// width: 300px;
// const InputWrapper = styled('div')`
//   width : 100%;
//   border: 1px solid #d9d9d9;
//   background-color: #fff;
//   border-radius: 4px;
//   padding: 7.5px;
//   display: flex;
//   flex-wrap: wrap;


//   &:hover {
//     border-color: #40a9ff;
//   }

//   &.focused {
//     border-color: #40a9ff;
//     box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
//   }

//   & input {
//     font-size: 14px;
//     height: 30px;
//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `;
// // width: 200px;
// const Listbox = styled('ul')`
//   width : 50vw;
//   max-height : 400px;
//   @media (min-width: 768px) {
//     width : 30vw;
//     max-height : 250px;
//   }

//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
//   list-style: none;
//   background-color: #fff;
//   color : black;
//   overflow: auto;
//   /* max-height: 250px; */
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 10px 12px;
//     display: flex;
//     align-items : center;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: #fafafa;
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li[data-focus='true'] {
//     background-color: #e6f7ff;
//     cursor: pointer;

//     & svg {
//       color: #000;
//     }
//   }
// `;

// const Container = styled('div')`
//   width : 50vw;

//   @media (min-width: 768px) {
//     width : 30vw;
//   }
// `;



// function AppBarSearch({ boxes, do_display }) {

//   const all_items = ( boxes ? [].concat.apply([], boxes.map( box => { return box.items })) : [] ).map( item => ({
//     ...item,
//     firstLetter: /[0-9]/.test(item.title[0]) ? '0-9' : item.title[0],
//   }))

//   const handle_click = (e) => { e.preventDefault(); }
  
//   const n_to_load = 20;

//   const {
//     getRootProps,
//     getInputLabelProps,
//     getInputProps,
//     getTagProps,
//     getListboxProps,
//     getOptionProps,
//     groupedOptions,
//     value,
//     focused,
//     setAnchorEl,
//   } = useAutocomplete({
//     id: 'customized-hook-demo',
//     defaultValue: [],
//     disableCloseOnSelect : true,
//     blurOnSelect : false,
//     options : boxes ? all_items : [],
//     onChange : () => {},
//     getOptionLabel: option => option.title,
//     groupBy : (option) => option.firstLetter
//   });


//   const { t } = useTranslation();
//   const alert = useAlert();


//   // let [ focused, setFocused ] = React.useState(false);

//   const inputProps = getInputProps();
//     let input_val = inputProps.value;


//   if( !do_display ){
//     return null;
//   }

//   return (
//     <Container>
//       <div {...getRootProps()}>
//         <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
//           <input 
//             { ...inputProps }
//             placeholder = { t('SEARCH_RESULTS:search_your_storage') }
//           />
//           <FaSearch style = {{ color : "gray", alignSelf : "center", marginRight : 10  }} />
//         </InputWrapper>
//       </div>
//       {groupedOptions.length > 0 && focused && input_val.length > 0 ? (
//         <Listbox {...getListboxProps()}>
//           {groupedOptions.sort((x, y) => x.key >= y.key).map( (group, index) => (
//             <>
//             <h4 style = {{ paddingLeft : 12 }}>{group.key}</h4>
//             {
//               group.options.slice(0, n_to_load).map( (item, i) => {
//                 const matches = match(item.title, input_val);
//                 const parts = parse(item.title, matches);
//                 return(
//                   <li {...getOptionProps({ item, index : group.index + i })} onClick = { handle_click } style = {{ display : "flex", justiftContent : "space-between" }}>
//                     {/* <span>{item.title}</span> */}
//                     <div>
//                       {parts.map((part, index) => (
//                         <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
//                           {part.text}
//                         </span>
//                       ))}
//                     </div>
//                   </li>
//                 )
//               }
//               )
//             }
//             </>
//           ))}

//         </Listbox>
//       ) : null}
//     </Container>
//   );
// }

// const mapStateToProps = (state , ownProps) => {
//   return {
//     boxes  : state.products.boxes,
//   }
// }

// export default connect(mapStateToProps)(AppBarSearch)


import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography, withStyles, Grid, Badge } from '@material-ui/core';
import { useTranslation, withTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import styled from 'styled-components';
import MapField from './map_components/MapField';

import IconButton from '@material-ui/core/IconButton';
import PermaDeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from '@material-ui/icons/Edit';
import { open_item_dialog } from './dialogs/AddItemDialog';
import { handle_remove_click } from './BoxPage/BoxItemCard';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { connect } from "react-redux";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { private_page_routes, navbar_paths } from '../App';

import {withRouter} from 'react-router-dom';



function LongMenu({item, t, alert}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  return (
    <div style = {{ display : "flex",  justifyContent : "flex-end", }}>
      <IconButton aria-label="Edit item" aria-controls="edit-item" onClick={() => open_item_dialog({ action_type : 'EDIT', item : item, })}>
        <EditIcon style = {{ fontSize : 17 }} />
      </IconButton>
      <IconButton aria-label="more" aria-controls="long-menu" onClick={ () => handle_remove_click(item, t, alert)}>
        <PermaDeleteIcon style = {{ fontSize : 17 }} />
      </IconButton>
    </div>
  );
}


const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: { ...style, top: style.top + LISTBOX_PADDING, },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  // const itemSize = smUp ? 36 : 48;
  const itemSize = smUp ? 56 : 58;


  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) { return 48; }
    return itemSize;
  };

  const getHeight = () => {
    return itemCount > 8 ? 8 * itemSize : itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};


function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}


const useStyles = theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];

class AppBarSearch extends React.PureComponent{

  constructor(props){
    super(props);
  }

  render(){

    const { classes, t, boxes } = this.props;

    const all_items = ( boxes ? [].concat.apply([], boxes.map( box => { return box.items })) : [] ).map( item => ({
      ...item,
      firstLetter: /[0-9]/.test(item.title[0]) ? '0-9' : item.title[0],
    })).sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter));

    const display = navbar_paths.indexOf(this.props.location.pathname) > -1;

    if(!display){ return null }

    return (
      <Autocomplete
        id="virtualize-demo"
        // style={{ width: 300 }}
        disableListWrap
        classes={classes}
        ListboxComponent={ListboxComponent}
        renderGroup={renderGroup}
        options={all_items}
        // options = { OPTIONS }
        groupBy={option => option.firstLetter}
        renderInput={params => {
          return(
          <MapField params = { params } />
          )
      }}
      renderOption={ (option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);
      
        return (
          // <Badge badgeContent={4} color="primary">
          <Grid container alignItems="center" spacing = { 1 } onClick = { () => this.props.history.push(`/app/box-page?box=${option.box_number}`) }>
            <Grid item xs = { 8 }>
              <Typography noWrap>
                {/* { option.title } */}
                {parts.map((part, index) => (
                  <span key={index} style={{ color : "black", fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </Typography>
              <Typography variant="body2" color="textSecondary" noWrap>
                {option.category}
              </Typography>
            </Grid>
            
            <Grid xs = { 4 }>
              <LongMenu t = {t} alert = {alert} item = {option} style = {{ fontSize : 24, color : "gray" }}/>
            </Grid>
          
          </Grid>
          // </Badge>
        );
      }}
      />
    );
  }
}

const mapStateToProps = (state , ownProps) => {
  return {
    boxes  : state.products.boxes,
  }
}

export default withStyles(useStyles)(
    connect(mapStateToProps)(
      withTranslation()(
        withRouter(
          AppBarSearch
        )
      )
    )
  )








// import React from "react";
// import { fade } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/styles';
// import SearchIcon from '@material-ui/icons/Search';
// import { InputBase } from '@material-ui/core';
// import { withRouter } from 'react-router-dom';
// import { withTranslation } from "react-i18next";

// const styles = (theme) => ({
//     search: {
//       position: 'relative',
//       borderRadius: theme.shape.borderRadius,
//       backgroundColor: fade(theme.palette.common.white, 0.15),
//       '&:hover': {
//         backgroundColor: fade(theme.palette.common.white, 0.25),
//       },
//       marginLeft: 0,
//       width: "50%",
//       marginRight : 20,
//       // backgroundColor : "yellow",

//       [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(1),
//         width: 'auto',
//       },

//   },
//   searchIcon: {
//     width: theme.spacing(7),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   inputRoot: {
//     color: 'inherit',
//     height : "100%",
//   },
//   inputInput: {
//     padding: theme.spacing(1.5, 1, 1.5, 7),
//     transition: theme.transitions.create('width'),
//     width: '100%',
    
//     [theme.breakpoints.up('sm')]: {
//       padding: theme.spacing(2, 1, 2, 7),
//       width: 150,
//       '&:focus': {
//         width: 300,
//       },
//     },

//   }
// });

// class AppBarSearch extends React.Component{
//     constructor(props){
//         super(props);
//         this.state = {
//           val : ''
//         }
//         this.text_ref = React.createRef();
//     }

//     handle_key_down = (e) => {
//       if(e.key === 'Enter'){
//         this.props.history.push(`/app/search-results?query=${this.state.val}`);
//         e.target.select();
//       }
//     }

//     render(){
//         const {classes, t} = this.props;
//         return(
//             this.props.do_display ? 


//             : null
//         );
//     }
// }
// export default (
//   withStyles(styles)
//   (
//     withTranslation()
//     (
//       withRouter(AppBarSearch)
//     )
//   )
// );















/* eslint-disable no-use-before-define */
// import React from 'react';
// import useAutocomplete from '@material-ui/lab/useAutocomplete';
// import CheckIcon from '@material-ui/icons/Check';
// import CloseIcon from '@material-ui/icons/Close';
// import styled from 'styled-components';

// import { connect } from "react-redux";
// import { FaBoxOpen, FaSearch } from 'react-icons/fa';




// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import PermaDeleteIcon from "@material-ui/icons/DeleteForever";
// import EditIcon from '@material-ui/icons/Edit';
// import { open_item_dialog } from './dialogs/AddItemDialog';
// import { handle_remove_click } from './BoxPage/BoxItemCard';

// import { useTranslation } from 'react-i18next';
// import { useAlert } from 'react-alert'




// import { makeStyles } from '@material-ui/core/styles';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import { FixedSizeList } from 'react-window';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     height: 400,
//     maxWidth: 300,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

// function renderRow(props) {
//   const { index, style } = props;

//   return (
//     <ListItem button style={style} key={index}>
//       <ListItemText primary={`Item ${index + 1}`} />
//     </ListItem>
//   );
// }


// export default function VirtualizedList() {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <FixedSizeList height={400} width={300} itemSize={46} itemCount={200}>
//         {renderRow}
//       </FixedSizeList>
//     </div>
//   );
// }






// function LongMenu({item, t, alert}) {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleClick = event => {
//     // event.preventDefault();    
//     console.log(event.currentTarget);
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = (e) => {
//     setAnchorEl(null);
//   };

//   return (
//     <div>
//       <IconButton
//         aria-label="Edit item"
//         aria-controls="edit-item"
//         onClick={() => open_item_dialog({ action_type : 'EDIT', item : item, })}
//       >
//         <EditIcon style = {{ fontSize : 17 }} />
//       </IconButton>
//       <IconButton
//         aria-label="more"
//         aria-controls="long-menu"
//         onClick={ () => handle_remove_click(item, t, alert)}
//       >
//         <PermaDeleteIcon style = {{ fontSize : 17 }} />
//       </IconButton>
//     </div>
//   );
// }


// // width: 300px;
// const InputWrapper = styled('div')`
//   width : 100%;
//   border: 1px solid #d9d9d9;
//   background-color: #fff;
//   border-radius: 4px;
//   padding: 7.5px;
//   display: flex;
//   flex-wrap: wrap;


//   &:hover {
//     border-color: #40a9ff;
//   }

//   &.focused {
//     border-color: #40a9ff;
//     box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
//   }

//   & input {
//     font-size: 14px;
//     height: 30px;
//     box-sizing: border-box;
//     padding: 4px 6px;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `;
// // width: 200px;
// const Listbox = styled('ul')`
//   width : 50vw;
//   max-height : 400px;
//   @media (min-width: 768px) {
//     width : 30vw;
//     max-height : 250px;
//   }

//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
//   list-style: none;
//   background-color: #fff;
//   color : black;
//   overflow: auto;
//   /* max-height: 250px; */
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 10px 12px;
//     display: flex;
//     align-items : center;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: #fafafa;
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li[data-focus='true'] {
//     background-color: #e6f7ff;
//     cursor: pointer;

//     & svg {
//       color: #000;
//     }
//   }
// `;

// const Container = styled('div')`
//   width : 50vw;

//   @media (min-width: 768px) {
//     width : 30vw;
//   }
// `;



// function AppBarSearch({ boxes, do_display }) {

//   const all_items = ( boxes ? [].concat.apply([], boxes.map( box => { return box.items })) : [] ).map( item => ({
//     ...item,
//     firstLetter: /[0-9]/.test(item.title[0]) ? '0-9' : item.title[0],
//   }))

//   // const [val, setValue] = React.useState("");
//   const handle_click = (e) => {
//     e.preventDefault();
//   } 

//   const {
//     getRootProps,
//     getInputLabelProps,
//     getInputProps,
//     getTagProps,
//     getListboxProps,
//     getOptionProps,
//     groupedOptions,
//     value,
//     focused,
//     setAnchorEl,
//   } = useAutocomplete({
//     id: 'customized-hook-demo',
//     defaultValue: [],
//     disableCloseOnSelect : true,
//     blurOnSelect : false,
//     options : boxes ? all_items : [],
//     onChange : () => {},
//     getOptionLabel: option => option.title,
//     groupBy : (option) => option.firstLetter
//   });


//   const { t } = useTranslation();
//   const alert = useAlert();

//   // let [ focused, setFocused ] = React.useState(false);

//   const inputProps = getInputProps();
//     let input_val = inputProps.value;

//   let grouped = [];
//   if(input_val.length < 3 ){
//     grouped = [{
//       key : t('G:information'),
//       options : [ { is_demo : true, title : t('SEARCH_RESULTS:enter_3_chars') }]
//     }]
//   }else{
//     grouped = groupedOptions.length > 0 ? groupedOptions : {
//       key : t('SEARCH_RESULTS:no_search_results'),
//       options : []
//     }
//   }

//   if( !do_display ){
//     return null;
//   }

//   return (
//     <Container>
//       <div {...getRootProps()}>
//         <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
//           <input 
//             { ...inputProps }
//             placeholder = { t('SEARCH_RESULTS:search_your_storage') }
//             // onFocus = { () => setFocused(true) }
//             // onBlur  = { () => setFocused(false) }
//           />
//           <FaSearch style = {{ color : "gray", alignSelf : "center", marginRight : 10  }} />
//         </InputWrapper>
//       </div>
//       {grouped.length > 0 && focused ? (
//         <Listbox {...getListboxProps()}>
//           {grouped.sort((x, y) => x.key >= y.key).map( (group, index) => (
//             <>
//             <h4 style = {{ paddingLeft : 12 }}>{group.key}</h4>
//             {
//               group.options.map( (item, i) => (
//                 <li {...getOptionProps({ item, index : group.index + i })} onClick = { handle_click } >
//                   <span>{item.title}</span>
//                   {
//                     item.is_demo ? null :
//                     <LongMenu t = {t} alert = {alert} item = {item} style = {{ fontSize : 24, color : "gray" }}/>
//                   }
//                 </li>
//               ))
//             }
//             </>
//           ))}
//         </Listbox>
//       ) : null}
//     </Container>
//   );
// }

// const mapStateToProps = (state , ownProps) => {
//   return {
//     boxes  : state.products.boxes,
//   }
// }

// export default connect(mapStateToProps)(AppBarSearch)

