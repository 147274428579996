import React from "react";
import { useTranslation } from "react-i18next";
import { ListItem, List, ListItemAvatar, Avatar, ListItemIcon, IconButton, Tooltip, ListItemText } from "@material-ui/core";


import InfoIcon from "@material-ui/icons/Info"
import MessageIcon from "@material-ui/icons/Message"
import EditIcon from '@material-ui/icons/Edit';
import PlusIcon from "@material-ui/icons/AddCircle";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"

import { store } from "../../store";
import { set_item_dialog, set_dialog_status, set_ays } from "../../actions/dialog_actions";
import { format_date } from "../../functions/date_functions";
import theme from "../../assets/styles/theme";
import { open_item_dialog } from "../dialogs/AddItemDialog";

import { useAlert } from 'react-alert'


export function handle_folder_item_click(item, history, t){
    if(item.is_for_sale){
      history.push(`/shop/item/?item=${item.item_id}`);
    }else{
      store.dispatch( set_ays({
        title : item.title,
        text : t('ITEM_CARD:item_information_dialog', {date :format_date(item.submit_date, t), category : item.category }),
        accept_text : t('ITEM_CARD:put_up_for_sale_button_text'),
        on_click : () => {
          open_item_dialog({ action_type : 'EDIT', item : item, })
          store.dispatch( set_dialog_status('ays', false) );
          setTimeout( function(){
            store.dispatch( set_dialog_status('item_dialog', true));
          }, 200);
        } 
      }))
      store.dispatch( set_dialog_status('ays', true));
    }
}

function get_left_icon(type, data, is_empty){
  switch(type){
    case "items":
      return is_empty ? 'Q' : data.title[0]
    
    case "offers":
      return <MessageIcon/>
  }
}

function get_right_icon(type, data, is_empty){
  switch(type){
    case "items":
      return (is_empty) ? <PlusIcon/>  : 
      (data.is_for_sale) ? <ShoppingCartIcon/> : <InfoIcon/>

    case "offers":
      return (is_empty) ? <InfoIcon/>  : <MessageIcon/>;

    default:
      return <EditIcon/>
  }
}

function get_tooltip(type, data, is_empty, t){
  switch(type){
    case "items":
      return (is_empty) ? t('ADD_ITEM_DIALOG:add_item_title')  : 
      (data.is_for_sale) ? t('ITEM_CARD:to_article') : t('G:read_more')

    case "offers":
      return (is_empty) ? t('G:read_more') : t('FOLDER_LIST:open_chat');

    default:
      t('G:read_more')
  }
}

function get_action_function(type){
  
}


export function FolderList(props) {

    const { t } = useTranslation();
    const { box, history, data } = props;
    const boxes = store.getState().products.boxes;
    const alert = useAlert();

    // let is_empty = ( !data || data.length === 0);
    let is_empty_data = data.length === 0;

    let empty_texts = {
      items : {
        title : t('FOLDER_LIST:no_items_registered'),
        secondary : t('FOLDER_LIST:register_first_item'),
        tooltip : t('ADD_ITEM_DIALOG:add_item_title')
      },
      offers : {
        title : t('FOLDER_LIST:no_new_offers'),
        secondary : t('FOLDER_LIST:no_new_offers_secondary'),
        tooltip : t('G:read_more')
      }
    }

    let data_set = props.data.length > 0 ? props.data : [{
      title : empty_texts[props.type].title,
      secondary  : empty_texts[props.type].secondary 
    }];


    
    return (
      <List style = {{height : 357, maxHeight : props.maxHeight ? props.maxHeight : "100%", overflow : "auto"}}>
        <h3 style = {{margin : 20}}> { props.title } </h3>
        {
          data_set.map( data => {
            return (
              <ListItem >
                <ListItemAvatar>
                  <Avatar style = {{ backgroundColor : theme.PRIMARY_COLOR, fontWeight : "bold" }}>
                    { get_left_icon(props.type, data, is_empty_data) }
                  </Avatar>
                </ListItemAvatar>
  
                <ListItemText 
                  primary = { data.title }
                  secondary = { data.submit_date ? format_date( data.submit_date, t ) : data.secondary }
                />
                
                <ListItemIcon>

                    <IconButton onClick = { () => {

                      if(is_empty_data){
                        if(props.type === 'items'){
                            if(!box){
                              history.push('/app/add-box');
                            }else{
                              open_item_dialog({
                                action_type : "ADD",
                                item : undefined,
                                box_id : box.box_id
                              })
                            }
                        }else if(props.type === "offers"){
                          alert.info(t('FOLDER_LIST:offers_info_alert'))
                        }
                      }else{
                        if(props.type === 'items'){
                          handle_folder_item_click(data, props.history, t)
                        }else{
                          history.push(`/app/chat?conversation=${data.conversation_id}`);
                        }
                      }
                    }}>
                  
                    <Tooltip title = { 
                      is_empty_data ? 
                      empty_texts[props.type].tooltip
                      :
                      get_tooltip(props.type, data, is_empty_data, t)
                    }>
                    { get_right_icon(props.type, data, is_empty_data) }
                    </Tooltip>
                  
                  </IconButton>
                </ListItemIcon>
  
              </ListItem>
            )
          }) 
        }
      </List>
    );
  }