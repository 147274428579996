import React from 'react';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Chart from '../components/chart';
import DashboardNData from '../components/DashboardNData';

import { withStyles } from '@material-ui/styles';

import AppFrame from "../components/AppFrame";
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Title from '../components/title';
import AddItemDialog from '../components/dialogs/AddItemDialog';
import ApproveModal from '../components/dialogs/ApproveModal';
import { remove_db_and_client_box } from '../logic/box_logic';
import PageLoadingLottie from '../components/PageLoadingLottie';
import { withTranslation } from 'react-i18next';
import BoxCard from '../components/BoxCard';
import ImageDialog from '../components/dialogs/ImageDialog';
import { Hidden } from '@material-ui/core';
import CardLayout from '../Layouts/CardLayout';

import NoBoxes from "../components/EmptyReplacements/NoBoxes"
import { deep_asc_sort } from '../functions/sorting_functions';
import BlueWhiteFrame from '../components/Frames/BlueWhiteFrame';

export async function enable_print_opportunity(html, t){
    let printWindow = window.open('', '', 'height=300,width=500');

    if(printWindow === null){
      alert( t('ERRORS:print_blocked') );
      return;
    }

    printWindow.document.write('<html><head><title>DIV Contents</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(html);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    setTimeout(function(){
      printWindow.print();
    }, 500);
}

const styles = theme => ({

});

const ConditionalWrapper = ({condition, children}) => {
  if(condition){ return <BlueWhiteFrame>{children}</BlueWhiteFrame> }
  return <AppFrame>{children}</AppFrame>  
}

class MyBoxes extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      side_bar_open : true,
      items : [],
      item_form_dialog_open : false,
      box_form_dialog_open : false,
      img_dialog_open : false,
      remove_dialog_open : false,
      clicked_box : undefined
    }
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:my_boxes')
  }

  toggle_open = () => {
    this.setState({side_bar_open : !this.state.side_bar_open});
  }

  render(){
    const {classes, t, history, boxes} = this.props; //Inherit styles from higher order component
    
  return (
    <ConditionalWrapper condition = { this.props.boxes && boxes.length === 0 }>
        { (this.props.boxes !== undefined) ? 
        <>
          <Grid container spacing={3} style = {{minHeight : "100%"}}>
            <Grid item xs={12}>
                { boxes.length > 0 ? 
                  <CardLayout
                    text = { t('MY_BOXES:text') }
                    data = { deep_asc_sort(boxes, "box_number") }
                    title = { t('DASHBOARD_SIDE_BAR:my_boxes') }
                    history = { history }
                    card_type = "BOX"
                  />
                  : 
                  <div style = {{flex : 1, height : "100%", width : "100%", display : "flex", alignItems : "center", justifyContent : "center"}}>
                    <NoBoxes history = { history }/>
                  </div>
                  }
            </Grid>     
          </Grid>
        
      </> : <PageLoadingLottie/>
      }
    </ConditionalWrapper>
  );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    boxes : state.products.boxes,
    n_tokens : state.global.n_tokens
  }
}

const mapDispatchToProps = { 

}


export default (
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(
      withTranslation()(MyBoxes)
    )
  )
);