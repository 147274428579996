export const SET_DIALOG_OPEN_STATUS = "SET_DIALOG_OPEN_STATUS";
export const SET_IMG = "SET_IMG";

/* AYS */
// export const SET_DIALOG_OPEN_STATUS = "SET_DIALOG_OPEN_STATUS";
export const SET_AYS = "SET_AYS";
export const SET_ITEM_DIALOG = "SET_ITEM_DIALOG";
export const SET_ITEM_DIALOG_BOX_ID = "SET_ITEM_DIALOG_BOX_ID";

export const SET_DIALOG = "SET_DIALOG";
export const UPDATE_DIALOG = "UPDATE_DIALOG";


export const SET_BOX_DIALOG = "SET_ITEM_DIALOG_BOX_ID";



export const set_dialog_status = (dialog, bool) => ({
    type : SET_DIALOG_OPEN_STATUS,
    payload : {
        dialog : dialog,
        bool : bool
    }
});

export const set_image = (img) => ({
    type : SET_IMG,
    payload : img
});



export const set_ays = (obj) => ({
    type : SET_AYS,
    payload : obj
});

export const set_dialog = (name, obj) => ({
    type : SET_DIALOG,
    payload : {
        name : name,
        obj : obj
    }
});

export const update_dialog = (name, obj) => ({
    type : UPDATE_DIALOG,
    payload : {
        name : name,
        obj : obj
    }
});



export const set_item_dialog = (obj) => ({
    type : SET_ITEM_DIALOG,
    payload : obj
});

export const set_item_dialog_box_id = (id) => ({
    type : SET_ITEM_DIALOG_BOX_ID,
    payload : id
});

export const set_box_dialog = (box) => ({
    type : SET_BOX_DIALOG,
    payload : {box : box}
})