import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { TextField, Tooltip } from '@material-ui/core';
import { FaUser, FaUserCircle } from 'react-icons/fa';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 450,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function MapField({params, history, style}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper component="form" className={classes.root} style = {{ ...style }}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton>

      {/* <InputBase
        className={classes.input}
        placeholder="Search your storage"
        inputProps={{ 'aria-label': 'search google maps', ...params }}
        {...params}
      /> */}
      <TextField
        className={classes.input}
        placeholder="Search your storage"
        color = "white"
        {...params}
      />
      
      {/* <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton> */}
      <Divider className={classes.divider} orientation="vertical" />
      
      <Tooltip title = { t('DASHBOARD_SIDE_BAR:my_profile') }>
        <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick = {  () => history.push('/app/profile') }>
          <FaUserCircle/>
        </IconButton>
      </Tooltip>
    </Paper>
  );
}

export default withRouter( MapField )