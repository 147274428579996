import React from "react";

import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListSubheader } from "@material-ui/core";
import { FolderList } from "./FolderList";
import BoxActionButtons from "./BoxActionButtons";
import { open_item_dialog } from "../dialogs/AddItemDialog";
import { deep_date_sort } from "../../functions/sorting_functions";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from "@material-ui/icons/AddCircle"

const useStyles = makeStyles( theme => ({
    root : {
      // flex : 1,
      height : "100%",
      display : "flex",
      flexDirection : "column",
      justifyContent : "space-around",
      // alignItems : "center"
    },
    button : {
      width : "100%",
      paddingTop : theme.spacing(1.3),
      paddingBottom : theme.spacing(1.3),
      [theme.breakpoints.down('sm')]: {
        width : "100%",
        marginTop : theme.spacing(2),
        paddingTop : theme.spacing(2),
        paddingBottom : theme.spacing(2),
      },
    },
    button_container : {
      display : "flex", 
      padding : "7.5%", 
      flexDirection : "column", 
      // justifyContent : "space-between", 
      height : "100%",
      width : "100%",
      // alignItems : "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection : "column",
      },
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
}));

export default function BoxMiddlePart(props){
    const { history, t, box, items, conversations } = props;

    const classes = useStyles();  
    
    if(!conversations){
      return null;
    }
  
    return(
        <>
        <Grid item xs = {12} md = {4} lg = { 5 }>
        <Paper>
          <FolderList
            title = { t('ADD_BOX_PAGE:recently_added_items') }
            type = "items"
            data = { items.slice(0, 3) }
            history = { history }
            box = { box }
          />
        </Paper>
      </Grid>
  
      <Grid item xs = {12} md = {4} lg = {4}>
        <Paper>
          <FolderList
            title = { t('ADD_BOX_PAGE:offers') }
            type = "offers"
            data = { 
              conversations.filter( convo => {
                let is_in_box = false;
                for(let i = 0; i < box.items.length; i++){
                  if(convo.item_id === box.items[i].item_id){
                    is_in_box = true;
                  }
                }
                return is_in_box;
              })
              .slice(0, 3) 
            }
            history = { history }
            box = { box }
          />
        </Paper>
      </Grid>
  
      <Grid item xs = {12} md = {4} lg = {3}>
        <Paper className = { classes.button_container }>
          <h3 style = {{marginTop : 0}}>{ t('G:actions') }</h3>
          <BoxActionButtons
            t = { t }
            box = { box }
            classes = { classes }
            handle_open_item_dialog = { () => {
              open_item_dialog({
                action_type : "ADD",
                item : undefined,
                box_id : box.box_id
              })
            }}
          />
        </Paper>
      </Grid>
  
    </>
    )

  }
  