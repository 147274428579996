import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';

import DeleteIcon from "@material-ui/icons/Delete";

import ip from "../../constants/ip";
import { prepare_socket_action } from "../../logic/action_socket_logic";
import { withTranslation } from "react-i18next";

import Transition from "./Transition";

const defualt_img = "https://sanitationsolutions.net/wp-content/uploads/2015/05/empty-image.png";


const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    drop_zone : {
        display : "flex",
        cursor : "pointer",
        backgroundColor : "rgb(230,230,230)",
        height : 150,
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "Roboto",
        marginTop : theme.spacing(4)
    },
    formControl : {
        width : "100%",
        marginTop : theme.spacing(2)
    }
});


class RemoveItemDialog extends React.Component{
    constructor(props){
        super(props);
    }

    render(){

        const {classes, t} = this.props;
        return(
            <Dialog
                open={this.props.open_status}
                onClose={ this.props.close_modal }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                
                <DialogContent>

                <DialogContentText>
                    {this.props.text}
                </DialogContentText>
                {
                    this.props.content ? this.props.content() : null
                }
                </DialogContent>
                
                <DialogActions>
                <Button variant="contained" color="default" className={classes.button} onClick = {this.props.close_modal}>
                    {this.props.cancel_text ? this.props.cancel_text : t('G:back') }
                </Button>
                <Button onClick = {this.props.on_click} variant="contained" color="default" className={classes.button}>
                    {this.props.accept_text}
                    {
                        (this.props.no_icon)
                        ?
                            null : 
                            this.props.icon ? 
                                this.props.icon() : 
                                <DeleteIcon className={classes.rightIcon} />
                    }
                
                </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default (
    withStyles(styles)
    (
        withTranslation()
        (
            RemoveItemDialog
        )
    )
)