import React from "react";
import { withStyles, Paper, Typography, Button } from "@material-ui/core";

import DeviceIcon from "@material-ui/icons/ImportantDevices"
import custom_theme from "../assets/styles/theme";
import { f_a_c } from "../functions/money_functions";
import tier_prices from "../constants/tier_prices";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { FaBoxOpen, FaBox, FaCreditCard } from "react-icons/fa";

const styles = (theme) => ({
    container : {
        padding : 20,
        borderRadius : 5,
        transition : "0.2s ease-in-out",
        '&:hover': {
            cursor : "pointer",
            // transform : "scale(1.05)"
        },
    },
    top_part : {
        textAlign : "center",
        padding : 15
    },
    icon : {
        fontSize : "4em",
        color : "gray",
        // color: custom_theme.PRIMARY_COLOR

    },
    price : {
        fontWeight : "bold",
        fontSize : "1.35em",
        color : custom_theme.PRIMARY_COLOR
    },
    bottom_container : {
        display : "flex",
        justifyContent : "center",
        marginTop : 20,
        marginBottom : 10
    }
});

class PricingComponent extends React.PureComponent{
    constructor(props){
        super(props);
    }

    componentWillReceiveProps(nextprops){
        if(this.props.current_subscription !== nextprops.current_subscription){
            //Do something
        }
    }

    render(){
        const {classes, tier, t, current_subscription, iso_code, history} = this.props;

        let m_y = this.props.is_year ? "year" : "month";
        const price = tier_prices[iso_code][m_y][tier.id];
        const interval_text = (this.props.is_year) ? t('G:year_short') : t('G:month_short')

        let button_text = (current_subscription === undefined) ? t("G:purchase") : t("G:upgrade");

        let on_click = this.props.on_click;
        if(localStorage.getItem('jwt') === null){
            button_text = t('G:sign_up');
            on_click = () => { ( history.push('/sign-up')) }
        }
        

        return(
            <Paper className = { classes.container }>
                <div className = { classes.top_part }>
                    {/* <DeviceIcon className = { classes.icon }/> */}
                    <FaBoxOpen className = { classes.icon } style = {{ marginRight : 5 }} />
                    <h1 style = {{ marginBottom : 5 }}>{tier.title}</h1>
                    <Typography className = {classes.price}>{f_a_c(price, this.props.iso_code)}/{ interval_text }</Typography>
                </div>
                
                <div style = {{ width : "85%", marginLeft : "7.5%" }}>
                    <hr/>
                    <ul>
                    {
                        tier.description.map( text => {
                            return <li><Typography variant = "body">{text}</Typography></li>
                        })
                    }
                    </ul>
                    <hr />
                </div>

                <div className = { classes.bottom_container }>
                    <Button className = { classes.button } color = "primary" variant = "contained" onClick = { on_click }
                    endIcon = { <FaCreditCard style = {{ margin : 5, padding : 1 }} /> }
                    >{button_text}</Button>
                </div>
            </Paper>
        )
    }
}

export default (
    // withRouter()(
    withStyles(styles)(
        withTranslation()(
                PricingComponent
            )
        )
    // )
);