//Varför i hela fridens namn finns det en statisk iso_code?
const default_subscription = {
    buttonText: 'Köp', //Köp
    currency_orientation : "RIGHT",
    currency : "$",
    iso_code : "usd",
}

const default_description = [
    'Upp till 100 föremål/låda',
    'Unika stickers',
    'Sökfunktionalitet',
    'Email-support',
];

export default {
    L_P : {
        header_title: "Organisera ditt förråd med Qboxr",
        header_text : "Dagarna av att blint leta runt i förrådet är slut med Qboxr's smarta organiseringslösning.",
        
    
        what_we_offer_title : "Organisera ditt förråd",
        what_we_offer_text  : "Du organiserar enkelt ditt förråd med Qboxr's simpla organiseringslösning. Direkt via appen eller här på nätet. Med unika QR-koder genererade för dina föremål har du koll på hela ditt förråd direkt i telefonen.",
        
        make_money_title : "Och tjäna pengar på att göra det!",
        make_money_text  : "Qboxrs marknadsplats tillåter dig att annonsera föremålen i ditt förråd med ett knapptryck. Chatta med intresserade köpare och få erbjudanden direkt i appen i våran chat.",

        how_it_works_title : "Hur det fungerar",
        how_it_works_text  : "Med hjälp av QR-koder länkar Qboxr ihop den fysiska världen med molnet. Organisering, inventering, försäljning och cloudstorage. Allt på en och samma plats.",
        
        
        how_it_works_step_one_title : "Registrera lådor och få unika QR-koder",
        how_it_works_step_two_title  : "Registrera dina föremål och få full koll",
        how_it_works_step_three_title : "Sälj dina föremål med ett klick",

        our_prices_title : "Prenumerationer",
        our_prices_text : "Qboxr offers a wide variety of subscription models.",

        support_title : "Dedicated support",
        support_text  : "We have a dedicated support that is managed through E-mail, alot of answers can also be found in our FAQ"
    },

    A_U : { //ABOUT US
        about_us_title : "Om oss",
        about_us_text  : "Vi tror på ett nytt sätt att sköta privatorganisering i hemmet! Vårat mål är att du ska få full koll på dina egna ägodelar i hemmet och aldrig någonsin behöva riva upp halva förrådet i letan av det du söker.",
        the_problem_title : "Problemet vi löser",
        the_problem_text  : "Enligt studier så spenderar i snitt en människa 2,5 dagar om året med att leta efter saker i hemmet. Med Qboxr's tjänster hoppas vi totalt eliminera denna tid för dig och erbjuda full koll och organisering på ditt hem och förråd.",
        see_how_it_works_title : "Se hur det fungerar",
        see_how_it_works_text  : "Att komma igång med Qboxr är mycket enkelt och att scanna in sitt förråd går smidigt att göra till en familjeaktivitet då flera enheter kan vara uppkopplade på samma konto samtidigt."
    },

    C_P : {
        header_title : "Kontakt",
        header_text  : "Att komma igång med Qboxr är enkelt och många svar på dina frågor går att finna i våran FAQ. Har du andra frågor kan du kontakta oss här.",
        how_to_get_in_touch_title : "Hur du når oss",
        how_to_get_in_touch_text : "Svaret på många frågor går att finna i våran FAQ. Om du inte hittar svaret på din fråga där kan du enkelt komma i kontakt med oss via Email."
    },

    P_P : { //Product Page
        product_title : "Produkten",
        product_text : "Qboxr är en organisationsapplikation tillgänglig på alla dina enheter. Leta aldrig runt i förrådet igen med Qboxr till hands.",
        cross_platform_title : "Hämta appen",
        cross_platform_text : "Qboxr finns tillgängligt på iOS, Android och direkt i din browser. Appen kan användas mellan enheterna samtidigt."
    },

    P_G : { //PUBLIC GLOBAL
        get_app : "Ladda hem appen",
        start_shopping : "Shoppa",
        register : "Registrera dig",
        sign_in  : "Logga in",
        Box       : "Låda",
        Boxes     : "Lådor",
        Items     : "Föråmål",
    },

    G : {
        read_more : "Läs mer",
        sign_in   : "Logga in",
        sign_out  : "Logga ut",
        sign_up   : "Registrera dig",
        contact   : "Kontakt",
        next      : "Nästa",
        accept    : "Acceptera",
        back      : "Tillbaka",
        generate  : "Generera",
        month_short : "Må",
        year_short : "År",
        purchase : "Köp",
        upgrade  : "Uppgradera",
        search   : "Sök",
        dashboard : "Kontrollpanel",
        see_my_boxes : "Till mina lådor",
        remove : "Ta bort",
        open_box : "Öppna",
        box : "Låda",
        open : "Öppna",
        ok : "Ok",
        cancel : "Avbryt",
        update : "Uppdatera",
        error : "Något gick fel",
        information : "Information",

        settings : "Inställningar",

        save : "Spara",

        filter : "Filtrera",
        results : "Resultat",

        date : "Datum",

        location_updated : "Din position uppdaterades",

        of : "av",
        matching : "matchande",
        articles : "artiklar",

        actions : "Hantera",
        content : "Innehåll",

        search_your_storage : "Sök i ditt förråd...",

        actions : "Handlingar",


        terms_of_agreement : "Användarvilkor",
        
        confirm : "Acceptera",

        slogan : "Organisera ditt förråd och tjäna pengar på att göra det"
    },

    TITLES : {
        home : "Qboxr",
        add_box_page : "Qboxr - Lägg till låda",
        about_us : "Qboxr - Om oss",
        chat : "Qboxr - Chatt",
        contact : "Qboxr - Contact",
        dashboard : "Qboxr - Mitt hem",
        forgot_password : "Qboxr - Glömt lösenord",
        my_boxes : "Qboxr - Mina lådor",
        pricing : "Qboxr - Prenumerationer",
        profile_page : "Qboxr - Min profil",
        reset_password : "Qboxr - Återställ lösenord",
        search_results : "Qboxr - Sökresultat",
        sign_in : "Qboxr - Logga in",
        sign_up : "Qboxr - Registrera dig",
        shop_landing : "Qboxr - Köp/Sälj",
        my_shop : "Min shop"
    },

    DATE : {
        today : "Idag",
        yesterday : "Igår",
        this_week : "Denna vecka",
        months : ["Januari", "Februari", "Mars",
        "April", "Maj", "Juni", "Juli",
        "Augusti", "September", "Oktober",
        "November", "December"]
    },

    PLURALS : { // Done
        boxInterval : " (0){Inga lådor};(1){1 låda};(2-inf){ {{count}} lådor};",
        itemInterval : "(0){Inga föremål registrerade};(1){1 föremål};(2-inf){ {{count}} föremål};",  
        leftToRegister : "(0){Inga lådor kvar att generera};(1){1 låda kvar att generera};(2-inf){ {{count}} låda kvar att generera};", 
        
        n_clicks : "(0){Inga klick};(1){1 klick};(2-inf){ {{count}} klick};",
        n_new_convos : "(0){Inga nya};(1){1 nytt erbjudande};(2-inf){ {{count}} nya erbjudanden};",
    },

    SIGN_IN : {
        email_placeholder : "E-post",
        password_placeholder : "Lösenord",
        sign_in : "Logga in",
        remember_me : "Kom ihåg mig",
        dont_have_account_link : "Inget konto? Registrera dig här!",
        forgot_password : "Glömt ditt lösenord?"
    },

    SIGN_IN_ERRORS : {
        WRONG_EMAIL_OR_PASSWORD : "Fel E-post eller lösenord",
        FORM_INCOMPLETE : "Alla fält med ( * ) måste fyllas i"
    },

    SIGN_UP : {
        register_title : "Registrera dig",
        password_re_placeholder : "Bekräfta lösenord",
        term_accept_text : "Jag har läst och förstår Qboxr's",
        privacy_policy : "Privacy policy",
        allow_emails : "Jag vill bli noterad med nyheter och erbjudanden via Email",
        already_has_account : "Har du redan ett konto? Logga in",
        STRONG : "Starkt",
        GOOD : "Bra",
        WEAK   : "Svagt",
        TOO_SHORT : "För kort"
    },

    FORGOT_PASSWORD : {
        forgot_title : "Skicka nytt lösenord",
        button_title : "Skicka nytt",
        email_has_been_sent : "Ett mail har skickats till den givna E-postaddressen med en länk för att återställa ditt lösenord."
    },

    RESET_PASSWORD : {
        title : "Återställ lösenord",
        old_password_pladeholder : "Nuvarande lösenord *",
        new_password_placeholder : "Nytt lösenord *",
        new_password_re_placeholder : "Bekräfta nya lösenordet *",
        button_title : "Återställ lösenordet",
        successfully_updated : "Ditt lösenord har ändrats. Du kan numera logga in med det nya."
    },
    RESET_PASSWORD_ERRORS : {
        INVALID_HASH : "Länken är ej giltig. Den är antingen för gammal eller inkorrekt. Begär en ny länk eller försök igen",

        NO_SUCH_EMAIL : "Det finns inget konto kopplat till den angivna E-postaddressen.",
        SOMETHING_WENT_WRONG_ON_INSERT : "Oj, någonting gick fel när lösenordets skulle ändras. Försök igen."
    },
    SIGN_UP_ERRORS : {
        INVALID_EMAIL_FORMAT : "Felaktig E-postaddress",
        FILL_ALL_FILEDS : "Alla fält med ( * ) måste fyllas i",
        PASSWORDS_NOT_THE_SAME : "Lösenorden stämmer inte överens",
        TERMS_NOT_ACCEPTED : "Du måste acceptera våran Privacy policy för att kunna registrera kontot.",
        EMAIL_ALREADY_REGISTERED : "Det finns redan ett konto associerat med den angivna E-postaddressen",
        EMAIL_ALREADY_REGISTERED_SHORT : "E-posten är ej tillgänglig",
        WELCOME_TO_QBOXR : "Välkommen till Qboxr, ditt konto har skapats"
    },

    DASHBOARD_GLOBALS : {
        my_boxes : "Mina lådor",
        recent_boxes : "Senaste lådorna",
        recent_items : "Senaste föremålen"
    },

    DASHBOARD_SIDE_BAR : {
        control_panel : "Kontrollpanelen",
        my_boxes      : "Mina lådor",
        add_box       : "Lägg till låda",
        start_page    : "Hem",
        shop          : "Köp/Sälj",
        messages      : "Meddelanden",
        /*---------------------------*/
        bottom_nav_title : "Konto",
        my_profile    : "Min profil",
        subscriptions : "Prenumerationer",
        get_app       : "Hämta appen",
        sign_out      : "Logga ut"
    },

    //Website not used in Native application
    DASHBOARD_CHART : { // NEW
        overview : "Överblick",
        items : "Föremål",
        no_boxes : "Du har inte registrerat några föremål än, så nu startar vi.",
        example_text : "Det här är ett exempel på hur ditt föremål visas med föremål registrerade.",
        hide : "Dölj"
    },  

    MY_BOXES : {
        text : "Alla dina registrerade lådor.",
        no_boxes_text : "Du har {{n_left}} box-tokens kvar.",
        no_boxes_welcome_title : "Välkommen till Qboxr!",
        no_boxes_welcome_text  : "Att registrera lådor är en mycket enkel process och görs via undersidan \"Lägg till låda\" ",
        no_boxes_welcome_support_text : "Ifall du behöver mer hjälp kan våran FAQ bistå med flertalet hjälpfulla tips."        
    },

    MY_SHOP : {
        text : "Alla dina föremål som för tillfället är till salu",
        // why_sell : "Qboxr tillåter dig att enkelt annonsera föremål direkt ifrån ditt förråd för försäljning",
        why_sell : "Du kan enkelt annonsera föremål i ditt förråd som du inte använder längre via våran marknadsplats, kostnadsfritt!",
        get_started : "Då sätter vi igång!",
        no_items_text : "Du har för tillfället inga föremål till salu",
        no_items_text_help  : "Allt du behöver göra för att registrera en vara på marknadsplatsen är",
        no_items_welcome_support_text : "Ifall du behöver mer hjälp att komma igång finns våran FAQ alltid öppen med svar.",
        
        my_articles : "My annonser",

        visits : "Besök",
        visits_data_graph_tool : "Graf över denna veckas besök och annat gällande dina föremål registrerade på marknadsplatsen ",
        
        todays_data : "Dagens information",

        items_for_sale : "Föremål till salu",
        for_sale : "Till salu",
        not_for_sale : "Inte till salu",

        revenue : "Omsättning",
        revenue_text : "Omsättning genererad sedan du gick med i Qboxr",
        revenue_data_graph_tool : "Graf över din omsättning sedan du gick med i Qboxr",

        n_clicks_tool : "Antalet besök dina föremål har fått under dagen",
        n_new_convos_tool : "Antalet intresserade köpare som hört av sig",

        register_location : "Registrera en plats för lådan som föremålet ligger i",
        checkbox_text    : 'Kryssa i rutan "Till salu" när du lägger till / ändrar på föremålet'
    },

    BOX_PAGE : {
        no_items_in_box : "Inga föremål registrerade i denna låda",
        date_added : "Registrerades",
        name : "Namn",
        category : "Kategori",
        sales_status : "Säljstatus"
    },

    BOX_ACTIONS : { // NEW
        add_item : "Lägg till föremål",
        print_label : "Skriv ut QR-ettikett",
        remove_box : "Ta bort låda",
        redirect_to_box : "Till lådan",
        register_box : "Registrera låda",
        open_box : "Öppna lådan"
    },

    ADD_BOX_SCREEN : { // NEW
        box_information_step : "Boxinformation",
        choose_label_step    : "Skapa ettikett",
        done_step            : "Klar!",
        add_your_first_box : "Lägg till din första låda",
        upgrade_for_more_tokens : "Uppgradera ditt abonnemang för fler tokens",
        learn_more : "Läs mer om hur det fungerar"
    },

    A_B_S_1 : {
        box_name_title : "Boxens namn *",
        box_name_placeholder : "Kort titel för att sammanfatta lådans innehåll.",
        box_owner_title : "Vems låda är det?",
        box_owner_placeholder : "t.ex. Min, Mammas, Brorsans",
        box_location_title : "Boxens plats",
        box_location_placeholder : "t.ex.. Vinden, Garaget, Källaren..",
        box_address_placeholder  : "Addressen möjliggör för dig att veta vart alla föremålen finns",
        box_address_title : "Address",
        box_description_title : "Kort beskrivning om lådans innehåll",
        box_description_placeholder : "t.e.x Julsaker, Kökssaker, Barnsaker",
        files_drop_text : "Släpp filerna här eller tryck för att välja fil",
        add_box : "Registrera lådan",
        edit_box : "Redigera lådan"
    },

    A_B_S_3 : {
        box_registered : "Lådan skapades",
        box_number : "Boxnummer",
        box_registered_alert_text : 'Låda #{{box_number}} skapades',
        print_label : "Skriv ut",
        redirect_to_box : "Gå till lådan"
    },

    ERRORS : {
        /* ADD_BOX_FORM */
        ADD_BOX_FORM_INVALID : "Du måste ange ett namn och en plats för att registrera lådan",

        /* Subscriptions */
        NOT_ENOUGH_TOKENS : "Inte tillräckligt med tokens kvar, uppgradera ditt konto eller ta bort någon av de registrerade lådorna för att registrera en ny.",
        SUBSCRIPTION_NOT_LOADED : "Din prenumeration har inte laddats än vänligen vänta",
        NO_SUBSCRIPTION : "Du har ingen prenumeration att ändra på",
        SAME_SUBSCRIPTION : "Du har redan en prenumeration på denna betalningsplan",
        SAME_SUBSCRIPTION_SHORT : "Du prenumerar redan på denna betalningsplan.",
        INVALID_PLAN   : "Du försökte ansluta dig till en betalningsplan som inte längre finns hos oss. Försök igen eller kontakta oss.",
        PAYMENT_FAILED : "Betalningen gick inte igenom. Kontrollera ditt saldo och försök igen. Om det inte skulle fungera, kontakta oss.",
        // DOWNGRADE : "You can't downgrade",
        DIFF_CURRENCY  : "Du kan inte byta betalnigsvaluta på en pågående prenumeration. Byt till {{iso_code}}",
        TOO_MANY_BOXES : `
        (0){You have too many boxes registered at the moment to downgrade to the wanted plan. You have {{n_registered}} boxes right now. Remove {{n_to_remove}} to downgrade to the wanted plan.}
        (1){You have too many boxes registered at the moment to downgrade to the wanted plan. You have {{n_registered}} box right now. Remove {{n_to_remove}} to downgrade to the wanted plan.}
        (2-inf){You have too many boxes registered at the moment to downgrade to the wanted plan. You have {{n_registered}} boxes right now. Remove {{n_to_remove}} to downgrade to the wanted plan.}
        `,
        TOO_MANY_BOXES : `
        (0-1){Du har för tillfället för många lådor registrerade för att nedgradera till den önskade betalningsplanen. Ta bort {{n_to_remove}} låda för att nedgradera till denna planen.};
        (2-inf){Du har för tillfället för många lådor registrerade för att nedgradera till den önskade betalningsplanen. Ta bort {{n_to_remove}} lådor för att nedgradera till denna planen..};
        `,
        
        NOT_A_CUSTOMER : "Du har inte något betalningsmedel registrerat hos Qboxr.",
    
        /* Print */
        print_blocked : "Fönstret blockerades. Tillåt popupfönster i din webbläsare och försök igen.",
        
        /* BoxPage */
        INVALID_BOX_NUMBER : "Du har inte någon låda med det boxnummret",
        
        ITEM_STILL_LOADING : "Föremålet laddar fortfarande, vänligen vänta.",

        /* Account related */
        EMAIL_ALREADY_REGISTERED : "Det finns redan ett konto kopplat till den angivna E-postaddressen",
        
        /* Fail related */
        FAILED_UPDATE : "Någonting gick fel när informationen skulle uppdateras",
        FAILED_INSERT : "Någonting gick fel när informationen skulle laddas upp",
        FAILED_DELETE : "Någonting gick fel vid försöket att ta bort föremålet. Försök igen.",
        ACCESS_DENIED  : 'Det finns inget sådant konto',//"Something seems odd with your account, please do contact us and we will sort it out for you.",

        /* Form realted */
        FORM_INCOMPLETE : "Alla fält med ( * ) måste fyllas i",
        INVALID_PASSWORD : "Felaktigt lösenord",

        /* Item Dialog related */
        NO_LOCATION_CANT_SELL : "Du måste registrera en address för lådor som du önskar annonsera föremål ur.",
        /* PRIVILAGE */
        NO_PRIVILAGE : "Du har inte befogenhet att utföra den önskade handlingen",

        //Email
        EMAIL_NOT_SENT : "Meddelandet kunde inte skickas, försök igen",

        /* 404 */
        ERROR_404 : "Sidan kunde tyvärr inte hittas, detta kan bero på att länken är gammal eller att sidan är borttagen"
    },

    INFO : {
        NO_IMG : "Föremålet har ingen bild."
    },

    MY_PROFILE : {
        my_profile : "Min profil",
        my_subscription : "Min prenumeration",
        no_subscription : "Ingen prenumeration",
        cancel_subscription : "Avbryt prenumeration",
        sections : {
            account : {
                title : "Konto",
                items : [
                    "Byt lösenord",
                    "Användarinformation",
                    "Ta bort konto"
                ],
            },
            subscriptions : {
                title : "Prenumeration",
                items : [
                    "Uppgradera betalningsplan",
                    "Byt betalningsmetod",
                    "Avbryt prenumeration"
                ]
            },
            privacy : {
                title : "Säkerhet",
                items : [
                    "Datavilkor",
                    "Dataanvändning",
                ]
            },
        }
    },

    CANCEL_SUBSCRIPTION : {
        are_you_sure : "Är du säker på att du vill avsluta din Qboxr-prenumeration? Du kommer fortfarande att bli debiterad för prenumerationens fulla period.",
        no_subscription_to_cancel : "Du har inte någon prenumeration som du kan avbryta."
    },

    CHANGE_PAYMENT_METHOD : {
        title : "Byt betalningsmetod",
        text  : "Uppdatera kortinformationen"
    },

    DASH_SUBSCRIPTION_PAGE : {
        title : "Prenumerationer",
        text_big : 'Qboxr erbjuder en tjänst ämnad att hjälpa dig att organisera ditt förråd och enkelt kunna sälja föremål du ej behöver.',
        text_small : 'Alla våra prenumerationer finns tillgängliga i månads och årscykler. Spara upp till 20% med årsprenumerationen.',
        switch_label : "Year",
    },

    PURCHASE_SUBSCRIPTION : {
        subscription : "Prenumerationer",
        has_sub_warning : "Du har redan ett pågende abonnemang",
        payment_success : "Betalningen nekad",
        next_payment_information : "When subscribing to a new plan your unused days of the current subscription are accounted for. Your next payment will be {{next_payment}} and thereafter {{new_price}}. This causes you to never have to pay doubble when you need to modify your plan.",
        
        next_payment_information : "När du ansluter dig till en ny betalningsplan tillgodoräknas de dagar du har kvar av ditt nuvarance abonnemang. Din nästa betalning kommer att bli {{next_payment}} och därefter {{new_price}}. Igenom detta behöver du aldrig betala dubbelt när du vill modifiera din betalningsplan.",
        
        /* Success */
        add_succcss : "Betalning klar. Du har blivit tilldelad {{n_tokens}} tokens som används för att generera lådor",
        edit_success : "Prenumerationen uppdaterades. Du har nu {{n_tokens}} kvar att generera lådor för",
        card_update_success : "Betalningsmetod uppdaterad",
        pay : "Betala"
    },

    EDIT_SUBSCRIPTION : {
        current_card : `Ditt nuvarande kort : {{card_brand}}-kort slutar med {{last_digits}}`,
        no_card : "Du har inget kort registrerat hos Qboxr."
    },

    ADD_BOX_PAGE : {
        add_item : "Lägg till föremål",
        print_label : "Skriv ut etikett",
        remove_box : "Ta bort lådan",
        items : "Föremål",
        recently_added_items : "Senast tillagda föremålen",
        offers : "Erbjudanden",

    },

    SEARCH_RESULTS : {
        title : "Sökresultat för ",
        enter_3_chars : "Skriv in minst 3 bokstäver för att söka",
        search_your_storage : "Sök i ditt förråd...",
        no_search_results : "Inga matchande resultat"
    },

    FILE_TEXT : {
        pick_file : "Välj fil",
    },

    /* SHOP */
    ITEM_CARD : {
        within : "Inom",
        see_more : "Läs mer",
        see_less : "Läs mindre",
        see_img  : "Se bild",
        to_article : "Till artikeln",
        edit : "Redigera",
        share : "Dela",
        information : "Information",
        
        /* Dialog question for sales */
        item_not_for_sale : "Föremålet är ej till salu",
        put_up_for_sale_question : "Skulle du vilja annonsera föremålet {{item_name}}?",
        put_up_for_sale_button_text : "Annonsera",
        item_information_dialog : "Föremålet lades till {{date}}. Föremålet är inte till salu och är kategoriserat under {{category}}",

        remove_item : "Ta bort föremål",

        estimated_distance : "Föremålet är estimerat {{distance}} km ifrån din nuvarande position",

        category : "Kategori",
        up_for_sale_since : "Laddades upp",
        price : "Pris",
        sub_category : "Underkategori",
        description : "Beskrivning",
        
        contact_seller : "Kontakta säljaren",
        no_phone_number : "Inget telefonnummer",
        report_article : "Rapportera artikeln",
        no_description : "Ingen beskrivning",
        delete : "Ta bort",

        location : "Plats"
    },


    SHOP_AUTOCOMPLETE : {
        filter_description : "Sökningarna görs relativt din nuvarande position. Igenom att modifiera sökradien och uppdatera din position kan du få mer exakta resultat.",
        search_radius : "Sökradie",
        sort_by : "Sortera efter",
        update_position : "Uppdatera position",

        SALES_CATEGORY : "Kategori",
        SUB_CATEGORY : "Underkategori",
        ARTICLE : "Artikel"
    },

    FOLDER_LIST : {
        
        //Items
        no_items_registered : "Du har inga föremål registrerade",
        register_first_item : "Registrera ditt första föremål här!",
        
        //Messages
        offers_info_alert : "Du har inga erbjudanden för de föremålen i lådan än.",
        no_new_offers : "Inga nya erbjudanden",
        no_new_offers_secondary : "Det finns för nuvarande inga erbjudanden för de föremålen till salu i denna lådan",
        open_chat : "Öppna chatten"  
    },

    EDIT_BOX_DIALOG : {
        text : 'Information som används för att beskriva lådan och dess innehåll'
    },

    MARK_AS_SOLD_DIALOG : {
        register_sale : "Registrera försäljning av föremål",
        price_a_piece : "Styckpris",
        total_price : "Samlat pris",
        how_many : "Hur många såldes",
        btn : "Markera som såld",
    },

    // REMOVE_ACCOUNT_DIALOG : {
    //     text : "Är du säker på att du vill ta bort ditt konto på Qboxr? Din prenumeration kommer fortfarande att debiteras sin fulla tid ut och alla dina lådor kommer att permanent bli borttagna."
    // },
    
    ADD_ITEM_DIALOG : {
        item_name_title : "Föremålets namn",
        item_category_title : "Kategori",
        n_of_item_title : 'Kvantitet',
        add_item_title : "Lägg till föremål",
        edit_item_title : "Redigera föremål",
        brag_text : 'Alla föremål du lägger till synkas i realtid mellan alla dina enheter',

        double_to_remove : "Dubbelklicka för att ta bort",

        sales_type_text : "Du säljer mer än 1 av denna produkten. Är priset avsett som styckpris eller för alla tillsammans.",

        /* SHOP */
        check_box_label : "Föremålet är till salu",
        sales_category  : "Försälningskategori",
        sub_category    : "Underkategori",
        description     : "Beskrivning",
        price           : "Pris",
    },

    ADD_ITEM_DIALOG_ERRORS : {
        INVALID_FORM : "Föremålsnamn och kategori krävs för att registrera föremålet",
        N_OF_NOT_NUMBER : "Mängden av föremålet måste vara ett nummer",
        INVALID_SHOP_ADD_FORM : "För att annonsera ett föremål måste alla fälten förutom beskrivning fyllas i.",
        PRICE_NOT_NUMBER : "Priset måste anges som ett nummer"
    },

    REMOVE_ITEM_DIALOG : {
        are_you_sure : "Är du säker på att du vill ta bort {{item_name}}",
        can_not_be_undone : "Handlingen går inte att ångra",
        delete_success : "Föremål borttaget"
    },

    REMOVE_BOX_DIALOG : {
        are_you_sure : "Är du säker på att du vill ta bort lådan {{box_name}}. Lådan kan inte återhämtas om den tas bort. Du kommer att få tillbaka en token när lådan tas bort."
    },

    CONTACT_US_DIALOG : {
        title : "Kontakta oss",
        text  : "Tack för att du vill komma i kontakt med och ge oss feedback. Vi jobbar ständigt med att utveckla våran verksamhet.",
        send : "Skicka",

        subject : "Ärende",
        subject_placeholder : 'Vad vill du ha hjälp med?',
        name : 'Namn',
        name_placeholder : "Ditt namn",
        message : "Meddelande",
        message_placeholder : 'Ditt meddelande'
    },

    CHANGE_PASSWORD_DIALOG : {
        new_password_title : "Skapa nytt lösenord",
        current_password : "Nuvarande lösenord",
        new_password_button : "Byt lösenord",
        success : "Lösenordet uppdaterades"
    },

    DELETE_ACCOUNT : {
        title : "Är du säker på att du vill ta bort ditt Qboxrkonto?",
        text  : "Alla dina föremål och lådor kommer raderas och du kommer fortfarande att debiteras för prenumerationens fulla tidsperiod.",
        accept_button_text : "Ta bort" 
    },

    CONTACT_DIALOG : {
        success : "Ditt meddelande har skickats till våran support och vi kommer svara så snabbt som möjligt."
    },


    UPDATE_INFORMATION_DIALOG : {
        title : "Uppdatera informationen",
        name : "Namn *",
        name_placeholder : "Namnet som visas i chatten",
        button_text : "Uppdatera",
        update_success : "Informationen uppdaterades"
    },


    PRINT_LABELS_DIALOG : {
        title : "Skriv ut ettiketter",
        text : "Välj de ettiketter du önskar skriva ut",
        n_labels : {
            zero : "0 ettiketter",
            one : "1 ettikett",
            other : "{{count}} ettiketter"
        },
        email_success : "Ett email med ettiketterna har skickats till din E-postaddress",
        email_failure : "Något gick fel när ettiketterna skulle skickas. Försök igen."
    },




    tiers : {
        // tiers : [
        BASIC : {
            ...default_subscription,
            title: 'Basic',
            subheader: 'Våran enklaste plan',
            description: [
                '10 lådor inkluderat',
                ...default_description
            ],
            sub_name : "Basic",
            description_text : "Det här är en betalningsplan som möjligör för att registrera upp till 10 lådor på Qboxr. Våran enklaste och billigaste plan.",            
            tier_level : 1,
            id : "BASIC"
        },
        PRO : {
            ...default_subscription,
            title: 'Pro',
            subheader: 'Mest populär',
            description: [
                '20 lådor inkluderat',
                ...default_description
            ],
            sub_name : "Pro",
            description_text : "Det här är en betalningsplan som möjliggör för att registrera upp till 20 lådor på Qboxr. Det är även våran vanligaste betalningsplan.",
            tier_level : 2,
            id : "PRO"
        },
        MASTER : {
            ...default_subscription,
            title: 'Master',
            subheader: 'För dig med många lådor',
            description: [
                '40 lådor inkluderat',
                ...default_description
            ],
            sub_name : "Master",
            description_text : "Det här är en betalningsplan som möjliggör för att registrera upp till 40 lådor på Qboxr. Välpassande föremål för dig med ett stort förråd.",
            tier_level : 3,
            id : "MASTER"
        },
        CORPORATE : {
            ...default_subscription,
            title: 'Diamond',
            subheader: 'Företagslösning',
            description: [
                '80 lådor inkluderat',
                ...default_description
            ],
            sub_name : "Diamond",
            description_text : "Det här är en betalningsplan som möjliggör för att registrera upp till 80 lådor på Qboxr. Välpassande för dig med ett mycket stort förråd eller för företag som vill sköta inventering.", 
            tier_level : 4,
            id : "CORPORATE"
        }
    // ]
    },

    CHAT : {
        title : "Chat",
        conversations : "Konversationer",
        search_conversations : "Sök bland konversationer",
        qboxr_user : "Qboxr-användare",
        no_chats : "Du har inga aktiva konversationer, när någon gör ett erbjudande på något av dina föremål eller du själv kontaktar en användare blir chatten tillgänglig.",
        default_msg_non_user : {
            author: 'them',
            type: 'text',
            data: {
                text: 'Du måste logga in för att kunna kontakta användaren.',
            }
        },
        default_msg : {
            author: 'them',
            type: 'text',
            data: {
                text: 'Chatten är den enklaste sättet att komma i kontakt med andra användare. Kom i kontakt nu!',
            }
        }
    },

    FILTER_ELEMENTS : {
        price_min : "Min-pris",
        price_max : "Max-pris",
        distance_title : "Avstånd ({{radius}}km)",
        show_filter : "Visa filter",
        hide_filter : "Dölj filter",

        
        sort_by_options : [
            { value : "DATE_RECENT", text : "Senaste" },
            { value : "DATE_OLDEST", text : "Äldst" },
            { value : "PRICE_MAX", text : "Dyraste"},
            { value : "PRICE_MIN", text : "Billigaste" },
        ]
    },


    SHOP : {
        not_defined : "Ej angiven",
        try_again : "Försök igen",

        dropdown_categories : [
            {
                title : "Ingen",
                items : [{ text : "Ingen", value : "NONE" }]
            },
            {
                title : "Hem",
                items : [
                    { text : "Bygg & trädgård", value : "BUILDING_AND_GARDEN" },
                    { text : "Möbler & inredning",value : "FURNITURE_AND_INTERIOR" },
                    { text : "Husgeråd & Vitvaror", value : "APPLIANCES" },
                    { text : "Verktyg", value : "TOOLS" }
                ]
            },
            {
                title : "Personligt",
                items : [
                    { text : "Kläder & skor", value : "CLOTHES_AND_SHOES" },
                    { text : "Accessoarer & klockor", value : "ACCESSORIES_AND_WATCHES" },
                    { text : "Barnkläder & Skor", value : "CHILDRENS_CLOTHING_AND_SHOES" },
                    { text : "Barnartiklar & Leksaker", value : "CHILDRENS_ARTICLES_AND_TOYS" }
                ]
            },
            {
                title : "Elektronik",
                items : [
                    { text : "Datorer & spel", value : "COMPUTERS_AND_GAMES" },
                    { text : "Ljud & bild", value : "SOUND_AND_PICTURE" },
                    { text : "Telefoner & tillbehör", value : "PHONES_AND_ACCESSORIES" }
                ]
            },
            {
                title : "Fritid & Hobby",
                items : [
                    { text : "Upplevelser & nöje", value : "EVENTS_AND_PLESSURE" },
                    { text : "Böcker & skönlitteratur", value : "BOOKS" },
                    { text : "Cyklar", value : "BIKES" },
                    { text : "Hobby & samlarprylar", value : "HOBBY_AND_COLLECTIBLES" },
                    { text : "Jakt & fiske", value : "HUNTING_FISHING" },
                    { text : "Musikutrustning", value : "MUSIC_EQUIPMENT" }, 
                    { text : "Sport & fritidsutrustning", value : "SPORTS_ITEMS" },
                ]
            }
        ],


        dropdown_sub_categories : {
            BUILDING_AND_GARDEN : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'WC', value : 'WC' },
                { text : 'Byggmaterial', value : 'BUILDING_MATERIAL' },
                { text : 'Kamin & värmare', value : 'HEATER' },
                { text : 'Kök', value : 'KITCHEN' },
                { text : 'Trädgårdsmaskiner', value : 'GARDEN_MACHINES' },
                { text : 'Trädgård & balkong', value : 'GARDEN_AND_BALCONY' }      
            ],
            FURNITURE_AND_INTERIOR : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Antikt & konst', value : 'ART' },
                { text : 'Belysning', value : 'LIGHT' },
                { text : 'Bord & stolar', value : 'TABLES_AND_CHAIRS' },
                { text : 'Hemtextil & prydnad', value : 'TEXTILE' },
                { text : 'Hyllor & förvaring', value : 'SHELVES_AND_STORAGE' },
                { text : 'Mattor', value : 'CARPETS' },
                { text : 'Säng & sovrum', value : 'BED_AND_BEDROOM' },
                { text : 'Soffa/fotölj/soffmöbler', value : 'SOFA' },
                { text : 'TV- &stereomöbler', value : 'CARPETS' },  
            ],
            APPLIANCES : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Diskmaskin', value : 'DISHWASHER' },
                { text : 'Kyl & frys', value : 'FRIDGE_FREEZE' },
                { text : 'Kökstillbehör & porslin', value : 'KITCHEN_EQUIPMENT' },
                { text : 'Spis & micro', value : 'STOVE' },
                { text : 'Tvättmaskin & torktumlare', value : 'WASHING_MACHINE' }, 
            ],
            ACCESSORIES_AND_WATCHES : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Klockor', value : 'WATCHES' },
                { text : 'Smycken', value : 'JEWLERY' },
                { text : 'Väskor', value : 'BAGS' },
            ],
            CHILDRENS_ARTICLES_AND_TOYS : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Barnmöbler', value : 'CHILDRENS_FURNITURE' },
                { text : 'Barnvagnar & tillbehör', value : 'CHILDRENS_FURNITURE' },
                { text : 'Leksaker', value : 'TOYS' },
            ],
            COMPUTERS_AND_GAMES : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Stationära datorer', value : 'STATIONARY_COMPUTERS' },
                { text : 'Bärbara datorer', value : 'LAPTOPS' },
                { text : 'Surfplattor', value : 'IPADS_AND_SUCH' },
                { text : 'Datortillbehör & program', value : 'COMPUTER_EXTRAS' },
                { text : 'PC-spel & onlinespel', value : 'PC_GAMES' },
                { text : 'TV-spel', value : 'TV_GAMES' },
            ],
            SOUND_AND_PICTURE : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Musik', value : 'MUSIC' },
                { text : 'Foto & videokameror', value : 'PICTURE_VIDEO' },
                { text : 'MP3-spelare', value : 'MP3_PLAYERS' },
                { text : 'Stereo & surround', value : 'STEREO_AND_SURROUND' },
                { text : 'Video & DVD-spelare', value : 'VIDEO_AND_DVD' },
                { text : 'TV & projektor', value : 'TV_AND_PROJECTOR' },
            ],
            PHONES_ACCESSORIES : [
                { text : "Telefoner", value : "PHONES" },
                { text : "Tillbehör", value : "ACCESSORIES" }
            ],
            BIKES : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Herr', value : 'MALE' },
                { text : 'Dam', value : 'FEMALE' },
                { text : 'Barn', value : 'CHILDREN' },
                { text : 'Mountainbike', value : 'MOUNTAINBIKE' },
                { text : 'Racer', value : 'RACER' },
                // { text : 'Övriga cyklar', value : 'OTHER_BIKES' },
            ],
            HOBBY_AND_COLLECTIBLES : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Frimärken & mynt', value : 'STAMPS_AND_COINS' },
                { text : 'Hobbyfordon', value : 'HOBBY_VEHICLES' },
                { text : 'Radiostyrt & modell', value : 'RADIO_DRIVEN' },
                { text : 'Serietidningar', value : 'COMIC_BOOKS' },
                { text : 'Symaskin & textil', value : 'SEWING_MACHINES_AND_TEXTILE' },
                { text : 'Historiska föremål', value : 'HISTORICAL_ITEMS' },
            ],
            HUNTING_FISHING : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Jakt', value : 'HUNTING' },
                { text : 'Fiske', value : 'FISHING' },
            ],
            MUSIC_EQUIPMENT : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Gitarr/bas/förstärkare', value : 'STRING_INSTRUMENTS' },
                { text : 'Piano & klaviatur', value : 'PIANO' },
                { text : 'Blåsinstrument', value : 'HORN' },
                { text : 'Trummor & slagverk', value : 'DRUMS' },
                { text : 'Studio- &scenutrustning', value : 'STUDIO_EQUIPMENT' },
            ],
            SPORTS_ITEMS : [
                { text : 'Annat', value : 'OTHERS' },
                { text : 'Bollsport', value : 'BALL_SPORTS' },
                { text : 'Camping & friluftsliv', value : 'CAMPING' },
                { text : 'Dyk & simning', value : 'SWIMMING' },
                { text : 'Golf', value : 'GOLF' },
                { text : 'Träning & Hälsa', value : 'TRAINING_HEALTH' },
                { text : 'Vintersport', value : 'WINTER_SPORTS' },
            ]
                
        },
        // dropdown_sub_categories : {
        //     ALL : [{text : "Underkategori", value : 'SUB_CATEGORY'}],
        //     CLOTHES : [ //Clothes
        //         { text : 'Alla', value : 'ALL' },            
        //         { text : 'Kläder & Skor', value : 'CLOTHES_SHOES' },
        //         { text : 'Barnkläder & Skor', value : 'CHILDREN_CLOTHES_SHOES' },
        //         { text : 'Accessoarer & Klockor', value : 'ACCESSORIES_WATCHES' },
        //         { text : 'Annat', value : 'OTHER' },
        //     ],
        //     APPLIANCES : [
        //         { text : 'Alla', value : 'ALL' },  
        //         { text : 'Kylskåp', value : 'REFRIGERATORS' },
        //         { text : 'Fläktar', value : 'FANS' },     
        //         { text : 'Ugnar', value : 'OVENS' },     
        //         { text : 'Tvättmaskiner', value : 'WASHING_MACHINES' },     
        //         { text : 'Annat', value : 'OTHER' },      
        //     ],
        //     HOBBY_WILDLIFE : [ //Hobby and wildlife
        //         { text : 'Alla', value : 'ALL' },
        //         { text : 'Böcker', value : 'BOOKS' },
        //         { text : 'Sporttillbehör', value : 'SPORTS_ACCESSORIES' },
        //         { text : 'Hobby & Samlingföremål', value : 'HOBBY_COLLECTIBLES' },
        //         { text : 'Skidor', value : 'SKIS' },
        //         { text : 'Jakt & Fiske', value : 'HUNTING_FISHING' },
        //         { text : 'Instrument', value : 'MUSIC' },
        //         { text : 'Cykel', value : 'BICYCLE' },
        //         { text : 'Annat', value : 'OTHER' },
        //     ],
        //     TECHNOLOGY : [   //Tech
        //         { text : 'Alla', value : 'ALL' },
        //         { text : 'Dator & Datorspel', value : 'COMPUTER_VIDEOGAMES' },
        //         { text : 'Ljud & Bild', value : 'SOUND_PICTURE' },
        //         { text : 'Telefon & Tillbehör', value : 'PHONES_ACCESSORIES' },
        //         { text : 'Annat', value : 'OTHER' },
        //     ],
        //     FURNITURE : [   //Furniture
        //         { text : 'Alla', value : 'ALL' },
        //         { text : 'Soffa & Fotölj', value : 'SOFAS_ARMCHAIRS' },
        //         { text : 'Bord & Stolar', value : 'TABLES_CHAIRS' },
        //         { text : 'Sängar', value : 'BEDS' },
        //         { text : 'Ljus', value : 'LIGHTNING' },
        //         { text : 'Konst', value : 'ART' },
        //         { text : 'Hyllor & Förvaring', value : 'SHELVES_STORAGE' },
        //         { text : 'Mattor', value : 'CARPETS' },
        //         { text : 'Utemöbler', value : 'OUTDOOR_FURNITURE' },
        //         { text : 'Annat', value : 'OTHER' },
        //     ],
        //     TOOLS_GARDEN : [   //Tools and garden
        //         { text : 'Alla', value : 'ALL' },
        //         { text : 'Verktyg', value : 'TOOL' },
        //         { text : 'Maskiner', value : 'MACHINES' },
        //         { text : 'Dekorationer', value : 'ORNAMENTS' },
        //     ],
        //     OTHER : [
        //         { text : 'Other', value : 'OTHER' },
        //     ], //Other
        // },
        sales_type_dropdown : [
            { text : "Styckpris", value : "PIECE" },
            { text : "Tillsammans", value : "BUNDLE" },
        ]
    },

    SHOP_LANDING : {
        title : "Köp / Sälj",
        recently_added : "Senast annonserade",
        no_more_results : "Inga fler resultat",
        no_items_found : "Inga resultat hittades för sökningen",
        looking_for : "Vad letar du efter?",
        reset_query : "Nollställ sökningen",
    },

    ARTICLE_PAGE : {
        items_for_sale_in_the_same_box : "Föremål till salu i samma låda",
        a_piece : "styck",
        pieces : "stycken",
        in_stock : "Lagerstatus"
    },

    SHOP_FORM : {
        search_within : "Sök inom {{distance}} {{unit}}"
    },

    SIDE_BAR : {
        my_storage : "Mitt förråd",
        my_profile : "Min profil",
        my_offers  : "Mina erbjudanden",
        shop : "Shop",
    },

    LABEL : {
        title : "Titel",
        description : "Beskrivning",
        box_number : "Boxnummer"
    },

    QR_SCANNER : {
        wrong_scan : "Du har ingen låda registrerad med den skannade QR-kodens värde."
    },

    CAMERA : {
        confirm : "Bekräfta",
        retake  : "Ta ny"
    },

    WELCOME_USER : {
        objects_array : [
        { 
            text :  "Välkommen till Qboxr, mitt namn är Albin och jag är här för att hjälpa dig att komma igång."
        },
        { 
            text :  "Du har blivit given 3 gratislådor som en del av din demoverision av appen. Så låt oss sätta igång och registrera din första låda.",
            button_text : "Registrera låda"
        },
        { 
            text :  "För varje låda som du registrerar så kommer en etikett att genereras. Du kan skriva ut dina etiketter närsomhelst i Appen.",
            button_text : "Skriv ut etikett",
        },
        { 
            text :  "Nu med dina första låda registrerad. Låt oss registrera ditt första föremål.",
            button_text : "Lägg till föremål",
        },
        {
            text : "Du kan enkelt tjäna pengar på dina föremål igenom att annonsera dem på våran marknadsplats. Detta görs med ett enkelt klick.",
            button_text : "Läs mer"
        },
        {
            text : "Hitta bland dina lådor med en simpel scan ",
            button_text : "Skanna låda"
        },
        {
            text : "Eller sök enkelt bland dina föremål med sökfältet.",
            button_text : "Sök"
        },
        {
            text : "Nu är du fullt redo att börja registrera hela ditt förråd. Lycka till!",
            button_text : "Mer hjälp"
        }   
    ]
    },
    COOKIE_CONSENT : {
        cookie_complianse : "Cookie-medgivande",
        we_use : "Vi använder cookies",
        text : "Vi använder cookies för att optimiera din upplevelse och visa relevant information. Igenom att klicka på acceptera så godkänner du för oss och våra partner att lagra kakor och andra teknologier för att optimera din vistelse. För att se mer läs våran",
        profile_text : "När du skapade ditt konto accepterade du Qboxrs cookie samt privacy policy. Detta ger oss och våra partner möjligheten att optimera sidan för dig och spara information om dig. Du kan ta tillbaka detta beslutet när som helst här. Notera att detta kommer att låsa ditt konto och radera all den informationen som vi har om dig, du kommer fortfarande att debiteras för den fulla prenumerationscykeln. Vill du dra tillbaka godkännandet ändå?",
        remove_complianse : "Radera godkännande",
        not_accept : "Godkänner ej",
        cookie_policy : "Cookie policy",
        how_we_use_it : "Hur vi behandlar data"
    },

}