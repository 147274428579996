import React from "react";

import Lottie from 'react-lottie';


export default class PageLoadingLottie extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            do_show : false
        }
    }

    componentWillMount(){
        setTimeout( () => {
            this.setState({ do_show : true })
        }, 250);        
    }
    
    render(){
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: require("../assets/animations/blue_box_loading.json"),
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
        };

        if(!this.state.do_show) return null;

        return(
            //85 vh for box yellow
            <div style = {{display : "flex", width : "100%", height : "95vh", justifyContent : "center", alignItems : "center"}}>
                <Lottie
                    options={defaultOptions}
                    height={400}
                    width={400}
                />
            </div>
        )
    }
}
