import React from 'react';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/styles';

import { TextField } from '@material-ui/core';


import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack"


import Button from '@material-ui/core/Button';

import Title from "../../components/title";

import ReactHtmlParser from 'react-html-parser';

import generate_custom_html from "../../assets/html/generate_html_label.js";
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";

import { rectangle_label_css, create_rectangle_label } from "../../assets/html/generate_new_html.js"

const styles = theme => ({
    container : {
        flex : 1,
        height : "100%"
    },
    button_container : {
        display : "flex",
        justifyContent : "center",
        marginTop : theme.spacing(4),
    },
    right_button : {
        marginLeft : theme.spacing(2)
    },
    card_container : {
        margin : theme.spacing(2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    label_container : {
        display : "flex",
        justifyContent : "center",
        padding : 50,
        [theme.breakpoints.down('sm')]: {
            padding : 0,
            marginTop : theme.spacing(3),
        },
    },
    core_container : {
        // [theme.breakpoints.down('sm')]: {
        //     display : "flex",
        //     flexDirection : "column-reveser"
        // },
    }

  });

class PageTwo extends React.Component{
    constructor(props){
        super(props);
    }


    componentWillMount(){
        const { boxes } = this.props;
        
        let new_box_number = 1;

        if(boxes.length !== 0){
            let deep_copy = JSON.parse(JSON.stringify(boxes));
            deep_copy.sort(function(a, b){
                return a.box_number > b.box_number;
            });            
            new_box_number = deep_copy[deep_copy.length - 1].box_number + 1;
        }

        this.setState({box_number : new_box_number})
    }

    render(){
        const {classes, t} = this.props;
        return(
            // <Grid container direction = "column" >
                <Grid container justify = "center">
                     
                    {/* <div style = {{display : "flex", flexDirection : "column", height : "100%", justifyContent : "space-around"}}>    */}
                    <Grid item xs = {11} container className = {classes.core_container}> 
                        <Grid item xs = {12} md = {6} container alignContent = "center" alignItems = "center" spacing = { 3 }>{/* Left side  */}
                            <Grid item xs = {12}><Title> { t('ADD_BOX_SCREEN:choose_label_step') } </Title> </Grid>
                            <Grid item xs = {12}>
                                <TextField 
                                    variant = "outlined"
                                    className = {classes.text_input}
                                    style = {{width : "100%"}}
                                    label = { t('label_title_title') }
                                    placeholder = { t('label_title_placeholder') }
                                    value = { this.props.label_title }
                                    name  = "label_title"
                                    onChange = {this.props.handle_change}
                                />
                            </Grid>
                            <Grid item xs = {12}>
                                <TextField 
                                    variant = "outlined"
                                    className = {classes.text_input}
                                    style = {{width : "100%"}}
                                    label = { t('label_description_title') }
                                    placeholder = { t('label_description_placeholder') }
                                    value = { this.props.label_description }
                                    name  = "label_description"
                                    onChange = {this.props.handle_change}
                                />
                            </Grid>
                        
                            <Grid item xs = {12}>
                                <div style = {{display : "flex", justifyContent : "space-around"}}>
                                    <Button variant="contained" aria-label="delete" className={classes.fab} onClick = { () => this.props.set_form_page(0)}>
                                        <ArrowBackIcon className={classes.extendedIcon} />
                                        { t('G:back') }
                                    </Button>
                                    <Button variant="contained" aria-label="delete" className={classes.right_button}  onClick = {this.props.submit_form}>
                                        <EditIcon className={classes.extendedIcon} />
                                        { t('G:generate') }
                                    </Button>
                                </div>
                            </Grid>
                    </Grid>
 
                    <Grid item xs = {11} md = {6} container className = { classes.label_container }>
                        <div style = {{display : "flex", flex : 1, justifyContent : "center"}}>
                            {/* {ReactHtmlParser(generate_custom_html({label_title : this.props.label_title, label_text : this.props.label_description, box_number : this.state.box_number}, t))} */}
                            {/* {
                                ReactHtmlParser(
                                `
                                ${rectangle_label_css}
                                ${create_rectangle_label({
                                    box_title : this.props.label_title,
                                    box_description : this.props.label_description,
                                    box_number : this.state.box_number
                                }, t)}
                                `
                                )
                            } */}
                        </div>
                    </Grid>
            </Grid>
         </Grid>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
      boxes  : state.products.boxes,
      n_tokens : state.global.n_tokens
    }
  }

export default (
    connect(mapStateToProps)
    (
        withStyles(styles)
        (
            withTranslation('A_B_S_2')(PageTwo)
        )
    )
);