import React from 'react';
import TextLink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './title';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function DashboardNData(props) {
  const classes = useStyles();
  let n_items = 0;
  props.boxes.forEach(box => {
    box.items.forEach( item => {
      n_items++;
    })
  });

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title style = {{ color : "black" }}>{t('DASHBOARD_N_DATA:title')}</Title>
      <Typography component="p" variant="h5">
      {
        t('PLURALS:boxInterval', { postProcess: 'interval', count: props.boxes.length })
      }
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        { 
          t('PLURALS:itemInterval', { 
            postProcess: 'interval',
            count: props.boxes.reduce(function (total, currentValue) {
              return total + currentValue.items.length;
          }, 0)
          })
        }
      </Typography>
    </React.Fragment>
  );
}