const default_subscription = {
    buttonText: 'Purchase',
    currency_orientation : "RIGHT",
    currency : "$",
    iso_code : "usd",
}
const default_description = [
    'Up to a 100 items/box',
    'Personalized labels',
    'Search functionality',
    'Email-support',
];

export default {
    L_P : {
        header_title: "Organize your storage with Qboxr.",
        header_text : "The days of rooting around blindly in your storage is a thing of the past with Qboxr's smart storage organization solution. Download the Qboxr app here.",
        // what_we_offer_title : "We understand storage!",
        
        what_we_offer_title : "Organizing your storage",
        what_we_offer_text : "Use Qboxr to organize your storage units and never spend another day blindly searching your storage units .A one time scan of your storage unit gives you storage structure for life.",
        
        make_money_title : "And make money from doing it!",
        make_money_text  : "Qboxr offers an advanced marketplace where you easily can advertise items from your storage in a click! We offer an implemented chat to interact with the sellers.",
        
        safe_data_title: "Safely stored data",
        safe_data_text: "By continous backups as well as using state of the art encryption methods. We are able to securly store your data wihthout occupying any of your devices memory.",

        how_it_works_title : "How it works",
        how_it_works_text  : "By marking your physical storage units with labeled QR codes you are able to link together your physical storage with the digital cloud. After registering your items in the labeled units a large set of tools are avaialble that will help you organize, track, group and very importantly find your items with ease.",
        
        how_it_works_step_one_title : "Generate your labels",
        how_it_works_step_two_title  : "Register your items",
        how_it_works_step_three_title : "Track items with ease",
        our_prices_title : "Subscriptions",
        our_prices_text : "Qboxr offers a wide variety of subscription models.",
        support_title : "Dedicated support",
        support_text  : "We have a dedicated support that is managed through E-mail, alot of answers can also be found in our FAQ"
    },
    A_U : { //ABOUT US
        about_us_title : "About us",
        about_us_text  : "We at Qboxr believe in a new way to manage private organization. Our goal is to assure you of not having to ever root through your entire storage unit to find that one thing you were looking for.",
        the_problem_title : "The problem we solve",
        the_problem_text  : "How many times have you found yourself ripping up the entire storage unit to find that one item that you were looking for? For us the amount times that occured lit a spark for this project. With Qboxr you gain full access and an overview of your storage unit.",
        see_how_it_works_title : "See how it works",
        see_how_it_works_text  : "Getting started with Qboxr is simple and the registration of your storage unit can easily be made into a family activity as multiple users may use the applicaiton at the same time."
    },
    P_P : {
        product_title : "Get started",
        product_text : "Get started with Qboxr with the blink of an eye with our mobile App. You can easily manage your storage through the app and chat with interested buyers.",
        cross_platform_title : 'Get the app',
        cross_platform_text : "Qboxr is avaible on iOS, Android and soon also in your browser. Download the app for free and get your storage organized."
    },
    C_P : {
        header_title : "Contact",
        header_text  : "There are multiple ways of getting your question answered. Our FAQ answers the most common questions we receive. If your question is not answered there, please do send us an email down below",
        how_to_get_in_touch_title : "How to get in touch",
        how_to_get_in_touch_text : "There are multiple ways of getting your question answered. Our FAQ answers the most common questions we receive. If your question is not answered there, please do send us an email down below"
    },
    P_G : { //PUBLIC GLOBAL
        get_app : "Download app",
        start_shopping : "Shop",
        register : "Sign up",
        sign_in  : "Sign in",
        Box       : "Box",
        Boxes     : "Boxes",
        Items     : "Items",
    },
    PRICING_PAGE : {
        header_title : "Pricing title",
        header_text : "Pricing text"
    }, 

    TITLES : {
        home : "Qboxr",
        add_box_page : "Qboxr - Add box",
        about_us : "Qboxr - About us",
        chat : "Qboxr - Chat",
        contact : "Qboxr - Contact",
        dashboard : "Qboxr - Dashboard",
        forgot_password : "Qboxr - Forgotten password",
        my_boxes : "Qboxr - My boxes",
        pricing : "Qboxr - Subscriptions",
        profile_page : "Qboxr - My profile",
        reset_password : "Qboxr - Reset password",
        search_results : "Qboxr - Search results",
        sign_in : "Qboxr - Sign in",
        sign_up : "Qboxr - Sign up",
        shop_landing : "Qboxr - Buy/Sell",
        my_shop : "My shop"
    },

    G : {
        read_more : "Read more",
        sign_in   : "Sign in",
        sign_up   : "Sign up",
        sign_out  : "Sign out",
        contact   : "Contact",
        next      : "Next",
        accept    : "Accept",
        back      : "Back",
        generate  : "Generate",
        month_short : "Mo",
        year_short : "Y",
        purchase : "Purchase",
        upgrade  : "Upgrade",
        search   : "Search",
        dashboard : "Dashboard",
        see_my_boxes : "To my boxes",
        remove : "Remove",
        open_box : "Open box",
        box : "Box",
        information : "Information",
        open : "Open",
        categories : "Categories",

        settings : "Settings",

        date : "Date",

        save : "Save",

        actions : "Actions",
        content : "Content",

        filter : "Filter",
        results : "Results",

        location_updated : "Location was updated",

        of : "of",
        matching : "matching",
        articles : "articles",

        search_your_storage : "Search your storage...",

        actions : "Actions",

        terms_of_agreement : "Terms of agreement",

        confirm : "Confirm",

        slogan : "Organize your storage and make money from doing it"
    },

    DATE : {
        today : "Today",
        yesterday : "Yesterday",
        this_week : "This week",
        months : ["January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"]
    },


    NAVBAR : {
        home : "Home",
        about_us : "About us",
        product : "Get started",
        contact : "Contact",
        buy_sell : "Buy / Sell",
        
        public_paths : [
            {
                text : "Home",
                path : "/"
            },
            // {
            //     text : "About us",
            //     path : "/about_us"
            // },
            {
                text : "Get started",
                path : "/get_started"
            },
            {
                text : "Buy / Sell",
                path : "/shop"
            }
        ]
    },

    PLURALS : { // Done
        boxInterval : " (0){No boxes};(1){1 box};(2-inf){ {{count}} boxes};",
        itemInterval : "(0){No items registered};(1){1 item};(2-inf){ {{count}} items};",  
        leftToRegister : "(0){No boxes left to generate};(1){1 box left to generate};(2-inf){ {{count}} boxes left to generate};",

        n_clicks : "(0){No clicks};(1){1 click};(2-inf){ {{count}} clicks};",
        n_new_convos : "(0){No new offers};(1){1 new offer};(2-inf){ {{count}} new offers};",
        
    },

    /*Dashboard*/  
    SIGN_IN : {
        email_placeholder : "Email",
        password_placeholder : "Password",
        sign_in : "Sign in",
        remember_me : "Remember me",
        dont_have_account_link : "No account? Register here!",
        forgot_password : "Forgotten your password?"
    },

    SIGN_IN_ERRORS : {
        WRONG_EMAIL_OR_PASSWORD : "Wrong email or password",
        FORM_INCOMPLETE : "All required fields (Fields with *) are not filled"
    },

    SIGN_UP : {
        register_title : "Sign up",
        password_re_placeholder : "Confirm password",
        term_accept_text : "I accept Qboxr's terms of agreement",
        allow_emails : "I want to updated through email regarding news and updates.",
        already_has_account : "Already have an account? Sign in"
    },

    FORGOT_PASSWORD : {
        forgot_title : "Send new password",
        button_title : "Send new",
        email_has_been_sent : "An email has been sent to the associated E-mail for this account with a link to reset your password.",
    },

    RESET_PASSWORD : {
        title : "Restore password",
        old_password_pladeholder : "Current password *",
        new_password_placeholder : "New password *",
        new_password_re_placeholder : "New password again *",
        button_title : "Restore password",
        successfully_updated : "Your password has been changed. You can now sign in with the new password."
    },
    RESET_PASSWORD_ERRORS : {
        INVALID_HASH : "The link is invalid. It is either to old or incorrect. Claim a new link and try again. ",
        NO_SUCH_EMAIL : "There is no account associated with that email.",
        SOMETHING_WENT_WRONG_ON_INSERT : "Oops, something went wrong when attempting to change your password. Try again."
    },

    SIGN_UP_ERRORS : {
        INVALID_EMAIL_FORMAT : "Invalid email format.",
        FILL_ALL_FILEDS : "All required fields has to be filled to register your account.",
        PASSWORDS_NOT_THE_SAME : "Passwords does not match.",
        TERMS_NOT_ACCEPTED : "You have to accept the terms of service to register.",
        EMAIL_ALREADY_REGISTERED : "There is already an account registered at Qboxr with the given email.",
        EMAIL_ALREADY_REGISTERED_SHORT : "Email already taken",
        WELCOME_TO_QBOXR : "Welcome to Qboxr. Your account has been created",
    },

    DASHBOARD_SIDE_BAR : {
        control_panel : "Controll panel",
        my_boxes      : "My boxes",
        add_box       : "Add box",
        start_page    : "Home page",
        shop          : "Buy/Sell",
        messages      : "Messages",
        my_shop       : "My shop",
        /*---------------------------*/
        bottom_nav_title : "Account",
        my_profile    : "My profile",
        subscriptions : "Subscriptions",
        get_app       : "Get the app",
        sign_out      : "Sign out"
    },
    DASHBOARD_GLOBALS : {
        my_boxes : "My Boxes",
        recent_boxes : "Recent boxes",
        recent_items : "Recent items"
    },

    DASHBOARD_CHART : { // NEW
        overview : "Overview",
        items : "Items",
        no_boxes : "You have not registered any boxes yet. So let's get started!",
        example_text : "This is an example of who your storage will be displayed with boxes registered.",
        hide : "Hide"
    },  

    DASHBOARD_N_DATA : { //NEW
        title : "My storage"
    },

    // DASHBOARD_LIST : { // NEW DATA
    //     recently_added : "Most recently added items",
    //     content_title  : "Content",
    //     items : "Items",
    //     column_item_name : "Title",
    //     column_date : "Date",
    //     column_items : "Items",
    //     column_category : "Category",
    //     column_box_number : "Box number",
    //     column_img : "Image",
    //     column_to_box : "To box",
    //     sort : "Sort",
    //     no_items_text : "You have not registered any items.",
    //     no_items_button_text : "Add",
    //     img_button_text : "See image",
    //     handle_button_text : "Handle",
    //     redirect_button_text : "To box",
    //     handle_button_texts : [
    //         "Edit",
    //         "Remove"
    //     ]
    // },

    MY_BOXES : {
        text : "All of your registered boxes.",
        no_boxes_text : "You have {{n_left}} box tokens remaining.",
        no_boxes_welcome_title : "Welcome to Qboxr!",
        no_boxes_welcome_text  : "Registering boxes is a very simple process and is done through the sub page \"Add box\" ",
        no_boxes_welcome_support_text : "If you need help getting started alot of helpful tips can be found in our FAQ."        
    },

    MY_SHOP : {
        text : "Theese are your items currently up for sale",
        why_sell : "Qboxr allows you to easily advertise items in your storage for people to purchase.",
        get_started : "Let's get started",
        no_items_text : "You currently have no items up for sale.",
        no_items_text_help  : "Putting an item up for sale is as easy as checking the \"Item for sale\" checkbox in the item form when adding or editing an item",
        no_items_welcome_support_text : "If you need help getting started alot of helpful tips can be found in our FAQ.",

        my_articles : "My articles",

        visits : "Visits",
        visits_data_graph_tool : "Graph over this weeks visits for your products on the marketplace" ,
        
        todays_data : "The current data for today",

        items_for_sale : "Items for sale",
        for_sale : "For sale",
        not_for_sale : "Not for sale",

        revenue : "Revenue",
        revenue_text : "Your revenue generated from sold items since you joined Qboxr",
        revenue_data_graph_tool : "Graph over your revenue since you joined Qboxr",

        n_clicks_tool : "The amount of times your items has been visited during the day",
        n_new_convos_tool : "The amount of new offers that has been made for your items today",

        register_location : "Register a location for the box the item is placed in",
        checkbox_text    : 'Check the checkbot "Item is for sale" when you add or modify an item'
    },

    BOX_PAGE : {
        no_items_in_box : "No items registered in this box",
        date_added : "Added",
        name : "Name",
        category : "Category",
        sales_status : "Sales status"
    },

    BOX_ACTIONS : { // NEW
        add_item : "Add item",
        edit_item : "Edit item",
        print_label : "Print QR-label",
        remove_box : "Remove box",
        redirect_to_box : "To box",
        register_box : "Register box",
        open_box : "Open box"
    },

    ADD_BOX_SCREEN : { // NEW
        box_information_step : "Box information",
        choose_label_step    : "Generate label",
        done_step            : "Done!",
        add_your_first_box : "Add your first box",
        upgrade_for_more_tokens : "Uppgradera ditt abonnemang för fler tokens",
        learn_more : "Read more about how it works"
    },

    A_B_S_1 : {
        box_name_title : "Box title *",
        box_name_placeholder : "Short title to sumarize the box content.",
        box_owner_title : "Who's box is it?",
        box_owner_placeholder : "e.g. Me, Mom, Husband",
        box_location_title : "Box location",
        box_location_placeholder : "e.g. Attic, Garage, Basement..",
        box_address_placeholder  : "The address allows you to see the location of all of your boxes",
        box_address_title : "Address",
        box_description_title : "Short description of the boxes content.",
        box_description_placeholder : "Christmas items, Kitchen items, Children things",
        files_drop_text : "Drag and drop the image or press to select"
    },

    A_B_S_2 : {
        label_title_title : "Title",
        label_title_placeholder : "This is the title that will be displayed on the physical label.",
        label_description_title : "Description",
        label_description_placeholder : "This is the description that will be displayed on the physical label.",
    },
    A_B_S_3 : {
        box_registered : "Box was created",
        box_number : "Box number",
        box_registered_alert_text : `
        (0){Box #{{box_number}} was created, {{n_tokens_left}} boxes remaining.};
        (1){Box #{{box_number}} was created, {{n_tokens_left}} box remaining.};
        (2-inf){Box #{{box_number}} was created, {{n_tokens_left}} boxes remaining.};
        `,
        print_label : "Print label",
        redirect_to_box : "Go to box"
    },
    
    ERRORS : {
        /* ADD_BOX_FORM */
        ADD_BOX_FORM_INVALID : "You have to enter a title and location to register the box.",

        /* Subscriptions */
        NOT_ENOUGH_TOKENS : "Not enough tokens. Feel free to upgrade your subscriptions to register more boxes.",
        SUBSCRIPTION_NOT_LOADED : "The subscription is loading",
        NO_SUBSCRIPTION : "You don't have a subscription to update",
        SAME_SUBSCRIPTION : "You are already subscribed to this plan.",
        SAME_SUBSCRIPTION_SHORT : "You are already subscribed to this plan.",
        INVALID_PLAN   : "The plan you tried to purchase is not registered at our end, try again or contact us.",
        PAYMENT_FAILED : "The purchase was declined. Try again, if it doesn't check your balance and if it does not work, please contact us.",
        // DOWNGRADE : "You can't downgrade",
        DIFF_CURRENCY  : "You can't change the currency on an ongoing subscription. Swap to {{iso_code}}",
        TOO_MANY_BOXES : `
        (0-1){You have too many boxes registered at the moment to downgrade to the wanted plan. Remove {{n_to_remove}} box to downgrade to the wanted plan.};
        (2-inf){You have too many boxes registered at the moment to downgrade to the wanted plan. Remove {{n_to_remove}} boxes to downgrade to the wanted plan.};
        `,
        NOT_A_CUSTOMER : "You don't have a card registered that you can change.",

        /* Print */
        print_blocked : "The window was blocked in the browser. Allow popup in your browser and try again",
        
        /* BoxPage */
        INVALID_BOX_NUMBER : "You don't have a box with that box number",
        
        /* Account related */
        EMAIL_ALREADY_REGISTERED : "There is already an account with that email registered",

        ITEM_STILL_LOADING : "The item is still loading please wait.",
        
        /* Fail related */
        FAILED_UPDATE : "Something went wrong while trying to update the values",
        FAILED_INSERT : "Something went wrong while trying to insert the values",
        FAILED_DELETE : "Something went wrong while trying to perform the delete. Try again.",
        ACCESS_DENIED  : 'There is no such account',//"Something seems odd with your account, please do contact us and we will sort it out for you.",

        /* Form realted */
        FORM_INCOMPLETE : "All required fields (Fields with *) are not filled",
        INVALID_PASSWORD : "Incorrect password",

        /* Item Dialog related */
        NO_LOCATION_CANT_SELL : "You have to register a location for the given box to advertise the items within it.",
        /* PRIVILAGE */
        NO_PRIVILAGE : "You don't have the privilage to perform the wanted action",

        //Email
        EMAIL_NOT_SENT : "The message could not be sent, try again. ",

        ERROR_404 : "Sorry we can't find that page! It might be an old link or it may have been removed."
    },

    INFO : {
        NO_IMG : "The item does not have an image attached"
    },

    MY_PROFILE : {
        my_profile : "My profile",
        my_subscription : "My subscription",
        // n_left_to_register : "{{n_left}} lådor kvar att registrera",
        no_subscription : "No subscription",
        cancel_subscription : "Cancel subscription",
        sections : {
            account : {
                title : "Account",
                items : [
                    "Change password",
                    "User information",
                    "Delete account"
                ],
            },
            subscriptions : {
                title : "Subscriptions",
                items : [
                    "Upgrade subscription plan",
                    "Change payment method",
                    "Cancel subscription"
                ]
            },
            privacy : {
                title : "Privacy",
                items : [
                    "Cookie usage",
                    "Data usage"
                ]
            }
        }
    },

    CANCEL_SUBSCRIPTION : {
        are_you_sure : "Are you sure that you would like to cancel your Qboxr subscription? You will still be debited for the remaining period of your subscription.",
        no_subscription_to_cancel : "You don't have a subscription to cancel."
    },

    CHANGE_PAYMENT_METHOD : {
        title : "Change payment method",
        text  : "Update card information"
    },

    //Dashboard subscription page
    DASH_SUBSCRIPTION_PAGE : {
        title : "Subscriptions",
        text_big : 'Qboxr offers a service aimed to increase the organization in your home and storage unit.',
        text_small : 'All of the subscription plans are avaible in a monthly and yearly debit cycle. Save up to 20% with the yearly plan.',
        switch_label : "Year",
    },

    PURCHASE_SUBSCRIPTION : {
        pay : "Pay",
        subscription : "Subscription",
        has_sub_warning : "You already have a subscription registered",
        payment_success : "Payment denied",
        next_payment_information : "When subscribing to a new plan your unused days of the current subscription are accounted for. Your next payment will be {{next_payment}} and thereafter {{new_price}}. This causes you to never have to pay doubble when you need to modify your plan.",
        
        /* Success */
        add_success : "Purchase complete. You have been granted {{n_tokens}} tokens to generate boxes.",
        edit_success : "Subscription successfully updated. You now have {{n_tokens}} at your disposal to generate boxes.", 
        card_update_success : "The card was updated",
    },
    EDIT_SUBSCRIPTION : {
        current_card : `Your current card : {{card_brand}}-card ends with {{last_digits}}`,
        no_card : "You don't have a card registered at Qboxr."
    },

    
    ADD_BOX_PAGE : {
        add_item : "Add item",
        print_label : "Print label",
        remove_box : "Remove box",
        items : "Items",
        recently_added_items : "Most recently added",
        offers : "Offers",
    },

    SEARCH_RESULTS : {
        title : "Search results for ",
        enter_3_chars : "Enter 3 letters to search",
        search_your_storage : "Search your storage...",
        no_search_results : "No results were found"
    },

    FILE_TEXT : {
        pick_file : "Pick file",
    },

    /* SHOP */
    ITEM_CARD : {
        within : "Within",
        see_more : "Read more",
        see_less : "Read less",
        see_img  : "See image",
        to_article : "See article",
        edit : "Edit",
        share : "Share",
        information : "Information",
        
        /* Dialog question for sales */
        item_not_for_sale : "The item is not for sale",
        put_up_for_sale_question : "Would you like to sell the item {{item_name}}?",
        put_up_for_sale_button_text : "Put up for sale",
        item_information_dialog : "The item was added {{date}}. The item is not for sale and is labeled under the category {{category}}",

        remove_item : "Remove item",

        estimated_distance : "The item is estimated to be {{distance}} km from your current position position",
        
        category : "Category",
        up_for_sale_since : "Uploaded",
        price : "Price",
        sub_category : "Sub category",
        description : "Description",
    
            
        contact_seller : "Contact seller",
        no_phone_number : "No phone number",
        report_article : "Report article",
        no_description : "No description",
        delete : "Delete",
        location : "Location"
    },

    SHOP_ARTICLE : {
        category : "Category",
        sub_category : "Sub category",
        up_for_sale_since : "Up for sale since",
        price : "Price"
    },

    SHOP_AUTOCOMPLETE : {
        filter_description : "Sökningarna görs relativt din nuvarande position. Igenom att modifiera sökradien och uppdatera din position kan du få mer exakta resultat.",
        filter_description : "The search is made relative to your current location. By modifying the search radius and updating your current position you can get more accurate results.",
        search_radius : "Search radius",
        sort_by : "Sort by",
        update_position : "Update position",

        SALES_CATEGORY : "Category",
        SUB_CATEGORY : "Sub category",
        ARTICLE : "Article"
    },

    FOLDER_LIST : {
        
        //Items
        no_items_registered : "You don't have any items registered.",
        register_first_item : "Register your first item here!",
        
        //Messages
        offers_info_alert : "You don't have an offer for any of the items in this box yet.",
        no_new_offers : "No new offers",
        no_new_offers_secondary : "There are no current offers for any items in this box.",
        open_chat : "Open chat"
        
    },


    /* Dialogs */
    EDIT_BOX_DIALOG : {
        text : 'Information used to describe the box and it\'s content'
    },

    MARK_AS_SOLD_DIALOG : {
        register_sale : "Register sale for the item",
        price_a_piece : "Price",
        total_price : "Total price",
        how_many : "How many were sold",
        btn : "Mark as sold"
    },

    CONTACT_US_DIALOG : {
        title : "Contact us",
        text  : "Thank you for wanting to get in touch with us. We are constantly working to improve our organization.",
        send : "Send",

        subject : "Subject",
        subject_placeholder : 'What would you like to get help with?',
        name : 'Name',
        name_placeholder : "Your name",
        message : "Message",
        message_placeholder : 'Your message'
    },
    
    ADD_ITEM_DIALOG : {
        item_name_title : "Item name",
        item_category_title : "Category",
        n_of_item_title : 'Quantity',
        add_item_title : "Add item",
        edit_item_title : "Edit item",
        brag_text : 'All items added are synced directly between all of your devices',
        image : "Image",

        sales_type_text : "You are selling more than 1 of this item. Is the price intended for 1 unit or all as a bundle",

        double_to_remove : "Click twice to remove",
        /* SHOP */
        sales_information : "Sales information",
        check_box_label : "Item is for sale",
        sales_category  : "Sales category",
        sub_category    : "Sub category",
        description     : "Description",
        price           : "Price",
    },

    ADD_ITEM_DIALOG_ERRORS : {
        INVALID_FORM : "Item name and category required",
        N_OF_NOT_NUMBER : "Amount of the item has to be a number",
        INVALID_SHOP_ADD_FORM : "All fields except description has to be filled to sell an item at Qboxr.",
        PRICE_NOT_NUMBER : "Price of the item has to be a number"
    },

    REMOVE_ITEM_DIALOG : {
        are_you_sure : "Are you sure that you want to remove {{item_name}}",
        delete_success : "The item was successfully deleted"
    },
    REMOVE_BOX_DIALOG : {
        are_you_sure : "Are you sure that you want to remove the box {{box_name}}. The box can not be recovered after you remove it. You will recieve one label token back upon removal."
    },

    CHANGE_PASSWORD_DIALOG : {
        new_password_title : "Create new password",
        current_password : "Current password",
        new_password_button : "Change password"
    },

    DELETE_ACCOUNT : {
        title : "Are you sure that you would like to delete your Qboxr account?",
        text  : "All of your boxes will be removed permanently and your billing will still be active for its full period.",
        accept_button_text : "Delete account" 
    },

    CONTACT_DIALOG : {
        success : "Your message has been sent and we are going to repond to it as soon as possible."
    },
    

    // SHARE_DIALOG : {
    //     text : "Dela med dig av f"
    // }


    UPDATE_INFORMATION_DIALOG : {
        title : "Update information",
        name : "Name *",
        name_placeholder : "Chat alias",
        button_text : "Update",
        update_success : "Information was updated"
    },
    


    tiers : {
        // tiers : [
        BASIC : {
            ...default_subscription,
            title: 'Basic',
            subheader: 'Our simplest plan',
            // price_month: 2,//prices['Basic'],
            // price_year : 20,
            description: [
                '10 boxes included',
                ...default_description
            ],
            sub_name : "Basic",
            description_text : "This is a subscription which enables you to register 10 boxes of items at Qboxr. Our most simple plan which is very cost efficient.",            
            tier_level : 1,
            id : "BASIC"
        },
        PRO : {
            ...default_subscription,
            title: 'Pro',
            subheader: 'Most popular',
            // price_month: 3,//prices['Basic'],
            // price_year : 30,
            description: [
                '20 boxes included',
                ...default_description
            ],
            sub_name : "Pro",
            description_text : "This is a subscription which enables you to register 20 boxes of items at Qboxr. It is also our most popular plan.",
            tier_level : 2,
            id : "PRO"
        },
        MASTER : {
            ...default_subscription,
            title: 'Master',
            subheader: 'For you with alot of boxes',
            // price_month: 4,
            // price_year : 40,
            description: [
                '40 boxes included',
                ...default_description
            ],
            sub_name : "Master",
            description_text : "This is a subscription which enables you to register 40 boxes of items at Qboxr. Well fitted for you with a large storage unit.",
            tier_level : 3,
            id : "MASTER"
        },
        CORPORATE : {
            ...default_subscription,
            title: 'Diamond',
            subheader: 'Corporate solution',
            // price_month: 5,
            // price_year : 50,
            description: [
                '80 boxes included',
                ...default_description
            ],
            sub_name : "Diamond",
            description_text : "This is a subscription which enables you to register 80 boxes of items at Qboxr. Well fitted for you with a large storage unit or to manage your companies inventory.", 
            tier_level : 4,
            id : "CORPORATE"
        }
    // ]
    },

    AUTHENTICATION : {
        confirm_password : "Confirm password",
        confirm_password_explanation : "Please provide the account password to perform the action",
        your_current_password : "Your current password"
    },





    /* CHAT */
    CHAT : {
        qboxr_user : "Qboxr user",
        you : "You",
        conversations : "Conversations",
        search_conversations : "Search amongst conversations",
        no_chats : "You don't have any active conversations. When someone makes you an offer or you yourself make an offer you will be able to use the chat.",
        default_msg_non_user : {
            author: 'them',
            type: 'text',
            data: {
                text: 'You have to sign in to be able to contact the owner through the chat',
            }
        },
        default_msg : {
            author: 'them',
            type: 'text',
            data: {
                text: 'The chat is the easiest way to get in touch with the advertiser. Get in touch now!',
            }
        }
    },


    /* Shop */

    FILTER_ELEMENTS : {
        price_min : "Price min",
        price_max : "Price max",
        distance_title : "Distance ({{radius}}km)",
        show_filter : "Show filter",
        hide_filter : "Hide filter",

        sort_by_options : [
            { value : "DATE_RECENT", text : "Most recent" },
            { value : "DATE_OLDEST", text : "Oldest" },
            { value : "PRICE_MAX", text : "Most expensive"},
            { value : "PRICE_MIN", text : "Cheapest" },
        ]
    },

    SHOP : {
        not_defined : "Unknown",
        try_again : "Try again",

        dropdown_categories : [
            {
                title : "None",
                items : [{ text : "None", value : "NONE" }]
            },
            {
                title : "Home",
                items : [
                    { text : "Building & garden", value : "BUILDING_AND_GARDEN" },
                    { text : "Furniture & decor",value : "FURNITURE_AND_INTERIOR" },
                    { text : "Housewares & appliances", value : "APPLIANCES" },
                    { text : "Tools", value : "TOOLS" }
                ]
            },
            {
                title : "Personal",
                items : [
                    { text : "Clothing & shoes", value : "CLOTHES_AND_SHOES" },
                    { text : "Accessories & watches", value : "ACCESSORIES_AND_WATCHES" },
                    { text : "Childrens clothing & shoes", value : "CHILDRENS_CLOTHING_AND_SHOES" },
                    { text : "Childrens articles & toys", value : "CHILDRENS_ARTICLES_AND_TOYS" }
                ]
            },
            {
                title : "Electronics",
                items : [
                    { text : "Computers & games", value : "COMPUTERS_AND_GAMES" },
                    { text : "Sound & picture", value : "SOUND_AND_PICTURE" },
                    { text : "Phones & accessories", value : "PHONES_AND_ACCESSORIES" }
                ]
            },
            {
                title : "Hobby",
                items : [
                    { text : "Events & plessure", value : "EVENTS_AND_PLESSURE" },
                    { text : "Books", value : "BOOKS" },
                    { text : "Bikes", value : "BIKES" },
                    { text : "Hobby & collectibles", value : "HOBBY_AND_COLLECTIBLES" },
                    { text : "Hunting & fishing", value : "HUNTING_FISHING" },
                    { text : "Music equipment", value : "MUSIC_ITEMS" }, 
                    { text : "Sports equipment", value : "SPORTS_ITEMS" },
                ]
            }
        ],



        dropdown_sub_categories : {
            BUILDING_AND_GARDEN : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'WC', value : 'WC' },
                { text : 'Building material', value : 'BUILDING_MATERIAL' },
                { text : 'Fireplace & heaters', value : 'HEATER' },
                { text : 'Kitchen', value : 'KITCHEN' },
                { text : 'Garden machines', value : 'GARDEN_MACHINES' },
                { text : 'Garden & balcony', value : 'GARDEN_AND_BALCONY' }      
            ],
            FURNITURE_AND_INTERIOR : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Antique & art', value : 'ART' },
                { text : 'Lighting', value : 'LIGHT' },
                { text : 'Tables & chairs', value : 'TABLES_AND_CHAIRS' },
                { text : 'Home textiles & decoration', value : 'TEXTILE' },
                { text : 'Shelves & storage', value : 'SHELVES_AND_STORAGE' },
                { text : 'Carpets', value : 'CARPETS' },
                { text : 'Bed & bedroom', value : 'BED_AND_BEDROOM' },
                { text : 'Couch/armchair/living room', value : 'SOFA' },
                { text : 'TV -&stereo furniture', value : 'CARPETS' },  
            ],
            APPLIANCES : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Dishwasher', value : 'DISHWASHER' },
                { text : 'Fridge & freezer', value : 'FRIDGE_FREEZE' },
                { text : 'Kitchen accessories & porcelain', value : 'KITCHEN_EQUIPMENT' },
                { text : 'Stove & micro', value : 'STOVE' },
                { text : 'Washing machine & dryer', value : 'WASHING_MACHINE' }, 
            ],
            ACCESSORIES_AND_WATCHES : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Watches', value : 'WATCHES' },
                { text : 'Jewelry', value : 'JEWLERY' },
                { text : 'Bags', value : 'BAGS' },
            ],
            CHILDRENS_ARTICLES_AND_TOYS : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Childrens furniture', value : 'CHILDRENS_FURNITURE' },
                { text : 'Strollers & accessories', value : 'CHILDRENS_FURNITURE' },
                { text : 'Toys', value : 'TOYS' },
            ],
            COMPUTERS_AND_GAMES : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'PC', value : 'STATIONARY_COMPUTERS' },
                { text : 'Laptops', value : 'LAPTOPS' },
                { text : 'Tablets', value : 'TABLETS' },
                { text : 'Computer Accessories & programs', value : 'COMPUTER_EXTRAS' },
                { text : 'PC games & online games', value : 'PC_GAMES' },
                { text : 'TV-games', value : 'TV_GAMES' },
            ],
            SOUND_AND_PICTURE : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Music', value : 'MUSIC' },
                { text : 'Cameras & video cameras', value : 'PICTURE_VIDEO' },
                { text : 'MP3-players', value : 'MP3_PLAYERS' },
                { text : 'Stereo & surround', value : 'STEREO_AND_SURROUND' },
                { text : 'Video & DVD players', value : 'VIDEO_AND_DVD' },
                { text : 'TV & projector', value : 'TV_AND_PROJECTOR' },
            ],
            PHONES_ACCESSORIES : [
                { text : "Phones", value : "PHONES" },
                { text : "Accessories", value : "ACCESSORIES" }
            ],
            BIKES : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Male', value : 'MALE' },
                { text : 'Female', value : 'FEMALE' },
                { text : 'Children', value : 'CHILDREN' },
                { text : 'Mountainbike', value : 'MOUNTAINBIKE' },
                { text : 'Racing', value : 'RACER' },
                // { text : 'Övriga cyklar', value : 'OTHER_BIKES' },
            ],
            HOBBY_AND_COLLECTIBLES : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Stamps & coins', value : 'STAMPS_AND_COINS' },
                { text : 'Hobby vehicles', value : 'HOBBY_VEHICLES' },
                { text : 'RC & model', value : 'RADIO_DRIVEN' },
                { text : 'Comic books', value : 'COMIC_BOOKS' },
                { text : 'Sewing machine & textile', value : 'SEWING_MACHINES_AND_TEXTILE' },
                { text : 'Historical items', value : 'HISTORICAL_ITEMS' },
            ],
            HUNTING_FISHING : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Hunting', value : 'HUNTING' },
                { text : 'Fishing', value : 'FISHING' },
            ],
            MUSIC_EQUIPMENT : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Guitar/base/amplifiers', value : 'STRING_INSTRUMENTS' },
                { text : 'Piano & keyboard', value : 'PIANO' },
                { text : 'Wind instruments', value : 'HORN' },
                { text : 'Drums & percussion', value : 'DRUMS' },
                { text : 'Studio & scene equipment', value : 'STUDIO_EQUIPMENT' },
            ],
            SPORTS_ITEMS : [
                { text : 'Others', value : 'OTHERS' },
                { text : 'Ball sports', value : 'BALL_SPORTS' },
                { text : 'Camping & outdoors', value : 'CAMPING' },
                { text : 'Diving & swimming', value : 'SWIMMING' },
                { text : 'Golf', value : 'GOLF' },
                { text : 'Training & health', value : 'TRAINING_HEALTH' },
                { text : 'Winter sports', value : 'WINTER_SPORTS' },
            ]
        },

        
        
        sales_type_dropdown : [
            { text : "Piece", value : "PIECE" },
            { text : "Bundle", value : "BUNDLE" },
        ]
    },

    SHOP_LANDING : {
        title : "Buy / Sell",
        recently_added : "Recently added",
        no_more_results : "No more results",
        no_items_found : "No items could be found",
        looking_for : "What are you looking for?",
        reset_query : "Reset query",
    },

    ARTICLE_PAGE : {
        items_for_sale_in_the_same_box : "Items for sale in the same box",
        a_piece : "piece",
        pieces : "pieces",
        in_stock : "In stock"
    },

    SHOP_FORM : {
        search_within : "Search within {{distance}} {{unit}}"
    },

    /* Physical */
    LABEL : {
        title : "Title",
        description : "Description",
        box_number : "Box number"
    },


    /* COOKIE_CONCENT */
    COOKIE_CONSENT : {
        cookie_complianse : "Cookie compliance",
        we_use : "We use cookies",
        text : "We use cookies to optimize your experience and show you relevant information. To find out more read our ",
        
        profile_text : "When you created your account you accepted the privacy policy and cookie policy of Qboxr. This gives us and our partners the abilirt to optimize and personalize the page for you. You can take back that admission here. Note that this will result in your account being locked as the site can not operate without cookies. You will still be depited for the full billing cycle of your subscription. Would you like to retake your admission?",
        remove_complianse : "Remove",
        
        not_accept : "I do not accept",
        cookie_policy : "Cookie policy",
        how_we_use_it : "Hur we use your data"
    },
}