import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { TextField, Tooltip, Button, FormControl, InputLabel, Select, MenuItem, DialogContentText, Typography, withWidth } from '@material-ui/core';
import { FaUser, FaUserCircle, FaMapMarked, FaLocationArrow, FaRedoAlt, FaRedo, FaCog } from 'react-icons/fa';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';

import TuneIcon from "@material-ui/icons/Tune";
import theme from '../../../assets/styles/theme';
import { try_to_set_location } from '../../../logic/init_logic';
import { store } from '../../../store';
import Transition from '../../dialogs/Transition';
import DialogBase from '../../dialogs/DialogBase';
import { set_form_value } from '../../../actions/shop_form_actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 450,
    padding : 5
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    paddingRight : 5
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    display: 'flex',
    flexDirection : "column",
    // justifyContent : "center",
    justifyContent : "flex-start",
    // flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
  },
}));

function ShopField({params, history, style, fetch_articles, query_string}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [radius, setRadius] = React.useState( store.getState().shop_form.radius );
  const [filter_type, setFilterType] = React.useState( store.getState().shop_form.filter_type );


  const handleClickOpen = () => { setOpen(true); };

  const handleClose = () => { setOpen(false);  };

  const save_settings = async() => { // Not sure if we have to await but do as of now
    await store.dispatch( set_form_value('radius', radius) );
    await store.dispatch( set_form_value('filter_type', filter_type) );
    fetch_articles(0);
    handleClose();
  }

  return (
    <Paper component="form" className={classes.root} style = {{ ...style }}>

      <TextField
        className={classes.input}
        placeholder = { t('SHOP_LANDING:looking_for') }
        style = {{ borderColor : "white" }}
        {...params}
      /> 

      {
        query_string.length > 0 ? 
        <Button style = {{ backgroundColor : theme.PRIMARY_COLOR, color : "white", margin : 8 }} onClick = { fetch_articles }>
          {t('G:search')}
        </Button> : null
      }
      
      <Divider className={classes.divider} orientation="vertical" />
      
      {/* <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick = {  () => history.push('/app/profile') }>
        <FaLocationArrow style = {{ fontSize : 21 }} />
      </IconButton> */}

      <div>
      <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={handleClickOpen}>
        <FaCog style = {{ fontSize : 21 }} />
      </IconButton>
      
      <DialogBase open_status = { open } close_modal = { handleClose } title = { t('G:settings') }>
        
        <DialogContent className={classes.container}>
          <DialogContentText>{ t('SHOP_AUTOCOMPLETE:filter_description') }</DialogContentText>
            
            <FormControl className={classes.formControl}>
              <InputLabel id="radius_label">{t('SHOP_AUTOCOMPLETE:search_radius')}</InputLabel>
              <Select labelId="radius_label" id="radius_select" value = { radius } input={<Input />}
                onChange={ (e) => setRadius(e.target.value) }
              >
                <MenuItem value={10}>10km</MenuItem>
                <MenuItem value={20}>20km</MenuItem>
                <MenuItem value={30}>30km</MenuItem>
                <MenuItem value={40}>40km</MenuItem>
                <MenuItem value={50}>50km</MenuItem>
                <MenuItem value={60}>60km</MenuItem>
                <MenuItem value={70}>70km</MenuItem>
                <MenuItem value={80}>80km</MenuItem>
                <MenuItem value={90}>90km</MenuItem>
                <MenuItem value={100}>100km</MenuItem>
                <MenuItem value={150}>150km</MenuItem>
                <MenuItem value={200}>200km</MenuItem>
                <MenuItem value={250}>250km</MenuItem>
                <MenuItem value={500}>500km</MenuItem>
                <MenuItem value={1000}>1000km</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">{t('SHOP_AUTOCOMPLETE:sort_by')}</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={ filter_type }
                onChange={ (e) => setFilterType(e.target.value) }
                input={<Input />}
              >
                {
                  t('FILTER_ELEMENTS:sort_by_options', { returnObjects : true }).map( (obj) => (
                    <MenuItem value={obj.value}>{obj.text}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            
            <Button variant = "contained" color="primary" aria-label="directions" onClick={ try_to_set_location } style = {{ marginTop : 10, padding : 10 }}>
              { t('SHOP_AUTOCOMPLETE:update_position') }
              <FaLocationArrow style = {{ fontSize : 21, marginLeft : 10 }} />
            </Button>
          </DialogContent>

          <DialogActions style = {{ alignSelf : "flex-end" }}>
              <Button variant="contained" color="default" className={classes.button} onClick = { handleClose }>
                { t('G:back') }
              </Button>
              <Button variant="contained" color="default" className={classes.button} onClick = { save_settings }>
                { t('G:save') }
              </Button>
          </DialogActions>

          </DialogBase>
    </div>
    </Paper>
  );
}

export default withWidth()(
  withRouter( ShopField ))