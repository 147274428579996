import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';


import TextField from '@material-ui/core/TextField';
import UpdateIcon from "@material-ui/icons/Update"
import ip from "../../constants/ip";

import custom_theme from "../../assets/styles/theme";
import { Typography } from "@material-ui/core";
import TextFormModal from "./TextFormModal";
import { withTranslation } from "react-i18next";

import { withAlert } from 'react-alert'

class ChangePasswordDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            password : '',
        }
    }
    
    submit_form = async() => {
        const { alert, t } = this.props;
      
        if(this.state.password === ""){
            alert.error('ERRORS:FORM_INCOMPLETE');
            return;
        }
        this.props.on_click(this.state.password);
    }


    set_value = (e) => {
        this.setState({[e.target.name] : e.target.value });
    } 

    render(){
        const {t} = this.props;
        
        this.fields = [
            {
                label : t('AUTHENTICATION:confirm_password'),
                placeholder : t('AUTHENTICATION:your_current_password'),
                password : true,
                value : this.state.old_password,
                name  : "password", 
                set_value : (e) => { this.set_value(e) }
            },
        ]
        return(
            <TextFormModal
                open_status = { this.props.open_status }
                set_open_status = { this.props.close_modal }
                fields = { this.fields }
                title = { t('AUTHENTICATION:confirm_password') }
                text = { t('AUTHENTICATION:confirm_password_explanation') }
                button_text = { t('G:confirm') }
                error_messages = { this.state.error_messages }
                on_click = { this.submit_form }
            />
        )
    }
}

export default (
    // withStyles(styles)(
        withTranslation()(
            withAlert()(
                ChangePasswordDialog
            )
        )
    // )
);