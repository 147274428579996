import styled from "styled-components";

export const PublicButtonContainer = styled.div`
    display : flex;
    flex-direction : row;
    width : 100%;
    flex-grow : 1;
    margin-top : 3em;
    margin-bottom : 3em;
`;
