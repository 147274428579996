import React from "react";
import { withTranslation } from "react-i18next";

class ChatFileSelector extends React.Component{

    constructor(props){
        super(props);
        this.file_ref = React.createRef();
    }

    handle_file_select = () => {
        const { active_conversation, t } = this.props;
        // let file = document.getElementById('chat_input_file_selector').files[0];
        // send_message(
        //     active_conversation.item_id, 
        //     {  }, 
        //     t
        // );
    }
    
    render(){
        return(
            <input 
                type="file"
                id="chat_input_file_selector" 
                style={{display: "none"}} 
                onChange = { (e) => console.log(e.target.value) /*this.handle_file_select*/ }
                ref={(el) => { this.file_ref = el; }}
            /> 
        )
    }
}

export default (
    withTranslation()(
        ChatFileSelector
    )
);