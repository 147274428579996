import React from "react";
import { Typography, Link, Container, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles( theme => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6, 0),
    },
    footer_link_container : {
        textAlign : "center",
        margin : theme.spacing(1, 0)  
    },
    footer_link : {
        padding : theme.spacing(3),
        textDecoration : "none"
    }
}));

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Qboxr
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export function Footer(){
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    return(
        <footer className={classes.footer}>
        <Container maxWidth="lg">
            <Typography variant="h6" align="center" gutterBottom>
            Qboxr
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            { t('G:slogan') + "!"}
            </Typography>

            <div className = { classes.footer_link_container }>
                <RouterLink to = "/contact">
                    <Link className = { classes.footer_link }>{t('G:contact')}</Link>
                </RouterLink>
                <RouterLink to = "/product">
                    <Link className = { classes.footer_link }>{ t('P_G:get_app') }</Link>
                </RouterLink>
                <RouterLink to = "/FAQ">
                    <Link className = { classes.footer_link }>FAQ</Link>
                </RouterLink>
            </div>

            <div>
                <Copyright />
            </div>
        </Container>
        </footer>
    )
}