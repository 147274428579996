import { makeStyles, useTheme } from "@material-ui/core";
import Lottie from 'react-lottie';
import React from "react";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};

export function ImageAndText(props){
    const theme = useTheme();
    const classes = useStyles(theme);

    if(props.text_only){
        return(
            <div>
                <h1 className = { `${classes.title} ${props.white ? classes.white : ""}`}>{ props.title }</h1>
                <p className  = { `${classes.text} ${props.white ? classes.white : ""}`}> { props.text } </p>
                { props.buttons }
            </div>
        )
    }

    return(
        <div className = { `${classes.container} ${props.odd ? classes.reversed_container : ""}`} >
            <div className = { `${classes.text_container} ${props.odd ? classes.reversed_text_container : ""}` }>
                <h1 className = {classes.title}> { props.title } </h1>

                <p className = {classes.text}> { props.text } </p>
                { props.buttons }
            </div>

            { 
            props.animation_data || props.component ?
            <div className = {`${classes.image_container} ${props.hide_on_small ? classes.hide_on_small : ""}`}>
                {
                    props.animation_data ? 
                    <Lottie
                        options={{...defaultOptions, animationData : props.animation_data}}
                        height= "100%"
                        width = "100%"
                    />
                    :
                    props.component
                }
            </div>
            : null
            }
        </div>
    )
}

const useStyles = makeStyles( theme => ({
    container : {
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "center",
        padding : theme.spacing(4),
        paddingBottom : 0,
        
        [theme.breakpoints.up('sm')]: {
            padding : theme.spacing(8),
            padding : 50,
            flexDirection : "row",
            justifyContent : "space-between"
        },
    },
    text_container : {
        [theme.breakpoints.up('sm')]: {
            flex : 1.5,
            paddingRight : theme.spacing(2),
            paddingLeft : 0,
        },
    },
    reversed_text_container : {
        [theme.breakpoints.up('sm')]: {
            display : "flex",
            flexDirection : "column",
            textAlign : "center",
            paddingRight : 0,
            paddingLeft : theme.spacing(2)
        }
    },
    title : {
        fontSize : "2.5em",
        [theme.breakpoints.up('sm')]: {
            fontSize : "3em"
        },
        [theme.breakpoints.up('md')]: {
            fontSize : "4.5em"
        },
    },
    text : {
        fontSize : "1.3em",
    },
    image_container : {
        display : "flex",
        flex : 1,
        alignItems : "center",
        justifyContent : "center",
        width: "100%"
    },
    reversed_container : {
        [theme.breakpoints.up('sm')]: {
            flexDirection : "row-reverse"
        },
    },
    hide_on_small : {
        [theme.breakpoints.down('sm')]: {
            display : "none"
        },
    },
    white : {
        color : "white !important"
    }

    
}));