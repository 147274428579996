import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps"

import QboxrSlider from "./QboxrSlider";

import MapIcon from '@material-ui/icons/Map';
import theme from '../../../assets/styles/theme';
import { connect } from "react-redux";
import { set_form_value } from "../../../actions/shop_form_actions";
import { bindActionCreators } from "redux";
import { store } from "../../../store";
import { set_global_reducer_value } from "../../../actions/global_actions";

import { withAlert } from 'react-alert'



class LocationSelector extends React.PureComponent{
    constructor(props){
      super(props);
      this.state = {
        loading : false,
      }
      this.map = React.createRef();
      this.circle = React.createRef();
      this.idleCalled = false;
    }
  
    async componentWillMount(){
      const { alert } = this.props;
      navigator.geolocation.getCurrentPosition(
        function(position) {
          let redux_location_obj = {
            lat : position.coords.latitude,
            lng : position.coords.longitude
          }
          store.dispatch(set_global_reducer_value('user_location', redux_location_obj));
        },
        function errorCallback(error) {
          // alert.info(error);
          console.log(error);
        },
        {
            maximumAge:Infinity,
             timeout:5000
        }
      );
    }
  
    handle_radius_change = (val) => {
      this.props.set_radius(val) //Since we set value in QboxrSlider
      const bounds = this.circle.current.getBounds();
      this.map.current.fitBounds(bounds);
    }
  
    render(){
      const { t } = this.props;
    
      if(!this.props.user_location){ return null }

      const GoogleMapInstance = withGoogleMap(props => (
        <GoogleMap
          ref = { this.map }
          defaultZoom={9}
          defaultCenter = { this.props.user_location }
        >
          <>
            <Marker
              position = {  this.props.user_location }
            />
              <Circle
                ref = { this.circle }
                defaultCenter={  this.props.user_location }
                radius={ this.props.radius * 1000}
                options={{
                  strokeColor: theme.PRIMARY_COLOR
                }}
              />
          </>
        </GoogleMap>
      ));
  
      return(
        <div>
  
          <div>
            <div style = {{display : "flex"}}>
              <MapIcon color = "primary"/>
              <h3 style = {{margin : 0, marginLeft : 10}}>{t('SHOP_FORM:search_within', { distance : this.props.radius, unit : "km" })}</h3>
            </div>
  
            <QboxrSlider
              value = { this.props.radius } 
              set_value = { (val) => {
                store.dispatch( set_form_value ('radius', val) ); //Normal dispatch did not want to work
              }}
            />
          </div>
          <div style = {{borderRadius : 10, overflow : "hidden", zIndex : 10000, marginTop : 10}}>
            <GoogleMapInstance
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement = {<div style={{ height: `35vh`, borderRadius : 20}} />}
              mapElement={<div style={{ height: `100%`, }} />}
            />  
          </div>
        </div>
      )
    }
  }

  const mapStateToProps = (state , ownProps) => {
    return {
      user_location : state.global.user_location,
      radius  : state.shop_form.radius,
    }
  }

  const mapDispatchToProps = dispatch => bindActionCreators(
    {
      set_form_value
    },
    dispatch,
  )

export default(
    connect(mapStateToProps, mapDispatchToProps)(
      withAlert()(
        LocationSelector
      )
    )
)