const iso_currency_name = {
    usd : '$',
    eur : '€',
    sek : 'kr'
}

const iso_price = {
    usd : {
        Basic : 2,
        Pro : 3,
        Master : 4,
        Diamond : 5,
    },
    eur : {
        Basic : 2,
        Pro : 3,
        Master : 4,
        Diamond : 5,
    },
    sek : {
        Basic : 20,
        Pro : 25,
        Master : 40,
        Diamond : 50,
    }
}

let u_save = {};

const tiers = (iso, is_year) => {
    let currency_sign = iso_currency_name[iso];
    let direction = (iso === "usd") ? "LEFT" : "RIGHT";
    let interval;
    if(!is_year){
        interval = (iso === 'usd' || iso === 'eur') ? "mo" : "mån";
    }else{
        interval = (iso === 'usd' || iso === 'eur') ? "year" : "år"
    }
    let interval_code = (is_year) ? "year" : "month";
    
    let prices = iso_price[iso];
    let instance = JSON.parse(JSON.stringify(prices));

    if(is_year){
        for(var key in prices){
            let year_price  = Math.floor(prices[key] * 12 * 0.8);
            instance[ key ] = year_price;
            u_save[key] = ( prices[key] * 12 - year_price )
        }
        prices = instance;
    }

    return [
    {
        title: 'Basic',
        subheader: 'Vårat enklaste paket',
        price: prices['Basic'],
        description: [
            '10 lådor inkluderat',
            'Upp till 100 föremål/box',
            'Genererade etiketter',
            'Sökfunktionalitet',
            'Prioriterad emailsupport',
        ],
        buttonText: 'Köp',
        buttonVariant: 'contained',
        currency_orientation : direction,
        currency : currency_sign,
        iso_code : iso,
        interval : interval,
        interval_code : interval_code,
        sub_name : "PRO",
        description_text : "Detta är ett abonnemang som ger dig möjligheten att registrera 10 lådor hos Qboxr",
        tier_level : 1,
        u_save : u_save['Basic']
    },
    {
        title: 'Pro',
        subheader: 'Most popular',
        price: prices['Pro'],
        description: [
            '20 lådor inkluderat',
            'Upp till 100 föremål/box',
            'Genererade etiketter',
            'Sökfunktionalitet',
            'Prioriterad emailsupport',
        ],
        buttonText: 'Köp',
        buttonVariant: 'contained',
        currency_orientation : direction,
        currency : currency_sign,
        iso_code : iso,
        interval : interval,
        interval_code : interval_code,
        sub_name : "PRO",
        description_text : "Detta är ett abonnemang som ger dig möjligheten att registrera 10 lådor hos Qboxr",
        tier_level : 2,
        u_save : u_save['Pro']
    },
    {
        title: 'Master',
        subheader: 'För dig med ett stort förråd',
        price: prices['Master'],
        description: [
            '40 lådor inkluderat',
            'Upp till 100 föremål/box',
            'Genererade etiketter',
            'Sökfunktionalitet',
            'Prioriterad emailsupport',
        ],
        buttonText: 'Köp',
        buttonVariant: 'contained',
        currency_orientation : direction,
        currency : currency_sign,
        iso_code : iso,
        interval : interval,
        interval_code : interval_code,
        sub_name : "ENTERPRISE",
        tier_level : 3,
        u_save : u_save['Master']
    },
    {
        title: 'Diamond',
        subheader: 'Vårat största paket',
        price: prices['Diamond'],
        description: [
            '80 lådor inkluderat',
            'Upp till 100 föremål/box',
            'Genererade etiketter',
            'Sökfunktionalitet',
            'Prioriterad emailsupport',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        currency_orientation : direction,
        currency : currency_sign,
        iso_code : iso,
        interval : interval,
        interval_code : interval_code,
        sub_name : "ENTERPRISE",
        tier_level : 4,
        u_save : u_save['Diamond']
    }
   ]
//    eur : [
//     {
//         title: 'Gratis',
//         price: '0',
//         description: ['3 lådor inkluderat', 'Upp till 20 föremål/box', 'Genererade etiketter', 'Sökfunktionalitet' , 'Email support'],
//         buttonText: 'Sign up for free',
//         buttonVariant: 'outlined',
//         currency_orientation : "RIGHT",
//         currency : "€",
//         iso_code : "eur",
//         month : "mo",
//         sub_name : "FREE",
//         description_text : "Gratistjänst för dig som testa på tjänsten under en trialperiod"
//     },
//     {
//         title: 'Pro',
//         subheader: 'Most popular',
//         price: '2,5',
//         description: [
//         '10 lådor inkluderat',
//         'Upp till 100 föremål/box',
//         'Genererade etiketter',
//         'Sökfunktionalitet',
//         'Prioriterad emailsupport',
//         ],
//         buttonText: 'Get started',
//         buttonVariant: 'contained',
//         currency_orientation : "RIGHT",
//         currency : "€",
//         iso_code : "eur",
//         month : "mo",
//         sub_name : "PRO",
//         description_text : "Detta är ett abonnemang som ger dig möjligheten att registrera 10 lådor hos Qboxr"
//     },
//     {
//         title: 'Företag',
//         price: '15-*',
//         description: [
//         'Skräddarsydd betalningsplan',
//         'Inventeringsverktyg',
//         "API-Nyckel till Qboxr's lagerstatustjänst",
//         'Telefon & Email support',
//         ],
//         buttonText: 'Contact us',
//         buttonVariant: 'outlined',
//         currency_orientation : "RIGHT",
//         currency : "€",
//         iso_code : "eur",
//         month : "mo",
//         sub_name : "ENTERPRISE"
//     }
//     ],
//     sek : [
//         {
//             title: 'Gratis',
//             price: '0',
//             description: ['3 lådor inkluderat', 'Upp till 20 föremål/box', 'Genererade etiketter', 'Sökfunktionalitet' , 'Email support'],
//             buttonText: 'Sign up for free',
//             buttonVariant: 'outlined',
//             currency_orientation : "RIGHT",
//             currency : "kr",
//             iso_code : "sek",
//             month : "mån",
//             sub_name : "FREE",
//             description_text : "Gratistjänst för dig som testa på tjänsten under en trialperiod"
//         },
//         {
//             title: 'Pro',
//             subheader: 'Most popular',
//             price: '20',
//             description: [
//             '10 lådor inkluderat',
//             'Upp till 100 föremål/box',
//             'Genererade etiketter',
//             'Sökfunktionalitet',
//             'Prioriterad emailsupport',
//             ],
//             buttonText: 'Get started',
//             buttonVariant: 'contained',
//             currency_orientation : "RIGHT",
//             currency : "kr",
//             iso_code : "sek",
//             month : "mån",
//             sub_name : "PRO",
//             description_text : "Detta är ett abonnemang som ger dig möjligheten att registrera 10 lådor hos Qboxr"
//         },
//         {
//             title: 'Företag',
//             price: '150-*',
//             description: [
//             'Skräddarsydd betalningsplan',
//             'Inventeringsverktyg',
//             "API-Nyckel till Qboxr's lagerstatustjänst",
//             'Telefon & Email support',
//             ],
//             buttonText: 'Contact us',
//             buttonVariant: 'outlined',
//             currency_orientation : "RIGHT",
//             currency : "kr",
//             iso_code : "sek",
//             month : "mån",
//             sub_name : "ENTERPRISE"
//         }
//     ]

};

export default tiers;