import React from "react";
import { Grid, useMediaQuery, useTheme, withStyles } from "@material-ui/core";

import Lottie from 'react-lottie';
import { withTranslation } from "react-i18next";
import { ImageAndText } from "./ImageAndText";
import styled from "styled-components";
import { CustomCardActionArea } from "./CustomCardActionArrow";
import public_styles from "../../assets/styles/public_styles";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
};

const HeaderPart = (props) => {
        const {classes, t} = props;
        const matTheme = useTheme();
        const greaterThanMid = useMediaQuery(matTheme.breakpoints.up("md"));

        return(
            <HeaderContainer matTheme={matTheme}>
                <TextContainer matTheme={matTheme} item sm={12} md={6}>
                    <ImageAndText
                        text_only
                        title={props.title}
                        text={props.text}
                        buttons = {
                            <HeaderButtonContainer matTheme={matTheme}>
                                <CustomCardActionArea matTheme={matTheme} style={{ flex: 1 }}>
                                    <img alt = "App store" src = "img/appstore_logo.png" className = {classes.brand_icon}/>
                                </CustomCardActionArea>

                                <CustomCardActionArea matTheme={matTheme} style = {{flex: 1, marginLeft : 30}}>
                                    <img alt = "Android" src = "img/android_logo.png" className = {classes.brand_icon}/>
                                </CustomCardActionArea>
                            </HeaderButtonContainer>
                        }
                        white
                    />
                </TextContainer>
                
                <LottieContainer item md = {12} matTheme={matTheme}>
                    <OpacityAwareLottie
                        matTheme={matTheme}
                        options={{
                            ...defaultOptions,
                            animationData : (props.animation_data) ? props.animation_data : defaultOptions.animationData
                        }}
                        width  = {"100%"}
                        height = {"100%"}
                        style={{opacity: greaterThanMid ? 1 : 0.55}}
                    />
                </LottieContainer>
            </HeaderContainer>
        )
}

const OpacityAwareLottie = styled(Lottie)`
    opacity: 0.6;
    ${(props) => props.matTheme.breakpoints.up('md')} {
        opacity: 1;
    }
`;

const HeaderContainer = styled.div`
    position : relative;
    display : flex;

    width : 100%;
    height : 100vh;
    
    background-image: url('/img/banner_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow : hidden !important;

    ${(props) => props.matTheme.breakpoints.up('md')} {
        flex-direction : row;
        width : 120%;
        box-sizing : border-box;
        padding-left : 20%;
        margin-left : -20%;
        height: 125vh;
        padding-top : 20%;
        margin-top : -20%;
        align-items : center;
    }
`;

const LottieContainer = styled(Grid)`
    position : absolute;
    right : 0;
    top : 0;   
    z-index : 0;

    height : 100%;
    width : 100%;    

    ${(props) => props.matTheme.breakpoints.up('md')} {
        position: static;
        z-index: 1;
        flex: 1;
    }
`;

const HeaderButtonContainer = styled(Grid)`
    display: flex;
    margin-top: 1em;
    margin-bottom: 1em;

    ${(props) => props.matTheme.breakpoints.down('md')} {
        width: 80%;
    }
`;

const TextContainer = styled(Grid)`
    display : flex;
    flex-direction : column;
    justify-content : center;
    padding : 0 20px;
    z-index : 1;

    ${(props) => props.matTheme.breakpoints.up('md')} {
        padding : 0 50px;
    }
`;

export default withStyles(public_styles)(withTranslation()(HeaderPart));