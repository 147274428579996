import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ip from '../../constants/ip';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default class AsyncAuto extends React.PureComponent{
    constructor(props){
        super(props);
        this.state = {
            loading : false,
            options : []
        }
    }

    load = async(value) => {
        this.setState({ loading : true });
        const response = await fetch(ip + "/storager/get_requests/autocompletes/get_my_categories.php",{
            method : "POST",
            body : JSON.stringify({ 
                jwt : localStorage.getItem('jwt'),
                filter : value
            })
        });
        const json = await response.json();
        this.setState({ options : json.categories, loading : false })
    }

    render(){
    return (
        <Autocomplete
            id="asynchronous-demo"
            open={this.state.open}
            onOpen={() => { 
                this.setState({open : true});
                this.load(this.props.value);
            }}
            onClose={() => { this.setState({open : false}); }}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={option => option}
            options={this.state.options}
            loading={this.state.loading}
            onChange = { (e, value) => {
                this.props.set_value(value);
            }}
            renderInput={params => (
            <TextField
                {...params}
                label = { this.props.label }
                variant="outlined"
                style = {{ width : "100%" }}
                value = { this.props.value }
                onChange = { (e) => {
                    this.props.set_value(e.target.value);
                    this.load(e.target.value);
                }}
                InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                    {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
                }}
            />
            )}
            {...this.props}
        //   style={{ ...props.style }}
        />
    );
    }
}