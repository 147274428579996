import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/styles';


import TextField from '@material-ui/core/TextField';
import UpdateIcon from "@material-ui/icons/Update"
import ip from "../../constants/ip";

import custom_theme from "../../assets/styles/theme";
import { Typography, withWidth } from "@material-ui/core";

import Transition from "./Transition"
import { withTranslation } from "react-i18next";

const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    error_text : {
        color : custom_theme.RED_COLOR,
        fontSize : "0.7em"
    }
});


class TextFormModal extends React.Component{
    constructor(props){
        super(props);
    }

    toggle = () => {
        this.props.set_open_status( !this.props.open_status );
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.props.on_click();
        }
    }

    render(){
        const {classes, t} = this.props;

        const full_screen = this.props.width === "xs";

        return(
            <Dialog
                open={this.props.open_status}
                onClose={ this.props.handle_clode ? this.props.handle_clode : this.toggle }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent = { Transition }
                fullScreen = { full_screen }
            >
                <DialogTitle id="alert-dialog-title" style = {{paddingTop : 30}}> { this.props.title } </DialogTitle>
                
                <DialogContent>

                {
                    this.props.text ? 
                    <DialogContentText> { this.props.text } </DialogContentText> : null
                }
                
                {
                    this.props.fields.map( tf => {
                        return (
                                <TextField
                                    variant = "outlined"
                                    label = { tf.label }
                                    type  = { tf.password  ? "password" : undefined }
                                    style = {{width : "100%"}}
                                    value = { tf.value }
                                    name  = { tf.name }
                                    onChange = { (e) => tf.set_value(e) }
                                    margin="normal"
                                    placeholder = { tf.placeholder }
                                    onKeyDown = { this.handleKeyDown }

                                    multiline={tf.useTextArea}
                                    minRows={tf.useTextArea ? 2 : undefined}
                                    maxRows={tf.useTextArea ?  6 : undefined}
                            />
                        )
                    })
                }
                </DialogContent>
                
                <DialogActions>
                <Button onClick = {this.toggle} variant="contained" color="default" className={classes.button}>
                    { t('G:back') }
                </Button>
                
                <Button onClick = {this.props.on_click} variant="contained" color="default" className={classes.button}>
                    { this.props.button_text }
                    <div> { this.props.icon } </div>
                </Button>
                
                </DialogActions>
            </Dialog>
        )
    }
}

export default (
    withStyles(styles)(
        withWidth()(
            withTranslation()(
                TextFormModal
            )
        )
    )
);