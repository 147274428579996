import React from "react";
import MaterialTable from "material-table";

import { format_date } from "../../../../functions/date_functions";
import Expand from "./Expand";
import { Typography } from "@material-ui/core";

class ItemTable extends React.PureComponent{
    constructor(props){
      super(props);
    }
    render(){
    
    const { data, t, alert } = this.props;
    return (
      <MaterialTable
        columns={[
          { title: t('BOX_PAGE:date_added'), field: 'date_str', 
            customSort : (a,b) => {
              return b.item_id - a.item_id
            }
          },
          { title: t('BOX_PAGE:name'), field: 'title' },
          { title: t('BOX_PAGE:category'), field: 'category' },
          {
            title: t('BOX_PAGE:sales_status'),
            field : 'is_for_sale_text',
          },
        ]}
        pageSize = { data.length <= 10 ? data.length : 10 } // Fix for later this line does not work. Make doo with the init 5 as off now
        data={
          data.map( item => ({
            ...item,
            is_for_sale_text : item.is_for_sale ? t('MY_SHOP:for_sale') : t('MY_SHOP:not_for_sale'),
            date_str : format_date(item.submit_date, t)
          })
          )
        }
        title = { t('G:content') }
        detailPanel={ rowData => {
          return ( <Expand  item = { rowData } t = {t} alert = {alert}/> )
        }}
        options = {{
          sorting : true
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        localization = {{
          body : {
            emptyDataSourceMessage : t('BOX_PAGE:no_items_in_box')
          }
        }}
      />
    )
  }
}

export default ItemTable;