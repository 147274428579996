import React from "react";
import { withStyles, withWidth } from "@material-ui/core";

import Lottie from 'react-lottie';
import Navbar from "../../components/public/Navbar.js";

import HeaderPart from "../../components/public/HeaderPart";

import ReactPlayer from 'react-player'

import public_styles from "../../assets/styles/public_styles";
import { withTranslation } from "react-i18next";
import AppFrame from "../../components/AppFrame.js";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};


class FourOFour extends React.Component{
    componentDidMount(){
        const { t } = this.props;
        window.scrollTo(0, 0);
        document.title = t('TITLES:dashboard')
    }

    render(){
        const {classes, t} = this.props;
        const is_mobile = this.props.width === 'xs' || this.props.width === 'sm';
        
        return(
            <AppFrame no_spacing>
                <div className = {classes.container}>
                    <HeaderPart
                        styles = {styles}
                        animation_data = {require("../../assets/animations/error_404.json")}
                        width  = {700}
                        height = {700}
                        title  = { "Oops!" }
                        text   = { t('ERRORS:ERROR_404') }
                        history= {this.props.history}
                    />
                </div>
            </AppFrame>
        )
    }
}

const styles = (theme) => ({
    ...public_styles(theme)
});

export default withStyles(styles)(withWidth()( withTranslation(['A_U'])(FourOFour)));