import React, { PureComponent } from 'react';

import { connect } from 'react-redux'

import IconButton from "@material-ui/core/IconButton/IconButton";

import { withTranslation } from 'react-i18next';


import { withAlert } from 'react-alert'

import ip from "../../constants/ip"
import { Avatar,Tooltip, ListItemIcon, TextField, Badge, Hidden, makeStyles, withStyles} from '@material-ui/core';

import AttatchFileIcon from "@material-ui/icons/AttachFile"
import { set_conversations, sort_conversations, set_messages, set_conversation_id} from '../../actions/chat_actions';
import { bindActionCreators } from 'redux';

import { Chatbubble } from './ChatBubble';
import { send_message, send_files, get_receiver, load_messages } from '../../logic/chat_logic';

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import MessageFeedPart from './MessageFeedPart';
import ChatFileSelector from './ChatFileSelector';
import { deep_message_sort } from '../../functions/sorting_functions';


const styles = ( theme ) => ({
    conversation_header : {
        display : "flex", 
        backgroundColor : "white",
        alignItems : "center", 
        borderBottom : "0.5px solid lightgray",
        padding : "30px 0",
        [theme.breakpoints.up('sm')]: {
            padding : "30px",
        },
    },
    avatar : {
        [theme.breakpoints.down('xs')]: {
            padding : "0",
        },
    }
})

class ChatInputPart extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
            chat_input : '',
            file : undefined
        }
        this.file_ref = React.createRef();
    }
    
    handle_key_down = (e) => {
        const { active_conversation, t } = this.props;
        if (e.key === 'Enter') {
            if(send_message( 
                active_conversation.item_id, 
                { text : this.state.chat_input },
                t
            )){
                this.setState({chat_input : ""});
            }
        }
    }

    handle_file_select = (file_list) => {
        const { active_conversation, t } = this.props;
        send_message(
            active_conversation.item_id, 
            {  }, 
            t
        );
    }
    onChangeHandler = (event) => {
        const { active_conversation, t } = this.props;
        let file_list = event.target.files;
        send_files(active_conversation.item_id, file_list, t);
    }

    render(){
        return(
            <div style = {{paddingLeft : 20, paddingRight : 20, height : "100%", display : "flex", alignItems : "center", justifyContent : "center"}}>
                <TextField
                    placeholder = "Write a message..."
                    variant = "outlined"
                    style = {{flex : 10}}
                    value = { this.state.chat_input }
                    onChange = { (e) => this.setState({ chat_input : e.target.value }) }
                    onKeyDown = { this.handle_key_down }
                />
                <div style = {{flex : 1, display : "flex", justifyContent : "center", alignItems : "center"}}>
                    <IconButton onClick = { () => this.file_ref.click() }>
                        <AttatchFileIcon/>
                    </IconButton>
                </div>
                

                <input 
                    type="file"
                    id="chat_input_file_selector" 
                    style={{display: "none"}} 
                    onChange={ this.onChangeHandler }
                    ref={(el) => { this.file_ref = el; }}
                /> 
                
            </div>
        )
    }
}


class MessageFeed extends React.Component{
    constructor(props){
        super(props);
        this.state = { 
        }
    }
    
    async componentWillMount(){
        const { active_conversation, alert } = this.props;
    }

    scrollToBottom = () => {
        setTimeout(() => { //The 25 millisecond delay is because the imgaes takes some time to render
            this.messages_end.scrollIntoView({ behavior: "smooth" });
        }, 25);
    }

    componentWillReceiveProps(nextprops){
        const { active_conversation, alert } = this.props;
        if(!this.props.active_conversation && nextprops.active_conversation){
            load_messages(active_conversation, alert);
        }
    }

    componentDidMount() {
        if(this.messages_end){ this.scrollToBottom(); }
    }
    componentDidUpdate() {
        if(this.messages_end){ this.scrollToBottom(); }
    }

    render(){
        const { u_id, active_conversation, t, alert, classes } = this.props;
        
        if( active_conversation === undefined ){ return null; }
        
        const reciever = get_receiver(active_conversation.participants, u_id, t);

        return(
            <div style = {{ display : "flex", flexDirection : "column", height : "100%" }}>
                
                <div style = {{ display : "flex",flexDirection : "column", height : "85%"}}>

                    <div className = { classes.conversation_header }>
                        <Hidden smUp>
                            <ArrowBackIcon color = "primary"  style = {{margin : "0 10px", fontSize : 20}} onClick = { () => this.props.set_clicked_conversation(false) } />
                        </Hidden>
                        <Avatar className = { classes }> { reciever.name[0] } </Avatar>
                        <h2 style = {{padding : 0, margin : 0, marginLeft : 20}}>{active_conversation.title}</h2>
                    </div>
                    
                    <div style = {{padding : 20, flexGrow : 1, overflow : "auto"}}>
                        {
                            deep_message_sort(active_conversation.messages).map( msg => {
                                return (
                                    <Chatbubble 
                                        msg = { msg }
                                        u_id = { u_id }
                                        participants = { active_conversation.participants }
                                    />
                                )
                            })
                        }
                        <div style={{ float:"left", clear: "both", }}
                            ref={(el) => { this.messages_end = el; }}>
                        </div>
                    </div>
                </div>
            
        
                <div style = {{ flexGrow : 1, height : "15%" }}>
                    <ChatInputPart
                        active_conversation = { active_conversation }
                        value = { this.state.chat_input }
                        on_change = { ( e ) => this.setState({chat_input : e.target.value}) }
                        on_key_down = { this.handle_key_down }
                        u_id = { u_id }
                        t = { t }
                    />
                </div>
            </div>

            
            
            
            
            
            
            // <div style = {{display : "flex", flexDirection : "column", justifyContent : "space-between", overflow : "auto"}}>
                
            //     <div style = {{ height : "90%", overflow : "hidden" }}> {/* 90% of the right side */}
            //         <MessageFeedPart
            //             active_conversation = { active_conversation }
            //             u_id = { u_id }
            //             alert = { alert }
            //             set_clicked_conversation = { this.props.set_clicked_conversation }
            //         />                
            //     </div>

            //     <div style = {{ height : "10%", display : "flex", flex : 1, alignItems : "center", padding : "10px 0" }}>
            //         <ChatInputPart
            //             active_conversation = { active_conversation }
            //             value = { this.state.chat_input }
            //             on_change = { ( e ) => this.setState({chat_input : e.target.value}) }
            //             on_key_down = { this.handle_key_down }
            //             u_id = { u_id }
            //             t = { t }
            //         />
            //     </div>
            // </div>
        )
    }
}



const mapStateToProps = (state , ownProps) => {
    return {
      conversations : state.chat.conversations,
      active_conversation_id : state.chat.active_conversation_id,
      active_conversation : state.chat.active_conversation
    }
}
  
const mapDispatchToProps = dispatch => bindActionCreators(
      {
          set_conversations,
          set_messages,
          set_conversation_id,
          sort_conversations
      },
      dispatch,
)


export default (
      withAlert()(
        connect(mapStateToProps, mapDispatchToProps)(
          withTranslation()(
            withStyles(styles)(
                MessageFeed
            )
          )
        )
      )
);