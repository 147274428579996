import React from "react";
import { load_messages, get_all_unread, get_receiver } from "../../logic/chat_logic";
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { ListItem, ListItemIcon, Badge, Avatar, ListItemText, Typography } from "@material-ui/core";

import theme from "../../assets/styles/theme";

import { bindActionCreators } from "redux";
import { withAlert } from 'react-alert';
import { format_date } from "../../functions/date_functions";
import { connect } from "react-redux";
class ConversationListItem extends React.Component{
    constructor(props){
        super(props);
    }
    on_click = () => {
        const { convo, alert } = this.props;
        setTimeout(() => {
            load_messages(convo , alert); 
            this.props.set_clicked_conversation(true);
        }, 100)
    }
    render(){
        const { convo, u_id, active_conversation, t } = this.props;

        let m_r_m = convo.messages[convo.messages.length - 1];
        let is_me = m_r_m.sender_id === u_id; 
        let is_selected = ( convo.conversation_id === active_conversation.conversation_id );
        let n_unread = get_all_unread(convo, u_id)
        let receiver = get_receiver(convo.participants, u_id, t);

        let display = m_r_m.text;

        let conversation_intent_type = ( convo.owner_id === u_id ) ? "SELLER" : "BUYER"; // First message will always be sent by the person that wishes to buy the product

        if(m_r_m.file_path){
            display = (
                <div style = {{display : "inline-flex", alignItems : "center"}}>
                    {m_r_m.file_name}
                    <FileIcon style = {{marginLeft : 10, fontSize : "1.25em"}}/>
                </div>
            );
        }

        return(
            <ListItem button selected = { is_selected } style = {{ borderRadius : 10, marginTop : 20}} onClick = { this.on_click }>                                    
                
                <ListItemIcon>
                    <Badge badgeContent={n_unread} max = {9} overlap = "circle" color="primary">
                        <Avatar>{ receiver.name[0] }</Avatar>
                    </Badge>        
                </ListItemIcon>

                <ListItemText
                    primary = { 
                    <React.Fragment>
                        <Typography component="span" style = {{display : "inline"}} color="textPrimary">
                        { convo.title }
                        </Typography>
                        
                        <div style = {{ display : "flex", alignItems : "center", flexDirection : "column", float : "right", fontFamily : "0.7em" }}>
                            <Typography variant = "subtitle2" color = "textSecondary" style = {{}}>
                                { format_date(m_r_m.timestamp, t) }
                            </Typography>
                            <Typography variant = "subtitle2" style = {{ color : conversation_intent_type === "BUYER" ? theme.PRIMARY_COLOR : theme.GREEN_COLOR }}>
                                { conversation_intent_type === "BUYER" ? "Köper" : "Säljer" }
                            </Typography>
                        </div>

                        </React.Fragment>
                        
                    }
                    secondary={
                        <React.Fragment>
                        <Typography component="span" variant="body2" style = {{ display : "inline", ...styles.max_height }} color="textPrimary">
                            { 
                                is_me ? 
                                t('CHAT:you') : convo.participants[m_r_m.sender_id].name
                            }
                            {" - "}
                            <span style = {{ color : "#757575" }}>{display}</span>
                        </Typography>
                        
                        </React.Fragment>
                    }
                />
            </ListItem>
        )
    }
}

const styles = {
    max_height : {
        display: "block", /* or inline-block */
        textOverflow: "ellipsis",
        wordWrap: "break-word",
        overflow: "hidden",
        maxHeight: "2.8em",
        lineHeight: "1.4em",
        fontSize : "0.9em"
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
      active_conversation_id : state.chat.active_conversation_id,
      active_conversation : state.chat.active_conversation,
    }
  }
  
  const mapDispatchToProps = dispatch => bindActionCreators(
      {
      },
      dispatch,
  )


export default (
      withAlert()(
        connect(mapStateToProps, mapDispatchToProps)(
            ConversationListItem
        )
      )
);

// export default ConversationListItem;