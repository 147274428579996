import {
    SET_UID
} from "../actions/authentication_actions.js"
  
const GlobalInitialState = {
    u_id : undefined
};
  
export default function AuthenticationReducer(state = GlobalInitialState, action) {

      switch(action.type) {
        case SET_UID:
          return {
            ...state,
            u_id : action.payload
          };
          
        default :
          return state;
      }
    }