import React from "react";

import { Button, Typography, Paper, IconButton, Badge, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

import { store } from "../../store";
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { FaBoxOpen, FaCreditCard } from "react-icons/fa";
import AddIcon from "@material-ui/icons/AddCircle"
import InfoIcon from '@material-ui/icons/Info';



const useStyles = makeStyles( theme => ({
    container: {
        // flex : 1,
        display : "flex",
        flexDirection : "column",
        padding : 40,
        // minWidth : "70%"
    }, 
    title_container : {
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection : "column",
            alignItems : "flex-start",
        }
    },
    btn : {
        [theme.breakpoints.down('sm')]: {
            marginTop : 10
        }
    }
}));

function NoBoxes(props){
    
    const { history } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    // let n_tokens = store.getState().global.n_tokens;
    const { n_tokens } = props;

    return(
            <Paper className = { classes.container }>
                <div className = { classes.title_container }>
                    <div>
                        <h1 style = {{ marginBottom : 5 }}>{t('MY_BOXES:no_boxes_welcome_title')}</h1>
                        <Typography variant = "subtitle1" >{t('DASHBOARD_CHART:no_boxes')}</Typography>
                    </div>
                    {/* <Button  className = { classes.btn } onClick = { () => history.push("/app/add-box") } variant = "contained" color = "primary"> { t('BOX_ACTIONS:register_box') } </Button> */}
                </div>
                {/* <ul className = {  }>
                    <li><Typography variant = "subtitle1" component = "p">{t('MY_BOXES:no_boxes_welcome_text')}</Typography></li>
                    <li><Typography variant = "subtitle1" component = "p" style = {{marginTop : 10}}>{t('MY_BOXES:no_boxes_text', {n_left : n_tokens})}</Typography></li>
                    <li><Typography variant = "subtitle1" component = "p" style = {{marginTop : 10}}>{t('MY_BOXES:no_boxes_welcome_support_text', {n_left : n_tokens})}</Typography></li>
                </ul> */}
                <List className={classes.root} style = {{ flex : 1 }}>
                    <ListItem button onClick = { () => { history.push('/app/add-box') } } style = {{ borderRadius : 5 }}> 
                        <ListItemIcon>
                            <Tooltip title = { t('MY_BOXES:no_boxes_text', {n_left : n_tokens}) }>
                                <Badge badgeContent = {3} color = "primary">
                                    <FaBoxOpen style = {{ fontSize : 22 }} />
                                </Badge>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-wifi" primary={ t('ADD_BOX_SCREEN:add_your_first_box') } />
                    </ListItem>

                    <ListItem button  onClick = { () => { history.push('/app/pricing') } } style = {{ borderRadius : 5 }}>
                        <ListItemIcon>
                            <FaCreditCard style = {{ fontSize : 19, marginLeft : 3 }}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary = { t('ADD_BOX_SCREEN:upgrade_for_more_tokens') }/>
                    </ListItem>

                    <ListItem button  onClick = { () => { history.push('/app/FAQ') } } style = {{ borderRadius : 5 }}>
                        <ListItemIcon>
                            <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary = { t('ADD_BOX_SCREEN:learn_more') }/>
                    </ListItem>

                    </List>
            </Paper>
    )
}

const mapStateToProps = (state , ownProps) => {
    return {
      n_tokens  : state.global.n_tokens,
    }
}

export default connect(mapStateToProps)(NoBoxes);