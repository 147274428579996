export default (theme) => ({
    flexGrow: 1,
    flex : 1,
    // width : "100%",
    display : "flex",
    justifyContent : "center",
    alignItems : "center",
    overflow: 'auto',
    minHeight : `100vh`,
    backgroundImage: `url("/img/banner_bg.png")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow : "hidden !important",
    padding : theme.spacing(4),
    
    [theme.breakpoints.up('sm')]: {
    minHeight : `calc(100vh - 80px)`,
    }
})