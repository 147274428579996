import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import AppFrame from "../components/AppFrame";
import { connect } from 'react-redux'
import { find_box_by_number, async_remove_client_and_db_box } from '../logic/box_logic';

import queryString from 'query-string';

import MapContainer from "../components/BoxPage/MapContainer";

import { open_item_dialog } from "../components/dialogs/AddItemDialog";

import PageLoadingLottie from "../components/PageLoadingLottie";

import { withTranslation, useTranslation } from 'react-i18next';


import { withAlert } from 'react-alert'

import ip from "../constants/ip"
import { set_item_dialog, set_ays } from '../actions/dialog_actions';
import { bindActionCreators } from 'redux';

import { BoxInfoTopPart } from '../components/BoxPage/BoxInfoTopPart';
import { deep_date_sort } from '../functions/sorting_functions';
import BoxMiddlePart from '../components/BoxPage/BoxMiddlePart';
import { prepare_socket_action } from '../logic/action_socket_logic';

import ItemsForSalePie from '../components/Graphs/ItemsForSalePie';
import ItemTable from '../components/Viritualizded/Table/ItemTable/ItemTable';




const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 280,
  },
  description : {
      marginTop : theme.spacing(1)
  }
});



class BoxPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {}
    this.classes = this.props.classes;
    this.fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);

    let params = queryString.parse(this.props.location.search);
    let box_number = params.box;
    this.box_number = box_number;
  }

  async componentWillMount(){
      const { boxes } = this.props;
      if( typeof boxes === "object" ){ this.init_box(boxes); }
  }

  open_file_selector = () => { this.refs.fileUploader.click() }

  open_edit_form = (item) => {
    this.setState({ clicked_item : item }, () => {
      this.open_item_form();
    })
  }

  change_box_img = async() => {
    let file = document.getElementById('box_update_img_file').files[0];
    let jwt = localStorage.getItem('jwt');

    let box = find_box_by_number(this.props.boxes, this.box_number)
    let box_id = box.box_id;

    let form_data = new FormData();
    form_data.append('jwt', jwt);
    form_data.append('img', file);
    form_data.append('box_id', box_id);

    let response = await fetch(`${ip}/storager/set_requests/update_box_img.php`, {
      method : "POST",
      body : form_data
    });

    let json_data = await response.json();
    if(json_data.success){
      prepare_socket_action("UPDATE_BOX", {
        box_id : box_id,
        box_data : {images : json_data.images }, //Where box data is replacement data
      });
    } 
  }

  init_box = (boxes) => {
    const { t, history, alert } = this.props;
    if(boxes){
      let box = find_box_by_number( boxes, this.box_number );
      
      if(box === undefined){
        alert.info( t('ERRORS:INVALID_BOX_NUMBER') );
        history.replace("/app/my-boxes");
        return;
      }else{
        this.setState({ box })
      }
      document.title = "Qboxr - " + box.box_title;
    }
  }

  handle_open_item_dialog = () => {
    open_item_dialog({
      action_type : 'ADD',
      item : undefined, 
    });
  }

  handle_double_click = () => {
    alert("Double");
  }

  componentWillReceiveProps (nextprops){
    if(typeof nextprops.boxes === "object"){
      this.init_box(nextprops.boxes);
    }
  }

  render(){
  const {classes, t, history, boxes, conversations} = this.props; //Inherit styles from higher order component
  
  const { box } = this.state;

  if(boxes === undefined || conversations === undefined){
    return <AppFrame><PageLoadingLottie/></AppFrame>
  }
  
  if(box === undefined){
    return <AppFrame><PageLoadingLottie/></AppFrame>
  }

  let has_img = box.images.length > 0;
  let img = has_img ? box.images[0].url : "/img/qboxr.png";
  let sorted_items = deep_date_sort(box.items).reverse();

  let hi = sorted_items.length > 0;

  return (
    <AppFrame>
        { 
        (box !== undefined) ? 
        <>
          <Grid container spacing={3}>

            {/* Top left */}
            <Grid item xs={12} md = { hi ? 6 : 12}>
              <BoxInfoTopPart
                classes = { classes }
                fixedHeightPaper = { this.fixedHeightPaper }
                t = { t }
                box = { box }
              />
            </Grid>

            {/* Top middle */}
            {/* <Grid item xs={12} md={3} lg={3}>
              <Tooltip title = { t('FILE_TEXT:pick_file') + " / " +  t('ADD_ITEM_DIALOG:double_to_remove') } aria-label = { t('FILE_TEXT:pick_file') + "/" +  t('ADD_ITEM_DIALOG:double_to_remove') }>
                <Paper className={this.fixedHeightPaper} style = {{cursor : "pointer"}} onClick = { this.open_file_selector } >
                  <input type="file" accept="image/*" id="box_update_img_file" ref="fileUploader" style={{display: "none"}} onChange = { this.change_box_img }/>
                  <img alt = "Boximage" src = { img } onDoubleClick = { this.handle_double_click }  style = {{width : "100%", height : "100%", maxHeight : "100%", maxWidth : "100%",  borderRadius : 5, objectFit : "fill"}}/>
                </Paper>
              </Tooltip>
            </Grid> */}

            {/* Map top right */}
            {/* <Grid item xs={12} md={3} lg={3}>
                <Paper className={this.fixedHeightPaper}>
                  <MapContainer cords = {{lat : box.location.lat, lng : box.location.lng}}/>
                  {
                    !box.location.has_location ? 
                    <Typography variant = "subtitle1" style = {{margin : "0 10px" , textAlign : "center", fontSize : "0.75em"}}>No location</Typography> : null
                  }
                </Paper>
            </Grid> */}
            {
            hi ?
            <Grid item xs={12} md={6}>
              <Paper className={this.fixedHeightPaper} style = {{ display : "flex", flexDirection : "column", justifyContent : "center", alignItems : "center" }}>
                <h2 style = {{ marginTop : -5, marginBottom : 0 }}>{t('MY_SHOP:items_for_sale')}</h2>
                <ItemsForSalePie items = { box.items } t = { t }/>
              </Paper>
            </Grid> : null
            }



            {/* Middle part */}
            <Grid item container spacing = {3}>
              <BoxMiddlePart
                items = { sorted_items }
                history = { history }
                box = { box }
                conversations = { this.props.conversations }
                t = { t }
              />
            </Grid>

            {/* Item grid */}
            {/* {
              (box.items && box.items.length > 0) ?
              <Grid item  lg = {12}>
                  <CardLayout
                    data = { sorted_items }
                    title = { t('ADD_BOX_PAGE:items') }
                    card_type = { "BOX_ITEM" }
                    history = { history }
                  />
              </Grid> : null
            } */}

            <Grid item xs = {12}>
              <ItemTable data = { sorted_items } alert = { alert } t = {t} />
            </Grid>


          </Grid>
        </>
        :
        <PageLoadingLottie/>
      }
    </AppFrame>
  );
  }
}

const mapStateToProps = (state , ownProps) => {
  return {
    boxes : state.products.boxes,
    conversations : state.chat.conversations
  }
}


const mapDispatchToProps = dispatch => bindActionCreators (
  {
    set_item_dialog
  },
  dispatch,
)

export default (
    withAlert()(
      connect(mapStateToProps, mapDispatchToProps)(
        withTranslation()(
          withStyles(styles)(
              BoxPage 
          )
        )
      )
    )
);