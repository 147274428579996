import React, { useState } from 'react';
// import { XAxis, YAxis, Label, ResponsiveContainer, BarChart, Bar, LineChart, Line} from 'recharts';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import Title from './title';

import custom_theme from "../assets/styles/theme";
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import theme from '../assets/styles/theme';
import { PIE_COLORS } from './Graphs/ItemsForSalePie';

export default function Chart(props) {

  const { t } = useTranslation();

  let generate_chart_data = () => {
    let instance = JSON.parse(JSON.stringify(props.boxes))
    instance.sort(function(a, b){
      return a.items.length <= b.items.length;
    });

    
    let chart_data = instance.map( (box) => {
      const n_items_for_sale = box.items.reduce( (tot, item) => (
        tot + item.is_for_sale
      ), 0)
      return({ 
        name: box.box_title,
        [t('MY_SHOP:not_for_sale')] : box.items.length,
        [t('MY_SHOP:for_sale')] : n_items_for_sale,
        // [t('MY_SHOP:not_for_sale')] :  (box.items.length - box.items.reduce(function (total, currentValue) {
        //   return total + currentValue.is_for_sale;
        // }, 0) )
        
      });
    });
    return chart_data;
  }
  

  let data = generate_chart_data(props.boxes);

  if(data.length === 0){
    for(let i = 0; i < 20; i++){
      data.push({ name: "", items : Math.floor( Math.random() * 100 )});
    }
  }

  const [state_data, set_state_data] = useState(data);
  const [has_dismissed, set_dismissed] = useState(false);

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <BarChart
          data={state_data}
          margin = {{ left : -16, top : 10, bottom : 10 }}
        >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis />
          {/* <Tooltip /> */}
          <Legend />
          <Bar dataKey = { t('MY_SHOP:not_for_sale') } stackId="a" fill={PIE_COLORS[0]} />
          <Bar dataKey = { t('MY_SHOP:for_sale') } stackId="a" fill={PIE_COLORS[1]} />
        </BarChart>
      </ResponsiveContainer>

      {
        (!has_dismissed && props.boxes.length === 0) ? 
        <div style = {{display : "flex", justifyContent : "space-between", alignItems : "center", margin : "5px 0"}}>
          <div>
            <Typography style = {{fontSize : "1em"}}>{t('DASHBOARD_CHART:no_boxes')}</Typography>
            <Typography style = {{fontSize : "0.8em", marginTop : 5}}>{t('DASHBOARD_CHART:example_text')}</Typography>
          </div>
          <Button variant = "contained" color = "primary" onClick = {() => {
            set_state_data([0,0,0,0,0,0,0,]);
            set_dismissed(true);
          }}>
            { t('DASHBOARD_CHART:hide') }
          </Button>
        </div> : null
      }    
    </React.Fragment>
  );
}