import { makeStyles } from "@material-ui/core";

const login_styles = (theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%'
    },
    grid: {
      // height: '100vh',
    },
    quoteContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    quote: {
      // backgroundColor: theme.palette.neutral,
      // height: '100%',
      // display: 'flex',
      // justifyContent: 'center',
      // backgroundImage: 'url(/img/woman_picking.jpg)',
      // alignItems: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center'
    },
    quoteInner: {
      textAlign: 'center',
      flexBasis: '600px'
    },
    quoteText: {
      color: theme.palette.white,
      fontWeight: 300
    },
    name: {
      marginTop: theme.spacing(3),
      color: theme.palette.white
    },
    
    bio: { color: theme.palette.white },

    contentContainer: {},
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    contentHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(5),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    logoImage: {
      marginLeft: theme.spacing(4)
    },
    contentBody: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    },
    backcontainer : {
      display : "flex", alignItems : "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection : "column"
      }
    },
    form: {
      // paddingLeft: 100,
      // paddingRight: 100,
      // paddingBottom: 125,
      // flex : 1,
      width : "70%",
      // marginLeft : "15%",
      padding : 20,
      // flexBasis: 700,
      [theme.breakpoints.down('sm')]: {
        width : "100%",
        marginLeft : 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    title: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),      
    },
    socialButtons: {
      marginTop: theme.spacing(3)
    },
    socialIcon: {
      marginRight: theme.spacing(1)
    },
    sugestion: {
      marginTop: theme.spacing(2)
    },
    textField: {
      marginTop: theme.spacing(2)
    },
    signInButton: {
      margin: theme.spacing(2, 0)
    }
  });

export default login_styles;