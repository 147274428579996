import React from "react";

import { Button, Typography, Paper, Tooltip, Badge } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

import { store } from "../../store";
import { useTranslation } from "react-i18next";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { FaBoxOpen, FaCreditCard } from "react-icons/fa";
import AddIcon from "@material-ui/icons/AddCircle"
import InfoIcon from '@material-ui/icons/Info';


const useStyles = makeStyles( theme => ({
    container: {
        display : "flex",
        flexDirection : "column",
        padding : 40
    }, 
    title_container : {
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection : "column",
            alignItems : "flex-start",
        }
    }
}));

export default function NoItemsForSale(props){
    
    const { history } = props;

    const { t } = useTranslation();
    const classes = useStyles();

    let n_tokens = store.getState().global.n_tokens;

    return(
            <Paper className = { classes.container }>
                <div className = { classes.title_container }>
                    <div>
                        <h1 style = {{ marginBot : 5 }}>{t('MY_SHOP:get_started')}</h1>
                        <Typography variant = "subtitle1" >{t('MY_SHOP:no_items_text')}</Typography>
                    </div>
                    <Button  onClick = { () => history.push("/app/my-boxes") } variant = "contained" color = "primary"> { t('DASHBOARD_SIDE_BAR:my_boxes') } </Button>
                </div>

                <ul>
                    <li><Typography variant = "subtitle1" component = "p">{t('MY_SHOP:why_sell')}</Typography></li>
                    <li>
                        <Typography variant = "subtitle1" component = "p" style = {{marginTop : 10}}>{t('MY_SHOP:no_items_text_help')}</Typography>
                        <ul>
                            <li>{t('MY_SHOP:register_location')}</li>
                            <li>{t('MY_SHOP:checkbox_text')}</li>
                        </ul>
                    </li>
                    {/* <li><Typography variant = "subtitle1" component = "p" style = {{marginTop : 10}}>{t('MY_BOXES:no_boxes_welcome_support_text', {n_left : n_tokens})}</Typography></li> */}
                </ul>
                {/* <List className={classes.root} style = {{ flex : 1 }}>
                    <ListItem button onClick = { () => { history.push('/app/add-box') } } style = {{ borderRadius : 5 }}> 
                        <ListItemIcon>
                            <Tooltip title = { t('MY_BOXES:no_boxes_text', {n_left : n_tokens}) }>
                                <Badge badgeContent = {3} color = "primary">
                                    <FaBoxOpen style = {{ fontSize : 22 }} />
                                </Badge>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-wifi" primary={ t('ADD_BOX_SCREEN:add_your_first_box') } />
                    </ListItem>

                    <ListItem button  onClick = { () => { history.push('/app/add-box') } } style = {{ borderRadius : 5 }}>
                        <ListItemIcon>
                            <FaCreditCard style = {{ fontSize : 19, marginLeft : 3 }}/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary = { t('ADD_BOX_SCREEN:upgrade_for_more_tokens') }/>
                    </ListItem>

                    <ListItem button  onClick = { () => { history.push('/app/FAQ') } } style = {{ borderRadius : 5 }}>
                        <ListItemIcon>
                            <InfoIcon/>
                        </ListItemIcon>
                        <ListItemText id="switch-list-label-bluetooth" primary = { t('ADD_BOX_SCREEN:learn_more') }/>
                    </ListItem>
                </List> */}
            </Paper>
    )
}