import React from "react";
import { withStyles, Grid, CardActionArea, makeStyles, useTheme } from "@material-ui/core";
import HIW from "../../components/public/HIW.js";
import custom_styles from "../../assets/styles/public_styles"
import HeaderPart from "../../components/public/HeaderPart";
import { withTranslation } from 'react-i18next';
import AppFrame from "../../components/AppFrame.js";
import { Footer } from "../../components/Footer.js";
import { ImageAndText } from "../../components/public/ImageAndText.js";
import { PublicButtonContainer } from "../../components/public/PublicButtonContainer.js";

class LandingPage extends React.Component{
    constructor(props){
        super(props);
    }   

    componentWillMount(){
        const { t } = this.props;
        window.scrollTo(0, 0);
        document.title = t('TITLES:home')
    }

    render(){
        const {classes} = this.props;
        
        const { t } = this.props;

        return(
            <AppFrame no_spacing>
                <div className = {classes.container}>
                    <HeaderPart
                        title  = { t('header_title') }
                        text   = { t('header_text') }
                        history = {this.props.history}
                        classes = {classes}
                    />

                    <ImageAndText
                        title = { t('what_we_offer_title') }
                        text  = { t('what_we_offer_text') }
                        animation_data = { require("../../assets/animations/some_box_anim.json") }
                    />

                    <ImageAndText
                        title = { t('safe_data_title') }
                        text  = { t('safe_data_text') }
                        animation_data = { require("../../assets/animations/safe_animation_server.json") }
                        odd = {true}
                    />

                    <div className = {classes.organize} style = {{flexDirection : "column"}}>
                        <ImageAndText
                            title = { t('how_it_works_title') }
                            text  = { t('how_it_works_text') }  
                        />
                        
                        <Grid container spacing={5} className = {`${classes.HIW_container}`}>
                            <Grid item xs={12} sm = {6} md={4}>
                                <HIW
                                    lottie = {require("../../assets/animations/file_printer.json")}
                                    title  = {t('how_it_works_step_one_title')}
                                    number = {1}
                                />    
                            </Grid>
                                
                            <Grid item xs={12} sm = {6} md={4}>
                                <HIW
                                    lottie = {require("../../assets/animations/yellow_qr.json")}
                                    title  = {t('how_it_works_step_two_title')}
                                    number = {2}   
                                />
                            </Grid>
                            <Grid item xs={12} sm = {12} md={4}>
                                <HIW
                                    lottie = {require("../../assets/animations/find_stuff.json")}
                                    title  = {t('how_it_works_step_three_title')}
                                    number = {3}
                                />
                            </Grid>
                        </Grid>

                    </div>

                    <ImageAndText
                        title = { t('A_U:see_how_it_works_title') }
                        text  = { t('A_U:see_how_it_works_text') }
                        component = {
                            <div className= {classes.wrapper}>
                                {/* <iframe className = { classes.player } width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe> */}
                                <iframe className = { classes.player } width="100%" height="100%" src="/videos/PromoVideo.mp4" frameborder="0" allowfullscreen></iframe>
                            </div>
                        }
                    />

                    <ImageAndText
                        animation_data = { require("../../assets/animations/cross_platform.json") }
                        title = { t('P_P:cross_platform_title') }
                        text =  {t('P_P:cross_platform_text')}
                        buttons = {
                            <PublicButtonContainer>
                                <CardActionArea className = {classes.amount_display} style = {{borderRadius : 10, flex : 1}}>
                                    <img alt = "" src = "img/appstore_logo.png" className = {classes.brand_icon}/>
                                </CardActionArea>

                                <CardActionArea className = {classes.amount_display} style = {{marginLeft : 30, borderRadius : 10, flex : 1}}>
                                    <img alt = "" src = "img/android_logo.png" className = {classes.brand_icon}/>
                                </CardActionArea>
                            </PublicButtonContainer>
                        }
                        hide_on_small
                    />

                    <Footer/>

                </div>
            </AppFrame>
        )
    }
}

const styles = (theme) => ({
    ...custom_styles(theme),
    HIW_container : {
        display : "flex",
        flexDirection : "row",
        justifyContent : "space-between",
        flexWrap : "wrap",
        paddingLeft : 70,
        paddingRight : 70,
        [theme.breakpoints.down('xs')]: {
            padding : 30,
            flexDirection : "column"
        },
    },

    wrapper : {
        position: "relative",
        flex : 1,
        borderRadius : 20,
        overflow : "hidden",
        backgroundColor : "black",

        minHeight: "200px",
        width: "100%",
        backgroundColor: "red",

        [theme.breakpoints.down('sm')]: {
            marginTop : theme.spacing(4),
        },
    },  
    player : {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    }
});

export default withStyles(styles)(withTranslation(['L_P'])(LandingPage));