import { init_action_socket, jwt_to_id } from "./action_socket_logic";
import { fetch_boxes } from "./box_logic";

import {store} from "../store";

import action_socket from "./action_socket_logic"
import ip from "../constants/ip";
import { set_n_tokens, set_user_subscription, set_subscription_loaded, set_iso_data, set_global_reducer_value } from "../actions/global_actions";
import { public_page_routes } from "../App";
import { set_uid } from "../actions/authentication_actions";
import { set_conversation, set_conversations, sort_conversations } from "../actions/chat_actions";
import { find_conversation } from "./chat_logic";
import queryString from 'query-string';


export const loadScript = (src) => (
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script);
    }
))
export const LoadCSS = (cssURL ) => (
    new Promise( function( resolve, reject ) {
        var link = document.createElement( 'link' );
        link.rel  = 'stylesheet';
        link.href = cssURL;
        document.head.appendChild( link );
        link.onload = function() { 
            resolve(); 
            console.log( 'CSS has loaded!' ); 
        };
    })
)


export async function init_subscription(t, alert){
    let subscription_response = await api_call_based_on_jwt("/payments/get_subscription.php");
    if(subscription_response.success){
        if(subscription_response.has_subscription){
            store.dispatch( set_user_subscription(subscription_response.subscription_data) );
        }else{
            //..
        }
        store.dispatch( set_subscription_loaded(true) );

    }else{
        alert.error(t('ERRORS:' + subscription_response.error_code));
    }
}

export async function init_tokens(t, alert){
    let tokens_response = await api_call_based_on_jwt("/get_requests/get_user_tokens.php");

    if(tokens_response.success){
        store.dispatch( set_n_tokens(tokens_response.n_tokens) );
    }else{
        alert.error(t('ERRORS:' + tokens_response.error_code));
    }
}

export async function init_uid(){
    let u_id = await jwt_to_id();
    store.dispatch( set_uid(u_id) );
}

export async function init_chat(){
    let jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/chat/get_user_conversations.php`, {
        method : 'POST',
        body : JSON.stringify({
            jwt : jwt,
            conversation_ids : store.getState().chat.conversations.map( obj => obj.conversation_id )
        })
    });
    let json_data = await response.json();
    let new_convo_array = [ ...store.getState().chat.conversations, ...json_data.conversations ];
    store.dispatch( set_conversations(new_convo_array) );
    
    return json_data.conversations; // Not all of the conversations just the array that was returned from the API call. Used to see if we should keep calling
}

async function init_customer(){
    let response = await fetch(`${ip}/storager/get_requests/init/get_customer_info.php`);
    let ip_data  = await response.json();

    // const code = ip_data.geoplugin_currencyCode ? ip_data.geoplugin_currencyCode : "usd";
    // const symbol = ip_data.geoplugin_currencySymbol ? ip_data.geoplugin_currencySymbol : "$";

    // store.dispatch(set_iso_data({ }));

    store.dispatch( set_global_reducer_value('iso_data', ip_data.iso_data) );
    store.dispatch( set_global_reducer_value('user_location', ip_data.location) );

    //We now already have an estimated IP-track, we can now ask the user if he wants to give us his/her location
    try_to_set_location( (obj) => set_global_reducer_value('user_location', ip_data.location) )
}

export default async function init_app(t, alert){

    // let jwt = localStorage.getItem('jwt');
    // let has_cookie_consent = localStorage.getItem('cookie_consent');
    
    // let params = queryString.parse(window.location.search);

    // if(!jwt && (params && typeof params.jwt === "string" )){
    //     localStorage.setItem('jwt', params.jwt);
    //     jwt = params.jwt;   
        
    // }

    // if( !(jwt || has_cookie_consent) ){ return }

    // if(jwt === null){
    //     init_customer();
    //     return;
    // }

    // // alert.info("Will init User");
    // let pathname = window.location.pathname;
    // if( jwt && ( pathname === "" || pathname === "/" ) ){
    //     window.location.replace("/app/dashboard"); //Not ideal since we are not using router here but good for now
    // }

    // init_customer();
    // init_subscription(t, alert);
    // init_tokens(t, alert);
    // fetch_boxes();
    // init_chat();
    // await init_uid();
    // init_action_socket();
}

async function get_user_information_based_on_jwt(file){
    let jwt = localStorage.getItem('jwt');
    // console.log("JWT : " + jwt);
    let response =  await fetch(`${ip}/storager/${file}`,{
        headers : {
            "Body-Type"    : "application/json",
            "Accept"       : "application/json",
            "Content-Type" : "application/json"
        },
        method : "POST",
        body : JSON.stringify({
            jwt : jwt
        })
    });
    let json_data = await response.json();
    return json_data;
}

export async function api_call_based_on_jwt(file, data){
    let jwt = localStorage.getItem('jwt');

    let data_set = {
        jwt : jwt,
    }
    if(typeof data === "object"){
        data_set = {...data_set, ...data};
    }

    let response =  await fetch(`${ip}/storager/${file}`,{
        method : "POST",
        body : JSON.stringify(data_set)
    });
    let json_data = await response.json();
    return json_data;
}



export function try_to_set_location(t, alert) { 
    function success(position) {
      const lat  = position.coords.latitude;
      const lng = position.coords.longitude;
      store.dispatch( set_global_reducer_value('user_location', {lat, lng}) );
      if( t && alert ){
          alert.info( t('G:location_updated') );
      }
    }
  
    function error() {
        // alert("Location could not be fetched");
    }
  
    if (!navigator.geolocation) { alert("Your browser does not support geolocation support") }
    else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  
  }
