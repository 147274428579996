import React from "react";
import { Grid, withWidth, withStyles, Typography } from "@material-ui/core";
import BoxItemCard from "../components/BoxPage/BoxItemCard";
import ItemCard from "../components/shop/ItemCard";

import { connect } from "react-redux";
import BoxCard from "../components/BoxCard";
import theme from "../assets/styles/theme";

const n_spacing = 1;

const styles = (theme) => ({
    /* When openened */
    open_big_left : {
        padding : `${theme.spacing(n_spacing)}px 0`,
        paddingRight : theme.spacing(n_spacing) 
    },
    open_big_right : {
        padding : `${theme.spacing(n_spacing)}px 0`,
        paddingLeft : theme.spacing(n_spacing) 
    },

    /* On Big not opened */
    closed_normal_left : {
        paddingTop : theme.spacing(n_spacing),
        paddingBottom : theme.spacing(n_spacing),
        paddingRight : theme.spacing(n_spacing)
    },
    closed_normal : {
        paddingTop : theme.spacing(n_spacing),
        paddingBottom : theme.spacing(n_spacing),
        // paddingLeft : theme.spacing( n_spacing ),
        paddingRight : theme.spacing( n_spacing )
    },
    closed_normal_right : {
        paddingTop : theme.spacing(n_spacing),
        paddingBottom : theme.spacing(n_spacing),
        // paddingLeft : theme.spacing(n_spacing),
    },
    // marg : {
    //     padding : 10
    // },

    /* Small */
    small : {
        padding : theme.spacing(n_spacing),
        paddingLeft : 0,
        paddingRight : 0
    }
})

class ShopLayout extends React.PureComponent{
    constructor(props){
        super(props);
    }

    shouldComponentUpdate(nextprops, nextstate){
        return JSON.stringify(nextprops.data) !== JSON.stringify(this.props.data)
    }

    get_shop_cards = () => {
        const { history, side_bar_open } = this.props;
        return this.props.data.map( (item, i) => {
            return (
                <ItemCard
                  key = { i }
                  item = { item }
                  history = { history }
                />
            )   
        });
    }

    get_item_cards = () => {
        const { history } = this.props;
        return this.props.data.map( (item, i) => {
            return (
                <BoxItemCard
                  key = { i }
                  item = { item }
                  history = { history }
                />
            )   
        });
    }

    get_box_cards = () => {
        const { history } = this.props;
        return this.props.data.map( (box, i) => {
            return (
                <BoxCard
                    key = { i }
                    box = { box }
                    history = { history }
                />
            )   
        });
        
    }

    get_card = ( card_type, data_obj ) => {
        const { history } = this.props;
        let card;
        switch (card_type) {
            case "SHOP_CARD":
                card =  <ItemCard item = { data_obj } history = { history } />
                break;
            case "BOX_ITEM":
                card =  <BoxItemCard  item = { data_obj } history = { history } />
                break;
            case "BOX":
                card =  <BoxCard box = { data_obj } history = { history } />
                break;
            default:
                break;
        }

        return card;
    }

    getClassName = (n) => {
        const { width, side_bar_open, classes } = this.props;
        // let number;
        // if(width === 'xl' || width === 'lg'){
        //     if(side_bar_open){ // Will be 6 
        //         return n % 2 === 0 ? classes.open_big_right : classes.open_big_left
        //     }else{ // Will be 4
        //         number = n % 4; // We know that there will be three
        //         if(number === 1){
        //             return classes.closed_normal_left;
        //         }
        //         if(number === 2 || number === 3){
        //             return classes.closed_normal;
        //         }
        //         return classes.closed_normal_right;
        //     }
        // }else if( width === 'md'){
        //     return n % 3 === 0 ? classes.open_big_right : classes.open_big_left
        // }else{
        //     return classes.small;
        // }

    }

    render(){
        const { history, card_type, data, side_bar_open, width } = this.props;

        return(
            <div>
                <Grid item xs = {12}>
                    { (this.props.small_title) ?
                    <h2 style = {{color : theme.PRIMARY_COLOR}}>{this.props.title}</h2> : <h1>{ this.props.title }</h1>
                    }
                    { this.props.text ? <Typography variant = "subtitle2" style = {{ marginBottom : 10 }}>{this.props.text}</Typography> : null }
                </Grid>

                <Grid container item xs = {12} spacing = { 2 }>
                    {
                        data.map( (data, i) => {
                            return(
                                <Grid 
                                    item 
                                    xs = { 12 } 
                                    sm = { 6 }
                                    md = { 4 } 
                                    lg = { (side_bar_open) ? 4 : 3 } 
                                    className = {  this.getClassName(i + 1) }
                                    key = { String(i) } 
                                >
                                    { this.get_card(card_type, data) }
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state , ownProps) => {
    return {
      side_bar_open : state.components.side_bar_opened
    }
  }
  

export default (
    connect(mapStateToProps)(
        // withWidth()(
            withStyles(styles)(
                ShopLayout
            )
        // )
    )
);
