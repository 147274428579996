import React, { PureComponent } from "react";
import { get_receiver, load_messages } from "../../logic/chat_logic";
import { Chatbubble } from "./ChatBubble";
import { Hidden, Avatar } from "@material-ui/core";

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import {deep_message_sort } from "../../functions/sorting_functions";

const styles = ( theme ) => ({
    conversation_header : {
        display : "flex", 
        alignItems : "center", 
        borderBottom : "0.5px solid lightgray",
        padding : "20px 0",
        [theme.breakpoints.up('sm')]: {
            padding : "25px",
        },
    },
    avatar : {
        [theme.breakpoints.down('xs')]: {
            padding : "0",
        },
    }
})

class MessageFeedPart extends React.Component{
    constructor(props){
        super(props);
    }

    scrollToBottom = () => {
        setTimeout(() => { //The 25 millisecond delay is because the imgaes takes some time to render
            this.messages_end.scrollIntoView({ behavior: "smooth" });
        }, 25);
    }

    componentWillReceiveProps(nextprops){
        const { active_conversation, alert } = this.props;
        if(!this.props.active_conversation && nextprops.active_conversation){
            load_messages(active_conversation, alert);
        }
    }

    componentDidMount() {
        this.scrollToBottom();
    }
    
    componentDidUpdate() {
        this.scrollToBottom();
    }

    render(){
        const { classes, t, u_id, active_conversation } = this.props;

        const reciever = get_receiver(active_conversation.participants, u_id, t);
        return(
            <div style = {{ display : "flex", flexDirection : "column", height : "100%", overflow : "hidden"}}>

                <div className = { classes.conversation_header }>
                    <Hidden smUp>
                        <ArrowBackIcon color = "primary"  style = {{margin : "0 10px", fontSize : 20}} onClick = { () => this.props.set_clicked_conversation(false) } />
                    </Hidden>
                    <Avatar className = { classes }> { reciever.name[0] } </Avatar>
                    <h2 style = {{padding : 0, margin : 0, marginLeft : 20}}>{active_conversation.title}</h2>
                </div>

                {/* The actual conversation */}
                <div style = {{padding : 20, overflow : "auto", flexGrow : 1}}>
                    {
                        deep_message_sort(active_conversation.messages).map( msg => {
                            return (
                                <Chatbubble 
                                    msg = { msg }
                                    u_id = { u_id }
                                    participants = { active_conversation.participants }
                                />
                            )
                        })
                    }
                    {/* THE 10% IS TO ADD SOME INNER SPACING IN SCROLL */}
                    <div style={{ float:"left", clear: "both", }}
                        ref={(el) => { this.messages_end = el; }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default (
    withStyles(styles)(
        MessageFeedPart
    )
)