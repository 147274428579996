import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, Avatar, Typography } from '@material-ui/core';

import ShareIcon from "@material-ui/icons/Share"
import InfoIcon from "@material-ui/icons/Info"
import EditIcon from "@material-ui/icons/Edit";


import { format_date } from '../../functions/date_functions';
import { withTranslation } from 'react-i18next';

import GeneralCard from '../shop/GeneralCard';

import OpenIcon from "@material-ui/icons/OpenInBrowser";
import DeleteIcon from "@material-ui/icons/Delete";
import { f_a_c } from '../../functions/money_functions';
import { store } from '../../store';
import { set_ays, set_dialog_status, set_item_dialog, update_dialog } from '../../actions/dialog_actions';
import { async_remove_client_and_db_item } from '../../logic/box_logic';

import { useAlert } from 'react-alert'

import { open_item_dialog } from "../dialogs/AddItemDialog";

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ShareButton } from '../shop/ItemCard';
import { handle_folder_item_click } from './FolderList';
import { update_item } from '../../actions/box_actions';
import ip from '../../constants/ip';
import { GiMoneyStack } from 'react-icons/gi';
import theme from '../../assets/styles/theme';
import { PlusOne } from '@material-ui/icons';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { register_item_sale } from '../../logic/shop_logic';


export async function handle_remove_click(item, t, alert){
    store.dispatch(set_ays({
        title : t('G:remove') + " " + item.title,
        text  : t('REMOVE_ITEM_DIALOG:are_you_sure', {item_name : item.title}),
        on_click : () => {
            async_remove_client_and_db_item(item, alert, () => { store.dispatch(set_dialog_status('ays', false)) })
        },
        accept_text : t('ITEM_CARD:remove_item'),
        no_icon : false,
    }));
    store.dispatch(set_dialog_status('ays', true));   
}

export async function handle_mark_as_sold_click(item, t, alert){
    store.dispatch(set_ays({
        title : t('MARK_AS_SOLD_DIALOG:btn'),
        on_click : async() => {
            let success = await register_item_sale(item, store.getState().dialogs.ays.form.n);
            if(success){ store.dispatch(set_dialog_status('ays', false)); 
            }else{ alert.info(t('ERRORS:INVALID_UPDATE')) }
        },
        content : () => (
            <div style = {{ display : "flex", flexDirection : "column"}}>
                <Typography variant = "subtitle1" style = {{ marginTop : -20 }}>{ t('MARK_AS_SOLD_DIALOG:register_sale') + " : " + item.title }</Typography>
                
                <Typography variant = "subtitle2" style = {{ marginTop : 20}}>{ t('MARK_AS_SOLD_DIALOG:price_a_piece') + " : " + f_a_c(item.price, item.iso_code)}</Typography>

                <Typography variant = "subtitle2" style = {{ marginTop : 20}}>{ t('MARK_AS_SOLD_DIALOG:how_many') }</Typography>
                
                <div style = {{ display : "flex", justifyContent : "space-between", alignItems : "center" }}>
                    <Typography variant = "subtitle1" style = {{ }}>{ store.getState().dialogs.ays.form.n + "/" + item.n_of_item }</Typography>                
    
                    <div style = {{ display : "flex" }}>
                        <IconButton onClick = { () => {
                            const n = store.getState().dialogs.ays.form.n;
                            store.dispatch(update_dialog('ays', {
                                form : { n : (n - 1 < 1) ? n : store.getState().dialogs.ays.form.n - 1 }
                            }))
                        }}>
                            <FaMinusCircle/>
                        </IconButton>
                        <IconButton onClick = { () => {
                            const n = store.getState().dialogs.ays.form.n;
                            store.dispatch(update_dialog('ays', {
                                form : { n : (n + 1 > item.n_of_item) ? n : store.getState().dialogs.ays.form.n + 1 }
                            }))
                        }}>
                            <FaPlusCircle/>
                        </IconButton>
                    </div>
                </div>
                <Typography variant = "subtitle2" style = {{ marginTop : 20}}>
                    { t('MARK_AS_SOLD_DIALOG:total_price') + " : " + f_a_c(item.price * store.getState().dialogs.ays.form.n, item.iso_code) } 
                </Typography>  
            </div>
        ),
        form : {
            n : 1
        },
        accept_text : t('MARK_AS_SOLD_DIALOG:btn'),
        icon : () => <GiMoneyStack style = {{ fontSize : 24, marginLeft : 5 }} />,
    }));
    store.dispatch(set_dialog_status('ays', true));  
}


function TopIcons(props){
  const { t, item, history, loading } = props;
  const alert  = useAlert();

  let edit_event = () => { !loading ? open_item_dialog({ action_type : 'EDIT', item : item, }) : alert.info(t('ERRORS:ITEM_STILL_LOADING')) };

  return(
    <Fragment>
      <Tooltip title = { t('ITEM_CARD:delete') } aria-label = { t('ITEM_CARD:delete') }>
        <IconButton onClick = { () => handle_remove_click(item, t, alert) } >
            <DeleteIcon/>
        </IconButton>
      </Tooltip>

      <Tooltip title = { t('ITEM_CARD:edit') } aria-label = { t('ITEM_CARD:edit') }>
        <IconButton onClick = {edit_event} >
            <EditIcon/>
        </IconButton>
      </Tooltip>

        {
        (item.is_for_sale) ?
            <Tooltip title = { t('MARK_AS_SOLD_DIALOG:btn') } aria-label = { t('ITEM_CARD:edit') }>
                <IconButton onClick = { () => handle_mark_as_sold_click(item, t, alert)} >
                    <GiMoneyStack style = {{ fontSize : 24  /*color : theme.GREEN_COLOR*/}}/>
                </IconButton>
            </Tooltip>
        : null
        }

  </Fragment>
  )
};

function BottomIcons(props){
  const { t, item, history } = props;
  return(
  <Fragment>
      { item.is_for_sale ? <ShareButton item = {item } t = { t }  /> : null }

      <Tooltip title = { "Information" } aria-label = { "Information" }>
          <IconButton>
              <InfoIcon />
          </IconButton>
      </Tooltip>

      {
      (item.is_for_sale) ?
      <Tooltip title = { t('ITEM_CARD:to_article') } aria-label = { t('ITEM_CARD:to_article') }>
        <IconButton onClick = { () => handle_folder_item_click(item, history, t) }>
            <ShoppingCartIcon/>
        </IconButton>
      </Tooltip>
      : null           
      }
  </Fragment>
  )
};



class BoxItemCard extends React.Component{
  constructor(props){
      super(props);
      this.state = {
          is_loading : false
      }
  }

  async componentWillMount(){
    const { item } = this.props;

    if(item.images.length > 0){ return }

    this.setState({ is_loading : true });

    let response = await fetch(`${ip}/storager/get_requests/get_img.php`, {
      method : "POST",
      body : JSON.stringify({ type : "ITEM", id : item.item_id })
    })
    let images = await response.json();

    let data = {
      box_id   : item.box_id,
      new_item : { ...item, images : images },
    }
    store.dispatch(update_item( data ));
    this.setState({ is_loading : false })
  } 

  render(){  
      const { item, t, history } = this.props;

      let img = item.img === "NO_IMG" ? "/img/qboxr.png" : item.img;
      let title = item.title;
      let text_parts = [
        // {variant : "subtitle2", text : "Boxnumber #" + item.box_number, color : theme.PRIMARY_COLOR},
        {variant : "subtitle2", text : item.category},
        {variant : "subtitle2", text : format_date(item.submit_date, t) },
        // {variant : "subtitle2", text : (item.is_for_sale) ? f_a_c(item.price, "sek") : t('ITEM_CARD:item_not_for_sale') },
      ]
      let bottom_text_parts = [
        {variant : "subtitle2", text : (item.is_for_sale) ? item.description : t('ITEM_CARD:no_description')},        
      ]


      return(
          <GeneralCard
              TopIcons = { () => <TopIcons {...this.props} loading = { this.state.is_loading }/> }
              BottomIcons = { BottomIcons }
              loading = { this.state.is_loading }
              title = { title }
              text_parts = { text_parts }
              bottom_text_parts = { bottom_text_parts }
              images = { item.images }
              history = { history }
              handle_img_click = { this.props.handle_img_click }
              box_number = { item.box_number }
              {
                ...this.props
              }
          />
      )
  }
}


export default(
    withTranslation()
    (
        (
            BoxItemCard
        )
    )   
)
  