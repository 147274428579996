import {store} from "../store";
import ip from "../constants/ip";

import {fetchBoxesSuccess} from '../actions/box_actions.js';
import { prepare_socket_action } from "./action_socket_logic";
import { get_cookie } from "./cookies";

export async function fetch_boxes(){
    let jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/get_requests/get_my_boxes.php`,{
            // headers : {
            //     "Content-Type" : "application/json",
            //     "Body-Type"    : "application/json",
            //     "Accept"       : "application/json"
            // },
            // credentials : "include",
            method : "POST",
            body : JSON.stringify({
                jwt : jwt
            })
    });
    
    let json_data = await response.json();
    store.dispatch(fetchBoxesSuccess(json_data));
    return json_data;
}


export function find_box(boxes , box_id){
    let box_data = undefined;
    boxes.forEach( (box, i) => { //Find it in O(n)
        if( parseInt(box.box_id) === parseInt(box_id) ){
            box_data = box;
            box_data.index = i;
        }
    });
    return box_data;
}

export function find_box_by_number(boxes , box_number){
    let box_data = undefined;
    boxes.forEach( (box, i) => { //Find it in O(n)
        if(box.box_number == box_number){ // Don't mind the types
            box_data = box;
            box_data.index = i;
        }
    });
    return box_data;
}

export async function remove_db_and_client_box(box){
    let jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/set_requests/remove_box.php`,{
        headers : {
            "Content-Type" : "application/json",
            "Body-Type"    : "application/json",
            "Accept"       : "application/json"
        },
        method : "POST",
        body : JSON.stringify({
            jwt : jwt,
            box : JSON.stringify(box)
        })
    });
    let json_data = await response.json();
    if(json_data.success == 1){
        prepare_socket_action("REMOVE_BOX", { box_id : box.box_id } );
    }
    return json_data;
}

export async function async_remove_client_and_db_item(item, alert, callback){
    // let item = this.props.item;
    let jwt = localStorage.getItem('jwt');

    let data = {item_id : item.item_id, jwt : jwt};

    let response = await fetch(`${ip}/storager/set_requests/remove_item.php`, {
        headers : {
            "Body-Type"    : "application/json",
            "Accept"       : "application/json",
            "Content-Type" : "application/json", // Request json back,
        },
        method : "POST",
        body : JSON.stringify( data )
    });
    let json_data = await response.json();

    if(json_data.success === 1){
        prepare_socket_action("REMOVE_ITEM", { item });
        callback(false);
    }else{
        alert.error("ERRORS:" + json_data.error_code);
    }
}

export async function async_remove_client_and_db_box(box_id, set_open_status){
    let jwt = localStorage.getItem('jwt');
    let data = {box_id, jwt};

    let response = await fetch(`${ip}/storager/set_requests/remove_box.php`, {
        method : "POST",
        body : JSON.stringify( data )
    });
    let json_data = await response.json();
    if(json_data.success == 1){
        prepare_socket_action("REMOVE_BOX", { box_id : box_id });
        prepare_socket_action("INCREMENT_TOKENS", {});
        set_open_status(false);
    }
}