import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


import TextLink from '@material-ui/core/Link';
import { Link } from "react-router-dom";


import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';

import AppFrame from "../components/AppFrame";


import queryString from 'query-string';

import ip from '../constants/ip';
import { CssBaseline, Avatar, TextField, Button } from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { withAlert } from 'react-alert'
import { withTranslation } from 'react-i18next';
import login_styles from '../assets/styles/login_styles';



class ResetPasswordPage extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      password : '',
      password_re : ''
    }
  }

  
    async componentWillMount(){
        //await init_app();

        const { alert, t } = this.props;
            
        let location = this.props.location.search;
        let params = queryString.parse(location);

        this.hash = params.hash;

        let response = await fetch(`${ip}/storager/authentication/set_new_password.php`, {
            method : "POST",
            body : JSON.stringify({
                hash : this.hash,
                do_change : false
            })
        });
        let json_data = await response.json();
        if(json_data.success === 0){
            alert.error( t(`RESET_PASSWORD_ERRORS:INVALID_HASH`) );
            this.props.history.replace("/sign-in");
        }
  }

  componentDidMount(){
    const { t } = this.props;
    document.title = t('TITLES:reset_password')
  }

  submit_form = async() => {
    const { alert, t } = this.props;
    if(this.state.password === '' || this.state.password_re === ''){
        alert.error( t('SIGN_UP_ERRORS:FILL_ALL_FIELDS') );
        return;
    }
    if(this.state.password !== this.state.password_re){
        alert.error( t('SIGN_UP_ERRORS:PASSWORDS_NOT_THE_SAME') );
        return;
    }
    let response = await fetch(`${ip}/storager/authentication/set_new_password.php`, {
        method : "POST",
        body : JSON.stringify({
            new_password : this.state.password,
            new_password_re : this.state.password_re,
            hash : this.hash,
            do_change : true
        })
    });

    let json_data = await response.json();
    
    if(json_data.success === 0){
        alert.error( t(`RESET_PASSWORD_ERRORS:${json_data.ERROR_CODE}`) );
        this.setState({
            password : '',
            password_re : ''
        });
    }else{
        this.props.history.replace("/sign-in");            
        alert.success( t('RESET_PASSWORD:successfully_updated') );
    }
    
  }

  render(){
    const {classes, t} = this.props; //Inherit styles from higher order component
  
    return (
    <AppFrame>
        <Container component="main" maxWidth="xs" className = {classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                { t('RESET_PASSWORD:title') }
            </Typography>
            <div className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="new_password"
                type="password"
                label = { t('RESET_PASSWORD:new_password_placeholder') }
                name="new_password"
                autoComplete="new_password"
                autoFocus
                value = {this.state.password}
                onChange = {(e) => this.setState({password : e.target.value})}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="new_password_re"
                label = { t('RESET_PASSWORD:new_password_re_placeholder') }
                name="new_password_re"
                autoComplete="new_password_re"
                type="password"
                value = {this.state.password_re}
                onChange = {(e) => this.setState({password_re : e.target.value})}
            />
            <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick = {this.submit_form}
            >
            { t('RESET_PASSWORD:button_title') }
            </Button>

            <Grid container>
                <Grid item xs>
                <Link to = "/sign-in" variant="body2">
                    <TextLink>
                        { t('G:sign_in') }
                    </TextLink>
                </Link>
                </Grid>
                <Grid item>
                <Link to = "/sign-up" variant="body2">
                    <TextLink>
                        { t('SIGN_IN:dont_have_account_link') }
                    </TextLink>
                </Link>
                </Grid>
            </Grid>
            </div>
        </div>
        </Container>
    </AppFrame>
  );
  }
}


// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BoxPage)));
export default (
    withTranslation()(   
        withAlert()(
            withStyles(login_styles)(ResetPasswordPage)
        )
    )
);