import React from "react";
import { withStyles, CardActionArea } from "@material-ui/core";


import Lottie from 'react-lottie';
import Navbar from "../../components/public/Navbar.js";

import HeaderPart from "../../components/public/HeaderPart";

import public_styles from "../../assets/styles/public_styles";
import { withTranslation } from "react-i18next";
import AppFrame from "../../components/AppFrame.js";
import { ImageAndText } from "../../components/public/ImageAndText.js";
import { PublicButtonContainer } from "../../components/public/PublicButtonContainer.js";

let defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/some_anim.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
};


class ProductPage extends React.Component{

    componentDidMount(){
        const { t } = this.props;
        window.scrollTo(0, 0);
        document.title = t('TITLES:about_us')
    }

    render(){
        const {classes, t} = this.props;
        return(

            <AppFrame no_spacing>
                
                <HeaderPart
                    animation_data = {require("../../assets/animations/about_us.json")}
                    title  = { t('product_title') }
                    text   = { t('product_text') } 
                    history = {this.props.history}
                    classes = {classes}
                />

       
                <ImageAndText
                    animation_data = { require("../../assets/animations/cross_platform.json") }
                    title = { t('P_P:cross_platform_title') }
                    text =  {t('P_P:cross_platform_text')}
                    buttons = {
                        <PublicButtonContainer>
                            <CardActionArea className = {classes.amount_display} style = {{borderRadius : 10, flex : 1}}>
                                <img alt = "" src = "img/appstore_logo.png" className = {classes.brand_icon}/>
                            </CardActionArea>

                            <CardActionArea className = {classes.amount_display} style = {{marginLeft : 30, borderRadius : 10, flex : 1}}>
                                <img alt = "Android" src = "img/android_logo.png" className = {classes.brand_icon}/>
                            </CardActionArea>
                        </PublicButtonContainer>
                    }
                    hide_on_small
                />

            </AppFrame>
        )
    }
}

const styles = (theme) => ({
    ...public_styles(theme),
});

export default withStyles(styles)(withTranslation(['P_P'])(ProductPage));