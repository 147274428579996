import React, { Fragment } from "react";

import {  withTranslation } from 'react-i18next';
import { IconButton, Tooltip, } from "@material-ui/core";


import ShareIcon from "@material-ui/icons/Share"
import PlusIcon from "@material-ui/icons/AddCircle";
import InfoIcon from "@material-ui/icons/Info"

import PrintIcon from "@material-ui/icons/LocalPrintshop";

import OpenIcon from "@material-ui/icons/OpenInBrowser";

import { enable_print_opportunity } from "../screens/MyBoxes";
import generate_custom_html from "../assets/html/generate_html_label";
import GeneralCard from "./shop/GeneralCard";
import { rectangle_label_css, create_rectangle_label } from "../assets/html/generate_new_html";
import { open_item_dialog } from "./dialogs/AddItemDialog";
import { set_dialog } from "../actions/dialog_actions";

import ip from "../constants/ip";

export async function create_label_sheet(boxes){  
    let response = await fetch(`${ip}/storager/labels/generate_labels.php`, {
      method : "POST",
      body : JSON.stringify({ boxes : boxes })
    });
    let text = await response.text();
    return text;
}


export async function open_print(boxes, t){
    let html = await create_label_sheet(boxes);

    enable_print_opportunity(html, t);

    // enable_print_opportunity(
    //     // generate_custom_html(
    //     //     {
    //     //         label_title : box.box_title,
    //     //         label_text  : box.box_description,
    //     //         box_number  : box.box_number,
    //     //         box_id      : box.box_id,
    //     //         no_include_media : true
    //     //     },
    //     //     t
    //     // )  
    //     `${rectangle_label_css}
    //     <div style = "width : 10cm; height : 5cm;">
    //     ${ create_rectangle_label(box, t)}
    //     </div>
    //     `
    // )
}

function TopIcons(props){
    const { t, box, history } = props;
    return(
    <Fragment>
        <Tooltip title = { t('BOX_ACTIONS:add_item') } aria-label = { t('BOX_ACTIONS:add_item') }>
            <IconButton onClick = { () => {
                open_item_dialog({
                    action_type : 'ADD',
                    box_id : box.box_id,
                    item : undefined, 
                });
            }}>
                <PlusIcon/>
            </IconButton>
        </Tooltip>

        <Tooltip title = { t('BOX_ACTIONS:print_label') } aria-label = { t('BOX_ACTIONS:print_label') }>
            <IconButton onClick = { () => open_print( [ box ] , t) }>
                <PrintIcon/>
            </IconButton>
        </Tooltip>


        <Tooltip title = { t('BOX_ACTIONS:open_box') } aria-label = { t('BOX_ACTIONS:open_box') }>
            <IconButton onClick = { () => {history.push( `/app/box-page?box=${box.box_number}`)}}>
                <OpenIcon/>
            </IconButton>
        </Tooltip>
    </Fragment>
    )
};

function BottomIcons(props){
    const { t, box } = props;
    return(
    <Fragment>
        <Tooltip title = { t('ITEM_CARD:share') } aria-label = { t('ITEM_CARD:share') }>
            <IconButton>
                <ShareIcon />
            </IconButton>
        </Tooltip>

        <Tooltip title = { t('ITEM_CARD:share') } aria-label = { t('ITEM_CARD:share') }>
            <IconButton>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    </Fragment>
    )
};



class BoxCard extends React.Component{

    constructor(props){
        super(props);
    }
    render(){  
        const { box, t, classes, history } = this.props;

        let title = box.box_title;
        let text_parts = [
            {variant : "subtitle1", text : box.location.name},
            {variant : "subtitle2", text : t('PLURALS:itemInterval', {count : box.items.length, postProcess: 'interval'})},
        ]
        let bottom_text_parts = [
            {variant : "subtitle2", text : (box.box_description && box.box_description !== "undefined") ? box.box_description : t('ITEM_CARD:no_description')},
        ]

        return(
            <GeneralCard
                box = { box }
                type = "BoxCard"
                TopIcons = { TopIcons }
                BottomIcons = { BottomIcons }
                title = { title }
                text_parts = { text_parts }
                bottom_text_parts = { bottom_text_parts }
                images = { box.images }
                history = { history }
                handle_img_click = { this.props.handle_img_click }
                box_number = { box.box_number }
            />
        )
    }
}

export default (
    withTranslation()
    (
        (
            BoxCard
        )
    )
)