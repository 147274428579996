import { store } from "../store";

import { add_box_item, remove_box_item, add_client_box, update_client_box, remove_client_box, update_item } from "../actions/box_actions";

import io from "socket.io-client";

import {action_server_ip} from "../constants/ip"; 
import { decrement_tokens , set_n_tokens, set_user_subscription, increment_tokens } from "../actions/global_actions";
import { api_call_based_on_jwt } from "./init_logic";
import { add_message, sort_conversations } from "../actions/chat_actions";

let action_socket = io( action_server_ip );
export default action_socket;


export async function jwt_to_id(){
  let response = await api_call_based_on_jwt('/get_requests/jwt_to_id.php');
  return response.u_id;
}

export async function prepare_socket_action(action_type, data){

    console.log("Action prepare called with ");
    console.log(action_type);

    let action_set = {};
    let u_id = store.getState().authentication.u_id;//await jwt_to_id();
    let jwt = localStorage.getItem('jwt');
    
    action_set.u_id = u_id;
    action_set.jwt  = jwt;
    action_set.data = data;
    action_set.action_type = action_type;

    action_socket.emit("redux_action", action_set);
}

export async function prepare_chat_action(action_type, data){

  let action_set = {}; 
  // let u_id = await jwt_to_id();
  let u_id = store.getState().authentication.u_id;
  let jwt = localStorage.getItem('jwt');

  action_set.u_id = u_id;
  action_set.jwt  = jwt;
  action_set.data = data;
  action_set.action_type = action_type;

  action_socket.emit("chat_action", action_set);
}



export function update_socket_id(){
  let jwt = localStorage.getItem('jwt');
  let u_id = store.getState().authentication.u_id;
  action_socket.emit('add_client', u_id);
}

export function init_action_socket() {

    action_socket = io(action_server_ip);//, { secure: true, reconnect: true, rejectUnauthorized : false });
    
    action_socket.on('add_client', async function(){
      let u_id = store.getState().authentication.u_id;
      if(u_id === undefined){ u_id = await jwt_to_id(); }
      action_socket.emit('add_client', u_id );
    });

    action_socket.on('send_socket_id', async(socket_id) => {
      let set = localStorage.setItem('socket_id', socket_id);
    });
    
    //Chat
    action_socket.on('chat_action', async(action_set) => {
      switch(action_set.action_type) {
        case "ADD_MESSAGE" :
            let message_to_add = action_set.data; //Make it clear
            console.log("SHOULD ADD THIS MESSAGe");
            console.log(message_to_add);
            store.dispatch(add_message(message_to_add))
          break;
          
        case "SCROLL_TO_BOTTOM" : 
            let scroll_function = action_set.data; //there will basically be a function in the data parameter... Define it and call it
            scroll_function();
          break;
      }
    });






    /* Redux action */
    action_socket.on('redux_action', async(action_set) => { //Will push to all clients currently logged in on the same user id... Infinite ammount of clients
        let data = action_set.data;
        // alert("redux_action came in");
        switch (action_set.action_type) {
        case "ADD_ITEM":
            console.log("Should add item to the store");
            store.dispatch(add_box_item(data.box_id, data.form_data));
          break;
        
        case "REMOVE_ITEM":
            store.dispatch(remove_box_item(data.item));
          break;

        case "UPDATE_ITEM":
            store.dispatch(update_item( data ));
          break;
        
        case "ADD_BOX":
            store.dispatch(add_client_box(data.box_data));
            store.dispatch(decrement_tokens({ }));
          break;
        
        case "UPDATE_BOX":
            store.dispatch(update_client_box(data.box_id, data.box_data));
          break;
        
        
        case "SET_SUBSCRIPTION":
            store.dispatch( set_user_subscription(data.subscription_data) );
          break;

        case "SET_N_TOKENS":
            store.dispatch( set_n_tokens(data.n_tokens));
          break;

        case "DECREMENT_TOKENS":
            store.dispatch(decrement_tokens());
          break;
      
        case "INCREMENT_TOKENS":
          store.dispatch(increment_tokens());
        break;
        
        case "REMOVE_BOX":
            store.dispatch(remove_client_box(data.box_id)); //data = box_id
        default:
          break;
      }
    });

    // window.action_socket = action_socket;
}