export default {
    USD : {
        month : {
            BASIC : 2,
            PRO   : 3,
            MASTER : 4,
            CORPORATE : 5
        },
        year : {
            BASIC : 20,
            PRO   : 30,
            MASTER : 40,
            CORPORATE : 50
        }
    },
    EUR : {
        month : {
            BASIC : 2,
            PRO   : 3,
            MASTER : 4,
            CORPORATE : 5
        },
        year : {
            BASIC : 20,
            PRO   : 30,
            MASTER : 40,
            CORPORATE : 50
        }
    },
    SEK : {
        month : {
            BASIC : 20,
            PRO   : 30,
            MASTER : 40,
            CORPORATE : 50
        },
        year : {
            BASIC : 200,
            PRO   : 300,
            MASTER : 400,
            CORPORATE : 500
        }
    }
}