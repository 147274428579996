import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import { withAlert } from 'react-alert'
import { withTranslation } from "react-i18next";
import { withWidth, IconButton } from "@material-ui/core";

import Transition from "./Transition";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkIcon from '@material-ui/icons/Link';


class ShareDialog extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const { t, item, alert } = this.props;
        const full_screen = this.props.width === "xs";

        if(!item){
            return null
        }

        return(
            <Dialog
                open={this.props.open_status}
                onClose = { this.props.close_modal } //{ () => { this.props.set_open_status(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
                fullScreen = { full_screen }
                ref = { (ref) => this.dialog_ref = ref }
            >
                <DialogTitle id="alert-dialog-title">{t('ITEM_CARD:share') + " " + item.title}</DialogTitle>

                <DialogContent style = {{ display : "flex", justifyContent : "center", alignItems : "center", backgroundColor : full_screen ? "rgb(30,30,30)" : "white" }}>
                    <IconButton onClick={ () => window.open(`https://www.facebook.com/sharer/sharer.php?u=www.qboxr.com/shop/item?item=${item.item_id}`) } >
                        <FacebookIcon/>
                    </IconButton>

                    <IconButton style = {{ marginLeft : 30 }} onClick={ () => window.open(`https://twitter.com/intent/tweet?text=Qboxr%20marketplace%20http://qboxr.com/shop/item?item=${item.item_id}`) } >
                        <TwitterIcon/>                    
                    </IconButton>
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={ this.props.close_modal } color="primary">
                        { t('G:back') }
                    </Button>
                </DialogActions>
            </Dialog>

        )
    }
}

export default (
    withTranslation()
    (
        withWidth()(
            withAlert()(ShareDialog)
        )
    )
);