export const ADD_MESSAGE = "ADD_MESSAGE";

export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_MESSAGES = "SET_MESSAGES";

export const SET_CONVERSATION_ID = "SET_CONVERSATION_ID";
export const SET_CONVERSATION    = "SET_CONVERSATION";

export const ADD_CONVERSATION = "ADD_CONVERSATION";

export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";
export const SORT_CONVERSATIONS = "SORT_CONVERSATIONS";


export const add_message = (chat_obj) => ({
    type: ADD_MESSAGE,
    payload : chat_obj
});

export const set_conversations = (conversations) => ({
    type : SET_CONVERSATIONS,
    payload : conversations
});

export const set_messages = (messages) => ({
    type : SET_MESSAGES,
    payload : messages 
});

export const set_conversation_id = (id) => ({
    type : SET_CONVERSATION_ID,
    payload : id
});

export const set_conversation = (conversation) => ({
    type : SET_CONVERSATION,
    payload : conversation
});

export const add_conversation = (conversation) => ({
    type : ADD_CONVERSATION,
    payload : conversation
})


export const mark_all_as_read = (conversation_id) => ({
    type : MARK_ALL_AS_READ,
    payload : conversation_id 
});

export const sort_conversations = () => ({
    type : SORT_CONVERSATIONS,
    payload : undefined
});