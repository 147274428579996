export default (theme) => ({
    appBarSpacer: theme.mixins.toolbar, //Add some spacing for the AppBar that is position absolute
    content: { //The content in which we show our page should be the entire page
      flexGrow: 1,
      // minHeight: '100vh',
      overflow: 'auto',
    },
    container: { // Add some padding to that content to not have it stick together with the AppSideBar.
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
});