import {
    SET_SIDEBAR
} from "../actions/component_actions.js"
  
  const GlobalInitialState = {
    side_bar_opened : false, 
  };
  
  export default function ComponentReducer(state = GlobalInitialState, action) {
  
      switch(action.type) {
        case SET_SIDEBAR:
            return {
                ...state,
                side_bar_opened : action.payload
            }
  
        default :
          return state;
      }
    }