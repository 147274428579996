import {
    SET_DIALOG_OPEN_STATUS,
    SET_AYS,
    SET_DIALOG,
    UPDATE_DIALOG,
} from '../actions/dialog_actions';

const default_ays = {
    title : '',
    text  : '',
    accept_text : '',
    cancel_text : '',
    on_click : () => {},
    content : null,
    // no_icon : false,
    icon : undefined,
    form : { }
}
const default_item_dialog = {
    item : undefined,
    box_id : undefined,
    action_type : '',
}

const initialState = {
    img : {
        open_status : false,
        img : undefined,
    },

    ays : {
        open_status : false,
        ...default_ays
    },

    item_dialog : {
        open_status : false,
        box_id : undefined,
        ...default_item_dialog
    },
    box_dialog : {
        open_status : false,
        box : undefined
    },
    share_dialog : {
        open_status : false,
        item : {}
    },

    password_prompt : {
        open_status : false
    }
};
const default_states = JSON.parse(JSON.stringify(initialState));

export default function DialogReducer(state = initialState, action) {
    switch(action.type) {
      case SET_DIALOG_OPEN_STATUS:
        console.log(action.payload);
        return {
            ...state,
            [action.payload.dialog] : {
                ...state[action.payload.dialog],
                open_status : action.payload.bool
            },
        }

      case SET_AYS :
        return {
            ...state,
            ays : {
                ...default_ays,
                ...state.ays.open_status,
                ...action.payload
            }
        }
        
      case UPDATE_DIALOG :
        return {
            ...state,
            [action.payload.name] : {
                ...default_states[action.payload.name],
                ...state[action.payload.name],
                ...action.payload.obj
            }
        }

      case SET_DIALOG : {
          return {
              ...state,
              [action.payload.name] : {
                  ...default_states[action.payload.name],
                //   ...state[action.payload.name],
                  ...action.payload.obj
              }
          }
      }

    // case SET_BOX_DIALOG:
    //     return {
    //         ...state,
    //         box_dialog : {
    //             ...state.box_dialog,
    //             ...action.payload
    //         }
    //     }

    //   case SET_ITEM_DIALOG : 
    //     return {
    //         ...state,
    //         item_dialog : {
    //             ...state.item_dialog,
    //             ...action.payload
    //         }
    //     }

        default:
            return state;
    }
}