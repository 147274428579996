import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';


import ImageIcon from "@material-ui/icons/Image";
import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DeleteIcon from "@material-ui/icons/Delete";

import Dropzone from 'react-dropzone';
import ip from "../../constants/ip";
import { prepare_socket_action } from "../../logic/action_socket_logic";

const defualt_img = "https://sanitationsolutions.net/wp-content/uploads/2015/05/empty-image.png";

const styles = theme => ({
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    buttonMargin : {
        marginTop : theme.spacing(1)
    },
    drop_zone : {
        display : "flex",
        cursor : "pointer",
        backgroundColor : "rgb(230,230,230)",
        height : 150,
        justifyContent : "center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "Roboto",
        marginTop : theme.spacing(4)
    },
    formControl : {
        width : "100%",
        marginTop : theme.spacing(2)
    }
});


class ApproveModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            open_status : false
        }
    }

    toggle = () => {
        this.props.set_open_status(!this.props.open_status);
    }

    render(){   

        const {classes} = this.props;

        return(
            <Dialog
                open={this.props.open_status}
                onClose={ this.toggle }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                
                <DialogContent>

                <DialogContentText>
                    {this.props.text}
                </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                <Button variant="contained" color="default" className={classes.button} onClick = {
                    (this.props.on_cancel) ? this.props.on_cancel : this.toggle
                }>
                    { (this.props.cancel_text) ? this.props.cancel_text : "Tillbaka" }
                </Button>
                <Button variant="contained" color="default" className={classes.button} onClick = {
                    (this.props.on_accept) ? this.props.on_accept : this.toggle
                }>
                    { (this.props.accept_text) ? this.props.accept_text : "Ta bort"}
                    <DeleteIcon className={classes.rightIcon} />
                </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ApproveModal);