import React, {Component} from 'react';
import {CardElement ,injectStripe} from 'react-stripe-elements';
import { Button, Grid, Dialog, Typography, IconButton, withWidth, DialogActions, DialogTitle, DialogContent, DialogContentText} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import ip from '../../constants/ip';

import Lottie from 'react-lottie';


import custom_theme from "../../assets/styles/theme";
import ItemActionPopup from '../IconComponents/ItemActionPopup';
import { f_a_c } from '../../functions/money_functions';
import { withTranslation } from 'react-i18next';

import { withAlert } from 'react-alert'

import { prepare_socket_action } from "../../logic/action_socket_logic";

import {Elements, StripeProvider} from 'react-stripe-elements';
import Transition from '../dialogs/Transition';
import tier_prices from '../../constants/tier_prices';
import { init_subscription, api_call_based_on_jwt } from '../../logic/init_logic';
import { async } from 'q';


const styles = theme => ({
    container : {
        padding : theme.spacing(4),
    },
    card_input : {
        marginTop : theme.spacing(2),
        marginBottom : theme.spacing(2),
        padding : theme.spacing(2),
        paddingBottom : theme.spacing(2),
        backgroundColor : "rgb(245,245,245)",
        borderRadius : 10
    },
    small_text : {
        color : "gray",
        fontSize : "0.95em"
    },
    green : {
        color : custom_theme.GREEN_COLOR
    },
    red : {
        color : custom_theme.RED_COLOR
    },
    yellow : {
        color : custom_theme.YELLOW_COLOR,
        fontFamily : "bold"
    }
});


const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require("../../assets/animations/credit_cards.json"),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
}

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            payment_complete : undefined,
            payment_errors : [],
            is_proccessing : false,
            currency : "usd",
            user_subscription : undefined,
            did_grade : false,
            warning : '',
            card_details : undefined,
            stripe : null
        }

    }

    async componentWillMount(){
        if(this.props.edit){
            let card_details = await api_call_based_on_jwt('/payments/get_card_details.php');
            if(card_details.success){
                this.setState({card_details : card_details.card_details});
            }else{
                this.setState({card_details : "NONE"});
            }
        }
    }

    make_request = async(data) => {
        let file = this.props.edit ? "change_card_details.php" : "start_subscription.php";
        let response = await fetch(`${ip}/storager/payments/${file}`, {
            method: "POST",
            body: JSON.stringify( data )
        });

        let json_data = await response.json();
        return json_data;
    }

    submit = async(ev) => { 
        const {alert, t} = this.props;
        if(this.state.is_proccessing) return; //Save us the hustle of people accidently pressing twice
        let jwt = localStorage.getItem('jwt');

        let {token, error} = await this.props.stripe.createToken({ name: "Qboxr User" });
        
        if(token !== undefined){
            this.setState({is_proccessing : true});

            let response = await this.make_request({
                token_id : token.id,
                jwt : jwt,
                tier_code : this.props.tier, 
                iso_code  : this.props.iso_code,
                interval  : this.props.is_year ? "year" : "month"
            });

            let successful_payment = ( response.successful_payment || response.success );
    
            this.setState({ is_proccessing : false});

            if (successful_payment) {
                this.setState({ // Reset
                    payment_errors : [],
                    payment_complete : true,
                    did_grade : (response.text === "EDIT"), 
                });
                let alert_message;
                switch(response.action_type){
                    case 'ADD':
                        alert_message = t('PURCHASE_SUBSCRIPTION:add_success', { n_tokens : response.n_tokens })
                        break;
                    case 'EDIT':
                        alert_message = t('PURCHASE_SUBSCRIPTION:edit_success', { n_tokens : response.n_tokens })
                        break;
                    case 'CARD_UPDATE':
                        alert_message = t('PURCHASE_SUBSCRIPTION:card_update_success')
                        break;
                }
                if(!this.props.edit){ //If we have changed our subscription and not our card details...
                    init_subscription(t, alert); // Perform a fetch
                }
                prepare_socket_action("SET_N_TOKENS", { n_tokens : response.n_tokens });
                this.props.toggle_open_status();
            
            }else if(!successful_payment){
                let error = t(`ERRORS:${response.error}`, {n_to_remove : response.n_to_remove, postProcess: 'interval'})
                alert.error( error );
            }
        }else{
            //Error in the form
        }
    }

    render() {
        const {classes, t} = this.props;
        const tier_code = this.props.tier;

        let m_y = this.props.is_year ? "year" : "month";
        let tier, price, interval_short;

        if(! this.props.edit){
            tier = t(`tiers:${tier_code}`, { returnObjects : true });
            price = tier_prices[this.props.iso_code][m_y][tier_code];
            interval_short = ( this.props.is_year ) ? t('G:year_short') : t('G:month_short')
        }
        const full_screen = this.props.width === "xs";

        return (
            <Dialog
                open = {this.props.open_status}
                onClose = { this.props.toggle_open_status }
                fullWidth={"md"}
                maxWidth = {"md"}
                TransitionComponent = { Transition }
                fullScreen = { full_screen }
            >
                <DialogTitle id="alert-dialog-title">{ (!this.props.title) ? `${t('PURCHASE_SUBSCRIPTION:subscription')} | ${tier.title}`: this.props.title}</DialogTitle>
                
            
                <DialogContent>
                    {
                        (this.props.tier) ?
                        <div>
                            <Typography variant = "p" style = {{ marginTop : -40}}>{tier.description_text}</Typography>
                        </div> 
                        : null
                    }

                    {
                        <DialogContentText style = {{ marginTop : 2.5 }}>
                        {
                            (!this.props.description) ? 
                                f_a_c( price, this.props.iso_code) + "/" + interval_short 
                                :
                                this.props.description
                        }
                        </DialogContentText>
                    }

                        {/* Current Card data */}
                        {   
                            (this.props.edit && this.state.card_details) ? 
                                <Typography variant = "subtitle2" /*style = {{marginTop : 10}} */ >
                                    {   (this.state.card_details === "NONE") ? 
                                        t('EDIT_SUBSCRIPTION:no_card') : 
                                        t('EDIT_SUBSCRIPTION:current_card', { card_brand : this.state.card_details.brand, last_digits : this.state.card_details.last_digits }) 
                                    }
                                </Typography> : null
                        }
                        
                        <CardElement
                            className = {classes.card_input}
                        />


                        { (this.props.current_subscription !== undefined) ? 
                            <div>
                                <Typography variant = "body2" style = {{ color : "orange" }}>{ t('PURCHASE_SUBSCRIPTION:has_sub_warning') }</Typography>
                                {
                                    (this.props.warning) ? 
                                    <Typography type = "span" className = {`${classes.small_text}`}>{this.props.warning}</Typography> : 
                                    <div style = {{display : "flex", justifyContent : "flex-start"}}>
                                        <Lottie
                                            options = {{...defaultOptions, animationData: require("../../assets/animations/calc_loading.json")}}
                                            height = {50}
                                            width = {50}
                                            style = {{marginTop : 10}}
                                        />
                                    </div>
                                }
                            </div> : null
                        }
                    </DialogContent>

                    <DialogActions style = {{ display : "flex", alignItems : "center",  marginBottom : 10,
                    justifyContent : ( this.state.is_proccessing ) ? "space-between" : "flex-end",
                    }}>
                        {full_screen && !this.state.is_proccessing ? 
                            <Button onClick = { this.props.toggle_open_status } variant="contained" color="default" className={classes.button}>
                                { t('G:back') }
                            </Button> : null
                        }
                        {
                            this.state.is_proccessing ? 
                            <div style = {{ marginLeft : 10 }}><Lottie options={defaultOptions} height={30} width={30}/></div>: null
                        }
                        <Button onClick={this.submit} variant = "contained">
                            {
                                (!this.props.button_text) ? 
                                t('PURCHASE_SUBSCRIPTION:pay') + ` ${ f_a_c( price, this.props.iso_code) + "/" + interval_short }` 
                                : this.props.button_text    
                            }
                        </Button>

                    </DialogActions>
            </Dialog>
        );
    }
}



export default (
    withStyles(styles)
    (
        withAlert()
        (
            withTranslation()
            (
                withWidth()(
                    injectStripe(CheckoutForm)
                )
            )
        )
    )    
);


