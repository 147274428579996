import React from "react";
import ip, { api_base } from "../../constants/ip";
import TextFormModal from "./TextFormModal";
import { withTranslation } from "react-i18next";

import { withAlert } from 'react-alert'
import { validate_email } from "../../functions/validations_functions";
import { api_call_based_on_jwt } from "../../logic/init_logic";

class ContactUsModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            subject : 'Vill prata',
            name : 'albin',
            email : 'albinwarme@live.se',
            message : 'Hej hej'
        }
    }

    async componentWillMount(){
        // if(localStorage.getItem('jwt')){
        //     let user_data = await api_call_based_on_jwt('/get_requests/get_user_data.php');
        //     user_data = user_data.user_data;
            
        //     this.setState({
        //         email : user_data.email,
        //         name : user_data.name,
        //     });
        // }
    }
    

    validate_input(){
        let success = true;
        
        let error_messages = [];
        let required = [ "email", "subject", "name", "message" ];

        for(let i = 0; i < required.length; i++){
            if(this.state[required[i]] === ''){
                success = false;
                error_messages.push('SIGN_IN_ERRORS:FORM_INCOMPLETE');
                break;
            }
        }

        if(!validate_email(this.state.email)){
            success = false;
            error_messages.push('SIGN_UP_ERRORS:INVALID_EMAIL_FORMAT');
        }

        return{
          succes : success,
          error_messages : error_messages
        }
    }

    submit_form = async() => {
        const { alert, t } = this.props;
        let validation = this.validate_input();
      
        if(!validation.succes){
            validation.error_messages.forEach( err => {
              alert.info( t(err) );
            });
            return;
        }
        // let jwt = localStorage.getItem('jwt');
        // jwt = jwt ? jwt : "NOT_A_USER";

        console.log(`${api_base}/api/v1/promo/get-in-touch`);

        let response = await fetch(`${api_base}/api/v1/promo/get-in-touch`, {
            method : "POST",
            body : JSON.stringify({
                subject : this.state.subject,
                name : this.state.name,
                email : this.state.subject,
                message : this.state.message
            }),
            headers: {
                // "Body-Type"    : "application/json",
                // "Accept"       : "application/json",
                "Content-Type" : "application/json", // Request json back,
            }
        })

        if(response.status == 200)
        {
            alert.success(t('CONTACT_DIALOG:success'));
            this.props.set_open_status(false);
        }
        else{
            // alert.info( t('ERRORS:' + json_data.error_code) );
            alert.info(t('ERRORS:EMAIL_NOT_SENT'))
        }
        // let json_data = await response.json();

        // if(json_data.success == 1){
        //     alert.success(t('CONTACT_DIALOG:success'));
        //     this.props.set_open_status(false);
        // }else{
        //     alert.info( t('ERRORS:' + json_data.error_code) );
        // }
    }


    set_value = (e) => {
        this.setState({[e.target.name] : e.target.value });
    } 

    render(){
        const {t} = this.props;
        
        this.fields = [
            {
                label : t('CONTACT_US_DIALOG:subject') + " * ",
                placeholder : t('CONTACT_US_DIALOG:subject_placeholder'),
                value : this.state.subject,
                name  : "subject", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('CONTACT_US_DIALOG:name') + " * ",
                placeholder :  t('CONTACT_US_DIALOG:name_placeholder'),
                value : this.state.name,
                name  : "name", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('SIGN_IN:email_placeholder') + " * ",
                placeholder : t('SIGN_IN:email_placeholder'),
                value : this.state.email,
                name  : "email", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('CONTACT_US_DIALOG:message') + " * ",
                placeholder : t('CONTACT_US_DIALOG:message_placeholder'),
                value : this.state.message,
                name  : "message", 
                set_value : (e) => { this.set_value(e) },
                useTextArea: true,
            },
        ]
        return(
            <TextFormModal
                open_status = { this.props.open_status }
                set_open_status = { this.props.set_open_status }
                fields = { this.fields }
                title = { t('CONTACT_US_DIALOG:title') }
                text = { t('CONTACT_US_DIALOG:text') }
                button_text = { t('CONTACT_US_DIALOG:send') }
                error_messages = { this.state.error_messages }
                on_click = { this.submit_form }
            />
        )
    }
}

export default (
    withTranslation()(
        withAlert()(
            ContactUsModal
        )
    )
);