
import custom_theme from "./theme";
export default (theme) => ({
    '@global': {
        body: {
          backgroundColor: theme.palette.common.white,
          // backgroundImage: `url("/img/banner_bg.png")`,
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          // overflow : "hidden !important",
        },
      },
      container : {
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        height : "100%",

      },
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          marginTop : 0
        },
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: custom_theme.GREEN_COLOR,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
});