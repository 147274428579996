import React from "react";
import { CardActionArea } from "@material-ui/core";
import styled from "styled-components";

export const CustomCardActionArea = styled(CardActionArea)`
    border-radius : 20px;
    padding : 30px;
    width : 30%;
    font-size : 2em;
    text-align : center;
    font-weight : bold;
    background-color : rgb(250,250,250);
    border : 1px solid lightgray;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;