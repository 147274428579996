import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light : "#10BBFF",
      main : "#10BBFF",
      dark : "#10BBFF",
      contrastText: '#fff',
    },
    white : "white"
  },
});

export default theme;