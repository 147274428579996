import React, { lazy } from "react";

import { withTranslation } from 'react-i18next';
import { Card, CardMedia, CardContent, Typography, IconButton, CardActions, Tooltip, Collapse, Badge, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";


import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { set_dialog_status, set_image, set_dialog } from "../../actions/dialog_actions";
import theme from "../../assets/styles/theme";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import clsx from 'clsx';
// import * as loadImage from 'blueimp-load-image'
import loadImage from 'blueimp-load-image'

export function get_oriented_img(imageUrl){
  let return_data = imageUrl;

  if(imageUrl === "NO_IMG"){ return "/img/qboxr.png"; }
  
  loadImage(
    imageUrl,
    (img, data) => {
      console.log(img.toDataURL());
      if (img.type === "error") {
        return_data = "/imgs/Qboxr_logo.png";
        console.error("Error loading image " + imageUrl);
      } else {
        return_data = img.toDataURL();
      }
    },
    { maxWidth: 600, orientation : true }
  );
  return return_data;
}

const styles = (theme) => ({
        card: {
          display: 'flex',
          flexDirection : "column"
        },
        details: {
          display: 'flex',
          flexDirection: 'row',
        },
        content: {
          // flex: '1 0 auto',
          flex : 3,
          // minWidth : 250
        },
        cover: {
          flex : 2.5,
          position : "relative",        
          [theme.breakpoints.down('sm')]: {
            minHeight : 120
          }
          // height : 151,
          // width: 151,
        },
        controls: {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        playIcon: {
          height: 38,
          width: 38,
        },
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
      
        collapsed_buttons : {
          display : "flex",
          justifyContent : "flex-end"
        }
});


class GeneralCard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            expanded : false,
            images : [],
            image_index : 0
        }
    }

    start_rotation = () => {
      //  clearInterval(this.image_index_interval);

      //   this.image_index_interval = setInterval(() => {
      //     if(this.state.image_index + 1 <= this.state.images.length - 1){
      //       this.setState({ image_index : this.state.image_index + 1 });
      //     }else{
      //       this.setState({ image_index : 0 });
      //     }
      //   }, 3500);
    }

    componentWillMount(){
      // const { images } = this.props;
      // if(images){
      //   const oriented_images = images.map( img => ( img.url) );
      //   this.setState({ images : oriented_images })
      //   this.start_rotation();
      // }
    }

    componentWillReceiveProps(nextprops){
        //We have to get the oriented message on the update fr our cards.you
        // if(nextprops.images !== this.props.images){
        //   const images = nextprops.images;
        //   const oriented_images = images.map( img => ( img.url) );
        //   this.setState({ images : oriented_images })
        //   this.start_rotation();
        // }
    }


    render(){  
        const { t, classes, loading } = this.props;
        const { TopIcons, title, text_parts, bottom_text_parts, BottomIcons, images } = this.props;
        
        const { set_dialog_status, set_image, set_dialog } = this.props;
        let expanded = this.state.expanded;

        let has_img = this.props.images.length > 0;

        let img = { url : has_img ? this.props.images[0].url : "/img/qboxr.png" }

        return(
           
            <Card className={classes.card}>
              
            <div className={classes.details}>
              <CardContent className={classes.content}>
                
                <Typography component="h6" variant="h6">
                  { title }
                </Typography>

                {
                    text_parts.map( function(t_p){
                        return <Typography variant = {t_p.variant}  color = "textSecondary">{t_p.text}</Typography>
                    })
                }
              </CardContent>
              
              <Tooltip title = { t('ITEM_CARD:see_img') } aria-label="share">
                {
                loading ?
                <div className = {classes.cover} style = {{ display : "flex", justifyContent : "center", alignItems : "center" }}>
                  <CircularProgress style = {{ alignSelf : "center" }} />
                </div>
                :
                <CardMedia
                  onClick = { () => {
                    set_dialog('img', { images : images });
                    set_dialog_status('img', true);
                  }}
                  className = {classes.cover}
                  image = { img.url }
                  style = {{ borderRadius : 5, margin : 10, cursor : "pointer" }}
                />
                }
              </Tooltip>
              </div>
      
              <CardActions disableSpacing style = {{flex : 1, justifyContent : "space-between"}}>
                
                <div>
                    <TopIcons { ...this.props } />
                </div>

                <div> 
                  {
                    (this.props.box_number) ? 
                      <span style = {{color : theme.PRIMARY_COLOR, fontSize : "0.8em", fontWeight : "bold", margin : "0 5px"}}>{ t('G:box') } #{this.props.box_number}</span>
                    : null
                  }

                  <Tooltip title = { expanded ? t('ITEM_CARD:see_less') : t('ITEM_CARD:see_more') }   aria-label = { t('ITEM_CARD:see_more') } >
                    <IconButton
                      className={clsx(classes.expand, { [classes.expandOpen]: expanded  })}
                      onClick={ () => this.setState({expanded : !this.state.expanded}) }
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>

                </div>
                
            </CardActions>
            
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph variant = "subtitle2"> { t('ITEM_CARD:information') } </Typography>
                
                {
                    bottom_text_parts.map( t_p => {
                        return <Typography inline variant = {t_p.variant}  color="textSecondary">{t_p.text}</Typography>
                    })
                }
    
                <div className = { classes.collapsed_buttons }>
                    <BottomIcons { ...this.props} />
                </div>
      
              </CardContent>
            </Collapse>
      
          </Card>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
  return {
    dialogs : state.products.dialogs,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators (
  {
    set_dialog_status,
    set_image,
    set_dialog
  },
  dispatch,
)


export default (
    connect(mapStateToProps, mapDispatchToProps)(
      withTranslation()(
          withStyles(styles)(
            // withRouter()
            (
              GeneralCard
            )
          )
      )
    )
)