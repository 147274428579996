import ip from "../constants/ip";
import { store } from "../store";
import { remove_box_item } from "../actions/box_actions";
import { prepare_socket_action } from "./action_socket_logic";



export async function register_item_visit( item_id ){
    const jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/shop/analytics/set/register_item_visit.php`,{
        method : "POST",
        body : JSON.stringify({ item_id : item_id, jwt : jwt })
    })
    let result = await response.text();
}

export async function register_item_sale(item, n){
    const jwt = localStorage.getItem('jwt');
    let response = await fetch(`${ip}/storager/shop/analytics/set/register_item_sale.php`,{
        method : "POST",
        body : JSON.stringify({ item_id : item.item_id, jwt, n })
    })
    let json_data = await response.json();
    if(json_data.success == 1){
        if(json_data.new_n == 0){
            prepare_socket_action("REMOVE_ITEM", { item });
        }else{
            prepare_socket_action( "UPDATE_ITEM", {
                box_id   : item.box_id,
                new_item : { ...item, n_of_item : json_data.new_n }
            });
        }


    }
    return json_data.success;
}