import ip from "../constants/ip";

export async function get_img(type, id){
    let response = await fetch(`${ip}/storager/get_requests/get_img.php`, {
        headers : {
            "Body-Type" : "application/json", // Send JSON
        },
        method : "POST",
        body : JSON.stringify({
            type : type,
            id : id
        })
    })
    let url = await response.text();
    return url;
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const get_end_point = (type, id) => {
    const jwt = localStorage.getItem('jwt');
    return `https://www.qboxr.com/storager/get_requests/image/img_endpoint.php?TYPE=${type}&ID=${id}${jwt ? '&auth=' + jwt : ""}`
}