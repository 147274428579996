import React from "react";
import { Paper, Container, Grid, Button, Typography } from "@material-ui/core";
import DashboardItemList from "../components/DashboardItemList";

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import queryString from 'query-string';
import AppFrame from "../components/AppFrame";

import PageLoadingLottie from "../components/PageLoadingLottie";
import { withTranslation } from "react-i18next";
import BoxItemCard from "../components/BoxPage/BoxItemCard";
import CardLayout from "../Layouts/CardLayout";
import { deep_date_sort, deep_desc_sort, deep_asc_sort } from "../functions/sorting_functions";
import theme from "../assets/styles/theme";

const styles = theme => ({

});

class SearchResults extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            items : undefined,
            categories : undefined,
            boxes : undefined
        }
    }

    componentWillMount(){
        const { boxes, history } = this.props;
        let params = queryString.parse(this.props.location.search);
        this.query_string = params.query;
        if(boxes){
            this.set_matching_results(boxes);
        }
    }

    componentWillReceiveProps(nextprops){
        
        // if(JSON.stringify(nextprops.boxes) !== JSON.stringify( this.props.boxes)){
            this.set_matching_results(nextprops.boxes);
        // }
    }

    componentDidMount(){
        const { t } = this.props;
        document.title = t('TITLES:search_results') + " - (" + this.query_string + ")"
    }

    componentDidUpdate(prevProps) {
        const { boxes } = this.props;
        let params = queryString.parse(this.props.location.search);
        if(params.query !== this.query_string){
            this.query_string = params.query;
            this.set_matching_results(boxes);
        }
    }


    set_matching_results = (boxes = this.props.boxes) => {

        let items = [];
        let categories = {}; //Will be a key value pair of merged matching results and a category as key
        let boxes_found = [];

        let is_matching_item = (item_title) => {
            let index = item_title.toUpperCase().indexOf(this.query_string.toUpperCase());
            return index;
        }
        let is_matching_category = (item_category) => {
            let index = item_category.toUpperCase().indexOf(this.query_string.toUpperCase());
            return index;//item_category.toUpperCase().includes(this.state.query_string.toUpperCase());
        }

        let is_matching_box = (box_title) => {
            return box_title.toUpperCase().indexOf(this.query_string.toUpperCase());
            // return box_title.toUpperCase().includes(this.query_string.toUpperCase());
       }

        boxes.forEach( (box, i) => {
            let box_word_index = is_matching_box(box.box_title);

            let vector = { box_index : i };

            box.items.forEach( (item, j) => {
                vector = { box_index : i, item_index : j };

                let item_word_index = is_matching_item(item.title);
                let category_word_index = is_matching_category(item.category);

                if(item_word_index > -1){
                    console.log("Item : " + item.title + " Word match " + this.query_string);
                    vector.index = item_word_index;
                    items.push( vector );
                }
                
                if(category_word_index > -1){ // If more than once category name/user merge them together here. It's for a searh i mean so make it as simple as possible
                    
                    vector.index = category_word_index;
                    (categories[ [item.category] ]) === undefined ? categories[ [item.category] ] = [vector] : categories[ [item.category] ].push(vector);
                }
            });
            
            if(box_word_index > -1){
                vector.index = box_word_index;
                boxes_found.push(vector);
            }
        });


        /*Assign matching categories*/
        let categories_array = []; //Will be a 2d array of [categories][vector]
        for(var key in categories){
            let temp_items = [];
            categories[key].forEach( (item) => {
                temp_items.push(item);
            }); 
            categories_array.push({
                title : key,
                items : temp_items
            });
        }  

        this.setState({
            items : deep_asc_sort(items, 'index'),
            categories : categories_array,
            boxes : deep_asc_sort(boxes_found, 'index')
        });
    }

    render(){
        const { t, history, boxes : prop_boxes } = this.props;
        const { items, categories, boxes } = this.state;

        if(!items || !categories || !boxes){
            return <AppFrame> <PageLoadingLottie/> </AppFrame>
        }

        let no_results = items.length === 0 && categories.length === 0 && boxes.length === 0;
        if(no_results === true){
            return(
                <AppFrame>
                    <div style = {{flex : 1, height : "100%", width : "100%", display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "center"}}>
                        <h1>Inga resultat</h1>
                        <Button color = "primary" variant = "contained" onClick = { history.goBack }>Tillbaka</Button>
                    </div>
                </AppFrame>
            )
        }

        return(
            <AppFrame>
                <Grid container spacing={3} style = {{height : "100%"}}>
                    <Grid item>
                        <h1 style = {{ padding : 0, margin : 0 }}>{t('SEARCH_RESULTS:title') } {this.query_string} </h1>
                    </Grid>
                
                    <Grid item xs={12}>
                        
                        { items.length > 0 ? 
                            <CardLayout
                                data = { items.map( vector => {
                                    console.log(vector);
                                    return prop_boxes[vector.box_index].items[vector.item_index]
                                }) }
                                title = { t('ADD_BOX_PAGE:items') }
                                history = { history }
                                card_type = "BOX_ITEM"
                            />
                            : null
                        }

                        {    
                            categories.length > 0 ? 
                                <>
                                <h1>{t('G:categories')}</h1>
                                {
                                    categories.map( (category_set, i) => (
                                            <CardLayout
                                                data = { category_set.items.map( vector => {
                                                    return prop_boxes[vector.box_index].items[vector.item_index]
                                                }) }
                                                title = { category_set.title + " (" + t('PLURALS:itemInterval', {postProcess: 'interval', count : category_set.items.length }) + ")"}
                                                small_title = { true }
                                                history = { history }
                                                card_type = "BOX_ITEM"
                                            />
                                        )
                                    )
                                }
                                </>
                            : null
                        }

                        { boxes.length > 0 ? 
                            <CardLayout
                                data = { boxes.map( (obj, i) => (
                                    prop_boxes[obj.box_index]
                                ))}
                                title = { t('P_G:Boxes') }
                                history = { history }
                                card_type = "BOX"
                            />
                            : null
                        }

                    </Grid>

                </Grid>
            </AppFrame>
        )
    }
}

const mapStateToProps = ( state ) => {
    return {
      boxes : state.products.boxes
    }
}
const mapDispatchToProps = { 

};

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(
        withTranslation()(
            SearchResults
        )
    )
);