import React from "react";
import { Button } from "@material-ui/core";

function PublicButton (props){
        return(
            <Button
                variant="contained"
                style = {{
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    paddingTop : 10,
                    paddingBottom : 10,
                    backgroundColor : "white"
                }}
                onClick = {props.onClick}
                className = {props.className}
            >
            {props.text}
            </Button>
        )
}

export default PublicButton;