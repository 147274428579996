import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { withStyles } from '@material-ui/styles';

import { withAlert } from 'react-alert'
import ip from "../../constants/ip";

import custom_theme from "../../assets/styles/theme";
import { Typography } from "@material-ui/core";
import TextFormModal from "./TextFormModal";
import { withTranslation } from "react-i18next";
import { validate_email } from "../../functions/validations_functions";
import { api_call_based_on_jwt } from "../../logic/init_logic";


class ChangePasswordDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email : '',
            name : '',
            did_update : false
        }
    }

    async componentWillMount(){
        let user_data = await api_call_based_on_jwt('/get_requests/get_user_data.php');
        user_data = user_data.user_data;
        
        this.setState({
            email : user_data.email,
            name : user_data.name,
            did_update : false
        });
    }

    validate_input(){
        const { t } = this.props;
        let success = true;
        let error_messages = [];
        
        if(!validate_email(this.state.email) ){
            error_messages.push( t('SIGN_UP_ERRORS:INVALID_EMAIL_FORMAT'));
            success = false;
        }

        return{
          succes : success,
          error_messages : error_messages
        }
    }

    submit_form = async() => {

        const { alert, t } = this.props;

        let validation = this.validate_input();
        if(!validation.succes){
            validation.error_messages.forEach( error => {
                alert.error(error);
            })       
            return;
        }
        
        let jwt = localStorage.getItem('jwt');
        let response = await fetch(`${ip}/storager/authentication/update_user_information.php`, {
            method : "POST",
            body : JSON.stringify({
                jwt              : jwt,
                name : this.state.name,
                email : this.state.email,
            })
        })
        let json_data = await response.json();
        
        if(json_data.success == 1){
            alert.success( t('UPDATE_INFORMATION_DIALOG:update_success') );
            window.location.reload();
            // this.props.set_open_status(false); // Close the modal
        }else{
            alert.error( t("ERRORS:" + json_data.error_code) );
        }

    }

    set_value = (e) => {
        this.setState({[e.target.name] : e.target.value });
    } 

    render(){
        const {t} = this.props;
        
        let fields = [
            {
                label : t('SIGN_IN:email_placeholder') + " * ",
                value : this.state.email,
                name  : "email", 
                set_value : (e) => { this.set_value(e) }
            },
            {
                label : t('UPDATE_INFORMATION_DIALOG:name'),
                value : this.state.name,
                name  : "name", 
                placeholder : t('UPDATE_INFORMATION_DIALOG:name_placeholder'),
                set_value : (e) => { this.set_value(e) }
            },
        ]
        return(
            <TextFormModal
                open_status = { this.props.open_status }
                set_open_status = { this.props.set_open_status }
                fields = { fields }
                title = { t('UPDATE_INFORMATION_DIALOG:title') }
                button_text = { t('UPDATE_INFORMATION_DIALOG:button_text')}
                error_messages = { this.state.error_messages }
                on_click = { this.submit_form }
            />
        )
    }
}

export default (
    // withStyles(styles)(
        withTranslation()(
            withAlert()(
                ChangePasswordDialog
            )
        )
    // )
);