export function format_date(date_time, t){
    const current_year = new Date().getFullYear();
   
    const exclude_time = date_time.length < 12
    const exclude_year = date_time.substring(0, 4) == current_year;

    
    //This may cause problem but it made it work and is only used for the graphs as of now
    if(exclude_time){ date_time += " 23:59:59" }

    let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
    let today = new Date();
    let date = new Date(date_time.replace(/-/g,"/"));

    let days_since_upload = Math.round(Math.abs((date.getTime() - today.getTime())/(oneDay)));

    let hours = (date.getHours() < 10) ? ( "0" + date.getHours() ) : date.getHours();
    let minutes = ( (date.getMinutes() < 10) ? "0" : "")  + date.getMinutes();
    

    if(days_since_upload === 0){ 
        return t('DATE:today') + ( exclude_time ? "" : (" " + hours + ":"  + minutes) );
    }
    if(days_since_upload === 1){ 
        return t('DATE:yesterday') + ( exclude_time ? "" : (" " + hours + ":"  + minutes) );
    }

    let monthNames = t('DATE:months', { returnObjects: true });

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = exclude_year ? '' : date.getFullYear();
    
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export function get_current_date_time(){
  let d = new Date();
  let date_time = d.toLocaleDateString();
  date_time += " " + d.getHours() + ":";
  date_time += d.getMinutes() + ":";
  date_time += d.getSeconds();
  return date_time
}

export const dates = {
  convert:function(d) {
      // Converts the date in d to a date-object. The input can be:
      //   a date object: returned without modification
      //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
      //   a number     : Interpreted as number of milliseconds
      //                  since 1 Jan 1970 (a timestamp) 
      //   a string     : Any format supported by the javascript engine, like
      //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
      //  an object     : Interpreted as an object with year, month and date
      //                  attributes.  **NOTE** month is 0-11.
      return (
          d.constructor === Date ? d :
          d.constructor === Array ? new Date(d[0],d[1],d[2]) :
          d.constructor === Number ? new Date(d) :
          d.constructor === String ? new Date(d) :
          typeof d === "object" ? new Date(d.year,d.month,d.date) :
          NaN
      );
  },
  compare:function(a,b) {
      // Compare two dates (could be of any type supported by the convert
      // function above) and returns:
      //  -1 : if a < b
      //   0 : if a = b
      //   1 : if a > b
      // NaN : if a or b is an illegal date
      // NOTE: The code inside isFinite does an assignment (=).
      return (
          isFinite(a=this.convert(a).valueOf()) &&
          isFinite(b=this.convert(b).valueOf()) ?
          (a>b)-(a<b) :
          NaN
      );
  },
  inRange:function(d,start,end) {
      // Checks if date in d is between dates in start and end.
      // Returns a boolean or NaN:
      //    true  : if d is between start and end (inclusive)
      //    false : if d is before start or after end
      //    NaN   : if one or more of the dates is illegal.
      // NOTE: The code inside isFinite does an assignment (=).
     return (
          isFinite(d=this.convert(d).valueOf()) &&
          isFinite(start=this.convert(start).valueOf()) &&
          isFinite(end=this.convert(end).valueOf()) ?
          start <= d && d <= end :
          NaN
      );
  }
}